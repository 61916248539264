import { Component, ElementRef, HostListener, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/account/login/login.component';
import { RegisterComponent } from 'src/app/account/register/register.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BaseComponent } from 'src/app/core/base.component';
import { environment } from 'src/environments/environment';
import { NavService } from '../../services/nav.service';
import { GetPageVM } from '../../classes/pages';
import { PageService } from '../../services/page.service';
import { RoleEnum } from '../../classes/role/role.enum';
import { MenuComponent } from '../../components/menu/menu.component';
import { SettingsComponent } from '../../components/settings/settings.component';
import { NgFor, NgIf } from '@angular/common';
import { LeftMenuComponent } from '../../components/left-menu/left-menu.component';



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [LeftMenuComponent, RouterLink, NgFor, NgIf, SettingsComponent, MenuComponent]
})


export class HeaderComponent extends BaseComponent implements OnInit {

  @Input() class: string;
    @Input() themeLogo: string = 'assets/images/icon/Group 5.png'; // Default Logo

    pages: GetPageVM[];
    isLoggedIn: boolean;

    stick: boolean = false;
    
  originalUrl = environment.original_lucent_url;
    userRole: RoleEnum;
  constructor(
    public navServices: NavService,
      private router: Router, private pageService: PageService,
      private modalService: NgbModal,
      private authServices: AuthService) {
    super();
  }

  ngOnInit(): void {
    document.body.style.overflowX = 'unset';
      this.closeMainMenu();

      this.pageService.onUpdateItem
          .pipe(this.takeUntilDestroy())
          .subscribe(() => {
              this.getPages();
          })

      this.getPages();
      this.getLoginState();
      this.authServices.onLoginStateChanged
          .pipe(this.takeUntilDestroy())
          .subscribe((state: boolean) => {
              if (state) {
                  const roles = this.authServices.getRole();
                  if (roles && roles.length > 0) {
                      this.userRole = this.getUserRole(roles);
                  }
              }
          });
    }
    private getPages() {
        this.pageService.getAllMenu()
            .pipe(this.takeUntilDestroy())
            .subscribe(pages => {
                
                this.pages = pages.menu;
            })
    }

    private getUserRole(roles: string[]): RoleEnum {
        if (roles.includes(RoleEnum.Admin)) {
            return RoleEnum.Admin;
        }
        if (roles.includes(RoleEnum.SalesManager)) {
            return RoleEnum.SalesManager;
        }
        if (roles.includes(RoleEnum.CustomerSupport)) {
            return RoleEnum.CustomerSupport;
        } else {
            return null; // Or any other appropriate value for non-admin users
        }
    }

    openLoginModal(): void {
        this.modalService.open(LoginComponent, {
            size: 'md',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart loginModalPopUp'
        })
    }

    openRegisterModal(): void {
        this.modalService.open(RegisterComponent, {
            size: 'md',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart loginModalPopUp'
        })
    }

    logout() {
        this.authServices.signOut()
    }

    private getLoginState() {
        this.authServices.onLoginStateChanged
            .pipe(this.takeUntilDestroy())
            .subscribe(
                (state: boolean) => this.isLoggedIn = state)
    }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.scrollY < 55)
      this.stick = false;
    else this.stick = true;
  }

  private closeMainMenu() {
    this.router.events
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
        this.navServices.mainMenuToggle = false;
      });
  }

  ngOnDestroy(): void {
    document.body.style.overflowX = 'hidden';
  }
}
