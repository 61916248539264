<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse" *ngIf="filterModel">
   
    <h3 class="collapse-block-title mt-0" (click)="collapse = !collapse">Goals</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter color-filter">
            <ul class="category-list" *ngIf="!IsMultiSelect">
                <li *ngFor="let category of filterModel.filterListValue">
                    <a [routerLink]="['/shop/products']" [queryParams]="{ category: category}">
                        {{ category | titlecase }}
                    </a>
                </li>
            </ul>

            <ng-container *ngIf="IsMultiSelect">
                <div class="custom-control custom-checkbox collection-filter-checkbox"
                     *ngFor="let value of filterModel.filterListValue; let i = index">
                    <input type="checkbox" [value]="value" [id]="standardFilterName+value"
                           [checked]="checked(value)" (change)="appliedFilter($event)" class="custom-control-input" />
                    <span *ngIf="IncludeColor" [class]="value"
                          [ngStyle]="{'background-color': colorEnum[value.toLowerCase()]}"></span>
                    <label [ngClass]="{'custom-control-label':true, 'pl-0': !IncludeColor}" for="{{ standardFilterName+value }}">
                        {{ value | titlecase }}
                    </label>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!-- End Categories List -->