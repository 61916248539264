import { Component, OnInit, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-stock-inventory',
    templateUrl: './stock-inventory.component.html',
    styleUrls: ['./stock-inventory.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class StockInventoryComponent implements OnInit {

  @Input() stock: any;

  constructor() { }

  ngOnInit(): void {
  }

}
