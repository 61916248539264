import { BaseSearchModel } from "../../../core/model/base-search-model";


export interface Coupon {
    id: number;
    title: string;
    couponCode: string;
    couponType: string;
    discountedPrice: number | null;
    discountPercentage: number | null;
    startDate: Date;
    endDate: Date | null;
    remainingUsage: number | null;
    isActive: boolean;
    couponsVariations: CouponsVariationsDTO[] | null;
}

export interface CouponsVariationsDTO {
    id: number;
    couponId: number;
    productId: number | null;
    customerId: number | null;
}

export class AddEditCoupon{
    id: number;
    title: string;
    couponCode: string;
    couponType: string;
    discountedPrice: number | null;
    discountPercentage: number | null;
    startDate: Date;
    endDate: Date | null;
    remainingUsage: number | null;
    isActive: boolean;
    productIds: number[] = []; // Initialize as an empty array
    customerIds: number[] = []; // Initialize as an empty array
}

export class CouponsSearchParamsModel extends BaseSearchModel {
}
