export interface DynamicFiltersModel {
    filterName: string;

    filterListValue: string[];
}

export interface DynamicFilterListModel {
    category: DynamicFiltersModel;
    contains: DynamicFiltersModel;

    subCategory: DynamicFiltersModel;

    color: DynamicFiltersModel;

    lenseColor: DynamicFiltersModel;

    brand: DynamicFiltersModel;

    department: DynamicFiltersModel;

    gender: DynamicFiltersModel;

    material: DynamicFiltersModel;
}

export enum DynamicFilterEnum {
    product = 'product',
    productTitle = 'productTitle',
    category = 'category',
    contains = 'contains',
    subCategory = 'subCategory',
    color = 'color',
    size = 'size',
    rating = 'rating',
    priceRange = 'priceRange',
    lenseColor = 'lenseColor',
    brand = 'brand',
    department = 'department',
    gender = 'gender',
    material = 'material',
    minPrice = 'minPrice',
    maxPrice = 'maxPrice',
    minAvailable = 'minAvailable',
    maxAvailable = 'maxAvailable',
    sortType = 'sortType',
    sortBy = 'sortBy',
    search = 'search',
    promotionId = 'promotionId',
    linesheetId = 'linesheetId',
    all = 'all',
}

export enum DynamicFilterNameEnum {
    product = 'Product',
    productTitle = 'Product Title',
    category = 'Category',
    contains = 'Contains',
    subCategory = 'Subcategory',
    color = 'Frame Color',
    lenseColor = 'Lens Color',
    brand = 'Brand',
    department = 'Department',
    gender = 'Gender',
    material = 'Material',
    minPrice = 'Min Price',
    maxPrice = 'Max Price',
    minAvailable = 'Min Available',
    maxAvailable = 'Max Available',
    sort = 'Sort',
    search = 'Search',
    promotion = 'Promotion',
    linesheet = 'Featured Line Sheet',
}

export class TagData {
    constructor(key: string, value: string) {
        this.key = key,
            this.value = value;
    }
    key: string;
    value: string;
}

export class SliderFilterModel {
    constructor(min: number, max: number) {
        this.min = min;
        this.max = max;
    }
    min: number;
    max: number;
}