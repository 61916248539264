<!-- Paris & for many Theme -->
<header class="pb-md-4 pb-0" [class.active]="stick && sticky" *ngIf="SelectedTheme !='rome' && SelectedTheme !='madrid'">
    <!--<app-topbar [data]="data" *ngIf="data?.header?.page_top_bar_enable"></app-topbar>-->
    <app-topbar></app-topbar>
    <div class="top-nav top-header sticky-header">
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12">
                    <div class="navbar-top">
                        <div class="d-xl-none d-inline">
                            <app-navbar-menu-button (activeMenu)="toggle($event)" [show]="active"></app-navbar-menu-button>
                        </div>
                        <!--<app-logo [textClass]="'txt-theme f-w-600'" [data]="data" [logo]="logo"></app-logo>-->
                        <a routerLink="" class="web-logo nav-logo">
                            <img [src]="logo" class="img-fluid" alt="logo">
                        </a>
                        <div class="middle-box">
                            <div class="location-box">
                                <!--<app-categories [style]="'input_dropdown'"></app-categories>-->
                                <app-theme-categories [filterModel]="categoryFilterlist" [IsCategorySection]="6" (categorySelected)="onCategorySelected($event)" style="width:100%">
                                </app-theme-categories>
                            </div>
                            <div class="search-box">
                                <app-search [selectedCategory]="selectedCategory"></app-search>
                            </div>
                        </div>
                        <div class="rightside-box">
                            <ul class="right-side-menu">
                                <li class="right-side">
                                    <!--<app-search-box></app-search-box>-->
                                </li>
                                <li class="right-side">
                                    <!--<app-call [data]="data"></app-call>-->
                                    <a href="javascript:void(0)" class="delivery-login-box">
                                        <div class="delivery-icon">
                                            <i class="ri-customer-service-line"></i>
                                        </div>
                                        <div class="delivery-detail">
                                            <h6>{{ '24_7_support_center' | translate }}</h6>
                                            <h5>{{ '+1-555-186-5359' }}</h5>
                                        </div>
                                    </a>
                                </li>
                                <li class="right-side">
                                    <div class="onhover-dropdown header-badge">
                                        <app-wishlist-cart [wishlistItems]="wishlistItems"></app-wishlist-cart>
                                    </div>
                                </li>
                                <li class="right-side">
                                    <div class="onhover-dropdown header-badge">
                                        <!-- Cart section -->
                                        <app-settings></app-settings>
                                    </div>
                                </li>
                                <li class="right-side onhover-dropdown">
                                    <!--<app-my-account></app-my-account>-->
                                    <div>
                                        <div class="delivery-login-box">
                                            <div class="delivery-icon">
                                                <i class="ri-user-line" *ngIf="!isLoggedIn; else userprofile"></i>
                                                <ng-template #userprofile>
                                                    <div class="user-box">
                                                        <img [src]="profileimageUrl || fallbackImageUrl" class="img-fluid" alt="profile">
                                                    </div>
                                                </ng-template>
                                            </div>
                                            <div class="delivery-detail">
                                                <div *ngIf="!isLoggedIn; else accountsection">
                                                    <h6>{{'hi'| translate }}</h6>
                                                    <h5>{{ 'my_account' | translate}}</h5>
                                                </div>
                                                <ng-template #accountsection>
                                                    <h6>{{'hi'| translate }} {{ userProfile?.firstName || 'User' }}</h6>
                                                    <h5>{{ 'my_account' | translate}}</h5>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="onhover-div onhover-div-login">
                                            <ul class="user-box-name">
                                                <ng-container *ngIf="!isLoggedIn; else loggedIn">
                                                    <li>
                                                        <a [routerLink]="['/account/login']" data-lng="en"> {{'login'| translate}} </a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="['/account/register']" data-lng="en"> {{'register'| translate}} </a>
                                                    </li>
                                                </ng-container>
                                                <ng-template #loggedIn>
                                                    <li class="product-box-contain">
                                                        <a [routerLink]="'/my-profile'"> <i class="ri-user-line me-2"></i> {{ 'my_account' | translate}} </a>
                                                    </li>
                                                    <li class="product-box-contain" *ngIf="userRole === 'Admin'">
                                                        <a [routerLink]="'/adminpanel'"> <i class="ri-dashboard-2-line me-2"></i> {{ 'dashboard' | translate}} </a>
                                                    </li>
                                                    <li class="product-box-contain">
                                                        <a [routerLink]="'/shop/wishlist'"> <i class="ri-user-heart-line me-2"></i> {{ 'wishlist' | translate}} </a>
                                                    </li>
                                                    <li class="product-box-contain">
                                                        <a [routerLink]="'/shop/my-reviews'"> <i class="ri-door-line me-2"></i>{{ 'review' | translate}}</a>
                                                    </li>
                                                    <li class="product-box-contain">
                                                        <a (click)="logout()"> <i class="ri-logout-box-r-line me-2"></i> {{ 'logout' | translate}}</a>
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-12">
                <div class="header-nav">
                    <div class="header-nav-left">
                        <app-button [class]="'dropdown-category'" [id]="'all_categories_btn'" [spinner]="false">
                            <i class="ri-apps-line f-w-600 me-2"></i>
                            <span>{{ 'all_category'| translate}} </span>
                        </app-button>
                        <div>
                            <div class="category-dropdown">
                                <div class="category-title">
                                    <h5>{{ 'categories'}}</h5>
                                    <app-button [type]="'button'" [class]="'btn p-0 close-button text-content'" [id]="'categories_btn'" [spinner]="false">
                                        <i class="ri-close-line"></i>
                                    </app-button>
                                </div>
                                <div *ngIf="categoryFilterlist">
                                    <app-theme-categories [IsCategorySection]="7" [filterModel]="categoryFilterlist" style="width: 100%;"></app-theme-categories>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="header-nav-middle menu-direction">
                        <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky  p-0">
                            <div class="offcanvas offcanvas-collapse order-xl-2" [class.show]="active">
                                <div class="offcanvas-header navbar-shadow">

                                    <h5>{{ 'Menu' | translate }}</h5>
                                    <app-button [type]="'button'" [class]="'btn-close lead'" [id]="'toggle_menu_btn'" [spinner]="false" (click)="toggle(false)">
                                        <i class="ri-close-fill"></i>
                                    </app-button>
                                </div>
                                <div class="offcanvas-body">
                                    <!--<app-menu></app-menu>-->
                                    <ul class="navbar-nav">
                                        <ng-container *ngFor="let item of pages">

                                            <li *ngIf="item.subMenu.length == 0" class="headernav-item" style="color:black">
                                                <a (click)="navigateToPage(item)" data-lng="en" style="font-size:16px!important">{{item.title}} </a>
                                            </li>
                                            <li *ngIf="item.subMenu.length > 0" class="nav-item dropdown dropdown-mega ng-star-inserted">
                                                <a class="nav-link dropdown-toggle ng-star-inserted" style="font-size:16px!important">{{item.title}}</a>
                                                <ul class="dropdown-menu">
                                                    <li *ngFor="let menu of item.subMenu" class="nav-item">
                                                        <a (click)="navigateToPage(menu)" data-lng="en" class="dropdown-item" style="font-size:14px !important"> {{menu.title}} </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                            <div class="offcanvas-backdrop fade" *ngIf="active" [class.show]="active" (click)="toggle(false)"></div>
                        </div>
                    </div>
                    <div class="header-nav-right" *ngIf="data?.header?.today_deals?.length">
                        <!--<app-deal [data]="data"></app-deal>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- Rome -->
<header class="header-2" [class.active]="stick && sticky" *ngIf="SelectedTheme =='rome'">
    <app-topbar></app-topbar>
    <div class="top-nav top-header sticky-header sticky-header-3">
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12">
                    <div class="navbar-top">
                        <app-navbar-menu-button (activeMenu)="toggle($event)" [show]="active"></app-navbar-menu-button>
                        <a routerLink="" class="web-logo nav-logo">
                            <img [src]="logo" class="img-fluid" alt="logo">
                        </a>
                        <div class="middle-box">
                            <div class="center-box">
                                <div class="searchbar-box order-xl-1 d-none d-xl-block">
                                    <app-search [selectedCategory]="selectedCategory" [style]="'standard'"></app-search>
                                </div>
                            </div>
                        </div>
                        <div class="rightside-menu">
                            <div class="option-list">
                                <ul>
                                    <li>
                                        <!--<app-my-account [style]="'standard'"></app-my-account>-->
                                    </li>
                                    <li>
                                        <!--<app-search-box [style]="'classic'"></app-search-box>-->
                                    </li>
                                    <li>
                                        <a [routerLink]="['/prod/compare']" class="header-icon">
                                            <i class="ri-arrow-left-right-line"></i>
                                        </a>
                                    </li>
                                    <li class="onhover-dropdown">
                                        <app-wishlist-cart [wishlistItems]="wishlistItems" [style]="'classic'"></app-wishlist-cart>
                                    </li>
                                    <li class="onhover-dropdown">
                                        <app-settings [style]="'classic'"></app-settings>
                                    </li>
                                    <div class="onhover-dropdown">
                                        <a href="javascript:void(0)" class="user-box" *ngIf="!isLoggedIn; else userProfile">
                                            <ng-template #userprofile>
                                                <div class="header-icon">
                                                    <img [src]="profileimageUrl || fallbackImageUrl" class="img-fluid" alt="profile">
                                                </div>
                                            </ng-template>
                                            <div class="user-name">
                                                <div *ngIf="!isLoggedIn; else accountsection">
                                                    <h6>{{'hi'| translate }}</h6>
                                                    <h4>{{ 'my_account' | translate}}</h4>
                                                </div>
                                                <ng-template #accountsection>
                                                    <h6>{{'hi'| translate }} {{ userProfile?.firstName || 'User' }}</h6>
                                                    <h4>{{ 'my_account' | translate}}</h4>
                                                </ng-template>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0)" class="header-icon user-icon search-icon">
                                            <i class="ri-user-line"></i>
                                        </a>

                                        <div class="onhover-div onhover-div-login">
                                            <ul class="user-box-name">
                                                <ng-container *ngIf="!isLoggedIn; else loggedIn">
                                                    <li>
                                                        <a [routerLink]="['/account/login']" data-lng="en"> {{'login'| translate}} </a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="['/account/register']" data-lng="en"> {{'register'| translate}} </a>
                                                    </li>
                                                </ng-container>
                                                <ng-template #loggedIn>
                                                    <li class="product-box-contain">
                                                        <a [routerLink]="'/my-profile'"> <i class="ri-user-line me-2"></i> {{ 'my_account' | translate}} </a>
                                                    </li>
                                                    <li class="product-box-contain" *ngIf="userRole === 'Admin'">
                                                        <a [routerLink]="'/adminpanel'"> <i class="ri-dashboard-2-line me-2"></i> {{ 'dashboard' | translate}} </a>
                                                    </li>
                                                    <li class="product-box-contain">
                                                        <a [routerLink]="'/shop/wishlist'"> <i class="ri-user-heart-line me-2"></i> {{ 'wishlist' | translate}} </a>
                                                    </li>
                                                    <li class="product-box-contain">
                                                        <a [routerLink]="'/shop/my-reviews'"> <i class="ri-door-line me-2"></i>{{ 'review' | translate}}</a>
                                                    </li>
                                                    <li class="product-box-contain">
                                                        <a (click)="logout()"> <i class="ri-logout-box-r-line me-2"></i> {{ 'logout' | translate}}</a>
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-12">
                <div class="main-nav">
                    <div class="header-nav-left">
                        <app-button [class]="'dropdown-category'" [id]="'all_categories_btn'" [spinner]="false">
                            <i class="ri-apps-line f-w-600 me-2"></i>
                            <span>{{ 'all_category'| translate}} </span>
                        </app-button>
                        <div>
                            <div class="category-dropdown">
                                <div class="category-title">
                                    <h5>{{ 'categories'}}</h5>
                                    <app-button [type]="'button'" [class]="'btn p-0 close-button text-content'" [id]="'categories_btn'" [spinner]="false">
                                        <i class="ri-close-line"></i>
                                    </app-button>
                                </div>
                                <div *ngIf="categoryFilterlist">
                                    <app-theme-categories [IsCategorySection]="7" [filterModel]="categoryFilterlist" style="width: 100%;"></app-theme-categories>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                        <div class="offcanvas offcanvas-collapse order-xl-2" [class.show]="active" id="primaryMenu">
                            <div class="offcanvas-header navbar-shadow">
                                <h5>{{ 'Menu' | translate }}</h5>
                                <app-button [type]="'button'" [class]="'btn-close lead'" [id]="'toggle_menu_btn'" [spinner]="false" (click)="toggle(false)">
                                </app-button>
                            </div>
                            <div class="offcanvas-body">
                                <ul class="navbar-nav">
                                    <ng-container *ngFor="let item of pages">
                                        <li *ngIf="item.subMenu.length == 0" class="nav-item dropdown" style="color:black">
                                            <a (click)="navigateToPage(item)" data-lng="en" style="font-size:16px!important">{{item.title}} </a>
                                        </li>
                                        <li *ngIf="item.subMenu.length > 0" class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle ng-star-inserted" style="font-size:16px!important">{{item.title}}</a>
                                            <ul class="dropdown-menu ng-star-inserted">
                                                <li *ngFor="let menu of item.subMenu" class="nav-item">
                                                    <a (click)="navigateToPage(menu)" data-lng="en" class="dropdown-item" style="font-size:14px !important"> {{menu.title}} </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                        <div class="offcanvas-backdrop fade" *ngIf="active" [class.show]="active" (click)="toggle(false)"></div>
                    </div>
                    <div class="right-nav">
                        <!-- Call Style Standard -->
                        <div class="nav-number">
                            <img src="assets/User-section/theme-banners/rome/support.png" class="img-fluid" alt="support number">
                            <span>{{ '+1-555-186-5359' }}</span>
                        </div>

                        <!-- Deal Standard Style -->
                        <a class="btn theme-bg-color ms-3 fire-button">
                            <div class="fire">
                                <img src="assets/User-section/theme-banners/rome/hot-sale.png" class="img-fluid" alt="today deal">
                            </div>
                            <span>{{ 'Hot Deals' | translate }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>


<!-- Madrid -->
<header class="header-3" [class.active]="stick && sticky" *ngIf="SelectedTheme =='madrid'">
    <div class="top-nav sticky-header sticky-header-2">
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12">
                    <div class="navbar-top">
                        <app-navbar-menu-button (activeMenu)="toggle($event)" [show]="active"></app-navbar-menu-button>
                        <a routerLink="" class="web-logo nav-logo">
                            <img [src]="logo" class="img-fluid" alt="logo">
                        </a>
                        <div class="middle-box">
                            <div class="center-box">
                                <app-search [style]="'classic'"></app-search>
                            </div>
                        </div>
                        <div class="rightside-menu support-sidemenu">
                            <!-- Call Style Classic -->
                            <div class="support-box">
                                <div class="support-image">
                                    <img src="assets/User-section/theme-banners/madrid/support.png" class="img-fluid" alt="support">
                                </div>
                                <div class="support-number">
                                    <h2>{{ '+1-555-186-5359' }}</h2>
                                    <h4>{{ '24_7_support_center' | translate }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-12 position-relative">
                <div class="main-nav nav-left-align">
                    <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky p-0">
                        <div class="offcanvas offcanvas-collapse order-xl-2" [class.show]="active" id="primaryMenu">
                            <div class="offcanvas-header navbar-shadow">
                                <h5>{{ 'Menu' | translate }}</h5>
                                <app-button [type]="'button'" [class]="'btn-close lead'" [id]="'toggle_menu_btn'" [spinner]="false" (click)="toggle(false)">
                                </app-button>
                            </div>
                            <div class="offcanvas-body">
                                <ul class="navbar-nav">
                                    <ng-container *ngFor="let item of pages">
                                        <li *ngIf="item.subMenu.length == 0" class="nav-item dropdown" style="color:black">
                                            <a (click)="navigateToPage(item)" data-lng="en" style="font-size:16px!important">{{item.title}} </a>
                                        </li>
                                        <li *ngIf="item.subMenu.length > 0" class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle ng-star-inserted" style="font-size:16px!important">{{item.title}}</a>
                                            <ul class="dropdown-menu ng-star-inserted">
                                                <li *ngFor="let menu of item.subMenu" class="nav-item">
                                                    <a (click)="navigateToPage(menu)" data-lng="en" class="dropdown-item" style="font-size:14px !important"> {{menu.title}} </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                        <div class="offcanvas-backdrop fade" *ngIf="active" [class.show]="active" (click)="toggle(false)"></div>
                    </div>
                    <div class="rightside-menu">
                        <ul class="option-list-2">
                            <li>
                                <!--<app-search-box [style]="'classic'"></app-search-box>-->
                            </li>
                            <li>
                                <a [routerLink]="['/prod/compare']" class="header-icon">
                                    <i class="ri-arrow-left-right-line"></i>
                                </a>
                            </li>
                            <li class="onhover-dropdown">
                                <app-wishlist-cart [wishlistItems]="wishlistItems" [style]="'classic'"></app-wishlist-cart>
                            </li>
                            <li class="onhover-dropdown">
                                <app-settings [style]="'classic'"></app-settings>
                            </li>

                        </ul>
                        <!--<app-my-account [style]="'classic'"></app-my-account>-->
                        <!-- My Account Classic Style -->
                        <div class="onhover-dropdown">
                            <a href="javascript:void(0)" class="ri-user-line user-box" *ngIf="!isLoggedIn; else userProfile">
                                <ng-template #userprofile>
                                    <div class="header-icon">
                                        <img [src]="profileimageUrl || fallbackImageUrl" class="img-fluid" alt="profile">
                                    </div>
                                </ng-template>
                                <div class="user-name">
                                    <div *ngIf="!isLoggedIn; else accountsection">
                                        <h6>{{'hi'| translate }}</h6>
                                        <h4>{{ 'my_account' | translate}}</h4>
                                    </div>
                                    <ng-template #accountsection>
                                        <h6>{{'hi'| translate }} {{ userProfile?.firstName || 'User' }}</h6>
                                        <h4>{{ 'my_account' | translate}}</h4>
                                    </ng-template>
                                </div>
                            </a>
                            <a href="javascript:void(0)" class="header-icon user-icon search-icon">
                                <i class="ri-user-line"></i>
                            </a>

                            <div class="onhover-div onhover-div-login">
                                <ul class="user-box-name">
                                    <ng-container *ngIf="!isLoggedIn; else loggedIn">
                                        <li>
                                            <a [routerLink]="['/account/login']" data-lng="en"> {{'login'| translate}} </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/account/register']" data-lng="en"> {{'register'| translate}} </a>
                                        </li>
                                    </ng-container>
                                    <ng-template #loggedIn>
                                        <li class="product-box-contain">
                                            <a [routerLink]="'/my-profile'"> <i class="ri-user-line me-2"></i> {{ 'my_account' | translate}} </a>
                                        </li>
                                        <li class="product-box-contain" *ngIf="userRole === 'Admin'">
                                            <a [routerLink]="'/adminpanel'"> <i class="ri-dashboard-2-line me-2"></i> {{ 'dashboard' | translate}} </a>
                                        </li>
                                        <li class="product-box-contain">
                                            <a [routerLink]="'/shop/wishlist'"> <i class="ri-user-heart-line me-2"></i> {{ 'wishlist' | translate}} </a>
                                        </li>
                                        <li class="product-box-contain">
                                            <a [routerLink]="'/shop/my-reviews'"> <i class="ri-door-line me-2"></i>{{ 'review' | translate}}</a>
                                        </li>
                                        <li class="product-box-contain">
                                            <a (click)="logout()"> <i class="ri-logout-box-r-line me-2"></i> {{ 'logout' | translate}}</a>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>
                        </div>








                        <!--<div>
                            <div class="delivery-login-box">
                                <div class="delivery-icon">
                                    <i class="ri-user-line" *ngIf="!isLoggedIn; else userprofile"></i>
                                    <ng-template #userprofile>
                                        <div class="user-box">
                                            <img [src]="profileimageUrl || fallbackImageUrl" class="img-fluid" alt="profile">
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="delivery-detail">
                                    <div *ngIf="!isLoggedIn; else accountsection">
                                        <h6>{{'hi'| translate }}</h6>
                                        <h5>{{ 'my_account' | translate}}</h5>
                                    </div>
                                    <ng-template #accountsection>
                                        <h6>{{'hi'| translate }} {{ userProfile?.firstName || 'User' }}</h6>
                                        <h5>{{ 'my_account' | translate}}</h5>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="onhover-div onhover-div-login">
                                <ul class="user-box-name">
                                    <ng-container *ngIf="!isLoggedIn; else loggedIn">
                                        <li>
                                            <a [routerLink]="['/account/login']" data-lng="en"> {{'login'| translate}} </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/account/register']" data-lng="en"> {{'register'| translate}} </a>
                                        </li>
                                    </ng-container>
                                    <ng-template #loggedIn>
                                        <li class="product-box-contain">
                                            <a [routerLink]="'/my-profile'"> <i class="ri-user-line me-2"></i> {{ 'my_account' | translate}} </a>
                                        </li>
                                        <li class="product-box-contain" *ngIf="userRole === 'Admin'">
                                            <a [routerLink]="'/adminpanel'"> <i class="ri-dashboard-2-line me-2"></i> {{ 'dashboard' | translate}} </a>
                                        </li>
                                        <li class="product-box-contain">
                                            <a [routerLink]="'/shop/wishlist'"> <i class="ri-user-heart-line me-2"></i> {{ 'wishlist' | translate}} </a>
                                        </li>
                                        <li class="product-box-contain">
                                            <a [routerLink]="'/shop/my-reviews'"> <i class="ri-door-line me-2"></i>{{ 'review' | translate}}</a>
                                        </li>
                                        <li class="product-box-contain">
                                            <a (click)="logout()"> <i class="ri-logout-box-r-line me-2"></i> {{ 'logout' | translate}}</a>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>
                        </div>-->





                    </div>
                </div>
            </div>
        </div>
    </div>
</header>










<div class="mobile-menu d-md-none d-block mobile-cart">
    <ul>
        <li [class.active]="menu.active" *ngFor="let menu of menuItem">
            <a href="javascript:void(0)" [routerLink]="menu.path" (click)="activeMenu(menu)">
                <i class="{{ menu.icon }}-line deactivated"></i>
                <i class="{{ menu.icon }}-fill activated"></i>
                <span>{{ menu.title }}</span>
            </a>
        </li>
    </ul>
</div>



