export class EditQuantityItemCart {
  constructor(basketItemId: number, count: number) {
    this.basketItemId = basketItemId
    this.count = count
  }
    basketItemId: number

    count: number
}

export class EditQuantityItemGuestCart {
    constructor(styleId: number, count: number) {
        this.styleId = styleId
        this.count = count
    }
    styleId: number

    count: number
}
