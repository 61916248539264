import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { environment } from 'src/environments/environment';
import { AddPageVM, GetPageVM, PagesSearchParamsModel } from '../classes/pages';
import { ContactUsForm } from '../classes/pages/add-page.model';
import { BannerDto } from '../classes/pages/banner.model';
import { GetMenuBannerVM } from '../classes/pages/get-menu-banner.model';

@Injectable({
  providedIn: 'root'
})
export class PageService extends HttpClientManager {

  controllerName: string = "Page";
  pagesApiUrl: string = `${environment.api_base_url}${this.controllerName}`
  onUpdateItem: Subject<any>;
  onUpdatePages: Subject<any>;
  pagesItemApiUrl: string;
  searchRequest = new PagesSearchParamsModel();
  pageItems: GetPageVM[] = [];
  onPagesChanged: BehaviorSubject<boolean>;
  banner: BannerDto;

  constructor(
    httpHandler: HttpHandler,
    protected router: Router,
    private toastrService: ToastrService) {
    super(httpHandler, router);
    this.onUpdateItem = new Subject();
    this.onUpdatePages = new Subject();
    this.onPagesChanged = new BehaviorSubject(false);
  }

  resolve(): Observable<PagingResult<GetPageVM>> | PagingResult<GetPageVM> {
    return this.getAllPages();
  }

  getAllMenu(): Observable<GetMenuBannerVM> {
    return this.getAll<GetMenuBannerVM>(`${this.pagesApiUrl}/menu`)
      .pipe(map((pages: GetMenuBannerVM) => {
        this.onUpdatePages.next(pages.banner)
        this.banner = pages.banner;
        return pages;
      }));
  }

  getAllPages(searchRequest: PagesSearchParamsModel = this.searchRequest): Observable<PagingResult<GetPageVM>> {
    return this.getAll<PagingResult<GetPageVM>>(this.pagesApiUrl, searchRequest)
      .pipe(map((pagesList: PagingResult<GetPageVM>) => {
        this.pageItems = pagesList.xData;
        return pagesList;
      }));
  }


  getPageById(id: number): Observable<GetPageVM> {
    return this.getById(this.pagesApiUrl, +id)
      .pipe(map((response: GetPageVM) => { return response }
        , (error: HttpManagerErrorResponse) => {
          this.showAllErrorMessages(error);
        })
      );
  }

  getPageByUrl(url: string): Observable<GetPageVM> {
    return this.getAll(`${this.pagesApiUrl}/${url}/menu`)
      .pipe(map((response: GetPageVM) => { return response }
        , (error: HttpManagerErrorResponse) => {
          this.showAllErrorMessages(error);
        })
      );
  }

  addPage(pages: AddPageVM) {
    return this.create<boolean>(this.pagesApiUrl, pages)
      .pipe(tap(() => {
        this.onUpdateItem.next();
        this.toastrService.success('Page has been added successfully.');
      },
        (error: HttpManagerErrorResponse) => {
          this.showAllErrorMessages(error);
        }
      ));
  }

  updatePage(page: AddPageVM) {
    return this.update<boolean>(this.pagesApiUrl, page).pipe(tap(() => {
      this.onUpdateItem.next();
      this.toastrService.success('Page has been edited successfully.');
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      }));
  }

  removePage(id: number) {
    this.deleteID<boolean>(this.pagesApiUrl, id).subscribe(() => {
      this.onUpdateItem.next();
      this.toastrService.success('Page has been deleted successfully.');
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      });
  }

  showAllErrorMessages(error: HttpManagerErrorResponse): void {
    error.msg.forEach((msg: string) => {
      this.toastrService.error(msg);
    });
    }

    
}
