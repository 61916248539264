import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { environment } from 'src/environments/environment';
import { AddPromotionItems, GetPromotion, PromotionItemSearchParamsModel, PromotionSearchParamsModel } from '../classes/promotion';
import { AddPromotionsVM } from '../classes/promotion/add-promotion.model';
import { PromotionChangeStatusVM } from '../classes/promotion/change-status-promotion.model';
import { GetPromotionItemVM } from '../classes/promotion/get-promotion-item.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends HttpClientManager {

  controllerName: string = "Promotion";
  promotionItemControllerName: string = "PromotionItem";
  promotionApiUrl: string = `${environment.api_base_url}${this.controllerName}`
  promotionItemApiUrl: string = `${environment.api_base_url}${this.promotionItemControllerName}`
  onUpdateItem: Subject<any>;
  searchRequest: PromotionItemSearchParamsModel;
  constructor(
    httpHandler: HttpHandler,
    protected router: Router,
    private toastrService: ToastrService) {
    super(httpHandler, router);
    this.onUpdateItem = new Subject();
    this.searchRequest = new PromotionItemSearchParamsModel();
  }

  getAllPromotion(searchRequest: PromotionSearchParamsModel): Observable<PagingResult<GetPromotion>> {
    return this.getAll<PagingResult<GetPromotion>>(this.promotionApiUrl, searchRequest)
      .pipe(map((promotionList: PagingResult<GetPromotion>) => {
        return promotionList;
      }));
  }

  getPromotionById(id: number): Observable<PagingResult<GetPromotionItemVM>> {
    const getAllRequest = Object.assign(new PromotionItemSearchParamsModel(), { page: 0, pageSize: 0 });
    getAllRequest.promotionId = id;
    return this.getAll(this.promotionItemApiUrl, getAllRequest)
      .pipe(map((promotionItem: PagingResult<GetPromotionItemVM>) => {
        return promotionItem;
      },
        (error: HttpManagerErrorResponse) => {
          this.showAllErrorMessages(error);
        }));
  }

    addPromotion(promotion: AddPromotionsVM): any {
        return this.create<boolean>(this.promotionApiUrl, promotion)
      .subscribe(() => {
        this.onUpdateItem.next();
        this.toastrService.success('add successful.');
      },
        (error: HttpManagerErrorResponse) => {
          this.showAllErrorMessages(error);
        }
      );
    }

    updatePromotion(promotion: AddPromotionsVM): any {

        return this.update<boolean>(this.promotionApiUrl, promotion)
            .subscribe(() => {
            this.onUpdateItem.next();
            this.toastrService.success('edited successful.');
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
               }
            );
    }


    /*addPromotion(promotion: AddPromotionsVM) {
        console.log("this promotion is going to add: ", promotion);
        return this.create<boolean>(this.promotionApiUrl, promotion)
            .pipe(tap(res => {
                if (res) {
                    this.onUpdateItem.next();
                    this.toastrService.success('add successful.');
                }
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            ));
    }*/

  addPromotionItems(request: AddPromotionItems) {
    return this.create<boolean>(this.promotionItemApiUrl, request)
      .pipe(tap(() => {
        this.onUpdateItem.next();
        this.toastrService.success('style items have been added to promotion successful.');
      },
        (error: HttpManagerErrorResponse) => {
          this.showAllErrorMessages(error);
        }
      ));
  }

  /*updatePromotion(promotion: FormData) {

      return this.update<boolean>(this.promotionApiUrl, promotion).pipe(tap(res => {
      this.onUpdateItem.next();
      this.toastrService.success('edited successful.');
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      }));
  }
 */
  removePromotion(id: number) {
    this.deleteIDs<boolean>(this.promotionApiUrl + `/Delete/${id}`, null).subscribe(res => {
      this.onUpdateItem.next();
      this.toastrService.success('delete successful.');
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      });
  }

  removePromotionItem(ids: number[]) {
    this.deleteIDs<boolean>(this.promotionItemApiUrl, ids).subscribe(res => {
      this.onUpdateItem.next();
      this.toastrService.success('delete successful.');
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      });
  }

  ChangeStatus(primotion: PromotionChangeStatusVM) {
    return this.update<boolean>(this.promotionApiUrl + '/ChangeStatus', primotion).pipe(tap(res => {
      this.onUpdateItem.next();
      this.toastrService.success('edited successful.');
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      }));
  }

  showAllErrorMessages(error: HttpManagerErrorResponse): void {
    error.msg.forEach((msg: string) => {
      this.toastrService.error(msg);
    });
  }
}
