import { GetPageItemVM } from "./get-page-item.model";

export class GetPageVM {
    id: number;
    title: string;
    keyWords: string;
    summary: string;
    description: string = "";
    url: string;
    parentTitle: string;
    priority: number;
    isActive: boolean;
    isBanner: boolean;
    bannerUrl: string;
    bannerTitle: string;
    parentID: number | null;
    subMenu: GetPageItemVM[];
}