import { Component, PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService, LoadingBarModule } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet } from '@angular/router';
import { SharedModule } from './shared/shared.module';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [LoadingBarModule, SharedModule, RouterOutlet]
})
    //@Injectable({
    //    providedIn: 'root'
    //})
export class AppComponent { 
  //  isAdminRoute: boolean;
  //// For Progressbar
  //loaders = this.loader.progress$.pipe(
  //  delay(1000),
  //  withLatestFrom(this.loader.progress$),
  //  map(v => v[1]),
  //);
  
  //constructor(@Inject(PLATFORM_ID) platformId,
  //  private loader: LoadingBarService, translate: TranslateService, router: Router) {
  //  if (isPlatformBrowser(platformId)) {
  //    translate.setDefaultLang('en');
  //    translate.addLangs(['en', 'fr']);
  //    } 

  //    if (router.url.startsWith('/adminpanel')) {
  //        this.isAdminRoute = true;
  //    }
  //}    
     

}
