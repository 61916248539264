export class ViewModel {

    constructor(value: string) {
        this.text = value;
        this.width = ((value.trim().length) * 7) + 30
    }

    text: string;

    width: number;
}