import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { GridSkeletonLoaderComponent } from '../shared/grid-skeleton-loader/grid-skeleton-loader.component';
import { NoItemFoundComponent } from '../../shared/components/no-item-found/no-item-found.component';
import { BaseComponent } from '../../core/base.component';
import { FedexComponent } from './fedex/fedex.component';
import { UPSComponent } from './ups/ups.component';

@Component({
  selector: 'app-shipping-info',
  templateUrl: './shipping-info.component.html',
    styleUrl: './shipping-info.component.scss',
    standalone: true,
    imports: [FedexComponent, UPSComponent, NgbNavModule, NgIf, DataTablesModule, NgFor, NgbTooltipModule, GridSkeletonLoaderComponent, NoItemFoundComponent]
})

export class ShippingInfoComponent extends BaseComponent implements OnInit {

    active = 'Fedex';
    constructor(
    ) { super(); }


    ngOnInit(): void {
    }
}

