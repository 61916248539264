import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';
import { calculateTotalPagesCount, generatePdf } from 'src/app/core/helper/utility';
import { ProductViewModel } from '../shared/model/product-view-model';
import { BasketService } from 'src/app/shared/services/basket.service';
import { LinesheetService } from 'src/app/shared/services/linesheet.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BaseComponent } from 'src/app/core/base.component';
import { NgbModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PromotionListModalComponent } from './promotion-list-modal/promotion-list-modal.component';
import { RoleEnum } from 'src/app/shared/classes/role/role.enum';
import { SetMinQuantityModalComponent } from './set-min-quantity-modal/set-min-quantity-modal.component';
import { GetAllProductIdsModel } from 'src/app/shared/classes/product/get-all-product-Ids.model';
import { SelectAllEmitArgs } from '../shared/model/select-all-emit-args';
import { SetLeadTimeModalComponent } from './set-lead-time-modal/set-lead-time-modal.component';
import { globalLinesheetAddEditModalComponent } from 'src/app/panel/global-linesheets/global-linesheet-add-edit-modal/global-linesheet-add-edit-modal.component';
import { GridSizeEnum } from 'src/app/shared/classes/grid-size-enum';
import { AddToBasketRequest } from 'src/app/shared/classes/basket';
import { RowInput } from 'jspdf-autotable';
import { GetAllLinesheetDto, GetAllLinesheetItemDto } from 'src/app/shared/classes/linesheet';
import { CurrencyPipe, NgIf, NgClass, NgFor } from '@angular/common';
import { GlobalLinsheetListModalComponent } from './global-linsheet-list-modal/global-linsheet-list-modal.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { ProductsListItemSkeletonComponent } from './products-list-item-skeleton/products-list-item-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProductsListItemComponent } from './products-list-item/products-list-item.component';
import { CheckBoxComponent } from '../../shared/components/check-box/check-box.component';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
import { SkeltonProductBoxComponent } from '../skelton-product-box/skelton-product-box.component';
import { HorizontalLoaderComponent } from '../../shared/components/loader/horizontal-loader/horizontal-loader.component';
@Component({
    selector: 'app-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        CheckBoxComponent,
        NgbDropdownModule,
        NgClass,
        NgFor,
        ProductsListItemComponent,
        NgxSkeletonLoaderModule,
        ProductsListItemSkeletonComponent,
        RouterLink,
        InfiniteScrollComponent,
        TranslationModule,HorizontalLoaderComponent,
        SkeltonProductBoxComponent
    ],
})
export class ProductsListComponent extends BaseComponent implements OnInit {
  isLoggedIn: boolean;
  isAdmin: boolean;
  totalPagesCount: number;
  pageSize: number = 25;
  all_products: ProductViewModel[] = [];
  brands: any[] = [];
  colors: any[] = [];
  size: any[] = [];
  minPrice: number = 0;
  maxPrice: number = 1200;
  tags: any[] = [];
  category: string;
  pageNo: number = 1;
  paginate: any = {}; // Pagination use only
  sortBy: string; // Sorting Order
  mobileSidebar: boolean = false;
  loader: boolean = true;
  noMoreProducts: boolean = false; // boolean when end of data is reached
  addItemCount = 8;
  areAllItemsSelected: boolean = false;
  manualAddToBasketRequest: AddToBasketRequest[] = [];
  allAddToBasketRequest: AddToBasketRequest[] = [];
  skeletonThemeCheckBox = {
    height: '35px',
    background:
      'linear-gradient(270deg, rgba(240, 240, 244, .6), rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };
  isExportPdfLoading: boolean = false;
  grid: string;
  @Input() pageNumber: number = 1;
  @Input() isLoadingProducts: boolean;
  @Input() layoutView: string = 'grid-view';
  isDownloadLineSheetLoading: boolean = false;
  private _allPaginatedProducts: ProductViewModel[] = [];
  @Input()
  set allPaginatedProducts(value: ProductViewModel[]) {
    this._allPaginatedProducts = value;
    this.setNoMoreProduct();
    this.checkAllItemsAreSelected();
  }
  get allPaginatedProducts(): ProductViewModel[] {
    return this._allPaginatedProducts;
  }

  private _totalItemsCount: number;
  @Input() set totalItemsCount(value: number) {
    this._totalItemsCount = value;
    if (value)
      this.totalPagesCount = calculateTotalPagesCount(
        this._totalItemsCount,
        this.pageSize
      );
  }

  selectedStyleItemsCount: number = 0;
  private _selectedProductIds: GetAllProductIdsModel[];
  get selectedProductIds(): GetAllProductIdsModel[] {
    return this._selectedProductIds;
  }
  @Input() set selectedProductIds(value: GetAllProductIdsModel[]) {
    this._selectedProductIds = value;
    this.selectedStyleItemsCount = this.getSelectedVariantItems()?.length;
    this.setNoMoreProduct();
  }

  @Output() paginatedResultsCountChange = new EventEmitter<number>();
  @Output() onScrollChange = new EventEmitter<number>();
  @Output() minQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() leadTimeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAllChange = new EventEmitter<SelectAllEmitArgs>();
  @Output() changeSelectedProductId: EventEmitter<GetAllProductIdsModel> = new EventEmitter<GetAllProductIdsModel>();

  constructor(
    public productService: ProductService,
    public basketService: BasketService,
    public linesheetService: LinesheetService,
    private authServices: AuthService,
    private modalService: NgbModal,
      private router: Router, private translate: TranslateService, private il8nService: il8nService,
    private currencyPipe: CurrencyPipe) {
      super();
      var selectedLanguage = JSON.parse(localStorage.getItem("language"));
      this.translate.setDefaultLang(selectedLanguage.code);
      this.translate.use(selectedLanguage.code);
  }

  ngOnInit(): void {
      this.grid = this.productService.getGridLocalStorageValue ?? GridSizeEnum.threeCols;
    this.getLoginState();

    this.productService.onChangeGrid
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
          this.grid = this.productService.getGridLocalStorageValue ?? GridSizeEnum.threeCols;
      })

      this.manageProductSelection(false);
      this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
  }

  onScroll(): void {
    this.setNoMoreProduct()
    this.IfThereIsMoreDataIncrementPageNumber();
    this.checkAllItemsAreSelected();
  }

  setMinQuantity() {
    const modalRef = this.modalService.open(SetMinQuantityModalComponent, {
      size: 'sm',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: 'SizeChart',
    });
    modalRef.componentInstance.styleIDs = this.getSelectedProductIds();
    modalRef.result.then(() => { },
      (resolve: boolean) => {
        if (resolve) this.minQuantityChange.emit()
        this.manageProductSelection(false)
      }
    );
  }

  setLeadTime() {
    const modalRef = this.modalService.open(SetLeadTimeModalComponent, {
      size: 'sm',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: 'SizeChart',
    });
    modalRef.componentInstance.styleIDs = this.getSelectedProductIds();
    modalRef.result.then(() => { },
      (resolve: boolean) => {
        if (resolve) this.leadTimeChange.emit()
        this.manageProductSelection(false)
      }
    );
  }

  onSelectAll(checked: boolean) {
    this.manageProductSelection(checked);
  }

  onProductSelectionChanged(product: ProductViewModel) {
    const emitItem = new GetAllProductIdsModel();
    emitItem.styleId = product.id;
    emitItem.styleItemIds = product.variants.map(f => f.id);
    emitItem.minQuantity = product.minQuantity;
    this.changeSelectedProductId.emit(emitItem);

    this.checkAllItemsAreSelected();
  }

  addToCart() {
    const selectedVariants = this.GetSelectedBasketRequest();
    this.basketService.addToCart(selectedVariants);
    this.manageProductSelection(false);
  }

  createLinesheet() {
    const selectedVariantsId: number[] = this.getSelectedVariantItems();
    this.linesheetService
      .createNewLinesheet(selectedVariantsId)
      .pipe(this.takeUntilDestroy())
      .subscribe(
        (lineSheetId: number) => {
          this.manageProductSelection(false);
          this.downloadLineSheet(lineSheetId)
        },
        (error) => this.linesheetService.showAllErrorMessages(error)
      );
  }

    onExportPdf() {
        this.manageProductSelection(true);
    const selectedVariantsId: number[] = this.getSelectedVariantItems();
    this.linesheetService
      .createNewLinesheet(selectedVariantsId)
      .pipe(this.takeUntilDestroy())
      .subscribe(
        (lineSheetId: number) => {
          this.manageProductSelection(false);
          this.getLinesheetDetails(lineSheetId)
        },
        (error) => this.linesheetService.showAllErrorMessages(error)
      );
  }


  addGlobalLineSheet() {
    const modalRef = this.modalService.open(globalLinesheetAddEditModalComponent, {
      size: 'md',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: 'SizeChart'
    })
    modalRef.componentInstance.selectedVariantsIds = this.getSelectedVariantItems();
    modalRef.result.then(() => { },
      (lineSheetId: number) => {
        this.manageProductSelection(false);
        if (lineSheetId)
          this.router.navigate([`panel/global-linesheets/detail/${lineSheetId}`]);
      });
  }

  updateGlobalLineSheet() {
    const modalRef = this.modalService.open(GlobalLinsheetListModalComponent, {
      size: 'sm',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: 'SizeChart',
    });
    modalRef.componentInstance.styleItemIds = this.getSelectedVariantItems()
    modalRef.result.then(
      () => { },
      () => this.manageProductSelection(false)
    );
  }

  onMakePromotionClick() {
    const modalRef = this.modalService.open(PromotionListModalComponent, {
      size: 'sm',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: 'SizeChart',
    });
    modalRef.componentInstance.styleItemIds = this.getSelectedVariantItems()
    modalRef.result.then(
      () => { },
      () => this.manageProductSelection(false)
    );
  }

  private IfThereIsMoreDataIncrementPageNumber() {
    if (!this.noMoreProducts) {
      this.pageNumber++;
      this.onScrollChange.emit(this.pageNumber);
    }
  }

  private manageProductSelection(checked: boolean) {
    this.selectAllChange.emit({ pageNumber: this.pageNumber, checked: checked });
  }

  private checkAllItemsAreSelected() {
    this.areAllItemsSelected = this.selectedProductIds.length >= this.allPaginatedProducts.length;
  }

  private setNoMoreProduct() {
    this.noMoreProducts = this.pageNumber >= this.totalPagesCount;
  }

  private getLoginState() {
    this.authServices.onLoginStateChanged
      .pipe(this.takeUntilDestroy())
      .subscribe((state: boolean) => {
        this.isLoggedIn = state;
        if (state)
          this.isAdmin = this.authServices
            .getRole()
            .some((r) => r == RoleEnum.Admin);
      })
  }

  private getSelectedVariantItems(): number[] {
    return this.selectedProductIds.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.styleItemIds], [])
  }

  private GetSelectedBasketRequest(): AddToBasketRequest[] {
    return this.selectedProductIds.reduce((accumulator, currentValue) => {
      var styleItems = currentValue.styleItemIds.map(m => ({
        styleItemId: m,
        count: currentValue.minQuantity || 1
      }));
      return [...accumulator, ...styleItems];
    }, [])
  }

  private getSelectedProductIds(): number[] {
    return this.selectedProductIds.map(p => p.styleId);
  }

  private downloadLineSheet(id: number) {
    this.isDownloadLineSheetLoading = true;
    this.linesheetService.exportLinesheet(id)
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
        this.isDownloadLineSheetLoading = false;
      },
        () => {
          this.isDownloadLineSheetLoading = false;
        });
  }


  private getLinesheetDetails(id: number) {
    this.isExportPdfLoading = true;
    let lineSheet: RowInput[] = [];
    this.linesheetService.getLinesheetById(id)
      .pipe(this.takeUntilDestroy())
      .subscribe((res: GetAllLinesheetDto) => {
        res.lineSheetItems.forEach((item: GetAllLinesheetItemDto) => {
          const price = this.currencyPipe.transform(item.variant.salesPrice, 'USD');
          lineSheet.push(['', item.variant.name, item.variant.color, item.variant.lensColor, item.variant.material,
            price, item.variant.quantityOnHand, item.futureStock])
        })
        setTimeout(() => {
          //TODO : change it when we get paginated data from server
          generatePdf(lineSheet, res).then(res => {
            this.isExportPdfLoading = false;
          });
        }, 0)
        this.isExportPdfLoading = false;
      },
        () => {
          this.isExportPdfLoading = false;
        });
  }
}
