import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/base.component';
import { AddPromotionItems, GetPromotion, PromotionSearchParamsModel } from 'src/app/shared/classes/promotion';
import { PromotionService } from 'src/app/shared/services/promotion.service';
import { NoItemFoundComponent } from '../../../shared/components/no-item-found/no-item-found.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
import { ModalComponent } from '../../../shared/components/modal/modal/modal.component';

@Component({
    selector: 'app-promotion-list-modal',
    templateUrl: './promotion-list-modal.component.html',
    styleUrls: ['./promotion-list-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, NgIf, NgFor, FormsModule, NoItemFoundComponent]
})
export class PromotionListModalComponent extends BaseComponent implements OnInit {

  promotionList: GetPromotion[]
  styleItemIds: number[]
  selectedPromotion: GetPromotion;

  constructor(private modalService: NgbModal,
    private promotionService: PromotionService) {
    super();
  }


  ngOnInit(): void {
    this.getPromotion()
  }


  getPromotion() {
    const getAllRequest = Object.assign(new PromotionSearchParamsModel(), { page: 0, pageSize: 0, isActive: true });
    this.promotionService.getAllPromotion(getAllRequest)
      .pipe(this.takeUntilDestroy())
      .subscribe((res) => {
        this.promotionList = res.xData;
      });
  }

  onModalCanceled() {
    this.modalService.dismissAll();
  }

  onModalConfirmed() {
    const request: AddPromotionItems = { promotionId: +this.selectedPromotion.id, styleItemId: this.styleItemIds }
    this.promotionService.addPromotionItems(request).subscribe(() => {
      this.onModalCanceled()
    });
  }

}
