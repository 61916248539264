import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { UserVerification } from 'src/app/shared/classes/user';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { ModalComponent } from '../../shared/components/modal/modal/modal.component';
import { NgIf, NgClass } from '@angular/common'; 
import { RouterModule } from '@angular/router';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ModalComponent,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        RouterModule,
        TranslationModule,
        BreadcrumbComponent
    ],
})
export class LoginComponent implements OnInit {
    @Input() users: UserVerification;
    form: FormGroup;
    title: string = "Login";
    confirmText: string = "Login";

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private modalService: NgbModal, private translate: TranslateService, private il8nService: il8nService
    ) {
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }

    ngOnInit(): void {
        this.createForm();
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    }

    createForm() {
        this.form = this.formBuilder.group({
            Email: ['', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
            Password: ['', [Validators.required]],
        });
    }

    onModalCanceled() {
        this.modalService.dismissAll();
    }

    onModalConfirmed() {
        if (this.form.invalid) return
        this.submitForm();
        
    }

    private submitForm(): void {
        const model = new UserVerification({
            username: this.form.value.Email,
            password: this.form.value.Password,
        });

        this.authService.verificationCode(model).subscribe(
            () => {
                this.router.navigateByUrl('/');
                this.modalService.dismissAll();
                this.onModalCanceled();
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        );
    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void {
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
    forgotPassword(): void {
        this.onModalCanceled();
        this.modalService.open(ForgetPasswordComponent, {
            size: 'md',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart loginModalPopUp'
        })
    }


}
