<h3 class="shippinginfo-heading">
    Shipping Methods
</h3>
<div class="shippinginfo-tabs-main d-flex">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
        <li ngbNavItem="Fedex">
            <a ngbNavLink>Fedex</a>
            <ng-template ngbNavContent>
                <app-fedex></app-fedex>
            </ng-template>
        </li>
        <li ngbNavItem="UPS">
            <a ngbNavLink>UPS</a>
            <ng-template ngbNavContent>
                <app-ups></app-ups>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="ml-4 shippinginfo-tabs-content-main"></div>
</div> 