import { NgbDate, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import jsPDF from "jspdf";
import autoTable, { RowInput } from 'jspdf-autotable';
import * as moment from "moment";
import { ColorEnum } from "src/app/shared/classes/color-enum";
import { GetAllLinesheetDto } from "src/app/shared/classes/linesheet";
import { Variants } from "src/app/shared/classes/product";
import { environment } from "src/environments/environment";

export function prepareQueryParamsObject(object: any): void {
    Object.entries(object).forEach(o =>
        !hasValue(o[1]) || (!Array.isArray(o[1]) && typeof (o[1]) === "object") || (Array.isArray(o[1]) && o[1].length === 0) ? delete object[o[0]] : 0
    );
}

export function hasValue(value: any) {
    if (value == undefined || value === null) {
        return false;
    }
    else {
        value = value.toString().trim();
        if (value === '' || value === "") {
            return false;
        }
    }

    return true;
}

/**
 * Calculates total pages count accorging to given page size & total items count & returns that.
 * @param totalItemsCount Total Items Count
 * @param pageSize Page Size
 * @returns
 */
export function calculateTotalPagesCount(totalItemsCount: number, pageSize: number): number {
    return Math.ceil(totalItemsCount / pageSize);
}

export function createColorList(variants: Variants[]): ColorEnum[] {
    var colorList: ColorEnum[] = []

    variants.forEach((v: Variants) => {
        if (v.color && !colorList?.includes(v.color))
            colorList.push(v.color);
    });

    return colorList
}

export function getPager(totalItems: number, currentPage: number = 1, pageSize: number = 25) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;

    startPage = 1;
    endPage = totalPages;
    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}

export function formatRangeDate(formatter: NgbDateParserFormatter, formDate: NgbDate | null) {
    const date = formatter.format(formDate);
    return new Date(date);
}

export function convertDateToNgbDate(date: string | Date): NgbDate | null {
    if (date) {
        const formattedDateStr = formatDate(date); // returns YYYY-MM-DD formatted date string
        const dateParts: number[] = formattedDateStr.split('-').map((x) => +x);

        const year: number = dateParts[0];
        const month: number = dateParts[1];
        const day: number = dateParts[2];

        return new NgbDate(year, month, day);
    }
    return null;
}

export function formatDate(date: Date | string, format: string = 'YYYY-MM-DD'): string {
    const inputFormat = typeof date === 'string' ? determineDateFormat(date) : null;
    if (!moment(date, inputFormat).isValid()) {
        return moment(new Date(date)).format(format);
    }
    return moment(date, inputFormat).format(format);
}

export function determineDateFormat(date: string): string | null {
    const dateReg = /\d{4}-\d{1,2}-\d{1,2}(T|\s)?(\d{2})?(:\d{2})?(:\d{2})?/.exec(date);

    if (dateReg) {
        let format = 'YYYY-MM-DD';

        if (dateReg[1]) {
            format += dateReg[1];
        }

        if (dateReg[2]) {
            format += 'HH';
        }

        if (dateReg[3]) {
            format += ':mm';
        }

        if (dateReg[4]) {
            format += ':ss';
        }

        return format;
    }

    if (/\d{1,2}\s\w{3}\s\d{4}/.test(date)) {
        return 'DD MMM YYYY';
    }

    return null;
}

export function generatePdf(body: RowInput[], lineSheet: GetAllLinesheetDto) {
    const doc = new jsPDF();
    const lineSheetItems = lineSheet.lineSheetItems;
    const defaultImageUrl: string = 'assets/images/product/placeholder.jpg';
    const myPromise = new Promise<boolean>((resolve, reject) => {
        autoTable(doc, {
            head: [['Image', 'Name', 'Frame Color', 'Lens Color', 'Material', 'Price USD', 'On Hand Quantity', 'Future Stock']],
            body: body,
            bodyStyles: { minCellHeight: 15 },
            headStyles: { fillColor: '#f8f9fa', textColor: '#212529' },
            margin: { top: 5, left: 0, right: 0, bottom: 5 },
            didDrawCell: function (data) {
                if (data.column.index === 0 && data.cell.section === 'body') {
                    let dim = data.cell.height - data.cell.padding('vertical');
                    const item = lineSheetItems.find(x => x.variant.name === data.row.raw[1]);
                    const src = item.variant?.styleImages.length > 0 ? item.variant.styleImages[0].file : defaultImageUrl;
                    doc.addImage(src, 'JPEG', data.cell.x + 2, data.cell.y + 2, dim, dim)
                }
            },
            styles: {
                fontSize: 10,
                valign: 'middle'
            },
        })
        doc.save(lineSheet.title + '.pdf');
        resolve(true);
    })
    return myPromise;
}