<app-button [type]="'button'"
            [class]="'btn p-0 position-relative header-wishlist'"
            [spinner]="false"
            [id]="'cart_sidebar_btn'"
            (click)="cartStyle == 'cart_sidebar' && cartToggle(true)" *ngIf="style == 'basic'">
    <i class="ri-shopping-cart-line"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge">
        {{ data?.basketItems?.length ?? 0 }} <span class="visually-hidden"></span>
    </span>
</app-button>

<!-- Cart Style Classic -->
<a href="javascript:void(0)" class="header-icon swap-icon" id="classicstylecart"
   (click)="cartStyle == 'cart_sidebar' && cartToggle(true)" *ngIf="style == 'classic'">
    <small class="badge-number badge-light">
        {{ data?.basketItems?.length ?? 0 }}
    </small>
    <i class="ri-shopping-cart-line"></i>
</a>

<!-- Cart On Hover -->
<div class="onhover-div"
     [ngClass]="showPanelClass">
    <div class="cart-title">
        <h4>{{ 'shopping_cart' | translate }}</h4>
        <a href="javascript:void(0)" (click)="cartToggle(false)">
            <i class="ri-close-line"></i>
        </a>
    </div>
    <div class="pere-text-box success-box" *ngIf="data?.basketItems?.length">
        <p *ngIf="shippingFreeAmt > data.subtotalPrice else content">
            {{ 'Spend' | translate}} 
            <span class="shipping">
                {{ (shippingFreeAmt - data.subtotalPrice) | currency:productService?.Currency.currency:'symbol' }}
            </span> {{ 'more_and_enjoy' | translate}} 
            <span class="shipping">{{ 'free_shipping'| translate}}!</span>
        </p>
        <ng-template #content>
            <p><span class="shipping">{{ 'congratulations'| translate}}!</span> {{ 'enjoy_free_shipping_on_ussss'| translate}}!</p>
        </ng-template>
        <div class="progress"
             [ngClass]="{ 'danger-progress': shippingCal <= 30, 'warning-progress': shippingCal >= 31 && shippingCal <= 80 }">
            <div class="progress-bar progress-bar-striped progress-bar-animated"
                 role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                 [ngStyle]="{ width: shippingCal+'%' }">
                <i class="ri-truck-line"></i>
            </div>
        </div>
    </div>
    <ul class="cart-list" *ngIf="data?.basketItems?.length">
        <li class="product-box-contain" *ngFor="let basketItem of data.basketItems">
            <div class="drop-cart">
                <a [routerLink]="['/products/detail/', basketItem.styleId]" class="drop-image">

                    <img [src]="basketItem.images ? basketItem.images : 'data:image/png;base64,' + basketItem.variant.imageFile" class="img-fluid" [alt]="basketItem.variant.fullName">

                </a>
                <div class="drop-contain">
                    <a href="javascript:void(0)">
                        <h5>{{  basketItem.variant?.name | uppercase }}</h5>
                    </a>
                    <h5 class="d-flex">
                        {{basketItem.price  | currency:productService?.Currency.currency:'symbol'}}
                        <del class="ms-2" *ngIf="(basketItem.variant.price.toFixed(2) !== basketItem.price.toFixed(2))">
                            <h6>
                                {{ basketItem.variant.price | currency}}
                            </h6>
                        </del>
                        <del class="ms-2" *ngIf="(basketItem.variant.price.toFixed(2) === basketItem.price.toFixed(2))">
                            <h6 *ngIf="basketItem.variant.compairCost > 0">
                                {{ basketItem.variant.compairCost| currency}}
                            </h6>
                        </del>
                    </h5>
                    <!--<h5 class="gram" *ngIf="item?.variation">
                        {{ item?.variation?.selected_variation }}
                    </h5>-->

                    <div class="cart_qty qty-box product-qty">
                        <div class="input-group h-100">

                            <app-button [class]="'btn qty-left-minus'"
                                        [id]="'add-to-cart'+basketItem.styleId"
                                        (click)="decrement(basketItem.id,basketItem.count)"
                                        [spinner]="false">
                                <i class="ri-subtract-line" *ngIf="basketItem.count > 1"></i>
                                <i class="ri-delete-bin-line" *ngIf="basketItem.count <= 1" (click)="removeItem(basketItem)"></i>
                            </app-button>
                            <input class="form-control input-number qty-input" type="number" name="quantity" [value]="basketItem.count" readonly>
                            <app-button [class]="'btn qty-left-minus'"
                                        [id]="'qty-left-minus'+basketItem.styleId"
                                        (click)="increment(basketItem.id,basketItem.count)"
                                        [spinner]="false">
                                <i class="ri-add-line"></i>
                            </app-button>
                        </div>
                    </div>
                    <div>
                        <div class="header-button-group">
                            <!--<app-button [class]="'edit-button close_button'" [id]="'cart_item_edit_btn'+item.product.id" [spinner]="false" (click)="VariationModal.openModal(item)" *ngIf="item?.variation">
                                <i class="ri-pencil-line"></i>
                            </app-button>-->
                            <app-button [class]="'delete-button close_button'" [id]="'cart_item_delete_btn'+basketItem.ID" [spinner]="false" (click)="removeItem(basketItem)">
                                <i class="ri-delete-bin-line"></i>
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="empty-cart-box" *ngIf="!data?.basketItems?.length">
        <i class="ri-shopping-cart-line"></i>
        <h5>{{ 'your_cart_is_currently_empty' | translate}}</h5>
    </div>
    <div class="bottom-box" *ngIf="data?.basketItems?.length">
        <p class="free">{{ 'shipping_and_taxes_are_calculated_at_checkout' | translate}}</p>
        <div class="price-box">
            <h5>{{ 'total' | translate}}:</h5>
            <h4 class="fw-bold">{{ data.subtotalPrice | currency:productService?.Currency.currency:'symbol' }}</h4>
        </div>
        <div class="button-group">
            <a [routerLink]="['/shop/cart']" class="btn btn-sm cart-button" (click)="onViewCartClick()">{{ 'view_cart' | translate }}</a>
            <!--<a [routerLink]="['/cart']" class="btn btn-sm cart-button" (click)="cartToggle(false)">
                {{ 'view_cart' | translate }}
            </a>
            <a [routerLink]="['/checkout']" class="btn btn-sm cart-button theme-bg-color text-white" (click)="cartToggle(false)">
                {{ 'checkout' | translate }}
            </a>-->
        </div>
    </div>
</div>
<div class="bg-overlay" [ngClass]="showPanelClass" (click)="cartToggle(false)"></div>
<!-- Free Shipping Celebration -->
<div class="confetti-wrapper" [class.show]="confetti == 1 && isFreeShipping">
    <ng-container *ngFor="let item of confettiItems">
        <div class="confetti-{{item}}"></div>
    </ng-container>
</div>

<!-- Below is old theme code  -->
<!--<div class="icon-nav">
    <ul>
        <li class="onhover-div mobile-cart mr-md-0 mr-2">
            <div>
                <a [routerLink]="['/shop/cart']">
                    <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
                    <span>Cart</span>
                </a>
            </div>
            <span class="cart_qty_cls">{{ data?.basketItems?.length ?? 0 }}</span>
            <ul class="show-div shopping-cart basket-popup" *ngIf='!data?.basketItems?.length'>
                <h5>Your cart is currently empty.</h5>
            </ul>
            <ul class="show-div shopping-cart p-0 pb-2 basket-popup" *ngIf='data?.basketItems?.length'>
                <li *ngFor="let basketItem of data.basketItems" class="p-1 d-flex border border-light">
                    <div class="media pr-2">
                        <a [routerLink]="['/products/detail/', basketItem.styleId]">
                            <img class="mr-3"
                                 [src]="basketItem.variant.mainImageID ? styleImageBaseUrl + basketItem.variant.mainImageID : defaultImageSrc"
                                 [alt]="'alt'">
                            <img class="mr-3" [src]="'data:image/png;base64,' + basketItem.variant.imageFile" [alt]="basketItem.variant.fullName">
                        </a>
                        <div class="media-body">
                            <a [routerLink]="['/products/detail/', basketItem.styleId]">
                                <h6>{{ basketItem.variant?.name | uppercase}}</h6>
                            </a>
                            <h6>
                                <span>
                                    {{ basketItem.count }} x
                                    {{
 basketItem.variant?.price | discount:basketItem.variant |
                      currency:productService?.Currency.currency:'symbol'
                                    }}
                                </span>
                            </h6>
                        </div>
                    </div>
                    <div class="close-circle" (click)="removeItem(basketItem)">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                </li>
                <li class="px-2">
                    <div class="total">
                        <h5>
                            subtotal :
                            <span>{{ data.subtotalPrice | currency:productService?.Currency.currency:'symbol' }}</span>
                        </h5>
                    </div>
                </li>
                <li class="px-2">
                    <div class="buttons">
                        <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>-->
