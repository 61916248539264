import { BaseSearchModel } from "../../core/model/base-search-model";

export interface ShippingInfo {
    id: number;
    serviceType: string;
    isActive: boolean | null;
    accountNumber: string;
    accessLicenseNumber: string;
    username: string;
    password: string;
    clientId: string;
    clientSecret: string;
    authorization: string;
    ship: string;
    track: string;
    rateAndTransitTimes: string;
    cancel: string;
    shipperName: string;
    streetLines: string;
    city: string;
    countryCode: string;
    state: string;
    postalCode: string;
    phoneNumber: string;
    companyName: string;
    weightUnits: string;
    weightOfEachGlassInKg: number;
    numberOfGlassInEachBox: number | null;
    upsPaymentType: number | null;
    upsServiceCode: number | null;
    upsPackageTypeCode: number | null;
}

export class ShippingInfoSearchParamsModel extends BaseSearchModel {
    ServiceType?: string;
}