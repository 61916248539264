import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHandler, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UserSummaryModel } from '../../shared/classes/user';
import { HttpClientManager, HttpManagerErrorResponse } from '../helper/http-client-manager';
import { TokenError } from '../model/token-error.model';
import { TokenModel } from '../model/token.model';
import { LoginResultEnum } from './auth.model';
import { AccessTokenResultVM, ForgotPasswordTokenResult, ResetPasswordResult } from 'src/app/shared/classes/user/user-auth.model';
import jwt_decode from "jwt-decode";
import { TokenResult } from '../../shared/classes/user/user-verification.model';
import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})

export class AuthService extends HttpClientManager {

    userSummary: UserSummaryModel;
    backendhttp: HttpClient;
    onLoginStateChanged: BehaviorSubject<boolean>;
    onMenuStateChanged: BehaviorSubject<boolean>;
    onUserSummaryChanged: BehaviorSubject<UserSummaryModel>;
    loginUrl: string = '/account/login';
    accountController: string = 'Account'
    accountApiUrl: string = `${environment.api_base_url}${this.accountController}`

    constructor(backEnd: HttpBackend, _http: HttpHandler,
        private toasterService:ToastrService,
        protected router: Router) {
        super(_http, router);
        this.backendhttp = new HttpClient(backEnd);
        this.onLoginStateChanged = new BehaviorSubject(this.isLoggedIn());
        this.onMenuStateChanged = new BehaviorSubject(this.isLoggedIn());
        this.onUserSummaryChanged = new BehaviorSubject(undefined);

        this.getCurrentUser();
    }

    getCurrentUser(): UserSummaryModel {
        var token = localStorage.getItem('token');
        if (token) {
            const tokenData = jwt_decode(token)
            return tokenData ? new UserSummaryModel(tokenData['sub'], tokenData['name']) : null;
        }
        return null;
    }

    isLoggedIn(): boolean {
        return localStorage.getItem('token') != null;
    }

    getRole(): string[] {
        const token = jwt_decode(localStorage.getItem('token'))
        return token['role'] instanceof Array ? token['role'] : [token['role']]
    }

    /*verificationCode(verifymodel: any) {
        console.log("This is going to be hit:", this.accountApiUrl);
        return this.create(this.accountApiUrl, verifymodel).pipe(tap((result: AccessTokenResultVM) => {
            if (result) {
                this.signIn(result.username, result.code);
            }
        }));
    }*/


    verificationCode(verifymodel: any) {
        return this.create(`${this.accountApiUrl}/Login`, verifymodel).pipe(
            tap((result: TokenResult) => {
                if (result) {
                    console.log("Received from server:", result);
                    this.storeToken(result);
                    this.onLoginStateChanged.next(true);
                } else {
                    return of(LoginResultEnum.isFailed);
                }
            }),
            catchError((error: any) => { //HttpErrorResponse
                let errorMsg: string;
                let concatmsg: string;
                if (error && error.msg && Array.isArray(error.msg)) {
                    concatmsg = error.msg.join(' ');
                }
                if (error.error instanceof Error) {
                    console.log(error.error);
                    errorMsg = "Client side or network error";
                } else {
                    switch (concatmsg) {
                        case 'UserName or Password is not Correct':
                            errorMsg = "Invalid username or password";
                            break;
                        case 'locked_out':
                            errorMsg = "Account locked out";
                            break;
                        case 'not_allowed':
                            errorMsg = "Login not allowed";
                            break;
                        default:
                            errorMsg = "Server connection error";
                            break;
                    }
                }
                this.toasterService.error(errorMsg);
                console.error(errorMsg);
                return throwError(new Error(errorMsg));
            })
        );
    }
    ForgotPassword(forgotpasswordmodel: any) {
        return this.create(`${this.accountApiUrl}/ForgotPassword`, forgotpasswordmodel).pipe(tap((result: ForgotPasswordTokenResult) => {
            if (result) {
                true;
            }
            else {
                false;
            }
        }));
    }

    ResetPassword(resetpasswordmodel: any) {
        return this.create(`${this.accountApiUrl}/ResetPassword`, resetpasswordmodel).pipe(tap((result: ResetPasswordResult) => {
            if (result) {
                true;
            }
            else {
                false;
            }
        }));
    }

    registerCustomer(registermodel: any) {
        return this.create(this.accountApiUrl, registermodel).pipe(tap((result: AccessTokenResultVM) => {
            if (result) {
                true;
            }
            else{
                false;
            }
        }));
    }

    registernewCustomer(registermodel: any) {
        return this.create(`${this.accountApiUrl}/Register`, registermodel).pipe(tap((result: AccessTokenResultVM) => {
            if (result) {
                true;
            }
            else {
                false;
            }
        }));
    }

    login(usermodel: any) {
        return this.create(`${this.accountApiUrl}/login`, usermodel);
    }

    signIn(username: string, password: string) {
        const body = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('client_id', environment.identity_server_clientID)
            .set('grant_type', 'password')
            .set('scope', environment.identity_server_scope);

        return this.backendhttp
            .post(environment.identity_server_token_endpoint,
                body.toString().replace("+", "%2B"),
                {
                    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                })
            .subscribe(response => {
                console.log("getiiing response from server after logged in and creating token:", response);
                if (response['access_token']) {
                    this.storeToken(response);
                    this.onLoginStateChanged.next(true);
                    return;
                }
                else {
                    return of(LoginResultEnum.isFailed);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                let errorMsg: string;
                if (error.error instanceof Error) {
                    console.log(error.error);
                    // this.toaster.error("Server connection error");
                    return throwError(new Error(errorMsg)); // Client side or network error
                }
                else if (error.error.error_description === 'invalid_username_or_password') {
                    // this.toaster.error("invalid username or password");
                    return throwError(new Error(errorMsg)); // invalid username or password
                }
                else if (error.error.error_description === 'locked_out') {
                    // this.toaster.error("Account loked out");
                    return throwError(new Error(errorMsg));
                }
                else if (error.error.error_description === 'not_allowed') {
                    // this.toaster.error("Login not allowed")
                    return throwError(new Error(errorMsg));
                }
                else {
                    // this.toaster.error("Server connection error");
                    return throwError(new Error(errorMsg)); // Client side or network error
                }

            })
    }


    signOut() {
        localStorage.removeItem('token');
        this.userSummary = undefined;
        this.onUserSummaryChanged.next(this.userSummary);
        this.onLoginStateChanged.next(false);
        console.log('signout');

        this.navigateToProduct()
    }

    navigateToProduct() {
        this.router.navigate(['/products'])
    }

    navigateToLogin(url?: string, isKeepReturnUrl: boolean = false) {
        this.onMenuStateChanged.next(false); // to hide menu if this method is called individually not in signOut. (e.g. like AuthGuard)    
        this.router.navigate([this.loginUrl], url && isKeepReturnUrl ? { queryParams: { returnUrl: url } } : {});
    }

    isTokenExpired(offset: number = 0): boolean {
        if (this.isLoggedIn()) {
            const token: TokenModel = JSON.parse(localStorage.getItem('token'));
            const a = new Date().valueOf();
            return (token.expires_in + offset) < a;
        }
        return true;
    }

    getCurrentPersonID(): number {
        const currentUser = this.getCurrentUser();
        if (currentUser !== null) {
            return currentUser.Id;
        }
        return null;
    }

    checkIsCurrentPersonID(personID: number): boolean {
        return this.getCurrentPersonID() === personID;
    }

    refreshToken(): Observable<TokenModel> {
        if (this.isLoggedIn()) {
            const token: TokenModel = this.getToken();
            if (token != null && token.refresh_token) {
                const body = new HttpParams()
                    .set('client_id', environment.identity_server_clientID)
                    .set('grant_type', 'refresh_token')
                    .set('refresh_token', token.refresh_token);

                return this.backendhttp
                    .post(environment.identity_server_token_endpoint,
                        body.toString(),
                        {
                            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                        }).pipe(
                            map((res: any) => {
                                this.storeToken(res);
                                return new TokenModel(
                                    res['access_token'],
                                    res['refresh_token'],
                                    res['expires_in']
                                );
                            }),
                            catchError(err => {
                                console.table(err);
                                throw new TokenError("Error");
                            })
                        );
            }
        }
        return throwError(new TokenError("Error"));
    }

    getToken(): TokenModel {
        if (localStorage.getItem('token') != null) {
            return JSON.parse(localStorage.getItem('token'));
        }
        else {
            return null;
        }
    }


    private storeToken(token: object): void {
        const expireDate = (+token['expires_in']); // * 1000 + a;
        console.log("expireDate " + expireDate)
        const tokenModel: TokenModel = new TokenModel(
            token['access_token'],
            token['refresh_token'] || '',
            expireDate
        );

        localStorage.setItem('token', JSON.stringify(tokenModel));
    }


    changeStoredUserName(userName: string) {
        const currentUser = localStorage.getItem('currentUser');
        const userSummary = <UserSummaryModel>JSON.parse(currentUser);
        if (userSummary.username !== userName) {
            userSummary.username = userName;
            this.setUserInfo(userSummary);
        }
    }


    private setUserInfo(userSummery: UserSummaryModel) {
        localStorage.setItem('currentUser', JSON.stringify(userSummery));
        this.userSummary = userSummery;
        this.onUserSummaryChanged.next(userSummery);
    }

    updateCurrentUserName(personID: number, userName: string): void {
        if (this.checkIsCurrentPersonID(personID)) {
            this.changeStoredUserName(userName);
        }
    }

    checkStatus() {
        this.onLoginStateChanged.next(this.isLoggedIn())

    }

}

