<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse" *ngIf="filterModel">
   
    <h3 class="collapse-block-title mt-0" (click)="collapse = !collapse">{{filterModel.filterName | titlecase}}</h3>
    <div class="">
        <div class="">
            <ul class="category-list" *ngIf="!IsMultiSelect">
                <li *ngFor="let category of filterModel.filterListValue">
                    <a [routerLink]="['/shop/products']" [queryParams]="{ category: category}">
                        {{ category | titlecase }}
                    </a>
                </li>
            </ul>

            <ng-container *ngIf="IsMultiSelect">
                <div class="row">
                    <div class="col-md-4" *ngFor="let value of filterModel.filterListValue; let i = index">
                        <div class="category-box">
                            <div class="image-box">
                                <img src="assets/images/categories/cat-img.jpg" alt="" />
                            </div>
                            <div class="content-box">
                                <p>{{ value | titlecase }}</p>
                                <input type="checkbox" [value]="value" [id]="standardFilterName+value"
                                       [checked]="checked(value)" (change)="appliedFilter($event)" class="custom-control-input" />
                                <span *ngIf="IncludeColor" [class]="value"
                                      [ngStyle]="{'background-color': colorEnum[value.toLowerCase()]}"></span>
                                <label for="{{ standardFilterName+value }}">
                                    Shop Now
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
               
            </ng-container>
        </div>
    </div>
</div>
<!-- End Categories List -->