<div class="container">
    <div class="row">
        <div class="col-12 border p-0">
            <div class="col-12 border-bottom image-box p-0">
                <ngx-skeleton-loader [theme]="skeletonThemeImg"></ngx-skeleton-loader>
            </div>
            <div class="p-2">
                <div class="col-7 p-0 pb-1">
                    <ngx-skeleton-loader [theme]="skeletonThemeText"></ngx-skeleton-loader>
                </div>
                <div class="col-12 p-0 description">
                    <ngx-skeleton-loader [theme]="skeletonThemeMultiLineText"></ngx-skeleton-loader>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="col-3 p-0">
                        <ngx-skeleton-loader [theme]="skeletonThemeMultiLineText"></ngx-skeleton-loader>
                    </div>
                    <div class="col-3 p-0">
                        <ngx-skeleton-loader [theme]="skeletonThemeMultiLineText"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>