import { Variants } from "../product";
import { LinesheetStatus } from "./linesheet-status.enum";
import { GetAllLinesheetDto } from "./get-linesheet.model";

export class GetAllLinesheetItemDto {
    id: number;
    LinesheetId: number;

    styleItemId: number;

    Status: LinesheetStatus;

    count: number;

    Price: number;

    ChangedPrice: number | null;

    Linesheet: GetAllLinesheetDto;

    variant : Variants;

    selected: boolean = false;

    futureStock: string;
}