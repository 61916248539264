import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { getPager } from 'src/app/core/helper/utility';
import { environment } from 'src/environments/environment';
import { AddToBasketRequest, basketSearchParamsModel, EditQuantityItemCart, GetAllBasketItemDto, GetBasketItemSummaryVM, GetCurrentBasketSummaryVM } from '../classes/basket';
import { EditBasketItemPriceVM, EditBasketSummaryVM } from '../classes/basket/edit-basket-summary.model';
import { GetAllBasketItemResponseDto } from '../classes/basket/get-all-basket-Item-Response.model';
import { GetCurrentBasketSimpleSummaryVM } from '../classes/basket/get-basket-simple-summary.model';
import { EditQuantityItemGuestCart } from '../classes/basket/edit-quantity.model';
import { ShippingProviderType } from '../classes/shipping/shipping-provider-type.model';


@Injectable({
  providedIn: 'root'
})
export class BasketService extends HttpClientManager {


  basketcontrollerName: string = "Basket";
    basketApiUrl: string = `${environment.api_base_url}${this.basketcontrollerName}`
    couponcontrollerName: string = "Coupons";
    couponApiUrl: string = `${environment.api_base_url}${this.couponcontrollerName}`
    shippingcontrollerName: string = "Shipping";
    shippingApiUrl: string = `${environment.api_base_url}${this.shippingcontrollerName}`;
    onUpdateItem: Subject<any>;
    onUpdateGuestItem: Subject<any>;
  onUpdatePriceRequestItems: Subject<any>;
    onUpdateBasketSummary: Subject<any>;
    onGuestUpdateBasketSummary: Subject<any>;
    searchRequest: basketSearchParamsModel;
    onGetGuestShippingData: Subject<any>; 
  basketItems: GetAllBasketItemDto[] = [];
  numberOfAllItems: number;

  constructor(
    httpHandler: HttpHandler,
    protected router: Router,
    private toastrService: ToastrService) {
    super(httpHandler, router);
      this.onUpdateItem = new Subject();
      this.onUpdateGuestItem = new Subject();
    this.onUpdatePriceRequestItems = new Subject();
      this.onUpdateBasketSummary = new Subject();
      this.onGuestUpdateBasketSummary = new Subject();
      this.onGetGuestShippingData = new Subject();
    this.searchRequest = new basketSearchParamsModel();
  }

  ///:::User Cart
  get cartItems(): Observable<GetCurrentBasketSummaryVM> {
    const itemsStream = new Observable(observer => {
      const cart = JSON.parse(localStorage.getItem('basketSummary'))
      observer.next(cart);
      observer.complete();
    });
    return <Observable<GetCurrentBasketSummaryVM>>itemsStream;
    }

  addToCart(request: AddToBasketRequest[]): any {
        console.log(request)
    this.create<string[]>(this.basketApiUrl + '/BasketItems/', request)
      .subscribe((errors) => {
          if (errors) {
          this.showErrorMsgInAddToCart(errors)
          }
        this.showSuccessMsg(request.map(x => x.styleItemId), errors.length)
        this.getCurrentBasketSummary().subscribe();
        return true
      },
          (error: HttpManagerErrorResponse) => {
          this.showAllErrorMessages(error);
        }
      );
    }

  getAllBasketItems(searchRequest: basketSearchParamsModel): Observable<GetAllBasketItemResponseDto> {
    if (searchRequest == undefined) searchRequest = this.searchRequest;
    else this.searchRequest = searchRequest;
    return this.getAll<GetAllBasketItemResponseDto>(this.basketApiUrl + '/BasketItems', searchRequest)
      .pipe(map(basketPagingResult => {
        this.basketItems = basketPagingResult.items.xData;
        this.numberOfAllItems = basketPagingResult.items.xCount;
        return basketPagingResult;
      }));
  }

  importBasket(formData: FormData): any {
    return this.uploadFile<boolean>(this.basketApiUrl + "/Import", formData).pipe(map(() => {
      this.onUpdateItem.next();
      this.toastrService.success('Basket is updated.');
      this.getCurrentBasketSummary().subscribe();
      return true
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      }
    ));
  }

  getCurrentBasketSummary(): Observable<GetCurrentBasketSummaryVM> {
    return this.getAll<GetCurrentBasketSummaryVM>(this.basketApiUrl + "/Summary").pipe(
      tap((r) => {
        this.resetBasketSummaryInLocalStorage(r);
      }
      ))
  }

  getCurrentBasketSimpleSummary(): Observable<GetCurrentBasketSimpleSummaryVM> {
    return this.getAll<GetCurrentBasketSimpleSummaryVM>(this.basketApiUrl + "/SimpleSummary");
    }

  deleteAllBasketItems() {
    this.deleteWithoutID<boolean>(this.basketApiUrl).subscribe(res => {
      if (res) {
        this.toastrService.success('Product has been deleted in cart.');
        this.onUpdateItem.next();
        this.onUpdatePriceRequestItems.next();
        this.getCurrentBasketSummary().subscribe();
      }
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
        this.onUpdateItem.next();
      });
  }

  deleteBasketItems(basketItemIDs: number[]) {
    this.deleteIDs<boolean>(`${this.basketApiUrl}/BasketItems`, basketItemIDs).subscribe(res => {
      if (res) {
          this.toastrService.success('Product has been deleted in cart.');
          this.onUpdateItem.next();
        this.getCurrentBasketSummary().subscribe();

        this.allItemsOfLastPageAreSelected(basketItemIDs)
          ? this.setPageNumber(this.searchRequest.page - 1)
          : this.onUpdateItem.next();
        this.onUpdatePriceRequestItems.next();
      }
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
        this.onUpdateItem.next();
      });
    }

  setPageNumber(pageNumber: number): any {
    this.searchRequest.page = pageNumber;
    this.onUpdateItem.next(this.searchRequest);
  }

  updateBasketItemQuantity(updateCount: EditQuantityItemCart) {
    this.update<boolean>(this.basketApiUrl + '/BasketItem', updateCount).subscribe(res => {
      if (res) {
        this.onUpdateItem.next();
        // this.toastrService.success('edit count success.');
        this.getCurrentBasketSummary().subscribe();
        // const search = new basketSearchParamsModel;
        // this.getAllBasket(search).subscribe();
      }
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
        this.onUpdateItem.next();
      });
  }

  updateBasketSummary(updateBasketSummary: EditBasketSummaryVM) {
        console.log("Update Basket Summary: ", updateBasketSummary)
    this.update<boolean>(this.basketApiUrl + `/Summary`, updateBasketSummary).subscribe(res => {
      if (res) {
        this.getCurrentBasketSummary().subscribe();
      }
    },
        (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      });
    }

  updateBasketItemPrice(editBasketItemPriceVM: EditBasketItemPriceVM) {
        this.update<boolean>(this.basketApiUrl + `/UpdateBasketItemPrice`, editBasketItemPriceVM).subscribe(res => {
      if (res) {
        this.getCurrentBasketSummary().subscribe();
      }
    },
      (error: HttpManagerErrorResponse) => {
        this.showAllErrorMessages(error);
      });
  }

  showAllErrorMessages(error: HttpManagerErrorResponse): void { // TODO: make it a shared function in utility
      error.msg.forEach((msg: string) => {
          console.log("This error is going to display now: ", msg);
      this.toastrService.error(msg);
    });
    }

  applyCoupon(couponCode: string): Observable<boolean> {
        const url = `${this.basketApiUrl}/ApplyCoupon?CouponCode=${couponCode}`;
        return this.create<boolean>(url, null).pipe(
            tap((result: boolean) => {
                this.toastrService.success("Coupon Added Successfully!!");
            }),
            catchError((error: HttpManagerErrorResponse) => {
                error.msg.forEach((msg: string) => {
                    this.toastrService.error(msg);
                });
                return of(false);
            })
        );
    }

  removeCoupon(couponCode: string): Observable<boolean> {
        const url = `${this.basketApiUrl}/RemoveCoupon?CouponCode=${couponCode}`;
        return this.create<boolean>(url, null).pipe(
            tap((result: boolean) => {
            }),
            catchError((error: HttpManagerErrorResponse) => {
                error.msg.forEach((msg: string) => {
                    this.toastrService.error(msg);
                });
                return of(false);
            })
        );
    }



  ///::: Guest Cart
  /*get guestCartItems(): Observable<GetCurrentBasketSummaryVM> {
      const guestCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : null;
      console.log("Guest Cart Items:", guestCart);
      console.log("Before making hit on server.");
        return this.create<GetCurrentBasketSummaryVM>(`${this.basketApiUrl}/GuestSummary`, guestCart)
            .pipe(
                tap((summary) => {
                    console.log("After getting response from server.");
                    return summary
                })
            );
    }*/

  get guestCartItems(): Observable<GetCurrentBasketSummaryVM> {
        const guestCartRaw = localStorage.getItem('guestCart');
        const guestCart = guestCartRaw ? JSON.parse(guestCartRaw) : null;

        console.log("Guest Cart Items:", guestCart);

        if (!guestCart || guestCart.length === 0) {
            console.log("Guest cart is empty or null, returning default empty summary.");
            return of(this.getEmptyBasketSummary());
        }

        console.log("Before making hit on server.");
        return this.create<GetCurrentBasketSummaryVM>(`${this.basketApiUrl}/GuestSummary`, guestCart)
            .pipe(
                tap((summary) => {
                    console.log("After getting response from server.");
                    return summary;
                })
            );
    }

  getAllGuestBasketItems(): Observable<GetAllBasketItemResponseDto> {
      const guestCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : null;
      if (!guestCart || guestCart.length === 0) {
          console.log("Guest cart is empty, returning default empty response.");

          const emptyResponse: GetAllBasketItemResponseDto = {
              hasMinQuantityError: false,
              items: {
                  xData: [],
                  xCount: 0
              }
          };

          this.basketItems = emptyResponse.items.xData;
          this.numberOfAllItems = emptyResponse.items.xCount;
          return of(emptyResponse);
      }
      console.log("Before making hit on server for Guest basket Items.");
      return this.create<GetAllBasketItemResponseDto>(`${this.basketApiUrl}/GuestBasketItems`, guestCart)
          .pipe(map(basketPagingResult => {
              console.log("After making hit on server for Guest basket Items.");
                this.basketItems = basketPagingResult.items.xData;
                this.numberOfAllItems = basketPagingResult.items.xCount;
                return basketPagingResult;
            }));
  }

  updateGuestBasketItemQuantity(updateCount: EditQuantityItemGuestCart) {
        console.log("request to update cart count: ", updateCount);
        let cart = JSON.parse(localStorage.getItem('guestCart')) || [];
        console.log('Initial cart:', cart);

        let product = cart.find(item => item.styleItemId === updateCount.styleId);
        console.log('Product found:', product);

        if (product) {
            product.count = updateCount.count;
            console.log('Updated product count:', product.count);
        } else {
            cart.push(updateCount);
            console.log('Added new product:', updateCount);
        }

        localStorage.setItem('guestCart', JSON.stringify(cart));
        console.log('Updated cart:', cart);
        this.onUpdateGuestItem.next();
        this.onGuestUpdateBasketSummary.next();
    }

  /*getGuestCurrentBasketSimpleSummary(): Observable<GetCurrentBasketSimpleSummaryVM> {
        const guestCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : [];
      return this.create<GetCurrentBasketSimpleSummaryVM>(`${this.basketApiUrl}/GuestSimpleSummary`, guestCart);
  }*/

  getGuestCurrentBasketSimpleSummary(couponCode?: string): Observable<GetCurrentBasketSimpleSummaryVM> {
        const guestCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : [];


        // Create the payload object
        const payload = {
            couponCode: couponCode || null,
            guestBasketItems: guestCart
        };

        if (guestCart.length === 0) {
            console.log("Guest cart is empty, returning default empty summary.");

            const emptySummary = this.getEmptyBasketSimpleSummary();
            return of(emptySummary);
        }

        return this.create<GetCurrentBasketSimpleSummaryVM>(`${this.basketApiUrl}/GuestSimpleSummary`, payload);
    }

  /*addToCartAsGuest(selectedVariants: AddToBasketRequest[]) {
        const currentCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : [];

        const updatedCart = [...currentCart, ...selectedVariants];
        localStorage.setItem('guestCart', JSON.stringify(updatedCart));
        this.showSuccessMsg(selectedVariants.map(x => x.styleItemId), 0);
        this.updateGuestBasketSummary();
    }*/

  addToCartAsGuest(selectedVariants: AddToBasketRequest[]) {
        console.log("Selected Variant in guest Add to cart:", selectedVariants);
        const currentCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : [];

        selectedVariants.forEach(variant => {
            const existingItem = currentCart.find(item => item.styleItemId === variant.styleItemId);
            if (existingItem) {
                existingItem.count += variant.count;
            } else {
                currentCart.push(variant);
            }
        });

        localStorage.setItem('guestCart', JSON.stringify(currentCart));
        this.showSuccessMsg(selectedVariants.map(x => x.styleItemId), 0);
        this.updateGuestBasketSummary();
    }

  updateGuestBasketSummary() {
        this.getGuestBasketSummary().subscribe(summary => {
            console.log('Guest basket summary updated:', summary);
        });
    }

  getGuestBasketSummary(): Observable<GetCurrentBasketSummaryVM> {
      const guestCartRaw = localStorage.getItem('guestCart');
      const guestCart = guestCartRaw ? JSON.parse(guestCartRaw) : null;

      console.log("Guest Cart Items:", guestCart);

      if (!guestCart || guestCart.length === 0) {
          console.log("Guest cart is empty or null, returning default empty summary.");
          return of(this.getEmptyBasketSummary());
      }
        return this.create<GetCurrentBasketSummaryVM>(`${this.basketApiUrl}/GuestSummary`, guestCart)
            .pipe(
                tap((summary) => {
                    this.onGuestUpdateBasketSummary.next(summary);
                    localStorage.setItem('guestCartSummaryItems', JSON.stringify(summary.basketItems));
                })
            );
  }

  getGuestBasketSummaryWithItems(): Observable<GetCurrentBasketSummaryVM> {
        const guestCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : [];
      return this.create<GetCurrentBasketSummaryVM>(`${this.basketApiUrl}/GuestSummary`, guestCart);
  }

  deleteGuestBasketItem(basketItemIDs: number) {
        let guestCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : [];

        if (!Array.isArray(guestCart)) {
            console.error('Error: guestCart is not an array.');
            return;
        }
        guestCart = guestCart.filter((item: any) => item.styleItemId !== basketItemIDs);

        localStorage.setItem('guestCart', JSON.stringify(guestCart));

        this.toastrService.success('Product has been deleted from guest cart.');

      this.onGuestUpdateBasketSummary.next(guestCart);
      this.onUpdateGuestItem.next();
      this.updateGuestBasketSummary();
  }

  deleteSelectedGuestBasketItems(basketItemIDs: number[]) {
        let guestCart = localStorage.getItem('guestCart') ? JSON.parse(localStorage.getItem('guestCart')!) : [];

        if (!Array.isArray(guestCart)) {
            console.error('Error: guestCart is not an array.');
            return;
        }

        guestCart = guestCart.filter((item: any) => !basketItemIDs.includes(item.styleItemId));

        localStorage.setItem('guestCart', JSON.stringify(guestCart));

        this.toastrService.success('Products have been deleted from guest cart.');

        this.onGuestUpdateBasketSummary.next(guestCart);
        this.onUpdateGuestItem.next();
        this.updateGuestBasketSummary();
  }

  deleteAllGuestBasketItems() {
        localStorage.removeItem('guestCart');

        this.toastrService.success('All products have been deleted from the guest cart.');

        this.onGuestUpdateBasketSummary.next([]);
        this.onUpdateGuestItem.next();
        this.updateGuestBasketSummary();
    }

  getGuestShippingRates(shippingProviderType: ShippingProviderType) {
        const storedAddress = localStorage.getItem('guestUserAddress');
        const storedCart = localStorage.getItem('guestCart');

        if (storedAddress && storedCart) {
            const storedAddress = localStorage.getItem('guestUserAddress');
            const storedCart = localStorage.getItem('guestCart');
            const guestCouponCode = localStorage.getItem('guestCouponCode');

            const address = JSON.parse(storedAddress);
            const cartItems = JSON.parse(storedCart);

            const shippingRequest = {
                shippingProviderType: shippingProviderType,
                address: {
                    streetLines: [address.address],
                    countryCode: address.countryCode,
                    provinceCode: address.stateCode,
                    city: address.city,
                    postalCode: address.postalCode,
                    phoneNumber: address.phoneNumber,
                    name: address.fullName
                },
                freeShippingException: false
            };

            const guestBasketItems = cartItems.map(item => ({
                styleItemId: item.styleItemId,
                count: item.count,
                price: item.price,
                images: item.images
            }));

            const payload = {
                request: shippingRequest,
                couponCode: guestCouponCode && guestCouponCode !== "" ? guestCouponCode : null,
                guestBasketItems: guestBasketItems
            };
            return this.create<GetCurrentBasketSimpleSummaryVM>(`${this.shippingApiUrl}/GetGuestRate`, payload)
                .pipe(tap(res => {
                    if (res) {
                        console.log("Get guest cart Summary with Tax and Delivery Rates: ", res);
                        localStorage.setItem('basketSummary', JSON.stringify(res));
                        this.onGetGuestShippingData.next(res);
                    }
                },
                    (error: HttpManagerErrorResponse) => {
                        console.log("getGuestShippingRates error: ", error);
                        this.showAllErrorMessages(error);
                    }
                ));
        }
    }

  private allItemsOfLastPageAreSelected(basketItemIDs: number[]): boolean {
    const paginate = getPager(this.numberOfAllItems, +this.searchRequest.page)
    const isLastPage = paginate.currentPage == paginate.endPage;
    const allItemsAreSelected = this.basketItems?.length === basketItemIDs.length

    return this.searchRequest.page > 1 && allItemsAreSelected && isLastPage
  }

  private showErrorMsgInAddToCart(errors: string[]) {
      errors.forEach((msg: string) => {
          console.log("This error is going to display: ", msg);
      this.toastrService.error(msg);
    });
  }

  private showSuccessMsg(variantIdList: number[], errorsCount) {

    let count = variantIdList.length;
    if (errorsCount) {
      count = variantIdList.length - errorsCount
    }
    if (!count)
      return;

    const successMsg = count == 1 ? 'Product has been added to the cart.' : count + ' ' + 'Products have been added to the cart.';
    this.toastrService.success(successMsg);
  }

  private getEmptyBasketSummary(): GetCurrentBasketSummaryVM {
        return {
            id: 0, 
            deliveryCost: 0,
            payableDeliveryCost: 0,
            subtotalPrice: 0,
            totalPrice: 0, 
            totalTaxAmount: 0,
            couponDiscount: 0,
            couponCode: null,
            basketItems: [] 
        };
    }

  private getEmptyBasketSimpleSummary(): GetCurrentBasketSimpleSummaryVM {
        return {
            id: 0,
            deliveryCost: 0,
            subtotalPrice: 0,
            totalTaxAmount: 0,
            totalPrice: 0,
            maxDeliveryDate: '',       
            maxDeliveryStyleName: '',   
            taxPromoCode: null,      
            payableDelivery: 0,
            couponDiscount: 0,
            couponCode: null        
        };
    }

  private resetBasketSummaryInLocalStorage(r) {
    localStorage.removeItem("basketSummary");
    localStorage.setItem('basketSummary', JSON.stringify(r));

    this.onUpdateBasketSummary.next(r);
  }

  addSelectedCustomerInLocalStorage(r) {
        localStorage.removeItem("customer");
        localStorage.setItem('customer', JSON.stringify(r));
   }

  removeSelectedCustomerInLocalStorage() {
        localStorage.removeItem("customer");
    }
}