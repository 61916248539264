import { Component, OnInit, Input, ViewChild, EventEmitter, Output, HostListener } from '@angular/core';
import { BaseComponent } from 'src/app/core/base.component';
import { createColorList } from 'src/app/core/helper/utility';
import { AvailableToSale } from 'src/app/shared/classes/available-to-sale.model';
import { AddToBasketRequest } from 'src/app/shared/classes/basket';
import { ColorEnum } from 'src/app/shared/classes/color-enum';
import { GridSizeEnum } from 'src/app/shared/classes/grid-size-enum';
import { Product, ProductSearchParamsModel, Variants } from 'src/app/shared/classes/product';
import { CartModalComponent } from 'src/app/shared/components/modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from 'src/app/shared/components/modal/quick-view/quick-view.component';
import { BasketService } from 'src/app/shared/services/basket.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
import { ProductViewModel } from '../../shared/model/product-view-model';
import { VariantViewModel } from '../../shared/model/variant-view-model';
import { QuickViewComponent as QuickViewComponent_1 } from '../../../shared/components/modal/quick-view/quick-view.component';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Router, RouterLink } from '@angular/router';
import { CheckBoxComponent } from '../../../shared/components/check-box/check-box.component';
import { NgIf, NgFor, NgClass, CurrencyPipe } from '@angular/common';
import { AuthService } from '../../../core/auth/auth.service';
import { GuestcartModalComponent } from '../../../account/guestcart-modal/guestcart-modal.component';
import { DynamicFilterEnum, TagData } from '../../../shared/classes/dynamic-fIlters';
import { ButtonComponent } from 'src/app/shared/widgets/NewTheme/button/button.component';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { AddWishlistRequest, AddWishlistRequestAsGuest } from '../../../shared/classes/basket/add';
import { WishlistService } from '../../../shared/services/wishlist.service';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
import { SkeltonProductBoxComponent } from '../../skelton-product-box/skelton-product-box.component';
import { HorizontalLoaderComponent } from '../../../shared/components/loader/horizontal-loader/horizontal-loader.component';
import { SharedService } from '../../../cartitempopup.service';
@Component({
    selector: 'app-products-list-item',
    templateUrl: './products-list-item.component.html',
    styleUrls: ['./products-list-item.component.scss'],
    standalone: true,
    imports: [NgIf, CheckBoxComponent, RouterLink, LazyLoadImageModule, NgFor, NgClass,HorizontalLoaderComponent,
        NgbTooltipModule, SkeltonProductBoxComponent, QuickViewComponent_1, CurrencyPipe,
        ButtonComponent, NgbRatingModule, TranslationModule]
})

export class ProductsListItemComponent extends BaseComponent implements OnInit {

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.innerWidth = window.innerWidth;
        this.setGridLayout();
    }
    private _product: ProductViewModel;
    @Input() get product(): ProductViewModel {
        return this._product;
    } set product(value: ProductViewModel) {
        this._product = value;

        if (value) {
            this.colorList = createColorList(this.product.variants)
            this.totalVariantsLength = value.variants?.length;
            const variantsAtsQuantity = value.variants?.map((v: VariantViewModel) => v.atsQuantity);
            this.totalVariantsQuantity = variantsAtsQuantity?.reduce((prev: number, current: number) => prev + current);
        }
    }

    @Input() currency: any = this.productService.Currency;
    @Input() thumbnail: boolean = false;
    @Input() onHoverChangeImage: boolean = false;
    @Input() cartModal: boolean = false;
    @Input() loader: boolean = true;
    @Input() index: number;
    @Input() isAdmin: boolean;
    @Input() isLoggedIn: boolean;
    @Output() select = new EventEmitter<ProductViewModel>();
    @ViewChild("quickView") QuickView: QuickViewComponent;
    @ViewChild("cartModal") CartModal: CartModalComponent;
    innerWidth: number;
    imageBaseUrl: string = environment.style_image_base_url;
    imageSrc: string;
    defaultImageUrl: string = 'assets/images/product/placeholder.jpg';
    price: number;
    quantityOnHand: number;
    promotionDiscount: number;
    promotionPrice: number;
    salesPrice: number;
    colorList: ColorEnum[];
    selectedVariant: VariantViewModel;
    totalVariantsLength: number = 0;
    totalVariantsQuantity: number = 0;
    isGridForColLgOrMobile: boolean = false;
    selectedVariantAtsCount: number;
    selectedVariantAtsDate: string; 
    FullName: string;
    compairCost: number;
    constructor(
        private productService: ProductService,
        private basketService: BasketService,
        private authServices: AuthService,
        private modalService: NgbModal, private router: Router, private sharedService: SharedService,
        private wishlistService: WishlistService, private translate: TranslateService, private il8nService: il8nService) {
        super();
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }

    ngOnInit(): void {
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
        if (this.loader) {
            setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
        }

        this.price = this.product.variants[0].price;
        this.quantityOnHand = this.product.variants[0].quantityOnHand;
        this.promotionDiscount = this.product.variants[0].promotion.discount;
        this.promotionPrice = this.product.variants[0].promotion.price;
        this.salesPrice = this.product.variants[0].salesPrice;
        this.compairCost = this.product.variants[0].compairCost;
        this.selectedVariant = this.product.variants.find(v => v.selected);
        this.selectedVariantAtsCount = this.selectedVariant?.availableToSales[0]?.count;
        this.selectedVariantAtsDate = this.selectedVariant?.availableToSales[0]?.availableDate;
        this.FullName = this.product.variants[0].fullName ? this.product.variants[0].fullName : this.product.variants[0].name;
       // this.productReviews = this.product.productReviews ? this.product.productReviews.length : 0;
        //console.log("this.product", this.product.variants[0].mainImageID)
        this.setMainImageSrc(this.product.imageIds, this.product.variants);
        this.setGridLayout();
        this.getLoginState();


        this.productService.onChangeGrid
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                this.setGridLayout();
            }); 
    }


    colorChange(selectedColor: string) {
        this.product.variants.forEach((variant: VariantViewModel) => {
            if (variant.color == selectedColor) {
                this.changeSelectedVariant(variant);
                this.price = variant.price;
                this.setSelectedVariantImageSrc(variant, "0");
            }
        })
    }

    private setGridLayout() {
        if (this.productService.getGridLocalStorageValue === GridSizeEnum.sixCols || innerWidth < 767) {
            this.isGridForColLgOrMobile = true;
        } else
            this.isGridForColLgOrMobile = false;
    }

    private changeSelectedVariant(v: VariantViewModel) {
        this.selectedVariant = v
        this.product.variants.map(v => v.selected = false)
        v.selected = true;
    }

    private findSelectedVariantMainImageId(v: Variants): number {

        //console.log("v.mainImageID", v.mainImageID);
        //console.log("variats",this.product.variants);

        return this.product.variants.find(image => image.mainImageID == v.mainImageID).mainImageID;
    }

    // Change Variants Image
    ChangeVariantsImage(src) {
        this.imageSrc = src;
    }

    /*addToCart() {
        
        const availableVariants = [...this.product.variants];
        const selectedVariants: AddToBasketRequest[] = []
        const count = this.product.minQuantity || 1;
        availableVariants.forEach((v) => selectedVariants.push({ styleItemId: v.id, count: count }));
        this.basketService.addToCart(selectedVariants);
    }*/

    addToCart(imageurl: any) {
        this.sharedService.triggerSettingsAction();
        const availableVariants = [...this.product.variants];
        const selectedVariants: AddToBasketRequest[] = []
        const count = this.product.minQuantity || 1;
        
        if (this.isLoggedIn) {
            availableVariants.forEach((v) => selectedVariants.push({ styleItemId: v.id, count: count, Images: imageurl }));
            console.log("user is logged in");
            this.basketService.addToCart(selectedVariants);
        } else {
            availableVariants.forEach((v) => selectedVariants.push({ styleItemId: v.id, count: count, Images: imageurl }));
            console.log("user is not logged in");
            this.basketService.addToCartAsGuest(selectedVariants);

            const isGuestUser = localStorage.getItem('IsGuestUser');
            console.log("IsGuest User From Local Stoarge:", isGuestUser);
            if (isGuestUser === '0' || isGuestUser === null) {
                this.router.navigate(['/account/guest']);
            }
        }
    }

    addToWishlist(product: any, image: any) {
        if (this.isLoggedIn) {
            this.productService.addToWishlist(product);
            console.log(product)
            const selectedVariants: AddWishlistRequest[] = []
            selectedVariants.push({ styleItemId: product.id });
            console.log(product.id)
            this.wishlistService.addWishlist(selectedVariants);
        }
        else {
            console.log("User is not logged in");
            const selectedVariants: AddWishlistRequestAsGuest[] = []
            selectedVariants.push({ styleItemId: product.id, Image: image, styleName: this.FullName, price: product.variants[0].salesPrice, quantityonHand: product.variants[0].quantityOnHand, minQuantity: product.minQuantity });
            console.log(product.id)
            this.wishlistService.addWishlistAsGuest(selectedVariants);
        }
    }

    addToCompare(product: any) {
        this.productService.addToCompare(product);
    }

    onSelectionChange(product: ProductViewModel, value: boolean) {
        product.selected = value
        this.select.emit(this.product)
    }

    private setSelectedVariantImageSrc(selectedVariant: Variants, title: string): void {
        const mainImageId = this.findSelectedVariantMainImageId(selectedVariant);
        this.imageSrc = mainImageId ? this.imageBaseUrl + mainImageId : 'assets/images/product/' + title.toString() + '_1.jpg';//this.defaultImageUrl;
    }

    private setMainImageSrc(imageIds: number[], selectedVariant: Variants[]): void {
        //const mainImageId = (selectedVariant !== null && Array.isArray(selectedVariant) && selectedVariant.length >= 1) ? this.findSelectedVariantMainImageId(selectedVariant[0]) : 0;
        //if (mainImageId < 1) {
        //    this.imageSrc = 'assets/images/product/noimage.jpg';
        //}
        //else if (mainImageId > 0) {
        //    const images: number[] = imageIds.filter(value => value !== mainImageId);
        //    this.imageSrc = (images !== null && Array.isArray(images) && images.length >= 1) ? this.imageBaseUrl + images[0] : 'assets/images/product/noimage.jpg';
        //}
        if (imageIds.length > 0) {
            this.imageSrc = imageIds.length > 1 ? this.imageBaseUrl + imageIds[1] : this.imageBaseUrl + imageIds[0];
            console.log("Image is gettng from here : ", this.imageSrc);
        } else {
            this.imageSrc =  'assets/images/product/noimage.jpg';
        }
    }

    private getLoginState() {
        this.authServices.onLoginStateChanged
            .pipe(this.takeUntilDestroy())
            .subscribe((state: boolean) => {
                console.log("Login state: ", state);
                this.isLoggedIn = state;
            })
    }
}
