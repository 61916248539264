import { SimpleUser } from ".";
import { RoleVM } from "../role/RoleVM";
import { SaleManagerDto } from "../seller/sale-manager-dto";

export class UserListModel {
    id: number;

    username: string;

    email: string;

    isActive: boolean;

    firstName: string;

    lastName: string;

    phoneNumber: string;

    name: string;

    isNegotiator: boolean;

    isFreePayment: boolean;

    isFreeShipping: boolean;

    customerSeller: SimpleUser;

    Roles: RoleVM[];

    saleManager: SaleManagerDto | null;
}