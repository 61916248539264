<app-button [type]="'button'"
            [class]="'btn p-0 position-relative header-wishlist'"
            [spinner]="false"
            [id]="'wishcart_sidebar_btn'"
            (click)="cartStyle == 'cart_sidebar' && cartToggle(true)" *ngIf="style == 'basic'">
    <i class="ri-heart-line"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge">
        {{ wishlistItems?.length ?? 0 }} <span class="visually-hidden"></span>
    </span>
</app-button>

<a href="javascript:void(0)" class="header-icon swap-icon"
   (click)="cartStyle == 'cart_sidebar' && cartToggle(true)" *ngIf="style == 'classic'">
    <small class="badge-number badge-light">
        {{  wishlistItems?.length ?? 0 }}
    </small>
    <i class="ri-heart-line"></i>
</a>
<!-- Cart On Hover -->
<div class="onhover-div"
     [ngClass]="showPanelClass">
    <div class="cart-title">
        <h4>{{ 'Wishlist_Cart' | translate }}</h4>
        <a href="javascript:void(0)" (click)="cartToggle(false)">
            <i class="ri-close-line"></i>
        </a>
    </div>

    <ul class="cart-list" *ngIf="wishlistItems?.length">
        <li class="product-box-contain" *ngFor="let basketItem of wishlistItems">
            <div class="drop-cart">
                <a [routerLink]="['/products/detail/', basketItem.styleItemId]" class="drop-image">

                    <!--<img [src]="imageBaseUrl +  basketItem.variant.styleImages[1].id" class="img-fluid" [alt]="basketItem.variant.fullName">-->

                    <img [src]="(basketItem.variant.styleImages[1] || {}).id ? imageBaseUrl + basketItem.variant.styleImages[1].id : basketItem.variant.styleImages" class="img-fluid" [alt]="basketItem.variant.fullName">

                </a>
                <div class="drop-contain">
                    <a href="javascript:void(0)">
                        <h5>{{basketItem.variant?.name | uppercase }}</h5>
                    </a>
                    <h6>
                        {{basketItem.variant.price  | currency:productService?.Currency.currency:'symbol'}}
                    </h6>

                    <div>
                        <div *ngIf="isLoggedIn" class="header-button-group">
                            <app-button [class]="'delete-button close_button'" [id]="'cart_item_delete_btn'+basketItem.ID" [spinner]="false" (click)="removefromWishlist(basketItem.variant)">
                                <i class="ri-delete-bin-line"></i>
                            </app-button>
                        </div>
                        <div *ngIf="!isLoggedIn" class="header-button-group">
                            <app-button [class]="'delete-button close_button'" [id]="'cart_item_delete_btn'+basketItem.ID" [spinner]="false" (click)="removefromGuestWishlist(basketItem.styleId)">
                                <i class="ri-delete-bin-line"></i>
                            </app-button>
                        </div>
                    </div>
                    <div class="add-to-cart-box">
                        <div *ngIf="isLoggedIn">
                            <app-button [id]="'add-to-cart'+basketItem.id"
                                        [class]="'btn btn-add-cart addcart-button'"
                                        [iconClass]="'ri-add-line'"
                                        [spinner]="false"
                                        (click)="addToCart(basketItem.variant)"
                                        *ngIf="basketItem.variant.quantityOnHand >= 1; else outStock">
                                {{ 'add'| translate}}
                                <span class="add-icon">
                                    <i class="ri-add-line"></i>
                                </span>
                            </app-button>
                        </div>

                        <div *ngIf="!isLoggedIn">
                            <app-button [id]="'add-to-cart'+basketItem.id"
                                        [class]="'btn btn-add-cart addcart-button'"
                                        [iconClass]="'ri-add-line'"
                                        [spinner]="false"
                                        (click)="addToCartAsGuest(basketItem.styleId, basketItem.variant.minQuantity, basketItem.variant.styleImages)"
                                        *ngIf="basketItem.variant.quantityOnHand >= 1; else outStock">
                                {{ 'add'| translate}}
                                <span class="add-icon">
                                    <i class="ri-add-line"></i>
                                </span>
                            </app-button>
                        </div>

                        <ng-template #outStock>
                            <app-button [id]="'out-of-stock'+basketItem.id"
                                        [class]="'btn btn-add-cart addcart-button'"
                                        [spinner]="false"
                                        [disabled]="true">
                                {{ 'sold_out'| translate}}
                            </app-button>
                        </ng-template>

                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="empty-cart-box" *ngIf="!wishlistItems?.length">
        <i class="ri-shopping-cart-line"></i>
        <h5>{{ 'your_cart_is_currently_empty'|translate }}</h5>
    </div>
    <!--<div class="bottom-box" *ngIf="data?.basketItems?.length">
        <p class="free">{{ 'Shipping and taxes are calculated at checkout' }}</p>
        <div class="price-box">
            <h5>{{ 'Total' }}:</h5>
            <h4 class="fw-bold">{{ data.subtotalPrice | currency:productService?.Currency.currency:'symbol' }}</h4>
        </div>
        <div class="button-group">
            <a [routerLink]="['/shop/cart']" class="btn btn-sm cart-button" (click)="cartToggle(false)">view cart</a>-->
    <!--<a [routerLink]="['/cart']" class="btn btn-sm cart-button" (click)="cartToggle(false)">
        {{ 'view_cart' | translate }}
    </a>
    <a [routerLink]="['/checkout']" class="btn btn-sm cart-button theme-bg-color text-white" (click)="cartToggle(false)">
        {{ 'checkout' | translate }}
    </a>-->
    <!--</div>
    </div>-->
</div>
<div class="bg-overlay" [ngClass]="showPanelClass" (click)="cartToggle(false)"></div>


