import { Component, PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService, LoadingBarModule } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ThemeService } from 'src/theme.service';
import { ThemeOptionService } from '../Store-Section/shared/services/theme-option.service';
import { AppFooterComponent } from 'src/app/shared/NewTheme/footer/app-footer/app-footer.component';
import { StickyCompareComponent } from 'src/app/shared/widgets/NewTheme/sticky-compare/sticky-compare.component'
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
@Component({
  selector: 'app-layout',
  standalone: true,
    imports: [LoadingBarModule, SharedModule, RouterOutlet, AppFooterComponent, RouterModule, StickyCompareComponent, TranslationModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
    isAdminRoute: boolean;
    // For Progressbar
    loaders = this.loader.progress$.pipe(
        delay(1000),
        withLatestFrom(this.loader.progress$),
        map(v => v[1]),
    );

    constructor(@Inject(PLATFORM_ID) platformId,
        private loader: LoadingBarService, router: Router, private themeService: ThemeService, public themeOptionService: ThemeOptionService, private translate: TranslateService, private il8nService: il8nService) {
        //if (isPlatformBrowser(platformId)) {
        //    translate.setDefaultLang('en');
        //    translate.addLangs(['en', 'fr']);
        //}


        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        if (!selectedLanguage || !selectedLanguage.code) {
            selectedLanguage = { code: 'en' }; // Assuming 'en' is the code for English
            localStorage.setItem("language", JSON.stringify({ "language": "English", "code": "en", "icon": "us" }));
        }
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);


        this.themeOptionService.preloader = true;
        if (router.url.startsWith('/adminpanel')) {
            this.isAdminRoute = true;
        }
    }
    ngOnInit() { 
        this.themeService.setTheme('user'); 
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    }
}
