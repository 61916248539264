<div class="container mobile-container">
    <div class="row">
        <div class="col-12 p-0 pt-4 mt-2">
            <div class="mb-5" *ngFor="let item of [].constructor(2)">
                <div class="col-6 p-0">
                    <ngx-skeleton-loader [theme]="skeletonThemeTitle"></ngx-skeleton-loader>
                </div>
                <div class="col-12 p-0 pt-4 mt-3">
                    <ngx-skeleton-loader [theme]="skeletonThemeRange"></ngx-skeleton-loader>
                </div>
            </div>
            <div class="mb-5" *ngFor="let item of [].constructor(2)">
                <div class="col-6 p-0 pb-3 pt-1 mb-1">
                    <ngx-skeleton-loader [theme]="skeletonThemeTitle"></ngx-skeleton-loader>
                </div>
                <div class="col-4 p-0 pb-2" *ngFor="let item of [].constructor(5)">
                    <ngx-skeleton-loader [theme]="skeletonThemeOption"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>