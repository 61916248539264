import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/base.component';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { LinesheetService } from 'src/app/shared/services/linesheet.service';
import { environment } from 'src/environments/environment';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from '../../../shared/components/modal/modal/modal.component';

@Component({
    selector: 'app-global-linesheet-add-edit-modal',
    templateUrl: './global-linesheet-add-edit-modal.component.html',
    styleUrls: ['./global-linesheet-add-edit-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, FormsModule, NgIf]
})
export class globalLinesheetAddEditModalComponent extends BaseComponent implements OnInit {

  title: string;
  id: number;
  selectedVariantsIds: number[] = [];
  formData: FormData;
  image: string | ArrayBuffer;
  file: any;
  linesheetApiUrl: string = `${environment.api_base_url}Linesheet/`;
  defaultImageUrl: string = 'assets/images/product/placeholder.jpg';

  constructor(private modalService: NgbModal,
    private linesheetService: LinesheetService) {
    super()
    this.formData = new FormData();
  }


  ngOnInit(): void {
    this.image = this.linesheetApiUrl + this.id + '/image'
  }

  errorImage(event) {
    event.target.src = this.defaultImageUrl
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.image = reader.result;
      reader.readAsDataURL(file);
      this.formData.append('File', file);
    }
  }

  onModalConfirmed() {
    this.formData.append('Title', this.title);
    this.selectedVariantsIds.forEach(item => {
      this.formData.append('LineSheetItemIDs[]', item.toString());
    })
    this.id
      ? this.editGlobalLineSheet()
      : this.addGlobalLineSheet()
  }

  private addGlobalLineSheet() {
    this.linesheetService
      .createNewGlobalLinesheet(this.formData)
      .pipe(this.takeUntilDestroy())
      .subscribe(
        (lineSheetId: number) => {
          this.modalService.dismissAll(lineSheetId)
        },
        (error) => this.linesheetService.showAllErrorMessages(error)
      );
  }

  private editGlobalLineSheet() {
    this.formData.append('Id', this.id.toString());
    this.linesheetService.updateGlobalLinesheet(this.formData)
      .pipe(this.takeUntilDestroy())
      .subscribe({
        next: () =>
          this.modalService.dismissAll(),
        error: (error: HttpManagerErrorResponse) =>
          this.linesheetService.showAllErrorMessages(error)
      })
  }

  onModalCanceled() {
    this.modalService.dismissAll();
  }

}

