import { Variants } from "../product";
import { BasketStatus } from "./basket-status.enum";

export class GetAllBasketItemDto {
    constructor(values: GetAllBasketItemDto) {
        Object.assign(this, values);
        if (values.variant.leadTime) this.calcLeadDate();
    }

    id: number;

    BasketId: number;

    styleItemId: number;

    Status: BasketStatus;

    count: number;

    price: number;

    ChangedPrice: number | null;

    variant: Variants;

    selected: boolean = false;

    leadDate: Date = new Date();

    hasFreeShipping: boolean;

    private calcLeadDate() {
        var today = new Date();
        this.leadDate.setDate(today.getDate() + this.variant.leadTime);
    }
}