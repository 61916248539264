<header>
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container-fluid">
      <div class="d-flex justify-content-end ">
        <div class="d-flex justify-content-center px-0 w-100 mr-2">
          <ul class="d-flex header-contact">
            <li class="max-width" >
                <a (click)="promotionItems(true)">
                    50% OFF FOR FIRST ORDER
                </a>
              
            </li>
          </ul>
         
        </div>
        
      </div>

    </div>
  </div>
</header>