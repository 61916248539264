<!-- breadcrumb start -->
<!--<div class="breadcrumb-section px-1">
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col-sm-4 d-flex mt-sm-0 mt-1">
        <div class="page-title">
          <h2 class="title">{{ title }}</h2>
        </div>
      </div>
      <div class="col-sm-4 d-flex align-items-center justify-content-center mt-sm-0 mt-1">
        <div *ngIf="bannerTitle && !isBanner" class="d-flex align-items-center">
          <a [routerLink]='"/pages/"+ bannerUrl' class="banner-title" [ngbTooltip]="'Go to '+ bannerTitle +' page'">
            {{bannerTitle}}
          </a>
        </div>
      </div>
      <div class="col-sm-4">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>-->

<section class="breadcrumb-section pt-0">
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain">
                    <h2 class="">{{ title}}</h2>
                    <nav>
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item">
                                <a><i class="ri-home-3-fill"></i></a>
                            </li>
                            <li class="breadcrumb-item">
                                {{ breadcrumb}}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- breadcrumb End -->