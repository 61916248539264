import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/account/login/login.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BaseComponent } from 'src/app/core/base.component';
import { GetPageVM } from '../../classes/pages';
import { PageService } from '../../services/page.service';
import { ProductsComponent } from 'src/app/products/products.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class TopHeaderComponent extends BaseComponent implements OnInit {

  @Input() topbar: boolean = true; // Default True
    //@Input() showPromotionProducts: boolean = false;
    //@Output() onSelected = new EventEmitter<any>();
    @ViewChild(ProductsComponent) child;

  constructor() {
    super()
  }

  ngOnInit() {
   
  }
    promotionItems(e) {
        //this.onSelected.emit(e);
        this.child.showPromotionProducts = true;
        console.log("Events", e);
        alert("You have clicked the anchor-1 tag");
    }
  
}
