import { Component, OnInit } from '@angular/core';
import { NgbNavModule, NgbModal, ModalDismissReasons, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/core/base.component';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { NoItemFoundComponent } from '../../../shared/components/no-item-found/no-item-found.component';
import { GridSkeletonLoaderComponent } from '../../shared/grid-skeleton-loader/grid-skeleton-loader.component';
import { DataTablesModule } from 'angular-datatables';
import { NgIf, NgFor } from '@angular/common';
import { ShippingInfo, ShippingInfoSearchParamsModel } from '../../../shared/classes/shipping-info.model';
import { ShippingInfoService } from '../../../shared/services/shipping-info.service';
import { AddUPSModalComponent } from './add-ups-modal/add-ups-modal.component';

@Component({
    selector: 'app-ups',
    templateUrl: './ups.component.html',
    styleUrls: ['./ups.component.scss'],
    standalone: true,
    imports: [NgIf, DataTablesModule, NgFor, NgbTooltipModule, GridSkeletonLoaderComponent, NoItemFoundComponent]
})
export class UPSComponent extends BaseComponent implements OnInit {
    isLoading: boolean = true;
    public closeResult: string;
    shippings: ShippingInfo[] = []

    dtOptions: DataTables.Settings = {
        columnDefs: [
            { orderable: false, targets: [0, 3] }
        ],
        scrollX: true,
    };
    constructor(
        private shippingInfoService: ShippingInfoService,
        private modalService: NgbModal
    ) { super(); }

    ngOnInit(): void {
        this.getShippingInfo();

        this.shippingInfoService.onUpdateItem.pipe(this.takeUntilDestroy()).subscribe(res => {
            if (res)
                this.getShippingInfo();
        })
    }


    getShippingInfo() {
        console.log('getShippingInfo: Fetching brands data');
        const getAllRequest = Object.assign(new ShippingInfoSearchParamsModel(), { page: 0, pageSize: 0 });
        this.shippingInfoService.getUPSShippingInfo()
            .pipe(this.takeUntilDestroy())
            .subscribe((res) => {
                console.log('getShippingInfo: Shipping data received:', res);
                this.resetDataTable(res);
            },
                (error: HttpManagerErrorResponse) => { },
                () => {
                    console.log('getShippings: Completed fetching shipping data');
                    this.isLoading = false;
                });
    }


    resetDataTable(res) {
        this.shippings = [];
        setTimeout(() => this.shippings = res, 0.0001);
    }

    openUPSModal(shippingInfo) {
        const modalRef = this.modalService.open(AddUPSModalComponent, {
            size: 'lg',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart'
        })
        modalRef.componentInstance.shippingInfo = shippingInfo;
        modalRef.result.then((result) => {
            `Result ${result}`
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        })
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
