import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer-two',
    templateUrl: './footer-two.component.html',
    styleUrls: ['./footer-two.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, DatePipe]
})
export class FooterTwoComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // default Logo
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  
  public today: number = Date.now();
  
  constructor() { }

  ngOnInit(): void {
  }

}
