<div class="row align-items-center justify-content-center">
    <div class="col-12">
        <div class="row align-items-center justify-content-between">
            <div class="col-6 d-flex align-items-center d-none" *ngIf="allPaginatedProducts.length">
                <check-box (checkedChange)="onSelectAll($event)" [name]="'all'" [checked]="areAllItemsSelected"
                           [indeterminate]="selectedProductIds.length > 0 && !areAllItemsSelected">
                </check-box>
                <label *ngIf="selectedProductIds.length > 0; else selectAll" for="all" class="mb-0 ml-1 d-flex align-items-end  ">
                    <h3 class="l-text-primary mb-0 mr-1 font-weight-bold">
                        {{selectedStyleItemsCount ?? 0}}
                    </h3>
                    <span class="font-size-14"> Item(s) Selected</span>l
                </label>
                <ng-template #selectAll>
                    <label for="all" class="text-secondary mb-0 ml-3 font-size-16 ">{{'Select_All'|translate}} </label>
                </ng-template>
            </div>

            <div class="col-6 align-items-center text-right d-none" *ngIf="selectedProductIds.length > 0">
                <span ngbDropdown *ngIf="isAdmin">
                    <button type="button" class="drpdown-list" id="dropdownBasic1" ngbDropdownToggle>
                        Admin action
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="onMakePromotionClick()">Make A Promotion</button>
                        <button ngbDropdownItem (click)="addGlobalLineSheet()">Add New Global Line Sheet</button>
                        <button ngbDropdownItem (click)="updateGlobalLineSheet()">Add Product To Existed Global Line Sheet</button>
                        <button ngbDropdownItem (click)="setMinQuantity()">Set Min Quantity </button>
                        <button ngbDropdownItem (click)="setLeadTime()">Set Lead Time</button>
                    </div>
                </span>
                <span ngbDropdown class="ms-1">
                    <button type="button" class="drpdown-list" id="dropdownBasic1" ngbDropdownToggle>
                        Line Sheet action
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem [disabled]="isExportPdfLoading" (click)="onExportPdf()">
                            <div *ngIf="isExportPdfLoading" class="spinner-border spinner-border-sm mr-1 text-secondary"
                                 role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            {{isExportPdfLoading ? 'Downloading' : 'Download as PDF'}}
                        </button>
                        <button ngbDropdownItem [disabled]="isDownloadLineSheetLoading" (click)="createLinesheet()">
                            <div *ngIf="isDownloadLineSheetLoading" class="spinner-border spinner-border-sm mr-1 text-secondary"
                                 role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            {{isDownloadLineSheetLoading ? 'Downloading' : 'Download as Excel'}}
                        </button>
                    </div>
                </span>
                <!--<a title="Add to Cart" class="btn btn-bold p-2 " (click)="addToCart()">
            <i class="ti-shopping-cart"></i> Add to Cart
        </a>-->
            </div>
        </div>
    </div>
        

    <div class="product-wrapper-grid w-100" [ngClass]="layoutView">

        <ng-container *ngIf="!isLoadingProducts else ListItemSkeleton">
            <ng-container *ngIf="allPaginatedProducts.length else noResult">
                <div class="product-list-section mt-0 g-3 g-sm-4 product-list-section row row-cols-2 row-cols-lg-2 row-cols-md-3 row-cols-xl-3 row-cols-xxl-4">
                    <div class="" *ngFor="let product of allPaginatedProducts;let i=index">
                        <app-products-list-item [product]="allPaginatedProducts[i]" [index]="i" [isAdmin]="isAdmin"
                                                [isLoggedIn]="isLoggedIn" (select)="onProductSelectionChanged($event)"
                                                [currency]="productService?.Currency" [thumbnail]="false" [cartModal]="true">
                        </app-products-list-item>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <div class="row">
            <ng-template #ListItemSkeleton>
                <div class="col-12">
                    <div class="col-3 pb-2 pt-3">
                        <ngx-skeleton-loader [theme]="skeletonThemeCheckBox"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of [].constructor(12)">
                    <div class="product-box">
                        <app-products-list-item-skeleton></app-products-list-item-skeleton>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <ng-template #noResult>
        <div class="collection-no-data no-data-added">
            <img src="assets/User-section/images/inner-page/no-product.png" class="img-fluid mb-4">
            <h4>{{'Sorry_Could_Find_Products'|translate}}</h4>
            <p>{{'Please_check_if_you_have_misspelt_something'|translate}}</p>
            <!--<a [routerLink]="['/products']" class="btn btn-bold">continue shopping</a>-->
        </div>
    </ng-template>

    <!--<infinite-scroll [noMoreProducts]="noMoreProducts" [noResult]="!allPaginatedProducts.length" (scroll)="onScroll()">
    </infinite-scroll>-->