import { BaseSearchModel } from "src/app/core/model/base-search-model";
export interface Brands{
    ID: number;
    BrandLogo: any;
    BrandCoverImage: any;
    Facebook: string;
    Instagram: string;
    Twitter: string;
}

export interface BrandSettings {
    id: number;
    isSubCategoryShowing: boolean;
    theme: string
    enableFreeShippingOnMinimumPurchase: boolean
    minimumPurchaseAmountForFreeShipping: number
    preferredPaymentMethod: string
}

export class BrandsSearchParamsModel extends BaseSearchModel {
}
