import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, NgIf, NgFor, UpperCasePipe, CurrencyPipe, CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { BasketService } from '../../services/basket.service';
import { EditQuantityItemCart, GetBasketItemSummaryVM, GetCurrentBasketSummaryVM, StyleItemSummaryVM } from '../../classes/basket';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DiscountPipe } from '../../pipes/discount.pipe';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from 'src/app/shared/widgets/NewTheme/button/button.component';
import { Store } from '@ngxs/store';
import { ToggleSidebarCart } from '../../../Store-Section/shared/action/cart.action';
import { EditQuantityItemGuestCart } from '../../classes/basket/edit-quantity.model';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, NgFor, UpperCasePipe, CurrencyPipe, DiscountPipe, ButtonComponent, CommonModule, TranslationModule]
})
export class SettingsComponent implements OnInit {
  styleImageBaseUrl: string = environment.style_image_base_url;
    showPanelClass: string = 'fixed-cart';
  public cartStyle: string = 'cart_sidebar';
  defaultImageSrc: string = 'assets/images/product/placeholder.jpg';
  search: boolean = false;
  data: GetCurrentBasketSummaryVM;
  isLoggedIn: boolean = false; 
  confettiItems = Array.from({ length: 150 }, (_, index) => index);
  shippingFreeAmt: number = environment.shippingFreeAmt;
  shippingCal: number = 0;
  confetti: number = 0;

  languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private store: Store,
        private translate: TranslateService, public productService: ProductService, private il8nService: il8nService,
        public basketService: BasketService, private router: Router, private authService: AuthService) {
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }


    ngOnInit(): void {
        console.log('Settings Component');
        this.authService.onLoginStateChanged.subscribe(isLogin => {
            this.isLoggedIn = isLogin;

            if (this.isLoggedIn) {
                console.log("User is logged in and going to load user basket");
                this.loadUserBasket();
            } else {
                console.log("User is not logged in and going to load guest basket");
                this.loadGuestBasket();
            }
        });
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    }

    loadUserBasket() {
       
        this.basketService.getCurrentBasketSummary().subscribe(res => {
            this.basketService.cartItems.subscribe(response => this.data = response);
        });

        this.basketService.onUpdateBasketSummary.subscribe(() => {
            this.basketService.cartItems.subscribe(response => {
                this.data = response;
                console.log("load data-----------------------------------")
                console.log(this.data)

                this.shippingCal = (this.data.subtotalPrice * 100) / this.shippingFreeAmt;
                if (this.shippingCal > 100) {
                    this.shippingCal = 100;
                    if (this.confetti == 0) {
                        this.confetti = 1;
                        setTimeout(() => {
                            this.confetti = 2;
                        }, 4000);
                    }
                } else {
                    this.confetti = 0;
                }
            });
        }); 
 
    }

    loadGuestBasket() {
        this.basketService.getGuestBasketSummary().subscribe(guestCartSummary => {

            this.data = guestCartSummary;
            console.log("load data when user is not logged in-----------------------------------")
            console.log(this.data)
        });

        this.basketService.onGuestUpdateBasketSummary.subscribe(() => {
            this.basketService.guestCartItems.subscribe(response => {
                this.data = response;

                this.shippingCal = (this.data.subtotalPrice * 100) / this.shippingFreeAmt;
                if (this.shippingCal > 100) {
                    this.shippingCal = 100;
                    if (this.confetti == 0) {
                        this.confetti = 1;
                        setTimeout(() => {
                            this.confetti = 2;
                        }, 4000);
                    }
                } else {
                    this.confetti = 0;
                }
            });
        });
    }


  searchToggle() {
    this.search = !this.search;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

    // Increment
    increment(productid: number, count: number) {
        if (productid != null) {
            console.log(productid)
            console.log(count)
            if (this.isLoggedIn) {
                const updateCount = new EditQuantityItemCart(productid, count + 1);
                this.basketService.updateBasketItemQuantity(updateCount);
            } else {
                const updateCount = new EditQuantityItemGuestCart(productid, count + 1);
                this.basketService.updateGuestBasketItemQuantity(updateCount);
            }
        }
    }

    // Decrement
    decrement(productid: number, count: number) {
        if (productid != null) {
            if (this.isLoggedIn) {
                const updateCount = new EditQuantityItemCart(productid, count - 1);
                this.basketService.updateBasketItemQuantity(updateCount);
            } else {
                const updateCount = new EditQuantityItemGuestCart(productid, count - 1);
                this.basketService.updateGuestBasketItemQuantity(updateCount);
            }
        }
    }
  removeItem(itemSummary: GetBasketItemSummaryVM) {
        if (this.isLoggedIn) {
            console.log("Delete from Basket when user is logged in", itemSummary.id);
            this.basketService.deleteBasketItems([itemSummary.id]);
        } else {
            console.log("Delete from Basket when user is not logged in", itemSummary.id);
            this.basketService.deleteGuestBasketItem(itemSummary.id);
        }
    }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
    }

    onViewCartClick() {
        const currentUrl = this.router.url;

        if (currentUrl === '/shop/cart') {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/shop/cart']);
            });
        } else {
            this.router.navigate(['/shop/cart']);
        }

        this.cartToggle(false);
    }

    cartToggle(value: boolean) { 

        if (value) {
            this.showPanelClass = 'fixed-cart show';
        }
        else {
            this.showPanelClass = 'fixed-cart';
        }
        this.store.dispatch(new ToggleSidebarCart(value));

    }
}
