<!--<div [ngClass]="IshomeComponent ? 'row g-4 ng-dirty ng-invalid ng-touched' : 'row g-4 ng-pristine ng-invalid ng-touched'">
  <div [ngClass]="IshomeComponent ? 'col-12' : 'col-12'">-->
    <!--<h2 [ngClass]="IshomeComponent ? 'home-modal-title' : 'modal-title'" *ngIf="!IshomeComponent">{{title}}</h2>-->
    <!--<button type="button" *ngIf="!IshomeComponent" class="close" data-dismiss="modal" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>-->
  <!--</div>
  <div [ngClass]="IshomeComponent ? '' : 'modal-body py-2 px-3'">
    <ng-content></ng-content>
  </div>
  <div [class]="(operatorsCenter? 'col-12':'col-12')">
    <button type="button"  [ngClass]="IshomeComponent ? 'btn btn-animation justify-content-center w-100' : 'btn btn-animation justify-content-center w-100'" *ngIf="confirmText" [disabled]="disableConfirmBtn"
      (click)="onConfirm()">{{confirmText}}</button>
    <button type="button" [ngClass]="IshomeComponent ? 'btn btn-animation btn-md d-inline-block theme-bg-color btn-white' : 'btn l-btn-secondary'" *ngIf="cancelText" (click)="onCancel()">{{cancelText}}</button>
  </div>
</div>-->


<div [ngClass]="IshomeComponent ? 'row ng-invalid ng-touched p-4' : 'row ng-invalid ng-touched p-4'">
    
    <div [ngClass]="IshomeComponent ? '' : 'modal-body'">
        <ng-content></ng-content>
    </div>
    <div class="button-box justify-content-center">
        <button type="button" [ngClass]="IshomeComponent ? 'btn btn-animation justify-content-center w-100' : 'btn btn-theme'" *ngIf="confirmText" [disabled]="disableConfirmBtn"
                (click)="onConfirm()">
            {{confirmText}}
        </button>
        <button type="button" [ngClass]="IshomeComponent ? 'btn btn-md btn-secondary fw-bold' : 'btn btn-md btn-secondary fw-bold'" *ngIf="cancelText" (click)="onCancel()">
            {{cancelText}}
        </button>
    </div>
</div>