<section class="p-0">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-section p-0">
                    <h3 class="title my-5">no item found</h3>
                </div>
            </div>
        </div>
    </div>
</section>