<app-modal [title]="'Featured Line Sheets'" [cancelText]="'Cancel'" [confirmText]="'Confirm'" (cancel)="onModalCanceled()"
  (confirm)="onModalConfirmed()">
  <div *ngIf="globalLinesheetList else noResult">
    <div *ngFor="let promotion of globalLinesheetList; let i = index"
      class="linesheet-item d-flex justify-content-start align-items-center px-2">
      <input class="promotion-item-radio pl-1" type="radio" [(ngModel)]="selectedGlobalLinesheet"
        [id]="'linesheeet-item-' + i" [name]="'linesheeet-item-' + i" [value]="promotion">
      <label class="linesheeet-item-label py-3 px-2 m-0 cursor-pointer w-100"
        [for]="'linesheeet-item-' + i">{{promotion.title}}</label>
    </div>
  </div>
</app-modal>

<ng-template #noResult>
  <app-no-item-found *ngIf="!isLoading"></app-no-item-found>
</ng-template>