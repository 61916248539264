import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { environment } from 'src/environments/environment';
import { ShippingInfo, ShippingInfoSearchParamsModel } from '../classes/shipping-info.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class ShippingInfoService extends HttpClientManager {

    controllerName: string = "ShippingInfo";
    shippingInfoApiUrl: string = `${environment.api_base_url}${this.controllerName}`
    onUpdateItem: Subject<any>;
    searchRequest: ShippingInfoSearchParamsModel;
    constructor(
        httpHandler: HttpHandler,
        protected router: Router,
        private modalService: NgbModal,
        private toastrService: ToastrService) {
        super(httpHandler, router);
        this.onUpdateItem = new Subject();
        this.searchRequest = new ShippingInfoSearchParamsModel();
    }

    getFedexShippingInfo(): Observable<PagingResult<ShippingInfo>> {
        return this.getAll<PagingResult<ShippingInfo>>(`${this.shippingInfoApiUrl}?serviceType=FEDEX_GROUND`)
            .pipe(map((promotionList: PagingResult<ShippingInfo>) => {
                return promotionList;
            }));
    }

    getUPSShippingInfo(): Observable<PagingResult<ShippingInfo>> {
        return this.getAll<PagingResult<ShippingInfo>>(`${this.shippingInfoApiUrl}?serviceType=UPS`)
            .pipe(map((promotionList: PagingResult<ShippingInfo>) => {
                return promotionList;
            }));
    }

    /*addShippingInfo(shippinginfo: FormData): any {
        return this.uploadFile<boolean>(this.shippingInfoApiUrl, shippinginfo)
            .subscribe(() => {
                this.onUpdateItem.next();
                this.toastrService.success('add successful.');
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            ); 
    }*/
    AddAccountDetails(Account: ShippingInfo) {
        return this.create<boolean>(this.shippingInfoApiUrl + '/AddAccount', Account)
            .pipe(tap(res => {
                if (res) {
                    this.refreshAccountList();
                    this.toastrService.success('Account added successfully');
                    this.modalService.dismissAll();
                }
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            ));
    }

    UpdateAccountDetails(Account: ShippingInfo) {
        return this.update<boolean>(this.shippingInfoApiUrl + '/UpdateAccount', Account).pipe(tap(res => {
            if (res) {
                this.refreshAccountList();
                this.modalService.dismissAll();
                this.toastrService.success('Account edited successfully.');
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }
    /*updateShippingInfo(shippinginfo: FormData) {

        return this.updateFile<boolean>(this.shippingInfoApiUrl, shippinginfo).pipe(tap(res => {
            this.onUpdateItem.next();
            this.toastrService.success('edited successful.');
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }*/

    removeShippingInfo(id: number) {
        this.deleteIDs<boolean>(this.shippingInfoApiUrl + `/Delete/${id}`, null).subscribe(res => {
            this.onUpdateItem.next();
            this.toastrService.success('delete successful.');
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            });
    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void { // TODO: make it a shared function in utility
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }

    private refreshAccountList() {
        this.onUpdateItem.next(true);
    }
}
