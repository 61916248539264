import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpHandler } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpClientManager, HttpManagerErrorResponse } from './../../core/helper/http-client-manager';
import { HorizontalFilterDto, ProductFilterDto } from './../classes/dynamic/product-filter.model';
import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
	filter?: {};
}

@Injectable({
	providedIn: 'root'
})

export class NavService extends HttpClientManager {
	controllerName: string = "StyleFilter";
	styleFilterApiUrl: string = `${environment.api_base_url}${this.controllerName}`

	screenWidth: any;
	leftMenuToggle: boolean = false;
	mainMenuToggle: boolean = false;
	panelMenuToggle: boolean = false;
	onUpdateItem: Subject<any>;
	public sidebarLoading: boolean = false;
	public search: boolean = false;

	constructor(
		httpHandler: HttpHandler,
		protected router: Router,
		private toastrService: ToastrService) {
		super(httpHandler, router);
		this.getMenuItems();
		this.onUpdateItem = new Subject();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	LEFTMENUITEMS: Menu[] = [
		{
			path: '/home', title: 'home', type: 'link', active: false
		},
		{
			path: '/products', title: 'Products', type: 'link'
		},
		{
			path: '/pages/aboutus', title: 'about us', type: 'link'
		},
		{
			path: '/pages/contact', title: 'contact us', type: 'link'
		},

	];

	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

	getMenuItems(): Observable<ProductFilterDto[]> {
		return this.getAll<ProductFilterDto[]>(this.styleFilterApiUrl + '/ProductFilter');
	}

	getHorizontalMenuItems(): Observable<HorizontalFilterDto[]> {
		return this.getAll<HorizontalFilterDto[]>(this.styleFilterApiUrl + '/HorizontalFilters');
	}

	addHorizontalFilter(filter: HorizontalFilterDto) {
		return this.create<boolean>(this.styleFilterApiUrl , filter)
			.pipe(tap(res => {
				if (res) {
					this.refreshFilterList();
					this.toastrService.success('Filter added successfully');
				}
			},
				(error: HttpManagerErrorResponse) => {
					this.showAllErrorMessages(error);
				}
			));
	}

	updateHorizontalFilter(filter: HorizontalFilterDto) {
		return this.update<boolean>(this.styleFilterApiUrl, filter).pipe(tap(res => {
			if (res) {
				this.refreshFilterList();
				
				this.toastrService.success('Filter edited successfully.');
			}
		},
			(error: HttpManagerErrorResponse) => {
				this.showAllErrorMessages(error);
			}));
	}

	removeHorizontalFilter(ids: number[]) {
		this.deleteIDs<boolean>(this.styleFilterApiUrl, ids).subscribe(res => {
			if (res) {
				this.refreshFilterList();
				this.toastrService.success('Filter deleted successfully.');
			}
		},
			(error: HttpManagerErrorResponse) => {
				this.showAllErrorMessages(error);
			});
	}

	private refreshFilterList() {
		this.onUpdateItem.next(true);
	}

	showAllErrorMessages(error: HttpManagerErrorResponse): void { // TODO: make it a shared function in utility
		error.msg.forEach((msg: string) => {
			this.toastrService.error(msg);
		});
	}
}
