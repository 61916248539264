import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAccountVM } from 'src/app/shared/classes/Payment-Accounts/add-account.model';
import { PaymentAccountService } from 'src/app/shared/services/payment-accounts.service';
import { StripeConnectionParam } from 'src/app/shared/classes/Payment-Accounts/StripeConnectionParam';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgClass, NgIf } from '@angular/common';
import { ModalComponent } from '../../../../shared/components/modal/modal/modal.component';   
import { ShippingInfoService } from '../../../../shared/services/shipping-info.service';
import { ShippingInfo } from '../../../../shared/classes/shipping-info.model';


@Component({
    selector: 'app-add-ups-modal',
    templateUrl: './add-ups-modal.component.html',
    styleUrls: ['./add-ups-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, FormsModule, ReactiveFormsModule, NgClass, NgIf]
})
export class AddUPSModalComponent implements OnInit {
    @Input() shippingInfo: ShippingInfo;
    accountname: string;
    isLoading: boolean;
    authorizationUrl: string;
    selectedOption: boolean;
    id: number;
    isDisabled: boolean = true;
    AccountStatus = [{ "id": "0", "name": "InActive" }, { "id": "1", "name": "Active" }];
    title: string = "Add New Account";
    form: FormGroup;
    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private shippingInfoService: ShippingInfoService,
        private modalService: NgbModal,
        private router: Router,
        private ToastrService: ToastrService
    ) { }

    ngOnInit() {
        
        if (this.shippingInfo) {
            console.log("Shipping info for edit:", this.shippingInfo);
            this.title = "Edit Shipping Setting";
        }
        this.createForm();

    }
    createForm() {
        this.form = this.formBuilder.group({
            id: [this.shippingInfo ? this.shippingInfo.id : ''],
            serviceType: [this.shippingInfo ? this.shippingInfo.serviceType : 'UPS'],
            isActive: [this.shippingInfo ? this.shippingInfo.isActive : true, Validators.required],
            accountNumber: [this.shippingInfo ? this.shippingInfo.accountNumber : '', Validators.required],
            accessLicenseNumber: [this.shippingInfo ? this.shippingInfo.accessLicenseNumber : '', Validators.required],
            username: [this.shippingInfo ? this.shippingInfo.username : '', Validators.required],
            password: [this.shippingInfo ? this.shippingInfo.password : '', Validators.required],
            ship: [this.shippingInfo ? this.shippingInfo.ship : '', Validators.required],
            track: [this.shippingInfo ? this.shippingInfo.track : '', Validators.required],
            rateAndTransitTimes: [this.shippingInfo ? this.shippingInfo.rateAndTransitTimes : '', Validators.required],
            cancel: [this.shippingInfo ? this.shippingInfo.cancel : '', Validators.required],
            shipperName: [this.shippingInfo ? this.shippingInfo.shipperName : '', Validators.required],
            streetLines: [this.shippingInfo ? this.shippingInfo.streetLines : '', Validators.required],
            city: [this.shippingInfo ? this.shippingInfo.city : '', Validators.required],
            countryCode: [this.shippingInfo ? this.shippingInfo.countryCode : '', Validators.required],
            state: [this.shippingInfo ? this.shippingInfo.state : '', Validators.required],
            postalCode: [this.shippingInfo ? this.shippingInfo.postalCode : '', Validators.required],
            phoneNumber: [this.shippingInfo ? this.shippingInfo.phoneNumber : '', Validators.required],
            companyName: [this.shippingInfo ? this.shippingInfo.companyName : '', Validators.required],
            weightUnits: [this.shippingInfo ? this.shippingInfo.weightUnits : '', Validators.required],
            weightOfEachGlassInKg: [this.shippingInfo ? this.shippingInfo.weightOfEachGlassInKg : '', Validators.required],
            numberOfGlassInEachBox: [this.shippingInfo ? this.shippingInfo.numberOfGlassInEachBox : '', Validators.required],
            upsPaymentType: [this.shippingInfo ? this.shippingInfo.upsPaymentType : '', Validators.required],
            upsServiceCode: [this.shippingInfo ? this.shippingInfo.upsServiceCode : '', Validators.required],
            upsPackageTypeCode: [this.shippingInfo ? this.shippingInfo.upsPackageTypeCode : '', Validators.required]
        });

        console.log("form:", this.form);

        if (this.shippingInfo) {
            this.id = this.shippingInfo.id;
            this.selectedOption = this.shippingInfo.isActive;
        } else {
            this.selectedOption = true;
        }
    }

    onModalCanceled() {
        this.modalService.dismissAll();
    }
    
    
    onModalConfirmed() {
        if (this.form.invalid) return
        this.submitForm();
    }
    submitForm() {
        const formValue = this.form.value;
        this.shippingInfo
            ? this.shippingInfoService.UpdateAccountDetails(formValue).subscribe()
            : this.shippingInfoService.AddAccountDetails(formValue).subscribe()
    }

    onStateChange() { 
        this.form.controls['isActive'].setValue(this.selectedOption);
    }

}
