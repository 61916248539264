import { Injectable } from '@angular/core';
import { HttpHandler } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { map, startWith, tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Images, Product, ProductSearchParamsModel, Variants } from '../classes/product';
import { environment } from 'src/environments/environment';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { Router } from '@angular/router';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { ColorEnum } from '../classes/color-enum';
import { StyleMinQuantityRequest } from '../classes/product/style-min-quantity-request.model';
import { GetAllProductIdsModel } from '../classes/product/get-all-product-Ids.model';
import { StyleLeadTimeRequestVM } from '../classes/product/style-lead-time-request.model';

const state = {
    products: JSON.parse(localStorage['products'] || '[]'),
    wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
    compare: JSON.parse(localStorage['compareItems'] || '[]'),
    cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
    providedIn: 'root'
})
export class ProductService extends HttpClientManager {

    Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
    OpenCart: boolean = false;
    productItems: Product[] = [];
    Products$: Observable<Product[]>;
    controllerName: string = "Style";
    styleApiUrl: string = `${environment.api_base_url}${this.controllerName}`
    initDetailProduct: Subject<any>;
    clearTag: Subject<any>;
    availableInStock: Subject<any>;
    onChangeGrid: Subject<any>;

    constructor(httpHandler: HttpHandler,
        protected router: Router,
        public toastrService: ToastrService) {
        super(httpHandler, router);
        this.initDetailProduct = new Subject();
        this.clearTag = new Subject();
        this.availableInStock = new Subject();
        this.onChangeGrid = new Subject();
    }

    /*
      ---------------------------------------------
      ---------------  Product  -------------------
      ---------------------------------------------
    */

    /*getAllProductsList(): Observable<Product[]> {
        return this.getAll<Product[]>(this.styleApiUrl)
            .pipe(
                map((product: Product[]) => {
                    const products = product.xData;
                    console.log("get products from api: ", product);
                    return products;
                })
            );
    }*/

    getAllProductsList(searchParams: any): Observable<Product[]> {
        return this.getAll<any>(this.styleApiUrl, searchParams) // Assuming the response type is any
            .pipe(
                map((response: any) => {

                    console.log("get products from api: ", response);
                    const products = response.xData as Product[]; // Assuming xData contains the array of products
                    return products;
                })
            );
    }

    getAllStyleIDs(searchRequest: ProductSearchParamsModel): Observable<GetAllProductIdsModel[]> {
        return this.getAll<GetAllProductIdsModel[]>(this.styleApiUrl + '/AllStyleIDs', searchRequest).pipe(map(idList => {
            return idList;
        }));
    }

    getAllProducts(searchRequest: ProductSearchParamsModel): Observable<PagingResult<Product>> {
        console.log("getAllProducts Service called with this parameter: ", searchRequest);
        return this.getAll<PagingResult<Product>>(this.styleApiUrl, searchRequest).pipe(map(productsPagingResult => {
            return productsPagingResult;
        }));
    }

    // Get Products By Id
    getProductById(id: string): Observable<Product> {
        return this.getById(this.styleApiUrl, +id)
            .pipe(
                map((product: Product) => {
                    this.setProductImages(product);
                    this.initDetailProduct.next(product);
                    return product;
                }),
                catchError((error: HttpManagerErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    // TODO: temp code to handle product images
    private setProductImages(product: Product): void {
        // product.images = [];
        // product.variants.forEach(variant => {
        //     product.images.push(this.getImage(variant));
        //     product.images.push(this.getImage(variant));
        //     product.images.push(this.getImage(variant));
        //     product.images.push(this.getImage(variant));
        //     product.images.push(this.getImage(variant));
        // });
    }

    // TODO: temp code to handle product images
    imageIndex: number = 1;
    private getImage(variant: Variants): Images {
        const src = `/assets/images/product/fashion/${this.imageIndex}.jpg`;
        if (this.imageIndex == 52)
            this.imageIndex = 1;
        else
            this.imageIndex++;

        const image: Images = {
            id: this.imageIndex,
            image_id: variant?.image_id,
            src,
            alt: variant?.color,
            variant_id: [variant?.id]
        };

        return image;
    }

    // Product
    private get products(): Observable<Product[]> {
        this.Products$ = this.get<Product[]>('assets/data/products.json').pipe(map(data => data));
        this.Products$.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
        return this.Products$ = this.Products$.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    }

    // Get Products
    public get getProducts(): Observable<Product[]> {
        return this.getAllProducts(new ProductSearchParamsModel()).pipe(map(productsPagingResult => {

            // this.filterProductsWithCorrectColor(productsPagingResult.xData);

            return productsPagingResult.xData;
        })); // TODO
    }

    filterProductsWithCorrectColor(productItems: Product[]): Product[] { // TODO: temp code
        productItems.forEach(product => {
            this.setProductImages(product);
            product.variants = [...product.variants.filter(v => ColorEnum[v.color] !== undefined)]; // TODO: temp code
        });

        productItems = [...productItems.filter(p => p.variants.length !== 0)]; // TODO: temp code
        return productItems;
    }


    /*
      ---------------------------------------------
      ---------------  Wish List  -----------------
      ---------------------------------------------
    */

    // Get Wishlist Items
    public get wishlistItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.wishlist);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    public get getGridLocalStorageValue() {
        return localStorage.getItem('grid');
    }

    setGridLocalStorageValue(value) {
        localStorage.setItem('grid', value);
        this.onChangeGrid.next();
    }

    // Add to Wishlist
    public addToWishlist(product): any {
        const wishlistItem = state.wishlist.find(item => item.id === product.id)
        if (!wishlistItem) {
            state.wishlist.push({
                ...product
            })
        }
       // this.toastrService.success('Product has been added in wishlist.');
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    // Remove Wishlist items
    public removeWishlistItem(product: Product): any {
        const index = state.wishlist.indexOf(product);
        state.wishlist.splice(index, 1);
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true
    }

    /*
      ---------------------------------------------
      -------------  Compare Product  -------------
      ---------------------------------------------
    */

    // Get Compare Items
    public get compareItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.compare);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    // Add to Compare
    public addToCompare(product): any {
        const compareItem = state.compare.find(item => item.id === product.id)
        if (!compareItem) {
            state.compare.push({
                ...product
            })
        }
        this.toastrService.success('Product has been added in compare.');
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    // Remove Compare items
    public removeCompareItem(product: Product): any {
        const index = state.compare.indexOf(product);
        state.compare.splice(index, 1);
        localStorage.setItem("compareItems", JSON.stringify(state.compare));
        return true
    }

    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Get Cart Items
    public get cartItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.cart);
            observer.complete();
        });
        return <Observable<Product[]>>itemsStream;
    }

    public addToCart(product: Product): any {
        this.addProductToCart(product)
        this.showSuccessMsg([product]);
        this.OpenCart = true; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
    }

    public addMultipleProductToCart(products: Product[]): any {
        products.forEach(p => this.addProductToCart(p))
        this.showSuccessMsg(products);
        this.OpenCart = true; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
    }

    private addProductToCart(product: Product) {
        const cartItem = state.cart.find(item => item.id === product.id);
        const qty = product.quantity ? product.quantity : 1;
        const items = cartItem ? cartItem : product;
        const stock = this.calculateStockCounts(items, qty);

        if (!stock) return false

        cartItem
            ? cartItem.quantity += qty
            : state.cart.push({ ...product, quantity: qty })
    }

    setMinQuantity(request: StyleMinQuantityRequest) {
        return this.create<StyleMinQuantityRequest>(this.styleApiUrl + '/SetMinQuantities', request)
            .pipe(tap(() => {
                this.toastrService.success("Style min quantity has been changed successfully.");
            }, (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error)
            }))
    }

    setLeadTime(request: StyleLeadTimeRequestVM) {
        return this.create<StyleLeadTimeRequestVM>(this.styleApiUrl + '/LeadTime', request)
            .pipe(tap(() => {
                this.toastrService.success("Style lead time has been changed successfully.");
            }, (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error)
            }))
    }

    private showSuccessMsg(products: Product[]) {
        const count = products.length;
        const successMsg = count == 1 ? 'Product has been added in the cart.' : count + ' ' + 'Products have been added in the cart.';
        this.toastrService.success(successMsg);
    }



    // Update Cart Quantity
    public updateCartQuantity(product: Product, quantity: number): Product | boolean {
        return state.cart.find((items, index) => {
            if (items.id === product.id) {
                const qty = state.cart[index].quantity + quantity
                const stock = this.calculateStockCounts(state.cart[index], quantity)
                if (qty !== 0 && stock) {
                    state.cart[index].quantity = qty
                }
                localStorage.setItem("cartItems", JSON.stringify(state.cart));
                return true
            }
        })
    }

    // Calculate Stock Counts
    public calculateStockCounts(product, quantity) {
        const qty = product.quantity + quantity
        const stock = product.variants[0].atsQuantity;
        if (stock < qty || stock == 0) {
            // this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
            product.isError = true;
            this.availableInStock.next(product);
            return false

        }
        product.isError = false;
        this.availableInStock.next(product);
        return true
    }

    // Remove Cart items
    public removeCartItem(product: Product): any {
        const index = state.cart.indexOf(product);
        state.cart.splice(index, 1);
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
    }

    // Total amount 
    public cartTotalAmount(): Observable<number> {
        return this.cartItems.pipe(map((product: Product[]) => {
            return product.reduce((prev, curr: Product) => {
                let price = curr.price;
                if (curr.discount) {
                    price = curr.price - (curr.price * curr.discount / 100)
                }
                return (prev + price * curr.quantity) * this.Currency.price;
            }, 0);
        }));
    }

    /*
      ---------------------------------------------
      ------------  Filter Product  ---------------
      ---------------------------------------------
    */

    // Get Product Filter
    public filterProducts(filter: any): Observable<Product[]> { // TODO
        return this.getProducts.pipe(map(product =>
            product.filter((item: Product) => {
                if (!filter.length) return true
                const Tags = filter.some((prev) => { // Match Tags
                    if (item.tags) {
                        if (item.tags.includes(prev)) {
                            return prev
                        }
                    }
                })
                return Tags
            })
        ));
    }

    // Sorting Filter
    public sortProducts(products: Product[], payload: string): any {

        if (payload === 'ascending') {
            return products.sort((a, b) => {
                if (a.id < b.id) {
                    return -1;
                } else if (a.id > b.id) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'a-z') {
            return products.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'z-a') {
            return products.sort((a, b) => {
                if (a.title > b.title) {
                    return -1;
                } else if (a.title < b.title) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'low') {
            return products.sort((a, b) => {
                if (a.price < b.price) {
                    return -1;
                } else if (a.price > b.price) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 'high') {
            return products.sort((a, b) => {
                if (a.price > b.price) {
                    return -1;
                } else if (a.price < b.price) {
                    return 1;
                }
                return 0;
            })
        }
    }

    /*
      ---------------------------------------------
      ------------- Product Pagination  -----------
      ---------------------------------------------
    */
    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 25) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        let paginateRange = 3;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void {
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
}
