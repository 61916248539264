
<div class="search-count theme-form d-flex align-items-center">

    <input type="text" class="form-control search-input" placeholder="Search"
           [(ngModel)]="searchProductName" (ngModelChange)="searchName($event)">
    <img src="/assets/images/icon/search-icon.png"/>
</div>
<!--<div class="product-top-filter">
    <div class="row">
        <div class="col-12 pl-lg-2">
            <div class="product-filter-content">-->
                <!--<div class="search-count theme-form py-2 d-flex align-items-center">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="text" class="form-control search-input" placeholder="Search"
                        [(ngModel)]="searchProductName" (ngModelChange)="searchName($event)">
                </div>-->
                <!-- <div class="collection-view">
                    <ul>
                        <li (click)="setLayoutView('grid-view')">
                            <i class="fa fa-th grid-layout-view"></i>
                        </li>
                        <li (click)="setLayoutView('list-view')">
                            <i class="fa fa-list-ul list-layout-view"></i>
                        </li>
                    </ul>
                </div> -->

                <!--<div class="d-flex justify-content-start tab-page col px-0">
                    <a class="col-5 col-lg-6 col-md-6 d-flex align-items-center justify-content-center cursor-pointer btn-light"
                        [class.active]="isAvailable" (click)="onAvailabilityChange(true)">Available</a>
                    <a class="col-7 col-lg-6 col-md-6 d-flex align-items-center justify-content-center cursor-pointer btn-light"
                        [class.active]="!isAvailable" (click)="onAvailabilityChange(false)">Upcoming Stock </a>
                </div>-->

                <!--<div class="collection-grid-view" *ngIf="layoutView == 'grid-view'">
                    <ul>
                        <li (click)="setGridLayout(gridSizeEnum.twoCols)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 94 94">
                                <g id="_2x2" data-name="2x2" transform="translate(-8577 -1478)">
                                    <rect id="Rectangle_3455" data-name="Rectangle 3455" width="42" height="42"
                                        transform="translate(8577 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3460" data-name="Rectangle 3460" width="42" height="42"
                                        transform="translate(8629 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3461" data-name="Rectangle 3461" width="42" height="42"
                                        transform="translate(8577 1530)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3462" data-name="Rectangle 3462" width="42" height="42"
                                        transform="translate(8629 1530)" fill="#FFD9F8" />
                                </g>
                            </svg>
                        </li>
                        <li (click)="setGridLayout(gridSizeEnum.threeCols)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 95 95">
                                <g id="_3x3" data-name="3x3" transform="translate(-8577 -1478)">
                                    <rect id="Rectangle_3455" data-name="Rectangle 3455" width="25" height="25"
                                        transform="translate(8577 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3464" data-name="Rectangle 3464" width="25" height="25"
                                        transform="translate(8577 1513)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3467" data-name="Rectangle 3467" width="25" height="25"
                                        transform="translate(8577 1548)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3455-2" data-name="Rectangle 3455" width="25" height="25"
                                        transform="translate(8612 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3465" data-name="Rectangle 3465" width="25" height="25"
                                        transform="translate(8612 1513)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3468" data-name="Rectangle 3468" width="25" height="25"
                                        transform="translate(8612 1548)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3455-3" data-name="Rectangle 3455" width="25" height="25"
                                        transform="translate(8647 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3463" data-name="Rectangle 3463" width="25" height="25"
                                        transform="translate(8647 1513)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3466" data-name="Rectangle 3466" width="25" height="25"
                                        transform="translate(8647 1548)" fill="#FFD9F8" />
                                </g>
                            </svg>
                        </li>
                        <li (click)="setGridLayout(gridSizeEnum.fourCols)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 94.851 94.85">
                                <g id="_4x4" data-name="4x4" transform="translate(-8577 -1478)">
                                    <rect id="Rectangle_3455" data-name="Rectangle 3455" width="16.25" height="16.25"
                                        transform="translate(8577 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3469" data-name="Rectangle 3469" width="16.25" height="16.25"
                                        transform="translate(8603.2 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3470" data-name="Rectangle 3470" width="16.25" height="16.25"
                                        transform="translate(8629.4 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3471" data-name="Rectangle 3471" width="16.25" height="16.25"
                                        transform="translate(8655.601 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3472" data-name="Rectangle 3472" width="16.25" height="16.25"
                                        transform="translate(8577 1504.2)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3476" data-name="Rectangle 3476" width="16.25" height="16.25"
                                        transform="translate(8577 1530.4)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3480" data-name="Rectangle 3480" width="16.25" height="16.25"
                                        transform="translate(8577 1556.6)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3473" data-name="Rectangle 3473" width="16.25" height="16.25"
                                        transform="translate(8603.2 1504.2)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3477" data-name="Rectangle 3477" width="16.25" height="16.25"
                                        transform="translate(8603.2 1530.4)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3481" data-name="Rectangle 3481" width="16.25" height="16.25"
                                        transform="translate(8603.2 1556.6)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3474" data-name="Rectangle 3474" width="16.25" height="16.25"
                                        transform="translate(8629.4 1504.2)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3478" data-name="Rectangle 3478" width="16.25" height="16.25"
                                        transform="translate(8629.4 1530.4)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3482" data-name="Rectangle 3482" width="16.25" height="16.25"
                                        transform="translate(8629.4 1556.6)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3475" data-name="Rectangle 3475" width="16.25" height="16.25"
                                        transform="translate(8655.601 1504.2)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3479" data-name="Rectangle 3479" width="16.25" height="16.25"
                                        transform="translate(8655.601 1530.4)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3483" data-name="Rectangle 3483" width="16.25" height="16.25"
                                        transform="translate(8655.601 1556.6)" fill="#FFD9F8" />
                                </g>
                            </svg>

                        </li>
                        <li (click)="setGridLayout(gridSizeEnum.sixCols)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 95 95">
                                <g id="_5x5" data-name="5x5" transform="translate(-8577 -1478)">
                                    <rect id="Rectangle_3455" data-name="Rectangle 3455" width="11" height="11"
                                        transform="translate(8577 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3484" data-name="Rectangle 3484" width="11" height="11"
                                        transform="translate(8598 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3485" data-name="Rectangle 3485" width="11" height="11"
                                        transform="translate(8619 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3486" data-name="Rectangle 3486" width="11" height="11"
                                        transform="translate(8640 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3487" data-name="Rectangle 3487" width="11" height="11"
                                        transform="translate(8661 1478)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3488" data-name="Rectangle 3488" width="11" height="11"
                                        transform="translate(8577 1499)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3489" data-name="Rectangle 3489" width="11" height="11"
                                        transform="translate(8598 1499)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3490" data-name="Rectangle 3490" width="11" height="11"
                                        transform="translate(8619 1499)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3491" data-name="Rectangle 3491" width="11" height="11"
                                        transform="translate(8640 1499)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3492" data-name="Rectangle 3492" width="11" height="11"
                                        transform="translate(8661 1499)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3493" data-name="Rectangle 3493" width="11" height="11"
                                        transform="translate(8577 1520)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3494" data-name="Rectangle 3494" width="11" height="11"
                                        transform="translate(8598 1520)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3495" data-name="Rectangle 3495" width="11" height="11"
                                        transform="translate(8619 1520)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3496" data-name="Rectangle 3496" width="11" height="11"
                                        transform="translate(8640 1520)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3497" data-name="Rectangle 3497" width="11" height="11"
                                        transform="translate(8661 1520)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3498" data-name="Rectangle 3498" width="11" height="11"
                                        transform="translate(8577 1541)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3499" data-name="Rectangle 3499" width="11" height="11"
                                        transform="translate(8598 1541)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3500" data-name="Rectangle 3500" width="11" height="11"
                                        transform="translate(8619 1541)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3501" data-name="Rectangle 3501" width="11" height="11"
                                        transform="translate(8640 1541)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3502" data-name="Rectangle 3502" width="11" height="11"
                                        transform="translate(8661 1541)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3503" data-name="Rectangle 3503" width="11" height="11"
                                        transform="translate(8577 1562)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3504" data-name="Rectangle 3504" width="11" height="11"
                                        transform="translate(8598 1562)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3505" data-name="Rectangle 3505" width="11" height="11"
                                        transform="translate(8619 1562)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3506" data-name="Rectangle 3506" width="11" height="11"
                                        transform="translate(8640 1562)" fill="#FFD9F8" />
                                    <rect id="Rectangle_3507" data-name="Rectangle 3507" width="11" height="11"
                                        transform="translate(8661 1562)" fill="#FFD9F8" />
                                </g>
                            </svg>

                        </li>
                    </ul>
                </div>-->
                <!--<div class="product-page-filter d-flex">
                    <select (change)="sorting($event)">
                        <option value="">Sorting Items</option>
                        <option value="a-z" sortBy="styleName" sortType="asc"
                            [selected]="_sortBy == 'styleName' && _sortType=='asc'">Alphabetically A-Z</option>
                        <option value="z-a" sortBy="styleName" sortType="desc"
                            [selected]="_sortBy == 'styleName' && _sortType=='desc'">Alphabetically Z-A</option>
                        <option value="low" sortBy="price" sortType="asc"
                            [selected]="_sortBy == 'price' && _sortType=='asc'">Price Low to High</option>
                        <option value="high" sortBy="price" sortType="desc"
                            [selected]="_sortBy == 'price' && _sortType=='desc'">Price High to Low</option>
                    </select>
                </div>-->
            <!--</div>
        </div>
    </div>
</div>-->