import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer-three',
    templateUrl: './footer-three.component.html',
    styleUrls: ['./footer-three.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, DatePipe]
})
export class FooterThreeComponent implements OnInit {

  @Input() class: string; // Default class 
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo

  public today: number = Date.now();
  
  constructor() { }

  ngOnInit(): void {
  }

}
