import { ViewportScroller, NgIf, NgFor, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterLink } from '@angular/router';
import { Options } from 'ng5-slider';
import { ProductService } from 'src/app/shared/services/product.service';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { ThemeCategoriesComponent } from 'src/app/shared/NewTheme/category/theme-categories/theme-categories.component';
import {
    DynamicFilterEnum,
    DynamicFilterListModel,
    DynamicFilterNameEnum,
    SliderFilterModel, 
    TagData
} from '../shared/classes/dynamic-fIlters';
import { SimpleLinesheetDto } from '../shared/classes/linesheet/get-simplelinesheet.model';
import { Product, ProductSearchParamsModel } from '../shared/classes/product';
import { GetAllProductIdsModel } from '../shared/classes/product/get-all-product-Ids.model';
import { GetPromotion } from '../shared/classes/promotion';
import { FilterDynamicService } from '../shared/services/filter-dynamic.service';
import { ProductViewModel } from './shared/model/product-view-model';
import { SelectAllEmitArgs } from './shared/model/select-all-emit-args';
import { CategoryDTO } from '../shared/classes/category-filter/category-dto.model';
import { ProductsListComponent } from './products-list/products-list.component';
import { GridComponent } from '../shared/widgets/grid/grid.component';
import { PromotionListComponent } from './promotion-list/promotion-list.component';
import { SideFilterSkeletonComponent } from './side-filter-skeleton/side-filter-skeleton.component';
import { CategoryFilterComponent } from './category-filter/category-filter.component';
import { SlideFiltersComponent } from '../shared/widgets/slide-filters/slide-filters.component';
import { LoaderComponent } from '../Store-Section/shared/components/widgets/loader/loader.component';
import { Paginate } from '../shared/classes/product/Paginate';
import { CommonModule } from '@angular/common';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
import { HorizontalLoaderComponent } from '../shared/components/loader/horizontal-loader/horizontal-loader.component';
@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        NgIf,
        CategoryFilterComponent,
        SideFilterSkeletonComponent,
        PromotionListComponent,
        NgFor,
        GridComponent,
        ProductsListComponent,
        BreadcrumbComponent,
        ThemeCategoriesComponent,
        SlideFiltersComponent,
        TitleCasePipe,
        LoaderComponent,
        CommonModule,HorizontalLoaderComponent,
        TranslationModule
    ],
})
export class ProductsComponent implements OnInit {
    @ViewChild(CategoryFilterComponent) categoryFilter: CategoryFilterComponent;
    grid: string = 'col-xl-3 col-6';
    layoutView: string = 'grid-view';
    products: any[] = [];
    priceSliderOptions: Options = { floor: 0, ceil: 100 };
    availableItemsSliderOptions: Options = { floor: 0, ceil: 5000 };
    minPrice: number = this.priceSliderOptions.floor;
    maxPrice: number = this.priceSliderOptions.ceil;
    minAvailable: number = this.availableItemsSliderOptions.floor;
    maxAvailable: number = this.availableItemsSliderOptions.ceil;
    tagsData: TagData[] = [];
    category: string;
    //paginate: any = {}; // Pagination use only
    sortBy: string; // Sorting Order
    searchProductName: string;
    sortType: string; // Sorting Order
    mobileSidebar: boolean = false;
    dynamicFilter: DynamicFilterListModel;
    dynamicFilterEnum = DynamicFilterEnum;
    dynamicFilterNameEnum = DynamicFilterNameEnum;
    filterEnumValues: string[] = Object.values(DynamicFilterEnum);
    totalItemsCount: number;
    queryParams: Params;
    pageNumber: number = 1;
    currentPage: number = 1;
    allPaginatedProducts: ProductViewModel[] = [];
    sortTypeValue: string;
    timer: any;
    promotionId: string;
    linesheetId: number;
    promotionTitle: string;
    linesheetTitle: string;
    isLoadingProducts: boolean = true;
    isLoadingFilter: boolean = true;
    selectedProductIds: GetAllProductIdsModel[] = [];
    isAvailable: boolean = true;
    selectedProductMenu: string;
    showPriceFilter: boolean = true;
    scrollToProducts: boolean = true;
    showProducts: boolean = true;
    @Input() showPromotionProducts: boolean = false;
    prevScrollPosition = 0;
    logoClass = "";
    currentFilterName: DynamicFilterEnum;
    categoryFilterlist: CategoryDTO;
    loadingProducts: boolean = false;
    itemsPerPage: number = 25;
    @Output() tagsDataChange: EventEmitter<any[]> = new EventEmitter<any[]>(); 
    paginate: Paginate;
    TotalNoOfPagination: number = 0;
    pages: number[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public productService: ProductService,
        private translate: TranslateService, private il8nService: il8nService,
        public dynamicFilterService: FilterDynamicService,
        private viewScroller: ViewportScroller) {
        this.filterEnumValues = Object.values(DynamicFilterEnum);
        this.getFilterByCategory();
        this.route.queryParams.subscribe((params: Params) => {
            this.products = [];
            let keys = Object.keys(params);
            if ((params.product || params.promotionId || params.linesheetId) && keys.length === 1) {
                this.resetSliderOptions();
            }
            this.setTagsDeta(params);
            this.storeQueryParams(params);
            this.sortTypeValue = this.tagsData?.find(
                (x) => x.key == DynamicFilterEnum.sortType
            )?.value;
            this.getAll(this.searchResult(this.pageNumber));
            //console.log("Search Result: ", this.searchResult());
            this.getAllDynamicFilters(); 
        });
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
       

    }
     
    pageSet(page: number) {  
        if (page < 1 || page > this.pages.length) return;
        this.currentPage = page;
        this.getAll(this.searchResult(this.currentPage));
    }
    setPagination() {
       // const totalPages = Math.ceil(this.totalItemsCount / this.itemsPerPage);
        this.TotalNoOfPagination = Math.ceil(this.totalItemsCount / this.itemsPerPage);
        this.pages = Array(this.TotalNoOfPagination).fill(0).map((x, i) => i + 1);
        console.log(this.pages);
    }
    isFirstPage(): boolean {
        return this.currentPage === 1;
    }

    isLastPage(): boolean {
        return this.currentPage === this.pages.length;
    }
    ngOnInit() {
        //this.navigateToSection();
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    } 
    navigateToSection() {
        const sectionElement = document.getElementById('products');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
    selectedPromotionInit(promotion: GetPromotion) {
        this.selectedPromotionDetail(promotion);
        this.scrollToProductItems();
        this.showProducts = true;
    }

    onPromotionChange(promotion: GetPromotion) {
        this.selectedPromotionDetail(promotion);
        this.updateRouteSelectedPromotion();

    }

    globalLineSheetList(linesheet: SimpleLinesheetDto) {
        this.selectedLinesheetDetail(linesheet);
        this.updateRouteSelectedLineSheet();
    }

    selectedLinesheetInit(linesheet: SimpleLinesheetDto) {
        this.selectedLinesheetDetail(linesheet);
        this.scrollToProductItems();
        this.productService.toastrService.info("By clicking on Line Sheets actions you can download PDF/Excel.");
    }

    onMinQuantityChanged() {
        //this.resetPageNumber();
        //this.getAll(this.searchResult());
    }

    onLeadTimeChange() {
        this.resetPageNumber();
        this.getAll(this.searchResult());
    }

    private storeQueryParams(params: Params) {

        /*
        this.minPrice = params.minPrice ? +params.minPrice : this.minPrice;
        this.maxPrice = params.maxPrice ? +params.maxPrice : this.maxPrice;
        this.minAvailable = params.minAvailable
            ? +params.minAvailable
            : this.minAvailable;
        this.maxAvailable = params.maxAvailable
            ? +params.maxAvailable
            : this.maxAvailable;
        this.searchProductName = params.productTitle ? params.productTitle : null;
        this.sortBy = params.sortBy ? params.sortBy : '';
        this.sortType = params.sortType ? params.sortType : '';
        this.promotionId = params.promotionId ? params.promotionId : '';
        this.linesheetId = params.linesheetId ? params.linesheetId : '';
        this.selectedProductMenu = params.product ? params.product : '';
        */
        //if (!params.product) {
        //    params = { [DynamicFilterEnum.product]: "Suplement" }
        //}
        //else{
        //    params = { [DynamicFilterEnum.product]: "Suplement" }
        //}

        this.queryParams = params;
    }

    private getFilterByCategory() {
        this.dynamicFilterService.getCategoryFilter().subscribe(
            (categories: CategoryDTO) => {
                this.categoryFilterlist = categories;
                this.isLoadingFilter = false;
            },
            (error) => {
                this.isLoadingFilter = false;
            }
        );
    }

    private getAllDynamicFilters() {
        this.dynamicFilterService.getAllDynamicFilters(this.queryParams).subscribe(
            (filters) => {
                this.dynamicFilter = filters;

                this.isLoadingFilter = false;
            },
            (error) => {
                this.isLoadingFilter = false;
            }
        );
    }


    private getAll(searchRequest?: ProductSearchParamsModel): void {
        if (this.showProducts) {
            this.loadingProducts = true;
            this.productService.getAllProducts(searchRequest).subscribe(
                (pagingResult) => {
                    console.log("pagingResult.xData", pagingResult.xData)
                    this.products = pagingResult.xData || [];
                    if (this.isAvailable && !this.products.length) {
                        //this.productService.toastrService.info("No available item found. Please change filters to see available ones.")
                        this.prepareNewPageProductsToResult(pagingResult.xData, searchRequest);
                        this.totalItemsCount = pagingResult.xCount;
                        this.isAvailable = false;
                        this.loadingProducts = false;
                        this.isLoadingProducts = false; 
                        this.setPagination();
                    } else {
                        this.allPaginatedProducts = [];
                        this.prepareNewPageProductsToResult(pagingResult.xData, searchRequest);
                        this.totalItemsCount = pagingResult.xCount;
                        this.loadingProducts = false;
                        this.isLoadingProducts = false; 
                        this.setPagination();
                    }
                    if (searchRequest.product != null && searchRequest.product != '') {
                        this.scrollToProducts = true;
                        this.scrollToProductItems();
                    }

                },
                (error) => {
                    this.isLoadingProducts = false;
                    this.loadingProducts = false;
                }
            );
            //alert("sss")

        }
        else {
            this.isLoadingProducts = false;
            this.loadingProducts = false;
        }

        //this.scrollToDivTop('products')
    }

    wait(ms) {
        var start = new Date().getTime();
        console.log("Start :", start);
        var end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    }

    private prepareNewPageProductsToResult(products: Product[], searchRequest?: ProductSearchParamsModel): void {
        if (this.pageNumber !== searchRequest.page) {
            this.pageNumber = searchRequest.page;
            this.allPaginatedProducts = [...this.allPaginatedProducts, ...products.map(p => new ProductViewModel(p))];
        } else {
            this.allPaginatedProducts = products.map(p => new ProductViewModel(p));
        }
        console.log(this.allPaginatedProducts);
        console.log("prepareNewPageProductsToResult END");
    }

    scrollChange(pageNumber: number): void {
        this.getAll(this.searchResult(pageNumber));
    }

    selectAllChange(arg: SelectAllEmitArgs): void {
        
        if (arg.checked) {
            this.productService
                .getAllStyleIDs(this.searchResult(arg.pageNumber))
                .subscribe((response) => {
                    
                    this.selectedProductIds = response
                    console.log("this.selectedProductIds", this.selectedProductIds);
                    this.syncSelectedProductsList()
                });
        } else {
            this.selectedProductIds = [];
            this.syncSelectedProductsList()
        }
        
    }
    changeSelectedProductId(product: GetAllProductIdsModel) {
        if (this.selectedProductIds.some(p => p.styleId === product.styleId)) {
            this.selectedProductIds = this.selectedProductIds.filter(f => f.styleId !== product.styleId)
        } else {
            this.selectedProductIds = [...this.selectedProductIds, product]
        }
        console.log("this.selectedProductIds changeselected", this.selectedProductIds);
        this.syncSelectedProductsList()
    }

    syncSelectedProductsList() {
        
        this.allPaginatedProducts = this.allPaginatedProducts
            .map((p: ProductViewModel) => {
                p.selected = this.selectedProductIds.some(s => p.id === s.styleId)
                return p;
            });

    }

    updateFilter(event: { filterValues: any[], filterType: DynamicFilterEnum }) {
        this.isLoadingProducts = true;
        if (event.filterType === DynamicFilterEnum.category) {
            const categoryIds = event.filterValues.map(item => item.CategoryName);
            const categoryIdsString = categoryIds.join(',');
            this.updateRoute(categoryIdsString, event.filterType);
        }
        if (event.filterType === DynamicFilterEnum.subCategory) {
            const subcategoryIds = event.filterValues.map(item => item.SubCategoryName);
            const subcategoryIdsString = subcategoryIds.join(',');
            this.updateRoute(subcategoryIdsString, event.filterType);
        }
        else if (event.filterType === DynamicFilterEnum.color) {
            const colors = event.filterValues.map(item => item.Color);
            const colorsString = colors.join(',');
            this.updateRoute(colorsString, event.filterType);
        }
        else if (event.filterType === DynamicFilterEnum.size) {
            const size = event.filterValues.map(item => item.Size);
            const sizeString = size.join(',');
            this.updateRoute(sizeString, event.filterType);
        }
        else if (event.filterType === DynamicFilterEnum.rating) {
            const rating = event.filterValues.map(item => item.Rating);
            const ratingString = rating.join(',');
            this.updateRoute(ratingString, event.filterType);
        }
        else if (event.filterType === DynamicFilterEnum.priceRange) {
            const prizerange = event.filterValues.map(item => item.PriceRange);
            const prizerangeString = prizerange.join(',');
            this.updateRoute(prizerangeString, event.filterType);
        }
        else if (event.filterType === DynamicFilterEnum.productTitle) {
            const title = event.filterValues.map(item => item.productTitle);
            const titleString = title.join(',');
            this.updateRoute(titleString, event.filterType);
        }
        else if (event.filterType === DynamicFilterEnum.all) {
            this.removeAllFilters();
        }

        this.scrollToProducts = false;
        this.showProducts = true;

    }

    updatePriceFilter(queryParams: SliderFilterModel) {
        if (queryParams.max != this.maxPrice) {
            this.maxPrice = queryParams.max;
            this.updateRoute(this.maxPrice, DynamicFilterEnum.maxPrice);
        }
        if (queryParams.min != this.minPrice) {
            this.minPrice = queryParams.min;
            this.updateRoute(this.minPrice, DynamicFilterEnum.minPrice);
        }
        this.scrollToProducts = false;
    }

    updateAvailableItemsFilter(queryParams: SliderFilterModel) {
        if (queryParams.max != this.maxAvailable) {
            this.maxAvailable = queryParams.max;
            this.updateRoute(this.maxAvailable, DynamicFilterEnum.maxAvailable);
        }
        if (queryParams.min != this.minAvailable) {
            this.minAvailable = queryParams.min;
            this.updateRoute(this.minAvailable, DynamicFilterEnum.minAvailable);
        }
        this.scrollToProducts = false;
    }

    sortByFilter(value) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                sortBy: value.sortBy ? value.sortBy : null,
                sortType: value.sortType ? value.sortType : null,
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });
    }

    searchProductNameUpdate(value: string) {
        if (this.timer) clearTimeout(this.timer);

        const deley = 500;
        this.timer = setTimeout(() => {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { productTitle: value ? value : null },
                queryParamsHandling: 'merge',
                skipLocationChange: false,
            });
        }, deley);
    }

    removePriceTag(tag) {
        if (tag.key == DynamicFilterEnum.minPrice)
            this.minPrice = this.priceSliderOptions.floor;
        if (tag.key == DynamicFilterEnum.maxPrice)
            this.maxPrice = this.priceSliderOptions.ceil;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [tag.key]: null },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });
    }

    removeAvailableItemsTag(tag) {
        if (tag.key == DynamicFilterEnum.minAvailable)
            this.minAvailable = this.availableItemsSliderOptions.floor;
        if (tag.key == DynamicFilterEnum.maxAvailable)
            this.maxAvailable = this.availableItemsSliderOptions.ceil;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [tag.key]: null },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });
    }

    removeSortTag(tag) {
        
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [tag.key]: null, sortType: null }, // TODO
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });
    }

    /*removeTag(tag) {
        //if (tag.key === 'promotionId' || tag.key === 'linesheetId') {
        //    this.selectAllChange({ pageNumber: 1, checked: false });
        //}
        
        let currentKeyParams: string[] = this.queryParams[tag.key].split(',');
        const paramIndex = currentKeyParams.findIndex((v) => v === tag.value);
        const x = currentKeyParams.splice(paramIndex, 1);
        const newValue = currentKeyParams.length
            ? currentKeyParams.join(',')
            : null;

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [tag.key]: newValue },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });

        this.tagsDataChange.emit(this.tagsData);

        this.productService.clearTag.next(tag);

        if (this.categoryFilter) {
            this.categoryFilter.removeCategory(tag.value); // Call the removeCategory method
        }
    }*/

    removeAllTags() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false,
        });
        this.productService.clearTag.next(null);
        this.resetSliderOptions();
        this.showProducts = true;
        this.categoryFilter.resetFilters(); // Call the resetFilters method
    }

    updateGridLayout(value: string) {
        this.grid = value;
        this.productService.setGridLocalStorageValue(value);
    }

    updateLayoutView(value: string) {
        this.layoutView = value;
        if (value == 'list-view') this.grid = 'col-lg-12';
        else this.grid = 'col-xl-3 col-md-6';
    }

    toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
    }

    isAvailableChanged(availability) {
        this.resetPageNumber();
        //this.isAvailable = availability;
        //this.getAll(this.searchResult());
    }

    private setTagsDeta(params: Params) {
        console.log("tags param: ", params);
        this.tagsData = [];
        this.resetPageNumber();
        this.filterEnumValues.forEach((key: string) => {
            const paramValues = params[key] ? params[key].split(',') : [];

            if (paramValues.length > 0) {
                paramValues.forEach((value: string) => {
                    this.tagsData.push(new TagData(key, value));
                });
            }

        });
        //if (this.tagsData.length < 1) {
        //    this.tagsData.push(new TagData("product", "Suplement"));
        //}
    }


    private updateRoute(
        queryParams: string | number,
        filterName: DynamicFilterEnum
    ) {
        console.log("Query Params: ", queryParams);
        console.log("Filter Name: ", filterName);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [filterName]: queryParams },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
            fragment: "smooth"
        });
    }



    private removeAllFilters() {
        const queryParamsToRemove = {};

        // List of all filter types you want to clear
        const filterTypesToClear = [
            DynamicFilterEnum.category,
            DynamicFilterEnum.subCategory,
            DynamicFilterEnum.color,
            DynamicFilterEnum.size,
            DynamicFilterEnum.rating,
            DynamicFilterEnum.priceRange,
            DynamicFilterEnum.productTitle,
        ];

        // Construct queryParamsToRemove based on filter types
        filterTypesToClear.forEach(filterType => {
            queryParamsToRemove[filterType] = undefined;
        });

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParamsToRemove,
            queryParamsHandling: 'merge',
            skipLocationChange: false,
            fragment: 'smooth'
        });
    }

    private filterBuilder(key?: DynamicFilterEnum): string {
        const filters = this.tagsData.filter((res) => res.key === key);

        const values = filters.map((items) => items.value);

        return values.join(',');
    }

    private filterBuilderValue(key?: DynamicFilterEnum): any {
        const values = this.tagsData.find((res) => res.key === key)?.value;
        return values;
    }

    private searchResult(pageNumber?: number) {
        const searchReq: ProductSearchParamsModel = new ProductSearchParamsModel();
        searchReq.product = this.filterBuilder(DynamicFilterEnum.product);

        searchReq.category = this.filterBuilder(DynamicFilterEnum.category);
        searchReq.subCategory = this.filterBuilder(DynamicFilterEnum.subCategory);
        searchReq.color = this.filterBuilder(DynamicFilterEnum.color);
        searchReq.size = this.filterBuilder(DynamicFilterEnum.size);
        searchReq.priceRange = this.filterBuilder(DynamicFilterEnum.priceRange);
        searchReq.rating = this.filterBuilder(DynamicFilterEnum.rating);
        searchReq.contains = this.filterBuilder(DynamicFilterEnum.contains);
        searchReq.productTitle = this.filterBuilder(DynamicFilterEnum.productTitle);

        searchReq.sortBy = this.filterBuilderValue(DynamicFilterEnum.sortBy);
        searchReq.search = this.filterBuilderValue(DynamicFilterEnum.search);
        searchReq.sortType = this.filterBuilderValue(DynamicFilterEnum.sortType);
        searchReq.promotionId = this.filterBuilderValue(
            DynamicFilterEnum.promotionId
        );
        searchReq.linesheetId = this.filterBuilderValue(
            DynamicFilterEnum.linesheetId
        );
        searchReq.page = pageNumber ? pageNumber : this.pageNumber;
        searchReq.isAvailable = this.isAvailable;
        searchReq.pageSize = this.itemsPerPage;
        return searchReq;
    }

    private resetPageNumber() {
        this.pageNumber = 1;
    }

    private resetSliderOptions() {
        this.minPrice = this.priceSliderOptions.floor;
        this.maxPrice = this.priceSliderOptions.ceil;
        this.minAvailable = this.availableItemsSliderOptions.floor;
        this.maxAvailable = this.availableItemsSliderOptions.ceil;
    }

    private updateRouteSelectedPromotion() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [DynamicFilterEnum.promotionId]: this.promotionId },
            skipLocationChange: false,
            fragment: "products"
        });
    }

    public scrollToProducts1() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [DynamicFilterEnum.promotionId]: this.promotionId },
            skipLocationChange: false,
            fragment: "products"
        });
    }

    private selectedPromotionDetail(promotion: GetPromotion) {
        this.promotionId = promotion.id;
        this.promotionTitle = promotion.title;
    }

    private selectedLinesheetDetail(linesheet: SimpleLinesheetDto) {
        this.linesheetId = linesheet.id;
        this.linesheetTitle = linesheet.title;
    }

    private updateRouteSelectedLineSheet() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [DynamicFilterEnum.linesheetId]: this.linesheetId },
            skipLocationChange: false,
            fragment: "products"
        });
        this.showProducts = true;
        this.scrollToDivTop('products')
    }

    scrollToDivTop(divId: string) {
        const element = document.getElementById(divId);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth'
            });
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    public scrollToProductItems() {
        if (this.scrollToProducts) {
            this.viewScroller.setOffset([80, 80]);
            this.viewScroller.scrollToAnchor('products');
        }
    }

    getDivTopPosition() {
        const divElement = document.getElementById('products');
    const rect = divElement.getBoundingClientRect();
    return rect.top + window.pageYOffset;
  }

  // Function to be called when the div reaches the top
  checkDivPosition() {
    const topPosition = this.getDivTopPosition();
      //console.log('Top position of the div:', topPosition);
      
    // Add your logic here based on the top position
    if (topPosition <= 0) {
      // The div is at the top of the viewport
    }
  }

  // Add a scroll event listener to the window
  //window.addEventListener('scroll', checkDivPosition);

    getDivTopPosition1(divId) {
        const divElement = document.getElementById(divId);
        //console.log(divElement)
    //const rect = divElement[0].getBoundingClientRect();
    return  window.scrollY;
    }

    //window.addEventListener('scroll', function() {
    //    const divIdToTrack = 'myDiv'; // Replace with the ID of the div you want to track
    //    const topPosition = getDivTopPosition(divIdToTrack);
    //    console.log('Top position of the div:', topPosition);
    //});

    checkScrollPosition() {

        const divIdToTrack = 'products'; // Replace with the ID of the div you want to track
        const topPosition = this.getDivTopPosition();
        
        if ((topPosition) < window.scrollY) {
            this.logoClass = "filter-sticky";
            //console.log('Top position of the div:', topPosition);
            //console.log("window.scrollY", window.scrollY);
        }
        else {
            this.logoClass = "";
        }
        //const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        
        //if (this.isScrollingUp()) {
        //    this.logoClass = "";
        //} else {
        //    if (scrollTop > 15) {
        //        this.logoClass = "logo-hide";
        //    }
        //}

        //this.checkScrollDirection();
    }

    isScrollingUp(): boolean {
        const currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
        if (currentScrollPosition > this.prevScrollPosition) {
            // Scroll is moving down
            this.prevScrollPosition = currentScrollPosition;
            return false;
        } else if (currentScrollPosition < this.prevScrollPosition) {
            // Scroll is moving up
            this.prevScrollPosition = currentScrollPosition;
            return true;
        }
        return false;
    }


    applyCategoryFilter(categoryName: string): void {
        //console.log('ProductListingComponent: Applying filter for category:', categoryName);
        this.categoryFilter.appliedFilter(null ,categoryName);
    }
}
