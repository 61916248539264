<div class="theme-card card-border admin-card">
    <div class="">
        <h5 for="coupon" class="m-0 font-size-16 font-weight-bold d-flex justify-content-between align-items-center">
            Coupons
            <a title="Add New Coupon" class="btn btn-bold" (click)="openCouponModal(null)">
                Add New Coupon
            </a>
        </h5>

    </div>

    <table *ngIf="coupons?.length else noResult" datatable [dtOptions]="dtOptions"
           class="card-body border-top table-striped p-0 w-100 table-item-nowrap">
        <thead class="bg-light">
            <tr>
                <th scope="col" class="pl-4 sort-none">#</th>
                <th scope="col">Title</th>
                <th scope="col">Code</th>
                <th scope="col">Coupon Type</th>
                <th scope="col">Discount</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Remaining Usage</th>
                <th scope="col">IsActive</th>
                <th scope="col" class="sort-none">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let coupon of coupons ; index as i">
                <td class="pl-4">{{ (i+1 )}}</td>
                <td>{{coupon.title}}</td>
                <td>{{coupon.couponCode}}</td>
                <td>{{coupon.couponType}}</td>
                <!--<td>
                    {{ coupon.discountPercentage ? "Percentage" : (coupon.discountedPrice !== null ? "Flat Discount": '') }}
                </td>-->
                <td>
                     {{ coupon.discountPercentage ? coupon.discountPercentage + '% OFF' : (coupon.discountedPrice !== null ? '$' + coupon.discountedPrice + ' Flat OFF' : '') }}
                </td>
                <!--<td>
                    {{ coupon.discountPercentage }}
                </td>-->
                <td>{{ coupon.startDate }}</td>
                <td>{{ coupon.endDate }}</td>
                <td>{{coupon.remainingUsage}}</td>
                <td>{{coupon.isActive ? "Active" : "InActive"}}</td>
                <td class="cursor-default">
                    <div class="d-flex flex-nowrap">
                        <a class="cursor-pointer pr-2" (click)="openCouponModal(coupon)" ngbTooltip="Edit"
                           container="body">
                            <i class="fa fa-pencil panel-action-btn btn-sm"></i>
                        </a>
                        <a class="cursor-pointer" (click)="removeCoupon(coupon.id)" ngbTooltip="Delete"
                           container="body">
                            <i class="fa fa-trash trash text-error panel-action-remove-btn btn-sm"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <ng-template #noResult>
        <!--<app-grid-skeleton-loader *ngIf="isLoading"></app-grid-skeleton-loader>-->
        <app-no-item-found *ngIf="!isLoading"></app-no-item-found>
        <!--<app-loader [isLoading]="isLoading"></app-loader>-->
        <app-loader [loaderClass]="'custom-loader-wrapper blur-bg'"></app-loader>
    </ng-template>

</div>