<app-breadcrumb [title]="'log_in'|translate" [breadcrumb]="'log_in'|translate"></app-breadcrumb>
<section class="log-in-section background-image-2 section-b-space">
    <div class="container-fluid-lg w-100">
        <div class="row">
            <div class="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div class="image-contain">
                    <img src="assets/User-section/images/inner-page/log-in.png" class="img-fluid" alt="log-in">
                </div>
            </div>
            <div class="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <!--<app-alert></app-alert>-->
                <div class="log-in-box">
                    <div class="log-in-title">
                        <h3>{{ 'welcome_to_ecom'|translate  }}</h3>
                        <h4>{{ 'register_your_account'|translate }}</h4>
                    </div>
                    <div class="input-box">
                        <app-modal [title]="title"  [confirmText]="'login'|translate" (cancel)="onModalCanceled()" [IshomeComponent]="true"
                                   (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">
                            <form class="row g-4" [formGroup]="form" (keydown.enter)="onModalConfirmed()">
                                <div class="col-12">
                                    <div class="form-floating log-in-form">
                                        <input type="email" class="form-control" id="Email" name="Email" placeholder="Enter Email" [formControl]="form.controls['Email']"
                                               [ngClass]="{'is-invalid': form.get('Email').invalid && form.get('Email').touched }" />
                                        <span *ngIf="form.get('Email').hasError('required') && form.get('Email').touched ||
                                          form.get('Email').hasError('email') && form.get('Email').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>

                                        <div *ngIf="form.get('Email').touched && form.get('Email').errors?.['required']" class="invalid-feedback">
                                            {{ 'email_is_required' | translate }}
                                        </div>
                                        <div *ngIf="form.get('Email').touched && form.get('Email').errors?.['email']" class="invalid-feedback">
                                            {{ 'invalid_email' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating theme-form-floating log-in-form">
                                        <input type="password" class="form-control" id="Password" name="Password" placeholder="Enter Password" [formControl]="form.controls['Password']"
                                               [ngClass]="form.get('Password').hasError('required') && form.get('Password').touched ||
                                            form.get('Password').hasError('minlength') && form.get('Password').touched ? 'is-invalid': ''" />
                                        <span *ngIf="form.get('Password').hasError('required') && form.get('Password').touched ||
                                                form.get('Password').hasError('minlength') && form.get('Password').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <div *ngIf="form.get('Password').touched && form.get('Password').errors?.['required']" class="invalid-feedback">
                                            {{ 'password_is_required' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="forgot-box">
                                        <div class="form-check ps-0 m-0 remember-box">
                                            <input class="checkbox_animated check-box d-none" type="checkbox" id="remeberme">
                                            <label class="form-check-label d-none" for="remeberme">{{ 'remember_me' |translate }}</label>
                                        </div>
                                        <a [routerLink]="['/account/Forget_password']" class="forgot-password">{{ 'forgot_password' |translate}}?</a>
                                    </div>
                                </div>
                            </form>
                        </app-modal>
                    </div>
                    <div class="other-log-in">
                        <h6>{{ 'or' |translate}}</h6>
                    </div>
                    <div class="sign-up-box">
                        <h4>{{ "dont_have_an_account" |translate}}?</h4>
                        <a [routerLink]="['/account/register']">{{ 'sign_up' |translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>







<!--<ng-container *ngIf="form">
    <app-modal [title]="title" [cancelText]="'Cancel'" [confirmText]="confirmText" (cancel)="onModalCanceled()" [IshomeComponent]="true"
               (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">

        <form class="theme-form" [formGroup]="form" (keydown.enter)="onModalConfirmed()">
            <div class="form-row">
                <div class="col-md-12 mb-6">
                    <label for="LastName">Email *</label>
                    <input type="email" class="form-control" id="Email" name="Email" placeholder="Enter Email" [formControl]="form.controls['Email']"
                           [ngClass]="{'is-invalid': form.get('Email').invalid && form.get('Email').touched }" />
                    <span *ngIf="form.get('Email').hasError('required') && form.get('Email').touched ||
                          form.get('Email').hasError('email') && form.get('Email').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>
                </div>

                <div class="col-md-12 mb-6">
                    <label for="Password">Password *</label>
                    <input type="password" class="form-control" id="Password" name="Password" placeholder="Enter Password" [formControl]="form.controls['Password']"
                           [ngClass]="form.get('Password').hasError('required') && form.get('Password').touched ||
                           form.get('Password').hasError('minlength') && form.get('Password').touched ? 'is-invalid': ''" />
                    <span *ngIf="form.get('Password').hasError('required') && form.get('Password').touched ||
                          form.get('Password').hasError('minlength') && form.get('Password').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>

                </div>


            </div>
        </form>
        <p>
            Forgot your password?
            <a href="#" (click)="forgotPassword()">Click here</a>
        </p>
    </app-modal>
</ng-container>-->