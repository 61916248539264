export class AddPageVM {
    public constructor(init?: Partial<AddPageVM>) {
        Object.assign(this, init);
    }
    id: number | null;
    title: string;
    keyWords: string;
    summary: string;
    description: string;
    url: string;
    priority: number;
    isActive: boolean;
    isBanner: boolean;
    parentID: number | null;
}

export class ContactUsForm {
    
    name: string;
    email: string;
    phone: string;
    message: string;


    
}