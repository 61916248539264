import { Component, OnInit } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-skeleton-product-box',
    templateUrl: './skeleton-product-box.component.html',
    styleUrls: ['./skeleton-product-box.component.scss'],
    standalone: true,
    imports: [NgxSkeletonLoaderModule]
})
export class SkeletonProductBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
