import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { Injector, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { switchMap, catchError, tap, filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { AuthError } from './auth.model';
import { TokenModel } from '../model/token.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/account/login/login.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _injector: Injector,
    private _router: Router,
    private modalService: NgbModal
  ) {
    this._router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (!event.url.toLowerCase().includes('account/login'))
          this.currentUrl = event.url;
      });
  }

  _authService: AuthService;
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  currentUrl = "/";


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this._authService = this._injector.get(AuthService);
    const AuthReq: HttpRequest<any> = this.addAuthHeader(req);

    if (false && this._authService.isLoggedIn() && this._authService.isTokenExpired()) {
      return this.refreshToken()
        .pipe(
          switchMap(() => {
            const newReq: HttpRequest<any> = this.addAuthHeader(req);
            return next.handle(newReq)
              .pipe(catchError(err => {
                if (err.status === 403) {
                  console.log('==== UnAuthorized (403) pre ===');
                  this._router.navigate(['/account', 'unauthorized']);
                  return throwError(new Error(''));
                } else if (err.status === 401) {
                  console.log('==== UnAuthorized (401) pre ===');
                  this._authService.signOut();
                  this._router.navigate(['/account/login']);
                  return throwError(new Error(''));
                }
                else {
                  return throwError(err);
                }
              }));
          }),
          catchError(err => {
            if (err) {
              console.log('==== Refreshing token Failed ====');
              this._authService.signOut();
              this._router.navigate(['/account/login']);
              return throwError(new Error(''));
            }
            else {
              return throwError(err);
            }

          })
        );
    }
    else {
      return next.handle(AuthReq)
        .pipe(catchError((err: HttpErrorResponse) => {

          if (err.status === 401) {
            if (!this._authService.isLoggedIn()) {
              console.log('==== UnAuthorized ( 401 ) ===');
              this._authService.signOut(); // TODO: uncomment the arguments => redirect to login page with the return url
              this.openLogin()
              return throwError(new AuthError(401));
            }

            return this.refreshToken()
              .pipe(
                switchMap(() => {
                  console.log('==== refreshing token ===');
                  const newReq: HttpRequest<any> = this.addAuthHeader(req);
                  return next.handle(newReq)
                    .pipe(catchError(err => {
                      if (err.status === 403) {
                        console.log('==== Forbidden (403) ===');
                        // unauthorized redirection is now handled at http-manager
                        //this._router.navigate(['/account', 'unauthorized']);
                        return throwError(new AuthError(403));

                      }
                      else if (err.status === 401) {
                        console.log('==== UnAuthorized (401) ===');
                        console.log(this._router.routerState.snapshot.url);
                        this._authService.signOut();
                        return throwError(new AuthError(401));
                      }
                      else {
                        return throwError(err);
                      }
                    }));
                }),
                catchError(err => {
                  if (err) {
                    console.log('==== Refreshing token Failed ====');
                    this._authService.signOut();
                    return throwError(new AuthError(401));
                  }
                  else {
                    return throwError(err);
                  }
                })
              );
          }
          else if (err.status === 403) {
            console.log('==== Forbidden (403) ===');
            // unauthorized redirection is now handled at http-manager
            // this._router.navigate(['/account', 'unauthorized']);
            return throwError(new AuthError(403));

          }
          else {
            // return throwError(err);
          }

        }));
    }
  }

  addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    const token: TokenModel = this._authService.getToken();
    if (token) {
      return req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token.access_token) });
    }
    else {
      return req;
    }
  }

  refreshToken(): Observable<TokenModel> {
    console.log('==== Refreshing token ====');
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this._authService.refreshToken()
        .pipe(
          tap(() => {
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next();
          }, err => {
            console.log("================================================");
            console.log(err);
            console.log("================================================");
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next();
          })
        );
    }
  }

  private openLogin() {
    this.modalService.open(LoginComponent, {
      size: 'md',
      ariaLabelledBy: 'size-modal',
      centered: true,
      windowClass: 'SizeChart'
    })
  }
}

