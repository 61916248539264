export class GetUser {
   
    id: number;

    firstName: string;
  
    lastName: string;
  
    username: string;
  
    isActive: boolean;
  
    isNegotiator: boolean;
  
    roleIDs: number[];

    profileImage: any;
}