import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [NgIf, CommonModule]
})
export class ModalComponent {

  @Input() title: string;
  @Input() cancelText: string;
  @Input() confirmText: string;
  @Input() closeBtn: boolean = true;
  @Input() operatorsCenter: boolean = true;
    @Input() disableConfirmBtn: boolean = false;
    @Input() IshomeComponent: boolean = false;
  @Output() confirm = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();


  onConfirm() {
    this.confirm.emit()
  }

  onCancel() {
    this.cancel.emit()
  }

}
