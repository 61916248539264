import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-item-found',
    templateUrl: './no-item-found.component.html',
    styleUrls: ['./no-item-found.component.scss'],
    standalone: true
})
export class NoItemFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
