import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientManager, HttpManagerErrorResponse } from '../../core/helper/http-client-manager';
import { Observable, Subject } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './user.service';
import { AddEditCoupon, Coupon, CouponsSearchParamsModel } from '../classes/promotion/coupon.model';
import { PagingResult } from '../../core/model/paging-result.model';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CouponService extends HttpClientManager{

    controllerName: string = "Coupons";
    userAddressApiUrl: string = `${environment.api_base_url}${this.controllerName}`
    onUpdateItem: Subject<any>;
    onCouponChange: Subject<any>;

    constructor(
        httpHandler: HttpHandler,
        protected router: Router,
        private toastrService: ToastrService,
        private userService: UserService) {
        super(httpHandler, router);
        this.onUpdateItem = new Subject();
        this.onCouponChange = new Subject();
    }

    getAllCoupon(): Observable<Coupon[]> {
        return this.getAll<Coupon[]>(this.userAddressApiUrl)
            .pipe(
                map((couponList: Coupon[]) => {
                    return couponList;
                })
            );
    }

    getCoupons(searchRequest?: CouponsSearchParamsModel): Observable<PagingResult<Coupon>> {
        return this.getAll<PagingResult<Coupon>>(this.userAddressApiUrl, searchRequest)
            .pipe(map((couponsList: PagingResult<Coupon>) => {
                this.onCouponChange.next(couponsList);
                return couponsList;
            }));
    }

    /*addCoupons(addCoupon: FormData) {
        return this.create<boolean>(this.userAddressApiUrl, addCoupon)
            .pipe(tap(res => {
                if (res) {
                    this.onUpdateItem.next();
                    this.toastrService.success('Coupon added successfully');
                }
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            ));
    }

    updateCoupons(editCoupon: FormData) {
        return this.update<boolean>(this.userAddressApiUrl, editCoupon).pipe(tap(res => {
            if (res) {
                this.onUpdateItem.next();
                this.toastrService.success('Coupon edited successfully.');
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }*/

    addCoupons(coupon: FormData) {
        console.log("couponformData:", coupon);
        return this.uploadFile<boolean>(this.userAddressApiUrl, coupon)
            .pipe(
                tap(() => {
                    this.onUpdateItem.next();
                    this.toastrService.success('Add successful.');
                }),
                catchError((error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                    throw error;
                })
            );
    }

    updateCoupons(coupon: FormData) {
        console.log("couponformData:", coupon);
        return this.updateFile<boolean>(this.userAddressApiUrl, coupon).pipe(tap(res => {
            this.onUpdateItem.next();
            this.toastrService.success('edited successful.');
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }

    removeCoupons(id: number) {
        this.deleteIDs<boolean>(this.userAddressApiUrl + `/Delete/${id}`, null).subscribe(res => {
            this.onUpdateItem.next();
            this.toastrService.success('Coupon Delete successful.');
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            });
    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void { // TODO: make it a shared function in utility
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
}
