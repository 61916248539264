export class SimpleUser {

    id: number;

    userName: string;

    firstName: string;

    lastName: string;

    fullTitle: string;

    email: string;

    fullName: string;

    phoneNumber: string;

    salesManagerId: number;
}

