import { AvailableToSale } from "src/app/shared/classes/available-to-sale.model";
import { ColorEnum } from "src/app/shared/classes/color-enum";
import { Product, ProductDynamicField, Variants } from "src/app/shared/classes/product";
import { StylePromotionItemVM } from "src/app/shared/classes/promotion/style-promotion-item.model";

export class VariantViewModel implements Variants {

  constructor(values: Product) {
    Object.assign(this, values);
    this.selected = false;
    this.available = this.availableToSales.length 
      ? this.availableToSales[0].availableDate == null && this.availableToSales[0].count > 0
      : false;
  }

  mainImageID?: number;
  variant_id?: number;
  id?: number;
  sku?: string;
  size?: string;
  color?: ColorEnum;
  image_id?: number;
    price: number;
    compairCost: number;
  styleID: number;
  name: string;
  fullName: string;
  isActive: boolean;
  isTrending: boolean;
  isFeatured: boolean;
  isTaxIncluded: boolean;
  salesDesc: string;
  salesPrice?: number;
  purchaseDesc: string;
  reorderPoint?: number;
  atsQuantity: number;
  quantityOnHand: number;
  averageCost: number;
  quantityOnOrder: number;
  quantityOnSalesOrder: number;
  lensColor: ColorEnum;
  nuOrder: string;
  wholesaleUSD: string;
  category: string;
  subCategory: string;
  subCategoryImage: string;
  brand: string;
  department: string;
  gender: string;
  material: string;
  styleGetDto: Product;
  styleDynamicFields: ProductDynamicField[];
  availableToSales: AvailableToSale[];
  discount: number;
  selected: boolean = false;
  available: boolean = true;
  promotion: StylePromotionItemVM;
}