export class UserNewRegister {

    public constructor(init?: Partial<UserNewRegister>) {
        Object.assign(this, init);
    }

    Email: string;

    PhoneNumber: string;

    FirstName: string;

    LastName: string;

    Password: string;

    ConfirmPassword: string;

}
