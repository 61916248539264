import { HttpClient, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { environment } from 'src/environments/environment';
import { PaymentsAccountSearch } from '../classes/Payment-Accounts/PaymentAccounts.Search';
import { IntegrationAccountDetails } from '../classes/Payment-Accounts/IntegrationAccountDetails.model';
import { StripeConnectionParam } from '../classes/Payment-Accounts/StripeConnectionParam';
import { AddAccountVM } from '../classes/Payment-Accounts/add-account.model';
import { UserService } from './user.service'; 
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PayProAccountVM, SquareAccountVM } from '../classes/Payment-Accounts/SquareAccountVM';

@Injectable({
    providedIn: 'root'
})
export class PaymentAccountService extends HttpClientManager {

    controllerName: string = "PaymentMethods";
    PaymentIntegrationApiUrl: string = `${environment.api_base_url}${this.controllerName}`
    onUpdateItem: Subject<any>;
    onAccountChange: Subject<any>; 

    constructor(
        httpHandler: HttpHandler,
        protected router: Router,
        private toastrService: ToastrService,
        private http: HttpClient,
        private modalService: NgbModal,
        private userService: UserService) {
        super(httpHandler, router);
        this.onUpdateItem = new Subject();
        this.onAccountChange = new Subject();
    }

    ///::: Stripe Section
    GetStripeAccountDetailsList(searchRequest?: PaymentsAccountSearch): Observable<PagingResult<IntegrationAccountDetails>> {
        return this.getAll<PagingResult<IntegrationAccountDetails>>(this.PaymentIntegrationApiUrl + '/GetAllStripeAccount', searchRequest)
            .pipe(map((StripeaccountList: PagingResult<IntegrationAccountDetails>) => {
                this.onAccountChange.next(StripeaccountList);
                return StripeaccountList;
            }));
    } 

    GetActiveAccountDetail(searchRequest?: PaymentsAccountSearch): Observable<any> {
        return this.get<IntegrationAccountDetails>(this.PaymentIntegrationApiUrl + '/GetActiveAccountDetails')
            .pipe(map((StripeaccountList: IntegrationAccountDetails) => {
                this.onAccountChange.next(StripeaccountList);
                return StripeaccountList;
            }));
    } 

    StripeAccountVerification(searchRequest?: StripeConnectionParam): Observable<any> {
        return this.getAll<any>(this.PaymentIntegrationApiUrl + '/GetVerifyStripAccount', searchRequest)
            .pipe(map((StripeaccountList: any) => {
                this.onAccountChange.next(StripeaccountList);
                return StripeaccountList;
            }),
                catchError((error: HttpManagerErrorResponse) => { return throwError(error); })

        );
    } 

    CallbackFromStripAccount(scope: string, code: string, state: string): Observable<any> { 
        const url = `${this.PaymentIntegrationApiUrl}/CallbackFromStripAccount?scope=${scope}&code=${code}&state=${state}`;
        return this.http.get(url);
    } 
      
    AddStripeAccountDetails(StripeAccount: AddAccountVM) {
        return this.create<boolean>(this.PaymentIntegrationApiUrl + '/AddStripeAccount', StripeAccount)
            .pipe(tap(res => {
                if (res) { 
                    this.refreshAccountList();
                    this.toastrService.success('Account added successfully');
                    this.modalService.dismissAll();
                }
            },
                (error: HttpManagerErrorResponse) => { 
                    this.showAllErrorMessages(error);
                }
            ));
    }

    UpdateStripeAccountDetails(StripeAccount: AddAccountVM) {
        return this.update<boolean>(this.PaymentIntegrationApiUrl + '/UpdateStripeAccount', StripeAccount).pipe(tap(res => {
            if (res) {
                this.refreshAccountList();
                this.modalService.dismissAll();
                this.toastrService.success('Account edited successfully.');
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }
     

    ///::: Papal Section
     
    GetPapalAccountDetailsList(searchRequest?: PaymentsAccountSearch): Observable<PagingResult<IntegrationAccountDetails>> {
        return this.getAll<PagingResult<IntegrationAccountDetails>>(this.PaymentIntegrationApiUrl + '/GetAllPapalAccounts', searchRequest)
            .pipe(map((PapalAccountList: PagingResult<IntegrationAccountDetails>) => {
                this.onAccountChange.next(PapalAccountList);
                return PapalAccountList;
            }));
    }

    AddPapalAccountDetails(PapalAccount: AddAccountVM) {
        return this.create<boolean>(this.PaymentIntegrationApiUrl + '/AddPapalAccount', PapalAccount)
            .pipe(tap(res => {
                if (res) {
                    this.refreshAccountList();
                    this.toastrService.success('Account added successfully');
                    this.modalService.dismissAll();
                }
            },
                (error: HttpManagerErrorResponse) => { 
                    this.showAllErrorMessages(error);
                }
            ));
    }

    UpdatePapalAccountDetails(PapalAccount: AddAccountVM) {
        return this.update<boolean>(this.PaymentIntegrationApiUrl + '/UpdatePapalAccount', PapalAccount).pipe(tap(res => {
            if (res) {
                this.refreshAccountList();
                this.modalService.dismissAll();
                this.toastrService.success('Account edited successfully.');
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }


    ///::: Square Section

    GetSquareAccountDetailsList(searchRequest?: PaymentsAccountSearch): Observable<PagingResult<IntegrationAccountDetails>> {
        return this.getAll<PagingResult<IntegrationAccountDetails>>(this.PaymentIntegrationApiUrl + '/GetAllSquareAccounts', searchRequest)
            .pipe(map((PapalAccountList: PagingResult<IntegrationAccountDetails>) => {
                this.onAccountChange.next(PapalAccountList);
                return PapalAccountList;
            }));
    }

    AddSquareAccountDetails(SquareAccount: SquareAccountVM) {
        return this.create<boolean>(this.PaymentIntegrationApiUrl + '/AddSquareAccount', SquareAccount)
            .pipe(tap(res => {
                if (res) {
                    this.refreshAccountList();
                    this.toastrService.success('Account added successfully');
                    this.modalService.dismissAll();
                }
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            ));
    }

    UpdateSquareAccountDetails(SquareAccount: SquareAccountVM) {
        return this.update<boolean>(this.PaymentIntegrationApiUrl + '/UpdateSquareAccount', SquareAccount).pipe(tap(res => {
            if (res) {
                this.refreshAccountList();
                this.modalService.dismissAll();
                this.toastrService.success('Account edited successfully.');
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }


    ///::: PayPro Section

    GetPayproAccountDetailsList(searchRequest?: PaymentsAccountSearch): Observable<PagingResult<IntegrationAccountDetails>> {
        return this.getAll<PagingResult<IntegrationAccountDetails>>(this.PaymentIntegrationApiUrl + '/GetAllPayProAccounts', searchRequest)
            .pipe(map((PaproAccountList: PagingResult<IntegrationAccountDetails>) => {
                this.onAccountChange.next(PaproAccountList);
                return PaproAccountList;
            }));
    }

    AddPayproAccountDetails(PayProAccount: PayProAccountVM) {
        return this.create<boolean>(this.PaymentIntegrationApiUrl + '/AddPayProAccount', PayProAccount)
            .pipe(tap(res => {
                if (res) {
                    this.refreshAccountList();
                    this.toastrService.success('Account added successfully');
                    this.modalService.dismissAll();
                }
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            ));
    }

    UpdatePayproAccountDetails(PayProAccount: PayProAccountVM) {
        return this.update<boolean>(this.PaymentIntegrationApiUrl + '/UpdatePayProAccount', PayProAccount).pipe(tap(res => {
            if (res) {
                this.refreshAccountList();
                this.modalService.dismissAll();
                this.toastrService.success('Account edited successfully.');
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }


    GetActiveAccountDetails(searchRequest?: PaymentsAccountSearch): Observable<PagingResult<any>> {
        return this.getAll<PagingResult<any>>(this.PaymentIntegrationApiUrl + '/GetActiveStripeAccountType', searchRequest)
            .pipe(map((StripeaccountList: PagingResult<any>) => {
                this.onAccountChange.next(StripeaccountList);
                return StripeaccountList;
            }));
    }

    private refreshAccountList() {
        this.onUpdateItem.next(true);
    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void { // TODO: make it a shared function in utility
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
}
