<ul class="pagination justify-content-center" *ngIf="paginate.pages && paginate.pages.length">
    <li class="page-item" [ngClass]="{disabled:paginate.currentPage === 1}">
        <a class="page-link" href="javascript:void(0)" tabindex="-1" aria-disabled="true" (click)="pageSet(paginate.currentPage - 1)">
            <i class="ri-arrow-left-s-line"></i>
        </a>
    </li>
    <li class="page-item" *ngFor="let page of paginate.pages"
        [ngClass]="{ 'active': paginate.currentPage == page }">
        <a class="page-link" href="javascript:void(0)" (click)="pageSet(page)">{{ page }}</a>
    </li>
    <li class="page-item" [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
        <a class="page-link" href="javascript:void(0)" (click)="pageSet(paginate.currentPage + 1)">
            <i class="ri-arrow-right-s-line"></i>
        </a>
    </li>
</ul>