import { SimpleLinesheetDto } from './../classes/linesheet/get-simplelinesheet.model';
import { GetAllLinesheetDto } from './../classes/linesheet/get-linesheet.model';
import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { environment } from 'src/environments/environment';
import { LinesheetUserSearchParamsModel, GetAllLinesheetItemDto, LinesheetSearchParamsModel, } from '../classes/linesheet';
import { EditLineSheetVM } from '../classes/linesheet/edit-linesheet.model';
import { AddLineSheetVM } from '../classes/linesheet/add-linesheet.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { RoleEnum } from '../classes/role/role.enum';
import { AddGlobalLinesheetItems } from '../classes/linesheet/add-global-linesheet-items.model';
import { SimpleGlobalLinesheetDto } from '../classes/linesheet/get-simpele-global-linesheet.model';

@Injectable({
    providedIn: 'root'
})
export class LinesheetService extends HttpClientManager {

    onUpdateLinesheet: Subject<any>;
    onUpdateLinesheetItems: Subject<any>;

    apiBaseUrl: string = `${environment.api_base_url}`;
    controllerName: string = "Linesheet";
    linesheetApiUrl: string = `${environment.api_base_url}${this.controllerName}`
    searchRequest: LinesheetUserSearchParamsModel;
    itemLinesheetList: GetAllLinesheetItemDto[] = [];

    constructor(
        httpHandler: HttpHandler,
        protected router: Router,
        private toastrService: ToastrService,
        private authServices: AuthService) {
        super(httpHandler, router);
        this.onUpdateLinesheet = new Subject();
        this.onUpdateLinesheetItems = new Subject();
        this.searchRequest = new LinesheetUserSearchParamsModel();
    }

    createNewLinesheet(variantIdList: number[]): Observable<number> {
        var request: AddLineSheetVM = new AddLineSheetVM();
        request.lineSheetItemIDs = variantIdList;
        return this.create<number>(this.linesheetApiUrl, request)
            .pipe(tap((result) => {
                this.getLinesheetById(result).subscribe((r) => { return r })
                this.toastrService.success('LineSheet has been created.')
            }));
    }


    createNewGlobalLinesheet(variantIdList: FormData): Observable<number> {
        return this.uploadFile<number>(this.linesheetApiUrl + '/Global', variantIdList)
            .pipe(tap((result) => {
                this.getLinesheetById(result).subscribe((r) => { return r })
                this.toastrService.success('LineSheet has been created.')
            }));
    }

    updateGlobalLinesheet(request: FormData) {
        return this.updateFile<boolean>(this.linesheetApiUrl + '/Global', request).pipe(
            tap(() => {
                this.toastrService.success('LineSheet has been edited.');
                this.onUpdateLinesheet.next();
            }));

    }

    updateGlobalLinesheetList(request: AddGlobalLinesheetItems){
        return this.update<boolean>(this.linesheetApiUrl + '/EditGlobalLinesheet', request)
        .pipe(tap(() => {
          this.toastrService.success('style items have been added to featured line sheet successful.');
        },
          (error: HttpManagerErrorResponse) => {
            this.showAllErrorMessages(error);
          }
        ));
    }

    getGlobalLinesheets(searchRequest: LinesheetSearchParamsModel): Observable<PagingResult<SimpleLinesheetDto>> {
        return this.getAll<PagingResult<SimpleLinesheetDto>>(this.linesheetApiUrl, searchRequest)
    }

    getGlobalLinesheetsList(): Observable<SimpleGlobalLinesheetDto[]> {
        return this.getAll<SimpleGlobalLinesheetDto[]>(`${this.linesheetApiUrl}/SimpleGlobalLinesheets`)
    }

    getAllAdminLinesheets(searchRequest: LinesheetSearchParamsModel): Observable<PagingResult<SimpleLinesheetDto>> {
        return this.getAll<PagingResult<SimpleLinesheetDto>>(this.linesheetApiUrl, searchRequest)
    }

    getLinesheetById(id: number): Observable<GetAllLinesheetDto> {
        var url = '';
        if (!id) url = this.linesheetApiUrl;
        else url = `${this.linesheetApiUrl}/${id}`;
        return this.getAll<GetAllLinesheetDto>(url)
    }

    getMyLinesheets(searchRequest: LinesheetUserSearchParamsModel): Observable<PagingResult<SimpleLinesheetDto>> {
        return this.getAll<PagingResult<SimpleLinesheetDto>>(this.apiBaseUrl + 'User/CurrentUser/LineSheet', searchRequest)
    }

    exportLinesheet(id: number): Observable<any> {
        let url = `${this.linesheetApiUrl}/Export/${id}`;
        return this.downloadFile(
            this.get<Observable<any>>(url,
                {
                    headers: { 'Contant-Type': 'application/json' },
                    responseType: 'blob' as 'json',
                    observe: 'response'
                }))
                .pipe(tap(() => {

                },
                    (error: HttpManagerErrorResponse) => {
                        this.showAllErrorMessages(error);
                    }
                ));
    }

    setPageNumber(pageNumber: number): any {
        this.searchRequest.page = pageNumber;
        this.onUpdateLinesheet.next(this.searchRequest);
    }


    showAllErrorMessages(error: HttpManagerErrorResponse): void {
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }

    deleteLinesheetById(id: number) {
        return this.deleteID(this.linesheetApiUrl, id).pipe(tap(() => {
            this.toastrService.success('LineSheet has been deleted.');
            this.onUpdateLinesheet.next();
        }));

    }

    deleteLinesheetItemByIds(lineSheetId: number, itemIds: number[]) {
        const url: string = this.linesheetApiUrl + '/' + lineSheetId + '/LineSheetItems'

        return this.deleteIDs(url, itemIds)
            .pipe(
                tap(() => {
                    this.toastrService.success('LineSheetItem has been deleted.');
                    this.onUpdateLinesheetItems.next();
                }));
    }

    updateLinesheetTitle(request: EditLineSheetVM): Observable<boolean> {
        return this.update<boolean>(this.linesheetApiUrl, request).pipe(
            tap(() => {
                this.toastrService.success('LineSheet title has been edited.');
                this.onUpdateLinesheet.next();
            }));

    }

    checkUserAccessToActions(): boolean {
        const userRoles: string[] = this.authServices.getRole();
        return userRoles.some((r) => r == RoleEnum.Admin);
    }

}