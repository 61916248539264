<div class="filter-category">
    <div class="filter-title ng-star-inserted">
        <h2 class="filter-title">{{'filters'|translate}}</h2>
        <a href="javascript:void(0)" (click)="resetFilters()">{{'clear_all'|translate}}</a>
    </div>
    <div class="selected-categories">
        <ul class="product-filter-tags">
            <li class="mr-1" *ngFor="let filter of getFilteredValues()">
                <a href="javascript:void(0)" class="filter-tag">
                    <span *ngIf="filter.ProductTitle">{{filter.ProductTitle}}</span>
                    <span *ngIf="filter.CategoryName">{{filter.CategoryName}}</span>
                    <span *ngIf="filter.SubCategoryName">{{filter.SubCategoryName}}</span>
                    <span *ngIf="filter.Color">{{filter.Color}}</span>
                    <span *ngIf="filter.Size">{{filter.Size}}</span>
                    <span *ngIf="filter.Rating">{{filter.Rating}}</span>
                    <span *ngIf="filter.PriceRange">{{filter.PriceRange}}</span>
                    <i class="ri-close-line" *ngIf="filter.ProductTitle" (click)="removeProductTitle(filter.ProductTitle)" style="margin-left: 10px;"></i>
                    <i class="ri-close-line" *ngIf="filter.CategoryName" (click)="removeCategory(filter.CategoryName)" style="margin-left: 10px;"></i>
                    <i hidden class="ri-close-line" *ngIf="filter.SubCategoryName" (click)="removeSubCategory(filter.SubCategoryName)" style="margin-left: 10px;"></i>
                    <i class="ri-close-line" *ngIf="filter.Color" (click)="removeColor(filter.Color)" style="margin-left: 10px;"></i>
                    <i class="ri-close-line" *ngIf="filter.Size" (click)="removeSize(filter.Size)" style="margin-left: 10px;"></i>
                    <i class="ri-close-line" *ngIf="filter.Rating" (click)="removeRating(filter.Rating)" style="margin-left: 10px;"></i>
                    <i class="ri-close-line" *ngIf="filter.PriceRange" (click)="removePriceRange(filter.PriceRange)" style="margin-left: 10px;"></i>
                </a>
            </li>
        </ul>
    </div>
</div>


<ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds" class="accordion custome-accordion ng-star-inserted">
    <ng-container>
        <ng-container>

            <!-- Categories Panel -->
            <!--  <ngb-panel id="categories" class="accordion-item">
            <ng-template ngbPanelHeader>
                <h2 class="accordion-header">
                    <button ngbPanelToggle class="accordion-button" id="ngb-accordion-item-0-toggle">
                        <span>
                            Categories
                        </span>
                    </button>
                </h2>

            </ng-template>
            <ng-template ngbPanelContent class="accordion-collapse collapse show" id="ngb-accordion-item-0-collapse">
                <div class="accordion-body">
                    <ul class="category-list custom-padding custom-height ng-star-inserted">
                        <li *ngFor="let category of filterModel" class="ng-star-inserted">
                            <check-box [name]="category.categoryID"
                                       class="checkbox_animated"
                                       [checked]="isChecked(category.categoryName)"
                                       (change)="appliedFilter(category.categoryID, category.categoryName); $event.stopPropagation()">
                            </check-box>
                            <label for="{{category.categoryID}}" (click)="$event.stopPropagation()">
                                {{ category.categoryName }}
                            </label>
                        </li>
                    </ul>
                </div>
            </ng-template>
            </ngb-panel>-->
            <!-- <ngb-panel id="categories" class="accordion-item">
                <ng-template ngbPanelHeader>
                    <h2 class="accordion-header">
                        <button ngbPanelToggle class="accordion-button" id="ngb-accordion-item-0-toggle">
                            <span>
                                Categories
                            </span>
                        </button>
                    </h2>

                </ng-template>
                <ng-template ngbPanelContent class="accordion-collapse collapse show" id="ngb-accordion-item-0-collapse">
                    <div class="accordion-body">
                        <ul class="category-list custom-padding custom-height ng-star-inserted">
                            <li *ngFor="let category of filterModel" class="ng-star-inserted">
                                <check-box [name]="category.categoryID"
                                           class="checkbox_animated"
                                           [checked]="isChecked(category.categoryName)"
                                           (change)="appliedFilter(category.categoryID, category.categoryName); $event.stopPropagation()">
                                </check-box>
                                <label for="{{category.categoryID}}" (click)="$event.stopPropagation()">
                                    {{ category.categoryName }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>-->

            <ngb-panel id="categories" class="accordion-item">
                <ng-template ngbPanelHeader>
                    <h2 class="accordion-header">
                        <button ngbPanelToggle class="accordion-button" id="ngb-accordion-item-0-toggle">
                            <span class="highlight-line">{{ 'categories'|translate }}</span>
                        </button>
                    </h2>
                </ng-template>
                <ng-template ngbPanelContent class="accordion-collapse collapse show" id="ngb-accordion-item-0-collapse">
                    <!-- Showing Categories and Sub Categories -->
                    <div *ngIf="IsSubCategoryShowing" class="accordion-body">
                        <ul class="category-list custom-padding custom-height ng-star-inserted">
                            <li *ngFor="let category of filterModel" class="ng-star-inserted" style="display: block;">
                                <div (click)="toggleSubcategories(category.categoryID)" style="cursor: pointer; justify-content: space-between;">
                                    <label for="{{category.categoryID}}" (click)="appliedFilter(category.categoryID, category.categoryName)" style="cursor:pointer;">
                                        {{ category.categoryName }}
                                    </label>
                                    <i *ngIf="category.subCategories && category.subCategories.length > 0"  [ngClass]="{'ri-arrow-down-s-line': !showSubcategories[category.categoryID], 'ri-arrow-up-s-line': showSubcategories[category.categoryID]}" class="fa"></i>
                                </div>
                                <ul *ngIf="showSubcategories[category.categoryID]" class="subcategory-list">
                                     <li *ngFor="let subcategory of category.subCategories">
                                         <label class="display-checkbox-container">
                                              <input class="d-none" type="checkbox"
                                               [checked]="isSubCategoryChecked(subcategory.subCategoryName)"
                                               (change)="appliedSubCategoryFilter(subcategory.subCategoryID, subcategory.subCategoryName)"
                                               [id]="subcategory"
                                               [name]="subcategory">
                                               <span class="checkmark" [class.checked]="isSubCategoryChecked(subcategory.subCategoryName)"></span>
                                             {{ subcategory.subCategoryName }}
                                         </label>
                                     </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- Showing only Categories -->
                    <div *ngIf="!IsSubCategoryShowing" class="accordion-body">
                        <ul class="category-list custom-padding custom-height ng-star-inserted">
                            <li *ngFor="let category of filterModel">
                                <label class="display-checkbox-container">
                                    <input class="d-none" type="checkbox"
                                           [checked]="isChecked(category.categoryName)"
                                           (change)="appliedFilter(category.categoryID, category.categoryName)"
                                           [id]="category"
                                           [name]="category">
                                    <span class="checkmark" [class.checked]="isChecked(category.categoryName)"></span>
                                    {{ category.categoryName }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>

            <!-- Colors Panel -->
            <ngb-panel id="colors" class="accordion-item">
                <ng-template ngbPanelHeader>
                    <h2 class="accordion-header">
                        <button ngbPanelToggle class="accordion-button" id="ngb-accordion-item-0-toggle">
                            <span class="highlight-line">{{ 'colors'|translate }}</span>
                        </button>
                    </h2>
                </ng-template>
                <ng-template ngbPanelContent class="accordion-collapse collapse show" id="ngb-accordion-item-0-collapse">
                    <div class="accordion-body">                        
                        <ul class="category-list custom-padding custom-height ng-star-inserted">
                            <li *ngFor="let color of colorOptions">
                                <label class="display-checkbox-container">
                                    <input class="d-none" type="checkbox"
                                           [checked]="isColorChecked(color)"
                                           (change)="appliedColorFilter(color)"
                                           [id]="color"
                                           [name]="color">
                                    <span class="checkmark" [class.checked]="isColorChecked(color)"></span>
                                    {{ color }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>

            <!-- Size Panel -->
            <ngb-panel id="sizes">
                <ng-template ngbPanelHeader>
                    <h2 class="accordion-header">
                        <button ngbPanelToggle class="accordion-button" id="ngb-accordion-item-0-toggle">
                            <span class="highlight-line">{{ 'size'|translate }}</span>
                        </button>
                    </h2>
                </ng-template>
                <ng-template ngbPanelContent class="accordion-collapse collapse show" id="ngb-accordion-item-0-collapse">
                    <div class="accordion-body">
                        <ul class="category-list custom-padding custom-height ng-star-inserted">
                            <li *ngFor="let size of sizeOptions">
                                <label class="display-checkbox-container">
                                    <input class="d-none" type="checkbox"
                                           [checked]="isSizeChecked(size)"
                                           (change)="appliedSizeFilter(size)"
                                           [id]="size"
                                           [name]="size">
                                    <span class="checkmark" [class.checked]="isSizeChecked(size)"></span>
                                    {{ size }}
                                </label>
                            </li>
                        </ul>
                    </div>

                </ng-template>
            </ngb-panel>

            <!-- Price Range Panel -->
            <ngb-panel id="priceRange">
                <ng-template ngbPanelHeader>
                    <h2 class="accordion-header">
                        <button ngbPanelToggle class="accordion-button" id="ngb-accordion-item-0-toggle">
                            <span class="highlight-line">{{ 'price_range'|translate }}</span>
                        </button>
                    </h2>
                </ng-template>
                <ng-template ngbPanelContent class="accordion-collapse collapse show" id="ngb-accordion-item-0-collapse">
                    <div class="accordion-body">
                        <ul class="category-list custom-padding custom-height ng-star-inserted">
                            <li *ngFor="let priceRange of priceRangeOptions">
                                <label class="display-checkbox-container">
                                    <input class="d-none" type="checkbox"
                                           [checked]="isPriceChecked(priceRange.value)"
                                           (change)="appliedPriceFilter(priceRange.value)"
                                           [id]="priceRange"
                                           [name]="priceRange">
                                    <span class="checkmark" [class.checked]="isPriceChecked(priceRange.value)"></span>
                                    {{ priceRange.display }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>

            <!-- Rating Panel -->
            <ngb-panel id="rating">
                <ng-template ngbPanelHeader>
                    <h2 class="accordion-header">
                        <button ngbPanelToggle class="accordion-button" id="ngb-accordion-item-0-toggle">
                            <span class="highlight-line">{{ 'rating'|translate }}</span>
                        </button>
                    </h2>
                </ng-template>
                <ng-template ngbPanelContent class="accordion-collapse collapse show" id="ngb-accordion-item-0-collapse">
                    <div class="accordion-body"> 
                        <ul class="category-list custom-padding custom-height ng-star-inserted">
                            <li *ngFor="let rating of ratingOptions">
                                <label class="display-checkbox-container">
                                    <input class="d-none" type="checkbox"
                                           [checked]="isRatingChecked(rating)"
                                           (change)="appliedRatingFilter(rating)"
                                           [id]="rating"
                                           [name]="rating">
                                    <span class="checkmark" [class.checked]="isRatingChecked(rating)"></span>
                                        <span *ngFor="let star of getStarArray(rating)" class="stars" [class.filled]="star.filled">
                                            &#9733;
                                        </span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>

        </ng-container>
    </ng-container>
</ngb-accordion>









<!-- New Categories Panel -->
<!--  <ng-container *ngFor="let category of filterModel; let i = index">
    <ngb-panel [id]="'panel-' + i">
        <ng-template ngbPanelHeader>
            <button ngbPanelToggle class="btn btn-link stretched-link">
                {{ category.categoryName }}
                <div class="icons">
                    <i class="fa fa-angle-up" aria-hidden="false"></i>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div>
            </button>
        </ng-template>
        <ng-template ngbPanelContent>
            <ul class="list-unstyled">
                <li *ngFor="let subCategory of category.subCategories">
                    <check-box [name]="subCategory.subCategoryID"
                               [checked]="isChecked(subCategory.subCategoryName)"
                               (change)="appliedFilter(subCategory.subCategoryID, subCategory.subCategoryName); $event.stopPropagation()">
                    </check-box>
                    <label for="{{subCategory.subCategoryID}}" (click)="$event.stopPropagation()">
                        {{ subCategory.subCategoryName }}
                    </label>
                </li>
            </ul>
        </ng-template>
    </ngb-panel>
</ng-container>-->
<!-- Combined Categories Panel -->
<!--<ngb-panel id="categories">
    <ng-template ngbPanelHeader>
        <button ngbPanelToggle class="btn btn-link stretched-link">
            Categories
            <div class="icons">
                <i class="fa fa-angle-up" aria-hidden="true"></i>
                <i class="fa fa-angle-down" aria-hidden="true"></i>
            </div>
        </button>
    </ng-template>
    <ng-template ngbPanelContent>
        <ul class="list-unstyled">
            <li *ngFor="let category of filterModel">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton-{{category.categoryName}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ category.categoryName }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton-{{category.categoryName}}">
                        <a class="dropdown-item" *ngFor="let subCategory of category.subCategories" (click)="appliedFilter(subCategory.subCategoryID, subCategory.subCategoryName); $event.stopPropagation()">
                            <check-box [name]="subCategory.subCategoryID" [checked]="isChecked(subCategory.subCategoryName)">
                            </check-box>
                            <label for="{{subCategory.subCategoryID}}" (click)="$event.stopPropagation()">
                                {{ subCategory.subCategoryName }}
                            </label>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </ng-template>
</ngb-panel>-->
