import { Component } from '@angular/core';
import { NgFor } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-grid-skeleton-loader',
    templateUrl: './grid-skeleton-loader.component.html',
    styleUrls: ['./grid-skeleton-loader.component.scss'],
    standalone: true,
    imports: [NgxSkeletonLoaderModule, NgFor]
})
export class GridSkeletonLoaderComponent {
  numbers = [0, 1, 2, 3]

  skeletonHeaderTheme = {
    height: '70px',
    background: 'linear-gradient(270deg, rgba(240, 240, 244, .3), rgba(240, 240, 244, .2))',
    marginBottom: '0px',
  };

  skeletonBodyTheme = {
    height: '50px',
    background: 'linear-gradient(270deg, rgba(240, 240, 244, .6), rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };

}
