import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BarRatingModule } from "ngx-bar-rating";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Header and Footer Components
import { FooterFourComponent } from './footer/footer-four/footer-four.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';
import { FooterThreeComponent } from './footer/footer-three/footer-three.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
import { FooterComponent } from './footer/footer/footer.component';
import { HeaderFourComponent } from './header/header-four/header-four.component';
import { HeaderOneComponent } from './header/header-one/header-one.component';
import { HeaderThreeComponent } from './header/header-three/header-three.component';
import { HeaderTwoComponent } from './header/header-two/header-two.component';
/*import { HeaderComponent } from './header/header/header.component';*/
import { HeaderComponent } from './header/header/header.component';
import { HeaderFiveComponent } from './header/header-five/header-five.component';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FiltersComponent } from './components/filter/filters.component';

import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MenuComponent } from './components/menu/menu.component';
import { ProductBoxFiveComponent } from './components/product/product-box-five/product-box-five.component';
import { ProductBoxFourComponent } from './components/product/product-box-four/product-box-four.component';
import { ProductBoxOneComponent } from './components/product/product-box-one/product-box-one.component';
import { ProductBoxThreeComponent } from './components/product/product-box-three/product-box-three.component';
import { ProductBoxTwoComponent } from './components/product/product-box-two/product-box-two.component';
import { ProductBoxVerticalSliderComponent } from './components/product/product-box-vertical-slider/product-box-vertical-slider.component';
import { ProductBoxVerticalComponent } from './components/product/product-box-vertical/product-box-vertical.component';
import { SettingsComponent } from './components/settings/settings.component';

// Modals Components
import { AgeVerificationComponent } from './components/modal/age-verification/age-verification.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { CartVariationComponent } from './components/modal/cart-variation/cart-variation.component';
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { SizeModalComponent } from './components/modal/size-modal/size-modal.component';
import { VideoModalComponent } from './components/modal/video-modal/video-modal.component';

// Skeleton Loader Components
import { SkeletonProductBoxComponent } from './components/skeleton/skeleton-product-box/skeleton-product-box.component';

// Layout Box
import { LayoutBoxComponent } from './components/layout-box/layout-box.component';

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { DiscountPipe } from './pipes/discount.pipe';
import { PriceComponent } from './widgets/price/price.component';

import { Ng5SliderModule } from 'ng5-slider';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPayPalModule } from 'ngx-paypal';
import { ColorVariantComponent } from './color-picker/color-picker.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { NoItemFoundComponent } from './components/no-item-found/no-item-found.component';
import { UserUsernameComponent } from './components/user-username/user-username.component';
import { TopHeaderComponent } from './header/top-header/top-header.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { ToLocalDatePipe } from './pipes/to-local-date.pipe';
import { BrandsComponent } from './widgets/brands/brands.component';
import { ColorsComponent } from './widgets/colors/colors.component';
import { CountdownComponent } from './widgets/countdown/countdown.component';
import { GridComponent } from './widgets/grid/grid.component';
import { PaginationComponent } from './widgets/pagination/pagination.component';
import { RelatedProductComponent } from './widgets/related-product/related-product.component';
import { ServicesComponent } from './widgets/services/services.component';
import { SizeComponent } from './widgets/size/size.component';
import { SlideFiltersComponent } from './widgets/slide-filters/slide-filters.component';
import { SocialComponent } from './widgets/social/social.component';
import { StockInventoryComponent } from './widgets/stock-inventory/stock-inventory.component';
import { CatagoriesComponent } from './components/catagories/catagories.component';
import { QuestionTextTransformPipe } from './pipes/question.pipe';
import { CouponComponent } from '../panel/coupon/coupon.component';
import { ShippingInfoComponent } from '../panel/shipping-info/shipping-info.component'; 
//import { CatagoriesComponent } from './components/catagories/catagories.component';
 


//Home component declared   

/*import { TopbarComponent } from 'src/app/shared/widgets/NewTheme/topbar/topbar.component';*/
import { NgxsModule } from '@ngxs/store';
import { LoaderState } from 'src/app/shared/widgets/NewTheme/state/loader.state';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from '../language.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    imports: [
        /*TopbarComponent,*/

        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CarouselModule,
        BarRatingModule,
        LazyLoadImageModule.forRoot({
            // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
        }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate:true,
        }),
        NgxsModule.forRoot([
            LoaderState,
        ]),
        NgxSkeletonLoaderModule,
        TranslateModule,
        NgxPayPalModule,
        Ng5SliderModule,
        NgxMaskModule.forRoot(),
        HeaderComponent,
        TopHeaderComponent,
        FooterComponent,
        HeaderOneComponent,
        FooterOneComponent,
        HeaderTwoComponent,
        FooterTwoComponent,
        HeaderThreeComponent,
        FooterThreeComponent,
        HeaderFourComponent,
        HeaderFiveComponent,
        FooterFourComponent,
        LeftMenuComponent,
        MenuComponent,
        SettingsComponent,
        BreadcrumbComponent,
        FiltersComponent,
        ProductBoxOneComponent,
        ProductBoxTwoComponent,
        ProductBoxThreeComponent,
        ProductBoxFourComponent,
        ProductBoxFiveComponent,
        ProductBoxVerticalComponent,
        ProductBoxVerticalSliderComponent,
        NewsletterComponent,
        QuickViewComponent,
        CartModalComponent,
        CartVariationComponent,
        VideoModalComponent,
        SizeModalComponent,
        AgeVerificationComponent,
        SkeletonProductBoxComponent,
        LayoutBoxComponent,
        TapToTopComponent,
        DiscountPipe,
        SafeHtmlPipe,
        QuestionTextTransformPipe,
        GridComponent,
        PaginationComponent,
        BrandsComponent,
        ShippingInfoComponent,
        CouponComponent,
        ColorsComponent,
        SizeComponent,
        PriceComponent,
        SlideFiltersComponent,
        ServicesComponent,
        CountdownComponent,
        SocialComponent,
        StockInventoryComponent,
        RelatedProductComponent,
        ColorVariantComponent,
        CheckBoxComponent,
        NoItemFoundComponent,
        UserUsernameComponent,
        ToLocalDatePipe,
        CatagoriesComponent
    ],
    exports: [
        NgxsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CarouselModule,
        BarRatingModule,
        LazyLoadImageModule,
        NgxSkeletonLoaderModule,
        TranslateModule,
        HeaderComponent,
        TopHeaderComponent,
        FooterComponent,
        HeaderOneComponent,
        FooterOneComponent,
        HeaderTwoComponent,
        FooterTwoComponent,
        HeaderThreeComponent,
        FooterThreeComponent,
        HeaderFourComponent,
        HeaderFiveComponent,
        FooterFourComponent,
        BreadcrumbComponent,
        FiltersComponent,
        ProductBoxOneComponent,
        ProductBoxTwoComponent,
        ProductBoxThreeComponent,
        ProductBoxFourComponent,
        ProductBoxFiveComponent,
        ProductBoxVerticalComponent,
        ProductBoxVerticalSliderComponent,
        NewsletterComponent,
        QuickViewComponent,
        CartModalComponent,
        CartVariationComponent,
        VideoModalComponent,
        SizeModalComponent,
        AgeVerificationComponent,
        SkeletonProductBoxComponent,
        LayoutBoxComponent,
        TapToTopComponent,
        DiscountPipe,
        SafeHtmlPipe,
        QuestionTextTransformPipe,
        ColorsComponent,
        SizeComponent,
        PriceComponent,
        SlideFiltersComponent,
        GridComponent,
        PaginationComponent,
        BrandsComponent,
        ShippingInfoComponent,
        CouponComponent,
        ServicesComponent,
        CountdownComponent,
        SocialComponent,
        StockInventoryComponent,
        RelatedProductComponent,
        ColorVariantComponent,
        CheckBoxComponent,
        NoItemFoundComponent,
        NgxMaskModule,
        UserUsernameComponent,
        ToLocalDatePipe,
        CatagoriesComponent
    ],
    providers: [CurrencyPipe, LanguageService]
})
export class SharedModule { }
