import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/base.component';
import { StyleLeadTimeRequestVM } from 'src/app/shared/classes/product/style-lead-time-request.model';
import { ProductService } from 'src/app/shared/services/product.service';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from '../../../shared/components/modal/modal/modal.component';

@Component({
    selector: 'app-set-lead-time-modal',
    templateUrl: './set-lead-time-modal.component.html',
    styleUrls: ['./set-lead-time-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, FormsModule]
})
export class SetLeadTimeModalComponent extends BaseComponent {
  styleIDs: number[];
  leadTime: number;

  constructor(
    private modalService: NgbModal,
    private productService: ProductService) {
    super()
  }

  onModalCanceled() {
    this.modalService.dismissAll(false);
  }

  onModalConfirmed() {
    const request: StyleLeadTimeRequestVM = { styleIDs: this.styleIDs, leadTime: this.leadTime }
    this.productService.setLeadTime(request)
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
        this.modalService.dismissAll(true);
      }, () => {
        this.modalService.dismissAll(false);
      })
  }
}
