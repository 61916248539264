export class EditUser {

    id: number;

    firstName: string;

    lastName: string;

    isActive: boolean;

    isNegotiator: boolean;

    roleId?: number;

    profileImage: any;
}

export class EditProfileImage {

    profileImage: any;
}