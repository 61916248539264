import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Images, Product, Variants } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ColorEnum } from 'src/app/shared/classes/color-enum';
import { createColorList } from 'src/app/core/helper/utility';
import { SkeletonProductBoxComponent } from '../../skeleton/skeleton-product-box/skeleton-product-box.component';
import { ColorVariantComponent } from '../../../color-picker/color-picker.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-product-box-one',
    templateUrl: './product-box-one.component.html',
    styleUrls: ['./product-box-one.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, LazyLoadImageModule, NgFor, ColorVariantComponent, SkeletonProductBoxComponent, QuickViewComponent, CartModalComponent, TitleCasePipe]
})
export class ProductBoxOneComponent implements OnInit {

  private _product: Product;
  @Input() get product(): Product {
    return this._product;
  } set product(value: Product) {
    this._product = value;
    if (value) this.colorList = createColorList(this.product.variants)
  }
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  
  ImageSrc: string
  price: number;
  colorList: ColorEnum[]

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.price = this.product.variants[0]?.price;
  }

  colorChange(selectedColor: string) {
    this.product.variants.forEach(v => {
      if (v.color == selectedColor) {
        this.price = v.price
        this.ImageSrc = this.findSelectedVariantImage(v).src
      }
    })
  }

  private findSelectedVariantImage(v: Variants): Images {
    return this.product.images.find(image => image.image_id == v.image_id);
  }

  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
