<header [class]="'main-header ' + ( stick ? 'box-shadow': '')">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
                        <app-left-menu class="show-left-menu"></app-left-menu>
                        <div class="py-0 menu-item d-flex flex-row">
                            <div class="toggle-nav mr-3" (click)="mainMenuToggle()">
                                <i class="fa fa-bars sidebar-bar"></i>
                            </div>
                            <a routerLink="" id="headerlogo" class="d-flex align-items-center">
                                <img [src]="themeLogo" class="img-fluid lucent-logo" alt="logo">
                            </a>


                        </div>
                    </div>
                    <div class="menu-right pull-right ml-5 w-100">
                        <div class="d-flex align-items-center">

                            <ul class="header-dropdown main-menu-header dynamic-page w-100 d-flex justify-content-end">
                                <ng-container *ngFor="let item of pages">

                                    <li *ngIf="item.subMenu.length == 0" class="pages-no-submenu">
                                        <a [routerLink]='"pages/"+item.url' data-lng="en">{{item.title}} </a>
                                    </li>
                                    <li class="onhover-dropdown pages-menu pages-submenu" *ngIf="item.subMenu.length > 0">
                                        {{item.title}}
                                        <ul class="onhover-show-div submenu">
                                            <li *ngFor="let menu of item.subMenu">
                                                <a [routerLink]='"pages/"+menu.url' data-lng="en"> {{menu.title}} </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ng-container>
                            </ul>

                            <div class="text-right">
                                <ul class="header-dropdown">
                                    <li class="onhover-dropdown mobile-account pr-4 max-width">
                                        <i class="fa fa-user" aria-hidden="true"></i> Account
                                        <ul class="onhover-show-div account-popup">
                                            <ng-container *ngIf="!isLoggedIn; else loggedIn">
                                                <li>
                                                    <a (click)="openLoginModal()" data-lng="en"> Login </a>
                                                </li>
                                                <li>
                                                    <a (click)="openRegisterModal()" data-lng="en"> Register </a>
                                                </li>
                                            </ng-container>
                                            <ng-template #loggedIn>
                                                <li>
                                                    <a [routerLink]="'/my-profile'"> My Profile </a>
                                                </li>
                                                <li *ngIf="userRole === 'Admin'">
                                                    <a [routerLink]="'/adminpanel'"> Dashboard </a>
                                                </li>
                                                <li *ngIf="userRole === 'SalesManager'">
                                                    <a [routerLink]="'/managerpanel'"> Dashboard </a>
                                                </li>
                                                <li *ngIf="userRole === 'CustomerSupport'">
                                                    <a [routerLink]="'/cspanel'"> Dashboard </a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="'/shop/wishlist'"> My Wishlist </a>
                                                </li>
                                                <li>
                                                    <a [routerLink]="'/shop/my-reviews'"> My Reviews </a>
                                                </li>
                                                <li>
                                                    <a (click)="logout()"> Logout </a>
                                                </li>
                                            </ng-template>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <!--<app-settings></app-settings>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cate-menu">
        <app-menu></app-menu>
    </div>
</header>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-WN72TBHE4N"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-WN72TBHE4N');
</script>