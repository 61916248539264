<div class="header-wrapper m-0" [class.skeleton-header]="navServices.sidebarLoading">
    <div class="header-logo-wrapper p-0">

        <div class="toggle-sidebar" checked="checked">
            <i (click)="mainMenuToggle()" class="ri-apps-line status_toggle middle sidebar-toggle"></i>
            <h2 class="text-white"> Logo Here </h2>
        </div>

        <div class="logo-wrapper">
            <a [routerLink]="['/adminpanel']">
                <img [src]="" class="img-fluid main-logo" alt="logo" />
            </a>
        </div>

    </div>

    <!--search-->
    <form class="form-inline search-full ng-untouched ng-pristine ng-valid" action="javascript:void(0)">
        <div class="form-group w-100">
            <input class="demo-input Typeahead-input form-control-plaintext w-100 ng-pristine ng-valid ng-touched" type="text" placeholder="Search ..." autofocus />
            <i class="ri-close-line close-icon"></i>
        </div>
        <div class="onhover-dropdown">
            <ul></ul>
        </div>
    </form>
    <!-- Menu and Profile -->
    <div class="nav-right right-header p-0">
        <ul class="nav-menus">
            <li>
                <span class="header-search">
                    <i class="ri-search-line"></i>
                </span>
            </li>
            <ng-container *ngFor="let item of pages">
                <li *ngIf="item.subMenu.length == 0" class="pages-no-submenu">
                    <a [routerLink]='"pages/"+item.url' data-lng="en">{{item.title}} </a>
                </li>
                <li class="onhover-dropdown pages-menu pages-submenu" *ngIf="item.subMenu.length > 0">
                    {{item.title}}
                    <ul class="profile-dropdown onhover-show-div active">
                        <li *ngFor="let menu of item.subMenu">
                            <a [routerLink]='"pages/"+menu.url' data-lng="en" class="dropdown-i">
                                <span>{{menu.title}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>

            <!-- Profile Section Integrated into New Theme -->

            <li class="profile-nav onhover-dropdown pe-0 me-0">
                <div class="media profile-media" (click)="clickHeaderOnMobile()">
                    <div class="profile-img">
                        <div class="user-round">
                            <img [src]="profileimageUrl" class="img-fluid" alt="profile">
                        </div>
                    </div>
                    <div class="user-name-hide media-body">
                        <span>
                            {{ userRole }}
                        </span>
                        <p class="mb-0 font-roboto">
                            <i class="middle ri-arrow-down-s-line">

                            </i>
                        </p>
                    </div>
                    <!--<ng-template #placeholder>
                        <div class="user-round">
                            <h4>{{ userRole.toUpperCase() }}</h4>
                        </div>
                    </ng-template>
                    <div class="user-name-hide media-body">
                        <span>{{ userRole }}</span>
                        <p class="mb-0 font-roboto">
                            {{ userRole }}
                            <i class="middle ri-arrow-down-s-line"></i>
                        </p>
                    </div>-->
                </div>
                <ul class="profile-dropdown onhover-show-div">
                    <!--[class.active]="active"-->
                    <ng-container *ngIf="!isLoggedIn; else loggedIn">
                        <li>
                            <a (click)="openLoginModal()" data-lng="en">
                            <i class="ri-user-line"></i>
                            <span>Login</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="openRegisterModal()" data-lng="en">Register</a>
                        </li>
                    </ng-container>
                    <ng-template #loggedIn>
                        <li>
                            <a [routerLink]="['/my-profile']">
                                <i class="ri-user-line"></i>
                                <span>My Profile</span>
                            </a>
                        </li>
                        <li *ngIf="userRole === 'Admin'">
                            <a [routerLink]="['/adminpanel']">
                                <i class="ri-home-line"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li *ngIf="userRole === 'SalesManager'">
                            <a [routerLink]="['/managerpanel']">
                                <i class="ri-home-line"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li *ngIf="userRole === 'CustomerSupport'">
                            <a [routerLink]="['/cspanel']">
                                <i class="ri-home-line"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/shop/wishlist']">
                                <i class="ri-heart-line"></i>
                                <span>My Wishlist</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/shop/my-reviews']">
                                <i class="ri-star-line"></i>
                                <span>My Reviews</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="logout()">
                                <i class="ri-logout-box-line"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </li>


        </ul>

    </div>
</div>

<!-- Category Menu (Same as Original) -->
<!--<div class="cate-menu">
    <app-menu></app-menu>
</div>-->





<script async src="https://www.googletagmanager.com/gtag/js?id=G-WN72TBHE4N"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-WN72TBHE4N');
</script>