import { ViewportScroller, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivationEnd, ActivatedRoute, NavigationEnd, Params, Router, RouterLink } from '@angular/router';
import { param } from 'jquery';
import { BaseComponent } from 'src/app/core/base.component';
import { DynamicFilterEnum } from '../../classes/dynamic-fIlters';
import { GetPageVM } from '../../classes/pages';
import { Menu, NavService } from '../../services/nav.service';
import { PageService } from '../../services/page.service';
import { HttpManagerErrorResponse } from './../../../core/helper/http-client-manager';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, RouterLink]
})
export class MenuComponent extends BaseComponent implements OnInit {

    public menuItems: Menu[];
    pages: GetPageVM[];
    queryParams: Params;
    currLink: string = null;
    firstProductLink: string = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public navServices: NavService,
        private pageService: PageService,
        private viewScroller: ViewportScroller) {
        super();
        this.fillHorizontalMenuItems();
        this.pageMenuItem();
        this.router.events.subscribe((event: ActivationEnd) => {
            this.navServices.mainMenuToggle = false;
        });
        this.route.queryParams.subscribe((params: Params) => {
            let keys = Object.keys(params);
            if ((params.product) && keys.length === 1) {

                this.setActiveLink(params);
            }
            else {
                this.currLink = null;
                //var link = this.menuItems[0];
                //console.log(link);
            }
        })
    }

    private setActiveLink(params: Params) {
        this.currLink = params.product;
    }

    ngOnInit(): void {

    }

    mainMenuToggle(): void {
        this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    }

    // Click Toggle menu (Mobile)
    toggletNavActive(item) {
        item.active = !item.active;
    }

    private fillHorizontalMenuItems() {
        this.navServices.getHorizontalMenuItems().subscribe(res => {
            if (!res) return;

            let menuItems = [];
            res.forEach(e => {
                menuItems.push(
                    {
                        path: '/products',
                        title: e.dataExtValue,
                        type: 'link',
                        filter: { 'product': e.dataExtValue }
                    });
            });
            return this.menuItems = menuItems;
        }, (error: HttpManagerErrorResponse) => {
            this.navServices.showAllErrorMessages(error);
        });
    }

    updateRoute(queryParams: string | number) {

        if (queryParams == "Pet" || queryParams == "Hair & Skin") {

            //this.router.navigate(['/pages/comingsoon'], {
            //    queryParams: { key: queryParams }
            //});
        }
        else {
            this.currLink = queryParams.toString();
            this.router.navigate(['/products'], {
                queryParams: { [DynamicFilterEnum.product]: queryParams },
                skipLocationChange: false,
                fragment: "products"
            });
            //this.scrollToDivTop("products");
        }

    }

    private pageMenuItem() {
        this.pageService.getAllMenu()
            .pipe(this.takeUntilDestroy())
            .subscribe(pages => {
                return this.pages = pages.menu;
            }, (error: HttpManagerErrorResponse) => {
                this.navServices.showAllErrorMessages(error);
            });
    }
    scrollToDivTop(divId: string) {
        const element = document.getElementById(divId);

        if (element) {
            // Option 1: Using scrollTop
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth' // Optional: Add smooth scrolling effect
            });

            // Option 2: Using scrollIntoView
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    private scrollToProductItems() {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor('products');
    }

}
