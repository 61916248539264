import { Component } from '@angular/core';
import {RouterModule } from '@angular/router';
import { CategoryDTO } from '../../../classes/category-filter/category-dto.model';
import { FilterDynamicService } from '../../../services/filter-dynamic.service';
import { TranslationModule } from 'src/app/translation.module';
import { ThemeCategoriesComponent } from 'src/app/shared/NewTheme/category/theme-categories/theme-categories.component';

@Component({
  selector: 'app-app-footer',
  standalone: true,
    imports: [RouterModule, ThemeCategoriesComponent, TranslationModule],
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.scss'
})
export class AppFooterComponent {
    categoryFilterlist: CategoryDTO;
    constructor(public dynamicFilterService: FilterDynamicService) {
        this.getFilterByCategory();
    }

    private getFilterByCategory() {
        this.dynamicFilterService.getCategoryFilter().subscribe(
            (categories: CategoryDTO) => {
                this.categoryFilterlist = categories;
            },
            (error) => {
            }
        );
    }
}
