import { Component, PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService, LoadingBarModule } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet } from '@angular/router';
import { SharedModule } from './shared/shared.module';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [LoadingBarModule, SharedModule, RouterOutlet]
})
    //@Injectable({
    //    providedIn: 'root'
    //})
export class AppComponent { 
  //  isAdminRoute: boolean;
  //// For Progressbar
  //loaders = this.loader.progress$.pipe(
  //  delay(1000),
  //  withLatestFrom(this.loader.progress$),
  //  map(v => v[1]),
  //);

  //constructor(@Inject(PLATFORM_ID) platformId,
  //  private loader: LoadingBarService, translate: TranslateService, router: Router) {
  //  if (isPlatformBrowser(platformId)) {
  //    translate.setDefaultLang('en');
  //    translate.addLangs(['en', 'fr']);
  //    }

  //    if (router.url.startsWith('/adminpanel')) {
  //        this.isAdminRoute = true;
  //    }
    //}    
    constructor(private router: Router) { } // Inject Router
    ngOnInit() {
        const storedTheme = localStorage.getItem('Theme');
        this.applyTheme(storedTheme);
        //this.setRoutes(storedTheme);
    }
    applyTheme(theme: string) {
        document.body.classList.remove('theme-color-1', 'theme-color-2', 'theme-color-3', 'theme-color-4', 'theme-color-5');
      
        if (theme === 'tokyo') {
            document.body.classList.add("theme-color-1");
        }
        else if (theme === 'osaka') {
            document.body.classList.add("theme-color-2");
        }
        else if (theme === 'rome') {
            document.body.classList.add("theme-color-3");
        }
        else if (theme === 'madrid') {
            document.body.classList.add("theme-color-4");
        }
        else if (theme === 'denver') {
            document.body.classList.add("theme-color-5");
        } 
    
    }

    setRoutes(theme: string) {
        // Dynamically change routes based on the theme if required
        if (theme === 'tokyo') {
            this.router.navigate(['/Tokyo']);
        }
        else if (theme === 'osaka') {
            this.router.navigate(['/Osaka']);
        }
        else if (theme === 'rome') {
            this.router.navigate(['/Rome']);
        }
        else if (theme === 'madrid') {
            this.router.navigate(['/madrid']);
        }
        // Add more route redirection logic for other themes as needed
    }

}
