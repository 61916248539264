import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthError } from '../auth/auth.model';
import { BaseModel } from '../model/base-model.model';
import { prepareQueryParamsObject } from './utility';
``
export interface HttpManagerResponse<T> {
    error?: (message: string, code: number) => void
    success?: (res: T) => void
}

export interface HttpManagerStatus {
    name: string;
    status: RequestStatus;
}

export class HttpManagerErrorResponse {
    public msg: string[];
    public code: number;

    constructor() { }
}

export enum RequestStatus {
    Success,
    Error,
}

export class HttpClientManager extends HttpClient {

    onRequestStatusChanging: Subject<HttpManagerStatus>;

    constructor(httpHandler: HttpHandler, protected router: Router) {
        super(httpHandler);
        this.onRequestStatusChanging = new Subject();
    }

    //#region == CRUD ==

    getAll<T>(url: string, queryParams?: any, name: string = undefined, suppress403Redirection: boolean = false): Observable<T> {
        const clonedQueryParams = { ...queryParams }; // spread operator
        prepareQueryParamsObject(clonedQueryParams);

        return this.get(`${url}`, { params: clonedQueryParams })
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    getById<T>(url: string, xID: number = null, name: string = undefined, suppress403Redirection: boolean = false): Observable<T> {

        let completeUrl = xID ? `${url}/${xID}` : url;
        return this.get(completeUrl)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    create<T>(url: string, item: any, name: string = undefined, suppress403Redirection: boolean = false): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.post<T>(`${url}`, JSON.stringify(item), options)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    update<T>(url: string, item: any = undefined, name: string = undefined, suppress403Redirection: boolean = false): Observable<T> {
        const body = item ? JSON.stringify(item) : null
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.put<T>(`${url}`, body, options)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    deleteWithoutID<T>(url: string, name: string = undefined, suppress403Redirection: boolean = false): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.delete<T>(url, options)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    deleteID<T>(url: string, id: number = null, name: string = undefined, suppress403Redirection: boolean = false): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        let completeUrl = id ? `${url}/${id}` : url;
        return this.delete<T>(completeUrl, options)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    deleteIDs<T>(url: string, ids: number[], name: string = undefined, suppress403Redirection: boolean = false): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: ids
        };

        return this.delete<T>(`${url}`, options)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: name, status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    //#endregion

    //#region == Upload/Download Files ==

    uploadFile<T>(url: string, formData: FormData, suppress403Redirection: boolean = false): Observable<T> {
        // const options = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'multipart/form-data',
        //     })
        // };

        return this.post<T>(`${url}`, formData)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: "", status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: "", status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: "", status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }

    updateFile<T>(url: string, formData: FormData, suppress403Redirection: boolean = false): Observable<T> {
        // const options = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'multipart/form-data',
        //     })
        // };

        return this.put<T>(`${url}`, formData)
            .pipe(map(res => {
                let baseModel: BaseModel<T>;
                baseModel = res as unknown as BaseModel<T>;
                if (baseModel.xStatus === undefined) {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 0;
                    errorModel.msg[0] = "General Model not found on from the server side";
                    this.onRequestStatusChanging.next({ name: "", status: RequestStatus.Error });
                    throw errorModel;
                } else if (baseModel.xStatus === true) {
                    this.onRequestStatusChanging.next({ name: "", status: RequestStatus.Success });
                    return baseModel.xResult;
                } else {
                    const errorModel = new HttpManagerErrorResponse();
                    errorModel.code = 200;
                    errorModel.msg = baseModel.xMessages;
                    this.onRequestStatusChanging.next({ name: "", status: RequestStatus.Error });
                    throw errorModel;
                }
            }),
                catchError((error: any) => {

                    let errorModel = this.errorHandling(error, suppress403Redirection);
                    return throwError(errorModel);
                }));
    }


    downloadFile<T>(observable: Observable<T>, suppress403Redirection: boolean = false): Observable<any> {
        // have used mergeMap because all of our file download APIs return 200, so we must create new Observable & check successful/unsuccessful download in it to handle Server error messages
        return observable.pipe(map((res: any) => { // 200
            return res;
        }), mergeMap(mergeRes => {

            const okResultObservable = Observable.create((observer: any) => {

                const fileName = this.getFileNameFromHttpResponse(mergeRes);

                if (fileName) { // Successful download
                    this.downLoadBlobFile(mergeRes.body, fileName);
                    observer.next();
                    observer.complete();
                }
                else { // UnsSuccessful download (getting Server Error message)
                    this.parseBlobErrorMessages(mergeRes.body)
                        .pipe(map(errorMessages => {
                            let errorModel = new HttpManagerErrorResponse();
                            errorModel.code = 0;
                            errorModel.msg = errorMessages;
                            observer.error(errorModel);
                        })).toPromise();
                }
            });

            return okResultObservable;
        })
            , catchError((error: any) => { // 500

                let errorModel = this.errorHandling(error, suppress403Redirection);
                return throwError(errorModel);
            })
        );

    }

    //#endregion

    //#region  == Private Methods ==

    private errorHandling(error: any, suppress403Redirection: boolean = false): HttpManagerErrorResponse {
        let errorModel: HttpManagerErrorResponse;

        if (error instanceof HttpManagerErrorResponse) {
            errorModel = error;
        }
        else if (error instanceof HttpErrorResponse) {
            errorModel = new HttpManagerErrorResponse();
            errorModel.code = error.status;

            if (error.error != undefined && error.error.xMessages != undefined) {
                errorModel.msg = error.error.xMessages;
            } else {
                errorModel.msg = [error.message];
            }
        }
        else if (error instanceof AuthError) {
            if (error.xCode === 403) {
                errorModel = new HttpManagerErrorResponse();
                errorModel.code = 403;
                errorModel.msg = ["You do not have access to perform this action"];
                if (!suppress403Redirection) {
                    this.router.navigate(['/account', 'unauthorized']);
                }
            }
            if (error.xCode === 401) {
                errorModel = new HttpManagerErrorResponse();
                errorModel.code = 401;
                errorModel.msg = ["You are not logged in"];
            }
        }
        else {
            errorModel = new HttpManagerErrorResponse();
            errorModel.code = 0;
            errorModel.msg = ["An error has occurred"];
        }

        this.onRequestStatusChanging.next({ name: null, status: RequestStatus.Error });
        return errorModel;
    }

    private getFileNameFromHttpResponse(httpResponse): string {
        let fileName: string = '';
        const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        if (contentDispositionHeader) {
            const result: string = decodeURI(contentDispositionHeader.split(';')[2].trim().split('=')[1]);
            fileName = result.substr(7);
        }
        return fileName;
    }

    private downLoadBlobFile(blob: Blob, fileName: string): void {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    private parseBlobErrorMessages(blob: Blob): Observable<string[]> {
        const reader: FileReader = new FileReader();
        let errorResult: BaseModel<any>;
        const errorMessagesPrepared = Observable.create((observer: any) => {
            reader.onloadend = (e) => {
                const text = e.srcElement['result'];
                errorResult = JSON.parse(text);
                observer.next(errorResult.xMessages);
                observer.complete();
            };
        });

        reader.readAsText(blob);

        return errorMessagesPrepared;
    }

    //#endregion
}