

<div class="product-box product-box-3 mb-0" *ngIf="!loader">

    <!--<div class="label-tag sale-tag" *ngIf="HorizontalSection==1"><span>{{'SALE'}}</span></div>
    <div class="label-tag" *ngIf="HorizontalSection==2"><span>{{'Featured'}}</span></div>-->
    <div class="product-image">
        <a [routerLink]="['/products/detail/', product.id]">
            <img [defaultImage]="imageSrc ? imageSrc : defaultImageUrl" [lazyLoad]="imageSrc" class="img-fluid" alt="{{ product.title}}">
        </a> 
        <ul class="product-option ng-star-inserted">
            <li>
                <a [routerLink]="['/products/detail/', product.id]"> 
                    <i class="ri-eye-line"></i>
                </a>
            </li> 
            <li>
                <a href="javascript:void(0)" class="notifi-wishlist" (click)="addToWishlist(product, (imageSrc ? imageSrc : defaultImageUrl))">
                    <!--(click)="addToWishlist(product.id)"-->
                    <i class="ri-heart-line"></i>
                </a>
            </li>
        </ul>
    </div>
    <div class="product-details">
        <a [routerLink]="['/product/', product.id]">
            <h6 class="name">{{FullName}}</h6>
        </a>
        <!--<p>{{ product?.description }}</p>-->
        <!--<h6 class="unit mt-1" *ngIf="product.unit">{{ product.unit }}</h6>-->
        <!--<h5 class="theme-color price">
        {{ price | currency }}
        <del class="text-content" *ngIf="promotionDiscount; else comparePriceTemplate">
            {{ promotionPrice | currency }}
        </del>
        <del class="text-content" #comparePriceTemplate>
            {{ compairCost | currency }}
        </del>
    </h5>-->

    <h5 class="theme-color price">
        {{ promotionPrice ? (promotionPrice | currency) : (price | currency) }}
        <del class="text-content" *ngIf="promotionDiscount !=0; else comparePriceTemplate">
            {{ price | currency }}
        </del>
        <ng-template #comparePriceTemplate>
            <del class="text-content">
                {{ compairCost | currency }}
            </del>
        </ng-template>
    </h5>
    <!--<h5 class="theme-color price">
        {{ promotionPrice ? (promotionPrice | currency) : (price | currency) }}
        <del class="text-content">
            {{ compairCost | currency }}
        </del>
    </h5>-->
    <!--<ng-container *ngIf="promotionDiscount; else  priceWithoutDiscount">
        <h5 class="sold text-content">
            <span class="theme-color price">{{price | currency}}</span>
            <del *ngIf="product.discount">{{promotionPrice | currency}}</del>
        </h5>
    </ng-container>
    <ng-template #priceWithoutDiscount>
        <h5>{{price | currency}}</h5>
    </ng-template>-->
        <div class="product-rating">
            <ngb-rating [rate]="this.product.productReviews ? this.product.productReviews.length:0" [max]="5"></ngb-rating>
            <!--<h6 class="theme-color" *ngIf="product.stock_status == 'in_stock'">
            {{ product.stock_status | titleCase }}
        </h6>-->
        <h6 class="theme-color">
            {{ quantityOnHand ? ('In_Stock'|translate) : ('Out_of_Stock'|translate)}}
        </h6>
        </div>
        <div class="add-to-cart-box">
            <app-button [id]="'add-to-cart'+product.id"
                        [class]="'btn btn-add-cart addcart-button'"
                        [iconClass]="'ri-add-line'"
                        [spinner]="false"
                        (click)="addToCart(imageSrc)"
                        *ngIf="quantityOnHand >= 1; else outStock">
                {{ 'add'|translate}}
                <span class="add-icon">
                    <i class="ri-add-line"></i>
                </span>
            </app-button>
            <ng-template #outStock>
                <app-button [id]="'out-of-stock'+product.id"
                            [class]="'btn btn-add-cart addcart-button'"
                            [spinner]="false"
                            [disabled]="true">
                    {{ 'sold_out'|translate}}
                </app-button>
            </ng-template>
        </div>
    </div>
</div> 



<!-- old code of product items -->
<!--<div *ngIf="!loader" class="item">
    <div class="img-wrapper">-->
        <!-- <div class="lable-block">
          <span class="lable3" *ngIf="product.new">new</span>
          <span class="lable4" *ngIf="product.sale">on sale</span>
        </div> -->
        <!--<div class="position-absolute m-2">
            <check-box [checked]="product.selected" [name]="'product-' + index"
                       (checkedChange)="onSelectionChange(product,$event)"></check-box>
        </div>

        <div class="front img-set-size">
            <a [routerLink]="['/products/detail/', product.id]">
                <img [defaultImage]="imageSrc ? imageSrc : defaultImageUrl" [lazyLoad]="imageSrc" alt="{{ product.title}}"
                     class="img-fluid lazy-loading main-image 222" />
            </a>
        </div>

        <div class="back" *ngIf="onHoverChangeImage">
            <a [routerLink]="['/products/detail/', product.id]">
                <img [src]="imageSrc" alt="{{ product.title }}" class="img-fluid lazy-loading">
            </a>
        </div>

        <ul class="product-thumb-list" *ngIf="thumbnail">
            <li class="grid_thumb_img" [class.active]="imageSrc == image.src" *ngFor="let image of product.images">
                <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
                    <img [lazyLoad]="imageSrc" alt="{{ product.title }}">
                </a>
            </li>
        </ul>-->
        <!--
        <div class="cart-info cart-wrap d-flex flex-column align-items-end justify-content-end">
          <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
            <i class="ti-shopping-cart"></i>
          </a>
          <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
            <i class="ti-shopping-cart"></i>
          </a>
          <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
            <i class="ti-heart" aria-hidden="true"></i>
          </a>
          <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
            <i class="ti-search" aria-hidden="true"></i>
          </a>
          <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
            <i class="ti-reload" aria-hidden="true"></i>
          </a>
        </div> -->
    <!--</div>

    <div [ngClass]="[(isGridForColLgOrMobile && isAdmin && isLoggedIn)?'product-item-mobile-admin': '' ,
  (isGridForColLgOrMobile && !(isAdmin && isLoggedIn))?'product-item-mobile ':'',
  (!isGridForColLgOrMobile && (isAdmin && isLoggedIn))?'product-item-admin':'',
  (!isGridForColLgOrMobile && !(isAdmin && isLoggedIn))?'product-item':'']"
         class="product-detail position-relative product-right p-2  d-flex flex-column justify-content-between">
        <span class="badge font-weight-black brand-label mr-1 position-absolute" *ngIf="selectedVariant.brand">
            {{selectedVariant.brand}}
        </span>
        <p class="d-none">{{ product?.description }}</p>
        <div class="d-flex flex-column justify-content-between">-->
            <!--<a target="_blank" [routerLink]="['/products/detail/', product?.id]">
              <h6 class="pb-0 product-title d-inline-block mt-0">{{ product?.title | uppercase }}</h6>
            </a>-->
            <!-- <p class="l-text-primary" *ngIf="selectedVariant.available">{{selectedVariant.atsQuantity}} Pieces Are Available
            </p> -->
            <!--<p class="l-text-primary font-sm" *ngIf="selectedVariant.available else upcomingStock">
              {{totalVariantsQuantity}}{{totalVariantsQuantity > 1 ? ' Pieces Are Available In ' : ' Piece Is Available In '}}
              {{totalVariantsLength}}{{totalVariantsLength > 1 ? ' Colors' : ' Color'}}
            </p>-->
        <!--</div>-->

        <!--<ng-template #upcomingStock>
          <div class="l-text-primary font-sm"
            *ngIf="selectedVariantAtsCount > 0 && selectedVariantAtsDate != null else leadTime">
            {{selectedVariantAtsCount || ''}}
            {{selectedVariantAtsCount > 1
            ? 'Pieces Are Available On '
            : 'Piece Is Available On '}}
            {{selectedVariantAtsDate | date}}
          </div>

          <ng-template #leadTime>
            <div class="l-text-primary font-sm" *ngIf="product.leadDate">
              Unlimited order from {{product.leadDate | date}}
            </div>
          </ng-template>
        </ng-template>-->

        <!--<div class="row align-items-start">
        </div>-->
        <!--<app-color-picker [colorList]="colorList" (colorChange)="colorChange($event)"></app-color-picker>-->
        <!--<div class="l-text-primary font-sm" *ngIf="product.minQuantity && isAdmin && isLoggedIn">
          Minimum Quantity : {{product.minQuantity}}</div>-->
        <!--<div [class]="isGridForColLgOrMobile ? '':'d-flex  justify-content-between' " class="px-3 pb-2">
            <div class="d-flex flex-row align-items-end">
                <ng-container *ngIf="promotionDiscount; else  priceWithoutDiscount">
                    <div class="d-flex flex-column w-100 align-items-start">
                        <del>{{price | currency}}</del>
                        <h4>{{promotionPrice | currency}}</h4>
                    </div>-->
                    <!--<div class="d-flex flex-row justify-content-end w-100 ml-2">
                      <span class="discount px-1">{{promotionDiscount}}%</span>
                    </div>-->
                <!--</ng-container>
                <ng-template #priceWithoutDiscount>
                    <h4>{{price | currency}}</h4>
                </ng-template>
            </div>

            <div [class]="'d-flex ' + (isGridForColLgOrMobile ? 'w-100':'pl-2')" ngbTooltip="Add to cart">
                <a href="javascript:void(0)" [class]="'shopping-cart btn ' + (isGridForColLgOrMobile ? 'w-100 mt-2':'') "
                   (click)="addToCart()"
                   *ngIf="cartModal">
                    Add <img src="/assets/images/icon/cart.png" />
                </a>
            </div>
        </div>
    </div>
</div>-->
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<!-- <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->