import { SimpleGetUserAddressVM } from "../address";

export class GetUserProfileModel {

    id: number;

    username: string;

    isActive: boolean;

    registerDate: Date | string;

    firstName: string;

    lastName: string;

    email: string;

    phoneNumber: string;

    profileUrl: string;

    address: string;

    fullname: string;

    profileImage: string;

    userAddresses: SimpleGetUserAddressVM[];
}
