<!--<section class="banner-buttons-section">
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="banner-button-box">
                        <div class="banner-button-image">
                            <a [routerLink]='"/pages/Personalized"'>
                                <img src="assets/images/icon/Biomarkers.jpg" alt="Biomarkers" />
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="banner-button-box">
                        <div class="banner-button-image">
                            <a href="/fillsurvey">
                                <img src="assets/images/icon/quiz.jpg" alt="Take The Quiz" />
                            </a>
                        </div>
                        <div class="btn-main">
                            <a href="/fillsurvey" target="_blank">Take The Quiz</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="banner-button-box" (click)="scrollToProductItems()">
                        <div class="banner-button-image">

                            <img src="assets/images/icon/Montly2.jpg" alt="Monthly Subscription" />

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <div id="products"></div>
</section>-->
<section class="section-b-space  shop-section" id="categories">
    <app-breadcrumb [title]="'Collections'|translate" [breadcrumb]="'Collections'|translate"></app-breadcrumb>
    <section>
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12" *ngIf="categoryFilterlist">
                    <app-theme-categories [filterModel]="categoryFilterlist" [IsCategorySection]="4" (categorySelected)="applyCategoryFilter($event)"></app-theme-categories>
                </div>
            </div>
        </div>
    </section>
    <div class="container-fluid-lg pt-5">
        <div>

            <div class="row">
                <div class="col-custome-3">
                    <div class="left-box" [class.openFilterbar]="mobileSidebar">
                        <!--<div class="collection-mobile-back px-4 py-3" (click)="toggleMobileSidebar()">
                            <span class="filter-back">
                                <i class="fa fa-angle-left" aria-hidden="true"></i> back
                            </span>
                        </div>-->

                        <div class="shop-left-sidebar">
                            <ng-container *ngIf="!isLoadingFilter">
                                <ng-container *ngIf="categoryFilterlist">
                                    <div class="accordion custome-accordion" style="display:block">
                                        <!--<app-slide-filters *ngIf="showPriceFilter" title="Price" [min]="minPrice" [max]="maxPrice"
                                          [options]="priceSliderOptions" (filterChange)="updatePriceFilter($event)"></app-slide-filters>

                                        <app-slide-filters *ngIf="isAvailable" title="Available Items" [min]="minAvailable"
                                          [max]="maxAvailable" [options]="availableItemsSliderOptions"
                                          (filterChange)="updateAvailableItemsFilter($event)">
                                        </app-slide-filters>-->
                                        <!--<app-filters [filterModel]="dynamicFilter.category" [filterType]="dynamicFilterEnum.category"
                                                     [tagsData]="tagsData" (filterChange)="updateFilter($event, dynamicFilterEnum.category)">
                                        </app-filters>-->
                                        <app-category-filter [filterModel]="categoryFilterlist" [tagsData]="tagsData"
                                                             (filterChange)="updateFilter($event)">
                                        </app-category-filter>

                                        <!--(tagsDataChange)="OntagsDataChange($event)"-->
                                        <!--<app-filters [filterModel]="dynamicFilter.contains" [filterType]="dynamicFilterEnum.contains"
                                                     [tagsData]="tagsData" (filterChange)="updateFilter($event, dynamicFilterEnum.contains)">
                                        </app-filters>-->
                                        <!--<app-filters [filterModel]="dynamicFilter.brand" [tagsData]="tagsData"
                                          [filterType]="dynamicFilterEnum.brand"
                                          (filterChange)="updateFilter($event, dynamicFilterEnum.brand)">
                                        </app-filters>

                                        <app-filters [filterModel]="dynamicFilter.color" [tagsData]="tagsData" [IncludeColor]="true"
                                          [filterType]=" dynamicFilterEnum.color"
                                          (filterChange)="updateFilter($event, dynamicFilterEnum.color)">
                                        </app-filters>

                                        <app-filters [filterModel]="dynamicFilter.lenseColor" [tagsData]="tagsData" [IncludeColor]="true"
                                          [filterType]=" dynamicFilterEnum.lenseColor"
                                          (filterChange)="updateFilter($event, dynamicFilterEnum.lenseColor)"></app-filters>

                                        <app-filters [filterModel]="dynamicFilter.gender" [tagsData]="tagsData"
                                          [filterType]="dynamicFilterEnum.gender"
                                          (filterChange)="updateFilter($event, dynamicFilterEnum.gender)">
                                        </app-filters>

                                        <app-filters [filterModel]="dynamicFilter.department" [tagsData]="tagsData"
                                          [filterType]="dynamicFilterEnum.department"
                                          (filterChange)="updateFilter($event, dynamicFilterEnum.department)"></app-filters>

                                        <app-filters [filterModel]="dynamicFilter.material" [tagsData]="tagsData"
                                          [filterType]="dynamicFilterEnum.material"
                                          (filterChange)="updateFilter($event, dynamicFilterEnum.material)"></app-filters>-->
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #filterSkeleton>
                                <app-side-filter-skeleton></app-side-filter-skeleton>
                            </ng-template>
                        </div>
                        <!-- <app-product-box-vertical-slider [title]="'New Products'">
                        </app-product-box-vertical-slider> -->
                        <!-- <div class="collection-sidebar-banner">
                          <a [routerLink]="['/products']">
                            <img src="assets/images/pic4.jpg" class="img-fluid" alt="banner">
                          </a>
                        </div> -->
                    </div>
                </div>
                <div class="col-custome-9">
                    <div class="page-main-content px-0">
                        <div *ngIf="showPromotionProducts">
                            <app-promotion-list (change)="onPromotionChange($event)"
                                                (selectedPromotionInit)="selectedPromotionInit($event)"></app-promotion-list>
                        </div>
                        <!--<div class="d-flex flex-column">-->

                            <!--<ul class="product-filter-tags">
                                <li class="mr-1" *ngFor="let tag of tagsData">
                                    <a href="javascript:void(0)" class="filter_tag"
                                       *ngIf="tag.key!= dynamicFilterEnum.maxPrice && tag.key!= dynamicFilterEnum.minPrice &&
                tag.key != dynamicFilterEnum.minAvailable && tag.key != dynamicFilterEnum.maxAvailable &&
                tag.key != dynamicFilterEnum.sortType && tag.key!= dynamicFilterEnum.sortBy &&
                tag.key!= dynamicFilterEnum.productTitle && tag.key!= dynamicFilterEnum.promotionId && tag.key!= dynamicFilterEnum.linesheetId">
                                        <span>{{dynamicFilterNameEnum[tag.key]+': '}}</span>
                                        <span class="ml-1 font-weight-bold">{{tag.value | titlecase}}</span>
                                        <i class="ti-close" (click)="removeTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.minPrice">
                                        <span>{{dynamicFilterNameEnum.minPrice +": "}}</span>
                                        <span class="ml-1 font-weight-bold">{{"$"+tag.value}}</span><i class="ti-close"
                                                                                                       (click)="removePriceTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.maxPrice">
                                        <span>{{dynamicFilterNameEnum.maxPrice +": "}}</span>
                                        <span class="ml-1 font-weight-bold">{{"$"+tag.value}}</span><i class="ti-close"
                                                                                                       (click)="removePriceTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.minAvailable">
                                        <span>{{dynamicFilterNameEnum.minAvailable +": "}}</span>
                                        <span class="ml-1 font-weight-bold">{{tag.value}}</span><i class="ti-close"
                                                                                                   (click)="removeAvailableItemsTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.maxAvailable">
                                        <span>{{dynamicFilterNameEnum.maxAvailable +": "}}</span>
                                        <span class="ml-1 font-weight-bold">{{tag.value}}</span><i class="ti-close"
                                                                                                   (click)="removeAvailableItemsTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.productTitle">
                                        <span>{{dynamicFilterNameEnum.productTitle +": "}}</span>
                                        <span class="ml-1 font-weight-bold">{{tag.value | titlecase}}</span><i class="ti-close"
                                                                                                               (click)="removeTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.promotionId">
                                        <span>{{dynamicFilterNameEnum.promotion +": "}}</span>
                                        <span class="ml-1 font-weight-bold">{{promotionTitle}}</span><i class="ti-close"
                                                                                                        (click)="removeTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.linesheetId">
                                        <span>{{dynamicFilterNameEnum.linesheet +": "}}</span>
                                        <span class="ml-1 font-weight-bold">{{linesheetTitle}}</span><i class="ti-close"
                                                                                                        (click)="removeTag(tag)"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="filter_tag" *ngIf="tag.key== dynamicFilterEnum.sortBy">
                                        <span>{{dynamicFilterNameEnum.sort+':'}}</span>
                                        <span class="ml-1 font-weight-bold"
                                              *ngIf="tag.value == 'styleName' && sortTypeValue == 'asc'">Alphabetically A-Z</span>
                                        <span class="ml-1 font-weight-bold"
                                              *ngIf="tag.value == 'styleName' && sortTypeValue == 'desc'">Alphabetically Z-A</span>
                                        <span class="ml-1 font-weight-bold" *ngIf="tag.value == 'price' && sortTypeValue == 'asc'">
                                            Price
                                            Low to High
                                        </span>
                                        <span class="ml-1 font-weight-bold" *ngIf="tag.value == 'price' && sortTypeValue == 'desc'">
                                            Price
                                            High to Low
                                        </span>
                                        <i class="ti-close" (click)="removeSortTag(tag)"></i>
                                    </a>
                                </li>
                                <li class="clear_filter" *ngIf="tagsData.length > 0">
                                    <a href="javascript:void(0)" class="clear_filter panel-action-btn btn-sm" (click)="removeAllTags()">
                                        Reset Filter
                                    </a>
                                </li>
                            </ul>-->
                        <!--</div>-->
                        <!--<div class="row" *ngIf="!showProducts && !isLoadingFilter">
                            <div class="col-sm-12" *ngIf="dynamicFilter">

                                <app-catagories [filterModel]="dynamicFilter.category" [filterType]="dynamicFilterEnum.category"
                                                [tagsData]="tagsData" (filterChange)="updateFilter($event, dynamicFilterEnum.category)">
                                </app-catagories>
                            </div>
                        </div>-->


                        <div class="row" *ngIf="showProducts">
                            <div class="col-sm-12">
                                <div class="row d-none">
                                    <div class="col-xl-12 px-0">
                                        <div class="filter-main-btn {{logoClass}}" (click)="toggleMobileSidebar()" (window:scroll)="checkScrollPosition()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i> Goals
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="collection-product-wrapper">
                                    <div class="row align-items-center d-none">
                                        <div class="col-lg-8">
                                            <div class="product-nav">
                                                <h3 class="products-block-title mt-0">Products</h3>
                                                <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView" [sortBy]="sortBy"
                                                          [sortType]="sortType" (setGrid)="updateGridLayout($event)" (setLayout)="updateLayoutView($event)"
                                                          (sortedBy)="sortByFilter($event)" [(searchProductName)]="searchProductName"
                                                          (searchProductNameChange)="searchProductNameUpdate($event)"
                                                          (isAvailableChange)='isAvailableChanged($event)' [isAvailable]="isAvailable">
                                                </app-grid>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">



                                        </div>
                                        <!--<div class="col-md-3">

        </div>-->

                                    </div>
                                    <!--<div *ngIf="loadingProducts" class="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
    </div>-->
                                    <div *ngIf="loadingProducts" class="text-center">
                                        <!--<img src="assets/images/ecommerceloader.gif" class="spinner" alt="Loading..." />-->
                                        <app-loader [loaderClass]="'custom-loader-wrapper blur-bg'"></app-loader>
                                    </div>

                                    <app-products-list *ngIf="!loadingProducts" (changeSelectedProductId)="changeSelectedProductId($event)"
                                                       [selectedProductIds]="selectedProductIds" [layoutView]="layoutView" [pageNumber]="pageNumber"
                                                       [totalItemsCount]="totalItemsCount" [allPaginatedProducts]="allPaginatedProducts"
                                                       [isLoadingProducts]="isLoadingProducts" (leadTimeChange)="onLeadTimeChange()"
                                                       (onScrollChange)="scrollChange($event)" (selectAllChange)="selectAllChange($event)"
                                                       (minQuantityChange)="onMinQuantityChanged()">
                                    </app-products-list>

                                    <nav class="custome-pagination">
                                        <ul class="pagination justify-content-center" *ngIf="pages && pages.length">
                                            <li class="page-item" [ngClass]="{disabled: isFirstPage()}">
                                                <a class="page-link" href="javascript:void(0)" tabindex="-1" aria-disabled="true" (click)="pageSet(currentPage - 1)">
                                                    <i class="ri-arrow-left-s-line"></i>
                                                </a>
                                            </li>
                                            <li class="page-item" *ngFor="let page of pages"
                                                [ngClass]="{'active disabled': currentPage == page}">
                                                <a class="page-link" href="javascript:void(0)" (click)="pageSet(page)">{{ page }}</a>
                                            </li>
                                            <li class="page-item" [ngClass]="{disabled: isLastPage()}">
                                                <a class="page-link" href="javascript:void(0)" (click)="pageSet(currentPage + 1)">
                                                    <i class="ri-arrow-right-s-line"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>