import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/base.component';
import { PageService } from '../../services/page.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, NgbTooltipModule]
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {

  @Input() title: string;
  @Input() breadcrumb: string;
  @Input() bannerTitle: string;
  @Input() bannerUrl: string;
  @Input() isBanner: boolean = false;

  constructor(
    private pageService: PageService) {
    super();
  }

  ngOnInit(): void {
    if (this.pageService.banner) {
      this.bannerTitle = this.pageService.banner.title;
      this.bannerUrl = this.pageService.banner.url;
    }
    
    this.pageService.onUpdatePages
      .pipe(this.takeUntilDestroy())
        .subscribe(banner => {
            
            this.bannerTitle = banner? banner.title:"";
            this.bannerUrl = banner ?banner.url:"";
      })
  }

}
