export class EditNegotiatorUserVM {

    userId: number
    
    isNegotiator: boolean
      
}

export class ChangePasswordUserVM {

    userId: number

    newPassword: string

    confirmPassword: string

}