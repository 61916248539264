<!-- Language Basic Style -->
<div (clickOutside)="hideDropdown()">
    <app-button [class]="'btn dropdown-toggle'"
                [type]="'button'"
                [id]="'open_dropdown_basic_btn'"
                [spinner]="false"
                (click)="openDropDown()">
        <span><div class="iti-flag {{ selectedLanguage.icon }}"></div> {{ selectedLanguage.language }}</span>
    </app-button>
    <ul class="dropdown-menu dropdown-menu-end language-dropdown" [class.show]="active">
        <li *ngFor="let language of languages">
            <a class="dropdown-item" href="javascript:void(0)" (click)="selectLanguage(language)">
                <div class="iti-flag {{ language.icon }}"></div>
                <span>{{ language.language }}</span>
            </a>
        </li>
    </ul>

    <!--<select (change)="onLanguageChange($event)">
    <option value="en">English</option>
    <option value="fr">French</option>-->
    <!-- Add more languages as needed -->
    <!--</select>-->
</div>
