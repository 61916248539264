import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { getPager } from 'src/app/core/helper/utility';
import { environment } from 'src/environments/environment';
import { AddToBasketRequest, basketSearchParamsModel, EditQuantityItemCart, GetAllBasketItemDto, GetCurrentBasketSummaryVM } from '../classes/basket';
import { EditBasketItemPriceVM, EditBasketSummaryVM } from '../classes/basket/edit-basket-summary.model';
import { GetAllBasketItemResponseDto } from '../classes/basket/get-all-basket-Item-Response.model';
import { GetCurrentBasketSimpleSummaryVM } from '../classes/basket/get-basket-simple-summary.model';
import { AddWishlistRequest, AddWishlistRequestAsGuest} from '../classes/basket/add';
import { GetAllWishlistItemResponseDto } from '../classes/wishlist/get-all-wishlist-Item-Response-model';
import { GetAllWishlistItemDto } from '../classes/wishlist/get-wishlist-items.model';
import { wishlistSearchParamsModel } from '../classes/wishlist/wishlist-search-param.model';


@Injectable({
    providedIn: 'root'
})

export class WishlistService extends HttpClientManager {

    wishlistcontrollerName: string = "Wishlist";
    wishlistApiUrl: string = `${environment.api_base_url}${this.wishlistcontrollerName}`
    onUpdateItem: Subject<any>;
    onUpdatePriceRequestItems: Subject<any>;
    onUpdateBasketSummary: Subject<any>;
    numberOfAllItems: number;
    searchRequest: wishlistSearchParamsModel;
    wishlistItems: GetAllWishlistItemDto[] = [];

    constructor(
        httpHandler: HttpHandler,
        protected router: Router,
        private toastrService: ToastrService) {
        super(httpHandler, router);
        this.onUpdateItem = new Subject();
        this.onUpdatePriceRequestItems = new Subject();
        this.onUpdateBasketSummary = new Subject();
        this.searchRequest = new wishlistSearchParamsModel();
    }

    getAllWishlistItems(searchRequest: wishlistSearchParamsModel): Observable<GetAllWishlistItemResponseDto> {
        if (searchRequest == undefined) searchRequest = this.searchRequest;
        else this.searchRequest = searchRequest;
        return this.getAll<GetAllWishlistItemResponseDto>(this.wishlistApiUrl + '/WishlistItem', searchRequest)
            .pipe(map(basketPagingResult => {
                this.wishlistItems = basketPagingResult.items.xData;
                this.numberOfAllItems = basketPagingResult.items.xCount;
                return basketPagingResult;
            }));
    }

    getAllGuestWishlistItems(): Observable<GetAllWishlistItemResponseDto> {
        return new Observable<GetAllWishlistItemResponseDto>(observer => {
            const guestWishlistCart = localStorage.getItem('guestWishlistCart') ? JSON.parse(localStorage.getItem('guestWishlistCart')!) : [];

            const mappedWishlistItems: GetAllWishlistItemResponseDto = {
                items: {
                    xData: guestWishlistCart.map(item => ({
                        styleItemId: item.styleItemId,
                        variant: { name: item.styleName, price: item.price, quantityOnHand: item.quantityonHand, styleImages: item.Image, minQuantity: item.minQuantity},
                        styleId: item.styleItemId,
                        styleItemTitle: item.styleName || item.styleItemId.toString(), 
                    })),
                    xCount: guestWishlistCart.length
                },
                hasMinQuantityError: false
            };

            observer.next(mappedWishlistItems);
            observer.complete();
        });
    }

    addWishlist(request: AddWishlistRequest[]): any {
        this.create<string[]>(this.wishlistApiUrl + '/WishlistItem/', request)
            .subscribe((errors) => {
                this.onUpdateItem.next();
                this.toastrService.success("Product is added to wishlist!!");
                return true
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            );
    }

    addWishlistAsGuest(request: AddWishlistRequestAsGuest[]): any {
        console.log("Selected Variant in wishlist Add to cart as guest:", request);
        const currentwishlistCart = localStorage.getItem('guestWishlistCart') ? JSON.parse(localStorage.getItem('guestWishlistCart')!) : [];

        request.forEach(variant => {
            const existingItem = currentwishlistCart.find(item => item.styleItemId === variant.styleItemId);
            if (!existingItem) {
                currentwishlistCart.push(variant);
            }
        });

        localStorage.setItem('guestWishlistCart', JSON.stringify(currentwishlistCart));
        this.onUpdateItem.next();
        this.toastrService.success("Product is added to wishlist!!");
    }

    removeWishlist(id: number, Istoastr:boolean= true): any {
        this.deleteIDs<string[]>(this.wishlistApiUrl + `/WishlistItem/${id}`, null)
            .subscribe((errors) => {
                this.onUpdateItem.next();
                if (Istoastr) {
                    this.toastrService.success("Product removed from wishlist!!");
                }
                return true
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            );
    }

    removeGuestWishlist(id: number): any {
        let guestwislists = localStorage.getItem('guestWishlistCart') ? JSON.parse(localStorage.getItem('guestWishlistCart')!) : [];

        if (!Array.isArray(guestwislists)) {
            console.error('Error: guestWishlist is not an array.');
            return;
        }
        guestwislists = guestwislists.filter((item: any) => item.styleItemId !== id);

        localStorage.setItem('guestWishlistCart', JSON.stringify(guestwislists));
        this.onUpdateItem.next();
        this.toastrService.success('Product has been deleted from wishlist cart.');
    }

    getwishlistStatus(id: number): Observable<boolean> {
        return this.getAll<boolean>(this.wishlistApiUrl + '/WishlistStatus?productId=' + id)
    }


    showAllErrorMessages(error: HttpManagerErrorResponse): void { // TODO: make it a shared function in utility
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
}