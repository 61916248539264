<div class="main-navbar">
  <div id="mainnav">
    <!-- <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div> -->
      <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

          <li class="back-btn">
              <div class="mobile-back text-right" (click)="mainMenuToggle()">
                  <!-- <span>Back</span> -->
                  <!-- <i class="fa fa-angle-right pl-2" aria-hidden="true"></i> -->
                  <i class="fa fa-close" aria-hidden="true"></i>
              </div>
          </li>
          <!--<li>
              <a [routerLink]='"products"' class="nav-link cursor-pointer" routerLinkActive="active">
                  All Products
                  
              </a>
          </li>-->
          <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">

              <!-- Link -->
              
              <!--<a (click)="updateRoute(menuItem.filter.product)" routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact: true}" class="nav-link cursor-pointer" [ngClass]="{'active': menuItem.filter.product==(currLink!=null?currLink:firstProductLink)}" *ngIf="menuItem.type === 'link'">
                  All Products
                  <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
                  <span class="sub-arrow" *ngIf="menuItem.children"></span>
              </a>-->


              <!--<a [routerLink]='"pages/comingsoon"' [queryParams]="{key: menuItem.filter.product}" routerLinkActive="active"
                 [routerLinkActiveOptions]="{exact: true}" class="nav-link cursor-pointer" *ngIf="menuItem.type === 'link'  && (menuItem.filter.product!='Supplement')" (click)="updateRoute(menuItem.filter.product)">
                  {{ menuItem.title }}
                  <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
                  <span class="sub-arrow" *ngIf="menuItem.children"></span>
              </a>-->
          </li>
          <!--<li>
              <a (click)="updateRoute('Pet')"  class="nav-link cursor-pointer" routerLinkActive="active">
                  Pet
              </a>
          </li>
          <li>
              <a (click)="updateRoute('Hair & Skin')"  class="nav-link cursor-pointer" routerLinkActive="active">
                  Hair & Skin
              </a>
          </li>-->
          <li *ngFor="let page of pages" class="dynamic-page">
              <a *ngIf="!page.subMenu.length" [routerLink]='"pages/"+page.url' class="nav-link">
                  {{page.title}}
              </a>
              <a *ngIf="page.subMenu.length" class="nav-link" (click)="toggletNavActive(page)">
                  {{page.title}}
                  <span class="sub-arrow"></span>
              </a>
              <ul class="nav-submenu" [class.opensubmenu]="page.active" *ngIf="page.subMenu.length">
                  <li *ngFor="let childrenItem of page.subMenu">
                      <a [routerLink]='"pages/"+childrenItem.url' class="nav-link">
                          {{childrenItem.title}}
                      </a>
                  </li>
              </ul>
          </li>
      </ul>
  </div>
</div>