<div class="collection-collapse-block border-0" [class.open]="collapse">
  <h3 class="collapse-block-title mt-0" (click)="collapse = !collapse">{{title | titlecase}}</h3>
  <div class="collection-collapse-block-content">
      <div class="collection-brand-filter">
            <ng5-slider 
                [(value)]="min" 
                [(highValue)]="max" 
                [options]="options" 
                (userChangeEnd)="filter($event)"
                *ngIf="isBrowser">
            </ng5-slider>
          </div>
</div>