import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/base.component';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { AddGlobalLinesheetItems } from 'src/app/shared/classes/linesheet/add-global-linesheet-items.model';
import { SimpleGlobalLinesheetDto } from 'src/app/shared/classes/linesheet/get-simpele-global-linesheet.model';
import { SimpleLinesheetDto } from 'src/app/shared/classes/linesheet/get-simplelinesheet.model';
import { LinesheetService } from 'src/app/shared/services/linesheet.service';
import { NoItemFoundComponent } from '../../../shared/components/no-item-found/no-item-found.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
import { ModalComponent } from '../../../shared/components/modal/modal/modal.component';


@Component({
    selector: 'app-global-linsheet-list-modal',
    templateUrl: './global-linsheet-list-modal.component.html',
    styleUrls: ['./global-linsheet-list-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, NgIf, NgFor, FormsModule, NoItemFoundComponent]
})
export class GlobalLinsheetListModalComponent extends BaseComponent implements OnInit {
  styleItemIds: number[];
  globalLinesheetList: SimpleGlobalLinesheetDto[] = [];
  selectedGlobalLinesheet: SimpleGlobalLinesheetDto;
  isLoading: boolean = false;

  constructor(
    private modalService: NgbModal,
    private linesheetService: LinesheetService) {
    super()
  }

  ngOnInit(): void {
    this.getGlobalLinsheet()
  }


  getGlobalLinsheet() {
    this.isLoading = true;
    this.linesheetService.getGlobalLinesheetsList()
      .pipe(this.takeUntilDestroy())
      .subscribe((res: SimpleGlobalLinesheetDto[]) => {
        this.globalLinesheetList = res;
        this.isLoading = false;
      }, (error: HttpManagerErrorResponse) => {
        this.linesheetService.showAllErrorMessages(error);
        this.isLoading = false
      }, () => this.isLoading = false);
  }

  onModalCanceled() {
    this.modalService.dismissAll();
  }

  onModalConfirmed() {
    const request: AddGlobalLinesheetItems = { id: +this.selectedGlobalLinesheet.id, styleItemIds: this.styleItemIds }
    this.linesheetService.updateGlobalLinesheetList(request).subscribe(() => {
      this.onModalCanceled()
    });
  }

}
