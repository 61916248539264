<ng-container *ngIf="form">
    <app-modal [title]="title" [cancelText]="'Cancel'" [confirmText]="confirmText" (cancel)="onModalCanceled()"
               (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">
        <form class="theme-form" [formGroup]="form" (keydown.enter)="onModalConfirmed()">

            <div style="margin-top: 20px;" class="form-row col-md-12 col-12">

                <div class="col-md-6 mb-6">
                    <label for="title">Title *</label>
                    <input type="text" class="form-control" placeholder="Title" [formControl]="form.controls['title']">
                </div>
                <div class="col-md-6 mb-6">
                    <label for="couponCode">Coupon Code *</label>
                    <input type="text" class="form-control" placeholder="Coupon Code" [formControl]="form.controls['couponCode']">
                </div>
            </div>

            <div style="margin-top: 20px;" class="form-row col-md-12 col-12">
                <div class="col-md-6 mb-6">
                    <label for="discountType">Discount Type *</label>
                    <select class="form-control" name="couponType" [formControl]="form.controls['discountType']">
                        <option value="byPercentage">Percentage</option>
                        <option value="byPrice">Flat</option>
                    </select>
                </div>
                <div class="col-md-6 mb-6" *ngIf="form.controls['discountType'].value === 'byPrice'">
                    <label for="discountedPrice">Ammount $</label>
                    <input type="text" class="form-control" placeholder="Flate Ammount" [formControl]="form.controls['discountedPrice']">
                </div>
                <div class="col-md-6 mb-6" *ngIf="form.controls['discountType'].value === 'byPercentage'">
                    <label for="discountPercentage">Percentage %</label>
                    <input type="text" class="form-control" placeholder="Discounted Percentage" [formControl]="form.controls['discountPercentage']">
                </div>
            </div>

            <div style="margin-top: 20px;" class="form-row col-md-12 col-12">
                <div class="col-md-6 mb-6">
                    <label for="couponType">Coupon Type *</label>
                    <select class="form-control" name="couponType" [formControl]="form.controls['couponType']">
                        <option value="GENERIC">GENERIC</option>
                        <option value="PRODUCTSPECIFIC">PRODUCTSPECIFIC</option>
                        <option value="CUSTOMERSPECIFIC">CUSTOMERSPECIFIC</option>
                    </select>
                </div>
                <div class="col-md-6 mb-6" *ngIf="form.controls['couponType'].value === 'PRODUCTSPECIFIC'">
                    <label for="product">Product *</label>
                    <select class="form-control" name="product"
                            [formControl]="form.controls['productIds']">
                        <option *ngFor="let prod of products" [value]="prod.id">
                            {{ prod.variants[0].name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mb-6" *ngIf="form.controls['couponType'].value === 'CUSTOMERSPECIFIC'">
                    <label for="customer">Customer *</label>
                    <select class="form-control" name="product"
                            [formControl]="form.controls['customerIds']">
                        <option *ngFor="let cust of customers" [value]="cust.id">
                            {{ cust.firstName }}
                        </option>
                    </select>
                </div>
            </div>


            <div style="margin-top: 20px;" class="form-row col-md-12 col-12">

                <div class="col-md-6 mb-6">
                    <label for="startDate">Start Date *</label>
                    <input type="datetime-local" class="form-control" placeholder="Start Date" [formControl]="form.controls['startDate']">
                </div>
                <div class="col-md-6 mb-6">
                    <label for="endDate">End Date</label>
                    <input type="datetime-local" class="form-control" placeholder="End Date" [formControl]="form.controls['endDate']">
                </div>
            </div>

            <div style="margin-top: 20px;" class="form-row col-md-12 col-12">

                <div class="col-md-6 mb-6">
                    <label for="facebook">Usages *</label>
                    <input type="text" class="form-control" placeholder="Remaining Usage" [formControl]="form.controls['remainingUsage']">
                </div>
                <div class="col-md-6 mb-6">
                        <label for="isActive" class="mr-3">IsActive *</label>
                        <div class="radio-main mr-3">
                            <input type="radio" name="isActive" id="isActiveYes" [formControl]="form.controls['isActive']" [value]="true">
                            <label for="isActiveYes">
                                <div class="radio-icon"></div>
                                <p>Yes</p>
                            </label>
                        </div>
                        <div class="radio-main mr-3">
                            <input type="radio" name="isActive" id="isActiveNo" [formControl]="form.controls['isActive']" [value]="false">
                            <label for="isActiveNo">
                                <div class="radio-icon"></div>
                                <p>No</p>
                            </label>
                        </div>
                </div>
            </div>
        </form>
    </app-modal>
</ng-container>