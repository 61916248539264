import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class il8nService {

    localEvent = new Subject<string>();
    constructor(private translate: TranslateService) {}

    ChangeLanguage(lang: string) {
        this.translate.use(lang);
        this.localEvent.next(lang); 
        if (lang === 'ur' || lang === 'ar') {
            document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
            document.body.classList.add('rtl');
        } else {
            document.getElementsByTagName('html')[0].removeAttribute('dir');
            document.body.classList.remove('rtl');
        }
    }
     
}
