<app-modal [title]="'Promotion List'" [cancelText]="'Cancel'" [confirmText]="'Confirm'" (cancel)="onModalCanceled()"
    (confirm)="onModalConfirmed()">
    <div *ngIf="promotionList else noResult" >
        <div *ngFor="let promotion of promotionList; let i = index" 
            class="promotion-item d-flex justify-content-start align-items-center px-2">
            <input class="promotion-item-radio pl-1" type="radio" [(ngModel)]="selectedPromotion"
                [id]="'promotion-item-' + i" [name]="'promotion-item-' + i" [value]="promotion">
            <label class="promotion-item-label py-3 px-2 m-0 cursor-pointer w-100"
                [for]="'promotion-item-' + i">{{promotion.title}}</label>
        </div>
    </div>
</app-modal>

<ng-template #noResult>
    <app-no-item-found></app-no-item-found>
</ng-template>