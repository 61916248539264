<form [formGroup]="form">
    <!--<div class="form-check form-check-inline">
        <input mdbRadio class="form-check-input" disabled type="radio" name="inlineRadioOptions" id="phoneNumber"
            [checked]="showPhoneNumber" (change)="onTypeOfUserNameChange(true)" />
        <label class="form-check-label" for="phoneNumber">Phone Number</label>
    </div>
    <div class="form-check form-check-inline">
        <input mdbRadio class="form-check-input" type="radio" name="inlineRadioOptions" id="email" [checked]="!showPhoneNumber"
            (change)="onTypeOfUserNameChange(false)" />
        <label class="form-check-label" for="email">Email</label>
    </div>-->
    <div class="form-group text-secondary" *ngIf="!showPhoneNumber ">
        <label for="email" class="l-font-color mt-1 mb-0 font-weight-light" style="text-align: left; display: flex;">
            Username</label>
        <input formControlName="email" type="email" class="form-control px-2 mt-3" id="email"
            [ngClass]="form.get('email').hasError('pattern') && form.touched ? 'is-invalid': ''"
            >
        <span *ngIf="form.get('email').hasError('pattern') && form.touched" class="invalid-feedback">
            Invalid Email
        </span>
    </div>
    <div class="form-group text-secondary" *ngIf="showPhoneNumber">
        <label for="phoneNumber" class="l-font-color mt-1 mb-0 font-weight-light">
            Please enter your phone number.</label>
        <div class="d-flex flex-row mt-3">
            <select name="" class="form-control text-secondary w-25">
                <option selected>{{areaCode}}</option>
            </select>
            <div class="w-100">
                <input formControlName="phoneNumber" type="tel" class="form-control" id="phoneNumber" minlength="10"
                    [ngClass]="form.get('phoneNumber').hasError('mask') && form.touched ? 'is-invalid': ''"
                    mask="(000) 000-0000" placeholder="phone number">
                <span *ngIf="form.get('phoneNumber').hasError('mask') && form.touched" class="mx-0 invalid-feedback d-flex">
                    Invalid Phone Number
                </span>
            </div>
        </div>
    </div>
</form>