import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import { isPlatformBrowser, NgIf, NgFor, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { Images, Product, Variants } from "../../../classes/product";
import { ProductService } from '../../../../shared/services/product.service';
import { createColorList } from 'src/app/core/helper/utility';
import { ColorEnum } from 'src/app/shared/classes/color-enum';
import { DiscountPipe } from '../../../pipes/discount.pipe';
import { ColorVariantComponent } from '../../../color-picker/color-picker.component';

@Component({
    selector: 'app-quick-view',
    templateUrl: './quick-view.component.html',
    styleUrls: ['./quick-view.component.scss'],
    standalone: true,
    imports: [NgIf, ColorVariantComponent, NgFor, RouterLink, TitleCasePipe, CurrencyPipe, DiscountPipe]
})
export class QuickViewComponent implements OnInit, OnDestroy {

  private _product: Product;
  @Input() get product(): Product {
    return this._product;
  } set product(value: Product) {
    this._product = value;
    if (value) this.colorList = createColorList(this.product.variants)
  }
  @Input() currency: any;
  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;

  closeResult: string;
  ImageSrc: string;
  counter: number = 1;
  modalOpen: boolean = false;
  colorList: ColorEnum[];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService) { }

  ngOnInit(): void {
    this.product.description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis lectus odio, non ultrices purus ultrices vitae. Etiam placerat iaculis erat, eu cursus ante porttitor vestibulum. Ut libero est, feugiat commodo maximus id, ultrices at enim. Donec fringilla, nibh eu efficitur lobortis, dui felis blandit dolor, ornare tristique quam felis ac ante. Mauris sit amet malesuada metus. Cras bibendum, orci quis mollis eleifend, quam urna finibus nisi, ac consequat mi diam ac enim. Curabitur eget ligula augue. Donec et pretium dui, et aliquam tortor. Sed rhoncus imperdiet facilisis. Praesent nec diam dignissim, cursus eros sit amet, viverra arcu. Donec hendrerit vitae mauris tristique iaculis." // TODO:description

  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.QuickView, {
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(variants) {
    const uniqSize = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size)
      }
    }
    return uniqSize
  }

  colorChange(selectedColor: string) {
    this.product.variants.forEach(v => {
      if (v.color == selectedColor) {
        this.ImageSrc = this.findSelectedVariantImage(v).src
      }
    })
  }

  private findSelectedVariantImage(v: Variants): Images {
    return this.product.images.find(image => image.image_id == v.image_id);
  }

  increment() {
    this.counter++;
  }

  decrement() {
    if (this.counter > 1) this.counter--;
  }

  async addToCart(product: any) {
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if (status)
      this.router.navigate(['/shop/cart']);
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
