import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { AccountComponent } from './account/account.component';
import { HomeComponent } from './home/home.component';
import { PaymentsComponent } from './payments/payments.component';
import { SurveyComponent } from './Quiz/survey/survey.component';
import { IntroComponent } from './intro/intro.component'; 
import { ProductsComponent } from './products/products.component'; 
import { VerifystripeComponent } from './panel/Payments-Accounts/stripe/verifystripe/verifystripe.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './core/auth/auth-gaurd';
import { RoleEnum } from './shared/classes/role/role.enum'; 
const routes: Routes = [
    {
        path: 'intro',
        component: LayoutComponent,
        loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
    },
    {
        path: 'home',
        component: LayoutComponent,
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    //{
    //    path: '', 
    //    component: StoreLayoutComponent,
    //    loadChildren: () => import('./Store-Section/store-layout/store-layout.module').then(m => m.storeLayoutModule)
    //    //redirectTo: './Store-Section/store-layout/store-layout.component',
    //    //pathMatch: 'full'
    //    //storeLayoutModule
    //},
    {
        path: '',
        component: LayoutComponent,
        loadChildren: () => import('./fastkart/home.module').then(m => m.newThemeModule)
    },
    {
        path: 'prod',
        component: LayoutComponent,
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
    },
    {
        path: 'collection',
        component: LayoutComponent,
        loadChildren: () => import('./fastkart/home.module').then(m => m.newThemeModule)
    },
    {
        path: 'shop',
        component: LayoutComponent,
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
    },
    {
        path: 'products',
        component: LayoutComponent,
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
    }, 
    {
        path: 'fillsurvey',
        component: LayoutComponent,
        loadChildren: () => import('./Quiz/survey/survey.module').then(m => m.SurveysModule)
    },
    {
        path: 'pages',
        component: LayoutComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'blogs',
        component: LayoutComponent,
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
    },
    {
        path: 'account',
        component: LayoutComponent,
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },
    {
        path: 'elements',
        component: LayoutComponent,
        loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)
    },
    {
        path: 'payments',
        component: LayoutComponent,
        loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
    }, 
    {
        path: 'verifystripe',
        component: VerifystripeComponent,
        loadChildren: () => import('./panel/Payments-Accounts/stripe/verifystripe/verifystripe.model').then(m => m.VerifystripeModule)
    }, 
    {
        path: 'adminpanel', 
        loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule),
        canActivate: [AuthGuard],
        data: {
            roles: [RoleEnum.Admin]
        }
    },
    {
        path: 'managerpanel',
        loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule),
        canActivate: [AuthGuard],
        data: {
            roles: [RoleEnum.SalesManager]
        }
    },
    {
        path: 'cspanel',
        loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule),
        canActivate: [AuthGuard],
        data: {
            roles: [RoleEnum.CustomerSupport]
        }
    },
    {
        path: 'my-profile',
        component: LayoutComponent,
        loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule)
    },
    {
        path: '**', // Navigate to Home Page if not found any page
        redirectTo: 'pages/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking',
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        //relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }