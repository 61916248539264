<footer class="section-t-space" ngClass="footer.footer_class">
    <div class="container-fluid-lg">
        <div class="main-footer section-b-space">
            <div class="row g-md-4 g-3">
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="footer-logo">
                        <!-- Logo section -->
                        <div class="theme-logo">
                            <a [routerLink]="['/']">
                                <img src="assets/images/icon/Group 5.png" class="img-fluid" alt="logo">
                            </a>
                        </div>
                        <!-- About section -->
                        <div class="footer-logo-contain">
                            <p>Discover convenience redefined at our multipurpose store. From fresh groceries to the latest fashion trends, find everything you need under one roof. Your one-stop shopping destination for a diverse range of products.</p>
                            <ul class="address">
                                <li *ngIf="data?.footer?.about_address">
                                    <i class="ri-home-line"></i>
                                    <a href="https://www.google.com/maps" target="_blank">{{ '1418 Riverwood Drive, CA 96052, US' }}</a>
                                </li>
                                <li *ngIf="data?.footer?.about_email">
                                    <i class="ri-mail-line"></i>
                                    <a href="mailto:{{ 'support@thinkdonesolutions.com' }}">{{ 'support@thinkdonesolutions.com' }}</a>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </div>
                <!--<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div class="footer-title" >
                        <h4>{{'categories'}}</h4>
                    </div>
                    <div class="footer-contain"> 
                        <app-theme-categories [filterModel]="categoryFilterlist" [IsCategorySection]="1">
                        </app-theme-categories>
                    </div>
                </div>-->
                <div class="col-xl col-lg-2 col-sm-3">
                    <div class="footer-title">
                        <h4>{{'useful_links' | translate}}</h4>
                    </div>
                    <div class="footer-contain">
                        <ul>
                            <li>
                                <a routerLink="/" class="text-content">{{'Home'|translate}}</a>
                            </li>
                            <li>
                                <a routerLink="/collection" class="text-content">{{'Collections'|translate}}</a>
                            </li>
                            <li>
                                <a routerLink="/pages/aboutus" class="text-content">{{'About Us'|translate}}</a>
                            </li>
                            <li>
                                <a routerLink="/blogs" class="text-content">{{'blogs'|translate}}</a>
                            </li>
                        </ul>

                    </div>
                </div>
                <div class="col-xl col-lg-2 col-sm-3">
                    <div class="footer-title">
                        <h4>{{ 'Help_Center'|translate}}</h4>
                    </div>
                    <div class="footer-contain">
                        <ul>
                            <li>
                                <a routerLink="/my-profile" class="text-content">{{'My_Account'|translate}}</a>
                            </li>
                            <li>
                                <a routerLink="/my-profile/my-orders" class="text-content">{{'My_Orders'|translate}}</a>
                            </li>
                            <li>
                                <a routerLink="/shop/wishlist" class="text-content">{{'wishlist'|translate}}</a>
                            </li>
                            <li>
                                <a routerLink="/faqs" class="text-content">{{'FAQs'|translate}}</a>
                            </li>
                            <li>
                                <a routerLink="/pages/contact" class="text-content">{{'contact_us'|translate}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="footer-title contact-title">
                        <h4>{{'contact_us' | translate}}</h4>
                    </div>
                    <div class="footer-contact">
                        <ul>
                            <li>
                                <div class="footer-number">
                                    <i class="ri-phone-line"></i>
                                    <div class="contact-number">
                                        <h6 class="text-content">{{ 'customer_support_24_7' | translate}} :</h6>
                                        <h5>{{'+1-555-186-5359'}}</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="footer-number">
                                    <i class="ri-mail-line"></i>
                                    <div class="contact-number">
                                        <h6 class="text-content">{{'email_address' | translate}} :</h6>
                                        <h5>{{'support@eCommerce.com'}}</h5>
                                    </div>
                                </div>
                            </li>
                            <li class="social-app mb-0">
                                <h5 class="mb-2 text-content">{{ 'download_app'| translate}} :</h5>
                                <ul>
                                    <li class="mb-0">
                                        <a href="" target="_blank">
                                            <img src="https://angular.pixelstrap.net/fastkart/assets/images/playstore.svg" alt="playstore">
                                        </a>
                                    </li>
                                    <li class="mb-0">
                                        <a href="" target="_blank">
                                            <img src="https://angular.pixelstrap.net/fastkart/assets/images/appstore.svg" alt="appstore">
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div class="sub-footer section-small-space">
            <div class="reserve">
                <h6 class="text-content">&copy;2023 TDS All rights reserved</h6>
            </div>
            <div class="payment">
                <img src="https://angular.pixelstrap.net/fastkart/assets/images/payment/1.png" alt="payment">
            </div>
            <div class="social-link">
                <h6 class="text-content">{{ 'stay_connected'| translate}}:</h6>
                <ul>
                    <li>
                        <a href="www.facebook.com" target="_blank">
                            <i class="ri-facebook-fill"></i>
                        </a>
                    </li>
                    <li>
                        <a href="www.twitter.com" target="_blank">
                            <i class="ri-twitter-fill"></i>
                        </a>
                    </li>
                    <li>
                        <a href="www.instagram.com" target="_blank">
                            <i class="ri-instagram-fill"></i>
                        </a>
                    </li>
                    <li>
                        <a href="www.pinterest.com" target="_blank">
                            <i class="ri-pinterest-fill"></i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</footer>
