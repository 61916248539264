import { BaseSearchModel } from "src/app/core/model/base-search-model";
import { AvailableToSale } from "./available-to-sale.model";
import { ColorEnum } from "./color-enum";
import { StylePromotionItemVM } from "./promotion/style-promotion-item.model";
import { UserInfo } from "os";
import { GetUserProfileModel } from "./user/get-user-profile.model";

// Products
export class Product {
    //#region MainProps
    id?: number;
    title?: string;
    description?: string;
    shortDescription?: string;
    isActive?: boolean;
    variants?: Variants[];
    productReviews?: ProductReviews[];
    images?: Images[]; // for products or variants ???
    imageIds?: number[];
    leadTime?: number;
    minQuantity?: number;
    //#endregion

    subCategory?: string; // TODO: remove it 
    department?: string; // TODO: remove it 
    gender?: string; // TODO: remove it 
    material?: string; // TODO: remove it 

    type?: string; // TODO: remove it 
    brand?: string; // TODO: remove it 
    collection?: any[]; // TODO: remove it 
    category?: string; // TODO: remove it 
    price?: number; // TODO: remove it 
    sale?: boolean; // TODO: remove it
    discount?: number; // TODO: remove it 
    stock?: number; // TODO: remove it 
    new?: boolean; // TODO: remove it 
    quantity?: number; // TODO: remove it 
    tags?: any[]; // TODO: remove it     
    details?: any[]; // TODO: remove it 
    comments?: any[];
}

export interface Variants {
    variant_id?: number;
    id?: number;
    sku?: string; // TODO: check if it's needed
    size?: string; // TODO: remove it 
    color?: ColorEnum;
    image_id?: number;
    mainImageID?: number;
    price: number; // TODO: remove it 
    compairCost: number;
    styleID: number;
    name: string;
    fullName: string;
    isActive: boolean;
    isTrending: boolean;
    isFeatured: boolean;
    isTaxIncluded: boolean;
    salesDesc: string;
    salesPrice?: number;
    purchaseDesc: string;
    reorderPoint?: number;
    atsQuantity: number;
    quantityOnHand: number;
    averageCost: number;
    quantityOnOrder: number;
    quantityOnSalesOrder: number;
    lensColor: ColorEnum;
    nuOrder: string;
    wholesaleUSD: string;
    category: string;
    subCategory: string;
    brand: string;
    department: string;
    gender: string;
    material: string;
    styleGetDto: Product;
    styleDynamicFields: ProductDynamicField[];
    availableToSales: AvailableToSale[];
    discount: number;
    promotion: StylePromotionItemVM;
    leadTime?: number;
    minQuantity?: number;
    styleImages?: any[];
}

export interface Images {
    image_id?: number;
    id?: number;
    alt?: string;
    src?: string;
    variant_id?: any[];
}

export interface ProductDynamicField {
    styleID: string;
    dataExtName: string;
    dataExtType: ProductDynamicFieldTypeEnum;
    dataExtValue: string;
}

export enum ProductDynamicFieldTypeEnum {
    detAMTTYPE = 0,
    detDATETIMETYPE = 1,
    detINTTYPE = 2,
    detPERCENTTYPE = 3,
    detPRICETYPE = 4,
    detQUANTYPE = 5,
    detSTR1024TYPE = 6,
    detSTR255TYPE = 7
}

export class ProductSearchParamsModel extends BaseSearchModel {
    product: string;
    productTitle: string;
    category: string;
    subCategory: string;
    color: string;
    size: string;
    rating: string;
    priceRange: string;
    contains: string;
    brand: string;
    frameColor: string;
    lensColor: string;
    gender: string;
    department: string;
    material: string;
    minPrice?: number;
    maxPrice?: number;
    minAvailable?: number;
    maxAvailable?: number;
    promotionId: number;
    linesheetId: number;
    isAvailable: boolean;
}


export interface ProductReviews {
    id: number;
    review: string;
    starsRating: number;
    styleItemId: number;
    userId: number;
    createDate: Date | string;
    productReviewsImages?: ProductReviewsImages[];
    user: GetUserProfileModel;
}

export interface ProductVariants {
    ID: number;
    StyleItemID: number;
    VariantName: string;
    VariantValue: string;
    Price: number;
    Quantity: number;
    Images: string;
    SortOrder: number;
}

export interface VariantAttributes {
    ID: number;
    StyleItemID: number;
    FristAttribute: string;
    SecondAttribute: string;
    ThirdAttribute: string;
    FourthAttribute: string;
    Price: number;
    Quantity: number;
    Images: string;
    SortOrder: number;
}
export interface ProductReviewsImages {
    id: number;
    productReviewId: number;
    image : string;
}