import { Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-products-list-item-skeleton',
    templateUrl: './products-list-item-skeleton.component.html',
    styleUrls: ['./products-list-item-skeleton.component.scss'],
    standalone: true,
    imports: [NgxSkeletonLoaderModule],
})
export class ProductsListItemSkeletonComponent {
  skeletonThemeText = {
    height: '20px',
    background:
      'linear-gradient(270deg, rgba(240, 240, 244, .6), rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };

  skeletonThemeMultiLineText = {
    height: '35px',
    background:
      'linear-gradient(270deg, rgba(240, 240, 244, .6), rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };

  skeletonThemeImg = {
    height: '100%',
    background:
      'linear-gradient(270deg, rgba(240, 240, 244, .6),rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };
}
