// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


  
export const environment = {
    production: true,
    staging: false,
    shippingFreeAmt: 32,
    instagram_token: 'INSTA_TOKEN',
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    api_base_url: 'https://ecapi.thinkdonesolutions.com/api/',
    style_image_base_url: 'https://ecapi.thinkdonesolutions.com/api/StyleImage/',
    app_Id: 'sq0idp-2m84hlR20-jo4ZACvWPNOQ',
    location_Id: 'Personage',
    original_lucent_url: 'https://ecstore.thinkdonesolutions.com/',
    identity_server_authority: "https://ecapi.thinkdonesolutions.com/",
    identity_server_token_endpoint: "https://ecapi.thinkdonesolutions.com/connect/token",
    identity_server_clientID: "Personage",
    identity_server_scope: "PersonageAPI",
    URL: 'https://ecstore.thinkdonesolutions.com/assets/data'
} 


/*For local machine*/
//export const environment = {
//    production: false,
//    staging: false,
//  instagram_token: 'INSTA_TOKEN',
//  stripe_token: 'STRIPE_TOKEN',
//  paypal_token: 'PAYPAL_TOKEN',
//  shippingFreeAmt: 32,
//  api_base_url: 'https://localhost:5001/api/',
//  style_image_base_url: 'https://localhost:5001/api/StyleImage/',
//  guest_style_image_base_url: 'https://localhost:5001/api/StyleImage/GetGuestImage/',
//  //  app_Id: 'sandbox-sq0idb-OK7sEkwADx4C67lAS5S7dQ',
//    //location_Id: 'LP86FRYMFQD9D',
//    app_Id: 'sandbox-sq0idb-JdCWhQQSLJYQAqObcyY3tA',
//    location_Id: 'LR9BCGH13CJYK',
//  original_lucent_url: 'https://lucentproductinc.com/',
//  identity_server_authority: "https://localhost:5001",
//  identity_server_token_endpoint: "https://localhost:5001/connect/token",
//    identity_server_clientID: "Personage",
//    identity_server_scope: "PersonageAPI",
//    baseURL: 'http://localhost:4200/',
//    URL: 'http://localhost:4200/assets/data'
//};

/*For Mobile machine*/
//export const environment = {
//    production: false,
//    staging: false,
//    instagram_token: 'INSTA_TOKEN',
//    stripe_token: 'STRIPE_TOKEN',
//    paypal_token: 'PAYPAL_TOKEN',
//    shippingFreeAmt: 32,
//    api_base_url: 'https://192.168.18.164:5001/api/',
//    style_image_base_url: 'https://192.168.18.164:5001/api/StyleImage/',
//    guest_style_image_base_url: 'https://192.168.18.164:5001/api/StyleImage/GetGuestImage/',
//    //  app_Id: 'sandbox-sq0idb-OK7sEkwADx4C67lAS5S7dQ',
//    //location_Id: 'LP86FRYMFQD9D',
//    app_Id: 'sandbox-sq0idb-JdCWhQQSLJYQAqObcyY3tA',
//    location_Id: 'LR9BCGH13CJYK',
//    original_lucent_url: 'https://lucentproductinc.com/',
//    identity_server_authority: "https://192.168.18.164:5001/",
//    identity_server_token_endpoint: "https://192.168.18.164:5001/connect/token",
//    identity_server_clientID: "Personage",
//    identity_server_scope: "PersonageAPI",
//    baseURL: 'http://localhost:4200/',
//    URL: 'http://localhost:4200/assets/data'
//};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
