import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from 'src/app/shared/widgets/NewTheme/button/button.component';
import { CommonModule } from '@angular/common';
import { LanguageService } from 'src/app/language.service';

@Component({
  selector: 'app-language',
  standalone: true,
    imports: [ButtonComponent, CommonModule],
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss'
})
export class LanguageComponent {
    @Input() style: string = 'basic';

    public active: boolean = false; 
    public languages: any[] = [
        {
            language: 'English',
            code: 'en',
            icon: 'us'
        },
        {
            language: 'Francais',
            code: 'fr',
            icon: 'fr'
        },
        {
            language: 'Urdu',
            code: 'ur',
            icon: 'pk'
        },
        {
            language: 'Arabic',
            code: 'ar',
            icon: 'ar'
        }
    ];
   
    public selectedLanguage: any = {
        language: 'English',
        code: 'en',
        icon: 'us'
    }
    
    constructor(private translate: TranslateService, private languageService: LanguageService) {

        let language = localStorage.getItem("language");
        console.log("Language >>>>");
        console.log(this.languages);
        if (language == null) {
            this.translate.use(this.selectedLanguage.code);
            this.languageService.setLanguage(this.selectedLanguage.code);
        } else {
            this.selectedLanguage = JSON.parse(language);
            this.translate.use(this.selectedLanguage.code);
            this.languageService.setLanguage(this.selectedLanguage.code);
        }
    }
    //constructor(private translate: TranslateService,private languageService: LanguageService) { 
    //}

    //onLanguageChange(event: any) {
    //    const selectedLanguage = event.target.value;
    //    this.languageService.setLanguage(selectedLanguage);
    //}
    selectLanguage(language: any) {
        this.active = false;
        this.translate.use(language.code);
        this.selectedLanguage = language;
        this.languageService.setLanguage(language.code);
        localStorage.setItem("language", JSON.stringify(this.selectedLanguage));
    }

    openDropDown() {
        this.active = !this.active;
    }


    hideDropdown() {
        this.active = false;
    }
}
