import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/core/base.component';
import { REGEX_CONST } from 'src/app/core/constants/regex-const';
import { NgxMaskModule } from 'ngx-mask';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-user-username',
    templateUrl: './user-username.component.html',
    styleUrls: ['./user-username.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        NgClass,
        NgxMaskModule,
    ],
})
export class UserUsernameComponent extends BaseComponent implements OnInit {
  @Input() form: FormGroup;

    showPhoneNumber: boolean = false;
    showEmail: boolean = true;
  areaCode: string = '+1';
  subscription: Subscription;

  ngOnInit(): void {
    this.updateUsername();
  }

  public onTypeOfUserNameChange(phoneNumberSelected: boolean): void {
    this.showPhoneNumber = phoneNumberSelected;

    this.showPhoneNumber
      ? this.setEmailToTheForm()
      : this.setPhoneNumberToTheForm();

    this.updateUsername();
    this.form.get('email').updateValueAndValidity();
    this.form.get('phoneNumber').updateValueAndValidity();

  }

  private setPhoneNumberToTheForm() {
    this.form.get('phoneNumber').setValue(null);
    this.form.get('phoneNumber').clearValidators();
    this.form.get('email').setValue('');

    this.form.get('email')
      .setValidators([
        Validators.required,
        Validators.pattern(REGEX_CONST.email),
      ]);
  }

  private setEmailToTheForm() {
    this.form.get('email').setValue(null);
    this.form.get('email').clearValidators();
    this.form.get('phoneNumber').setValue('');
    this.form.get('phoneNumber').setValidators([Validators.required]);
  }

  private updateUsername(): void {
    this.subscription?.unsubscribe();

    if (this.showPhoneNumber) {
      this.subscription = this.form
        .get('phoneNumber')
        .valueChanges.pipe(this.takeUntilDestroy())
        .subscribe((phoneNumber) => {
          this.form.get('username').setValue(this.areaCode + phoneNumber);
        });
    } else {
      this.subscription = this.form
        .get('email')
        .valueChanges.pipe(this.takeUntilDestroy())
        .subscribe((email) => {
          this.form.get('username').setValue(email);
        });
    }
  }
}
