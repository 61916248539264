import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CategoryDTO } from '../../shared/classes/category-filter/category-dto.model'
import { DynamicFilterEnum, TagData } from '../../shared/classes/dynamic-fIlters';
import { CheckBoxComponent } from '../../shared/components/check-box/check-box.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrandsSearchParamsModel } from '../../shared/classes/brands.model';
import { HttpManagerErrorResponse } from '../../core/helper/http-client-manager';
import { BrandsService } from '../../shared/services/brands.services';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
import { Category } from '../../shared/classes/category/category.model';

@Component({
    selector: 'app-category-filter',
    templateUrl: './category-filter.component.html',
    styleUrls: ['./category-filter.component.scss'],
    standalone: true,
    imports: [NgbAccordionModule, NgFor, NgIf, CheckBoxComponent, NgbModule, NgClass, TranslationModule]
})
export class CategoryFilterComponent implements OnInit {
    @Input() filterModel: CategoryDTO[];
    @Input() tagsData: TagData[];
    @Output() filterChange: EventEmitter<{ filterValues: any[], filterType: string }> = new EventEmitter<{ filterValues: any[], filterType: string }>();
    @Input() IsSubCategoryShowing: boolean;    
    colorOptions: string[] = ['Red', 'Blue', 'Green', 'Yellow', 'Black'];
    sizeOptions: string[] = ['S', 'M', 'L', 'XL', 'XXL'];
    priceRangeOptions: any[] = [
        { value: '0-100', display: '$0 - $100' },
        { value: '101-200', display: '$101 - $200' },
        { value: '201-300', display: '$201 - $300' },
        { value: '301-400', display: '$301 - $400' },
        { value: '401-Above', display: '$401 - Above' }
    ];
    ratingOptions: number[] = [1, 2, 3, 4, 5];

    panel: string = 'panel1';
    filterValues: any[] = [];
    standardFilterName: string;
    newactiveIds: string[] = ['categories', 'colors', 'sizes', 'priceRange', 'rating'];
    activeIds: any[] = [];
    subCategoryList: { subCategoryID: number, subCategoryName: string, subCategoryImage: string }[] = [];
    showSubcategories: boolean[] = []; 

    public get _tagsData(): TagData[] {
        return this.tagsData;
    }

    constructor(private brandService: BrandsService, private translate: TranslateService, private il8nService: il8nService,) {
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }

    ngOnInit(): void {
        this.getBrandSettings();
        console.log("this is filter values in categories: ", this.filterValues);
        console.log("this categories: ", this.filterModel);
        if (this.filterModel) {
            for (let [key, value] of Object.entries(this.filterModel)) {
                this.activeIds.push(value.categoryName);
            }
        }
        if (this.filterModel && Array.isArray(this.filterModel)) {
            this.filterModel.forEach(category => {
                if (Array.isArray(category.subCategories)) {
                    this.subCategoryList.push(...category.subCategories);
                }
            });
        }else {
            console.error('filterModel is not properly defined or not an array');
        }

        console.log("Categories: ", this.filterModel);
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
        this.checkMatchingTags();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['tagsData']) {
            this.OntagsDataChange(changes['tagsData'].currentValue);
        }
    }

    OntagsDataChange(updatedTagsData: any) {
        this.tagsData = [];
        if (updatedTagsData && Array.isArray(updatedTagsData)) {
            this.tagsData = updatedTagsData;
        }
        this.checkNewMatchingTagsAfterChageFromHeader();
    }

    checkMatchingTags(): void {
        console.log("Filter Value for Checking tags: ", this.filterValues)
        this.tagsData.forEach(tag => {
            if (tag.key === "productTitle") {
                const existing = this.filterValues.find(item => item.ProductTitle === tag.value);
                if (!existing) {
                    this.filterValues.push({ ProductTitle: tag.value });
                }
            } else if (tag.key === "category") {
                const existingCategory = this.filterValues.find(item => item.CategoryName === tag.value);
                if (!existingCategory) {
                    this.filterValues.push({ CategoryName: tag.value });
                
                }
            }
            else if (tag.key === "subCategory") {
                const existingSubCategory = this.filterValues.find(item => item.SubCategoryName === tag.value);
                if (!existingSubCategory && existingSubCategory != undefined) {
                    this.filterValues.push({ SubCategoryName: tag.value });
                }
            }
        });

        console.log("Updated filter values:", this.filterValues);
    }

    async appliedFilter(CategoryId, CategoryName) {
        //console.log("This category is going to filter: ", CategoryName);
        const index = this.filterValues.findIndex(item => item.CategoryName === CategoryName);
        if (index === -1) {
            this.filterValues.push({
                CategoryName: CategoryName
            });
        }
        else
        {
            if (index !== -1) {
                this.filterValues.splice(index, 1);
            }
        }
        
        // Emit filter change
        const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
        await this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.category });

        //console.log("Categories all as applied filter: ", this.filterModel);

        // Fetch subcategories related to the applied category
        const subCategoriesOfThisCategory = this.filterModel.find(c => c.categoryName === CategoryName).subCategories;
        //console.log("All Subcategories after filter:", subCategoriesOfThisCategory);

        // Iterate over each subcategory and apply subcategory filter
        for (const subCategory of subCategoriesOfThisCategory) {
            await this.appliedSubCategoryFilter(null, subCategory.subCategoryName);
        }
    }

    appliedSubCategoryFilter(CategoryId, CategoryName) {
        //console.log("This category is going to filter: ", CategoryName);
        const index = this.filterValues.findIndex(item => item.SubCategoryName === CategoryName);
        if (index === -1)
            this.filterValues.push({
                SubCategoryName: CategoryName
            });
        else {
            if (index !== -1) {
                this.filterValues.splice(index, 1);
            }
        }

        const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.subCategory });
    }


    isChecked(subCategoryId: string): boolean {
        return this.filterValues.some(item => item.CategoryName === subCategoryId);
    }

    isSubCategoryChecked(subCategoryId: string): boolean {
        return this.filterValues.some(item => item.SubCategoryName === subCategoryId);
    }

    async removeCategory(categoryName: string) {
        const index = this.filterValues.findIndex(item => item.CategoryName === categoryName);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
            await this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.category });

            console.log("Categories all as applied filter: ", this.filterModel);
            const category = this.filterModel.find(c => c.categoryName === categoryName);
            if (category) {
                const subCategoriesOfThisCategory = category.subCategories;
                console.log("All Subcategories after filter:", subCategoriesOfThisCategory);

                await this.removeAllSubCategory(subCategoriesOfThisCategory.map(sub => sub.subCategoryName));
            }
        }
    }
    removeSubCategory(subcategoryName: string): void {
        const index = this.filterValues.findIndex(item => item.SubCategoryName === subcategoryName);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
            this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.subCategory });
        }
    }
    removeAllSubCategory(subcategoryNames: string[]): void {
        this.filterValues = this.filterValues.filter(item => !subcategoryNames.includes(item.SubCategoryName));
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.subCategory });
    }
    removeColor(color: string): void {
        const index = this.filterValues.findIndex(item => item.Color === color);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
            this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.color });
        }
    }
    removeSize(size: string): void {
        const index = this.filterValues.findIndex(item => item.Size === size);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
            this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.size });
        }
    }
    removePriceRange(priceRange: string): void {
        const index = this.filterValues.findIndex(item => item.PriceRange === priceRange);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
            this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.priceRange });
        }
    }
    removeRating(rating: string): void {
        const index = this.filterValues.findIndex(item => item.Rating === rating);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
            this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.rating });
        }
    }
    removeProductTitle(title: string): void {
        const index = this.filterValues.findIndex(item => item.ProductTitle === title);
        if (index !== -1) {
            this.filterValues.splice(index, 1);
            this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.productTitle });
        }
    }

    resetFilters(): void {
        this.filterValues = [];
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.all });
    }

    appliedProductTitle(title: string) {
        const index = this.filterValues.findIndex(item => item.Title === title);
        if (index === -1) {
            this.filterValues.push({ Title: title });
        } else {
            this.filterValues.splice(index, 1);
        }

        const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.productTitle });
    }

    appliedColorFilter(color: string) {
        const index = this.filterValues.findIndex(item => item.Color === color);
        if (index === -1) {
            this.filterValues.push({ Color: color });
        } else {
            this.filterValues.splice(index, 1);
        }

        const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.color});
    }

    isColorChecked(color: string): boolean {
        return this.filterValues.some(item => item.Color === color);
    }

    appliedSizeFilter(size: string) {
        const index = this.filterValues.findIndex(item => item.Size === size);
        if (index === -1) {
            this.filterValues.push({ Size: size });
        } else {
            this.filterValues.splice(index, 1);
        }

        const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.size });
    }

    isSizeChecked(size: string): boolean {
        return this.filterValues.some(item => item.Size === size);
    }

    appliedRatingFilter(rating: string) {
        const index = this.filterValues.findIndex(item => String(item.Rating) === String(rating));
        if (index === -1) {
            this.filterValues.push({ Rating: rating });
        } else {
            this.filterValues.splice(index, 1);
        }

        const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.rating });
    }

    isRatingChecked(rating: string): boolean {
       return this.filterValues.some(item => String(item.Rating) === String(rating));
    }

    appliedPriceFilter(priceRange: string) {
        const index = this.filterValues.findIndex(item => item.PriceRange === priceRange);
        if (index === -1) {
            this.filterValues.push({ PriceRange: priceRange });
        } else {
            this.filterValues.splice(index, 1);
        }

        const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
        this.filterChange.emit({ filterValues: this.filterValues, filterType: DynamicFilterEnum.priceRange });
    }

    isPriceChecked(priceRange: string): boolean {
        return this.filterValues.some(item => item.PriceRange === priceRange);
    }

    getStarArray(rating: number): any[] {
        const starArray = [];
        for (let i = 1; i <= 5; i++) {
            starArray.push({ filled: i <= rating });
        }
        return starArray;
    }

    checkNewMatchingTagsAfterChageFromHeader(): void {
        this.filterValues = [];
        this.tagsData.forEach(tag => {
            if (tag.key === "productTitle" && tag.value !== "," && tag.value !== "") {
                const existing = this.filterValues.find(item => item.ProductTitle === tag.value);
                if (!existing) {
                    this.filterValues.push({ ProductTitle: tag.value });
                }
            } else if (tag.key === "category" && tag.value !== "," && tag.value !== "") {
                const existingCategory = this.filterValues.find(item => item.CategoryName === tag.value);
                if (!existingCategory) {
                    this.filterValues.push({ CategoryName: tag.value });
                }
            } else if (tag.key === "subCategory" && tag.value !== "," && tag.value !== "") {
                const existingSubCategory = this.filterValues.find(item => item.SubCategoryName === tag.value);
                if (!existingSubCategory) {
                    this.filterValues.push({ SubCategoryName: tag.value });
                }
            } else if (tag.key === "color" && tag.value !== "," && tag.value !== "") {
                const existingColor = this.filterValues.find(item => item.Color === tag.value);
                if (!existingColor) {
                    this.filterValues.push({ Color: tag.value });
                }
            } else if (tag.key === "size" && tag.value !== "," && tag.value !== "") {
                const existingSize = this.filterValues.find(item => item.Size === tag.value);
                if (!existingSize) {
                    this.filterValues.push({ Size: tag.value });
                }
            } else if (tag.key === "rating" && tag.value !== "," && tag.value !== "") {
                const existingRating = this.filterValues.find(item => item.Rating === tag.value);
                if (!existingRating) {
                    this.filterValues.push({ Rating: tag.value });
                }
            } else if (tag.key === "priceRange" && tag.value !== "," && tag.value !== "") {
                const existingPriceRange = this.filterValues.find(item => item.PriceRange === tag.value);
                if (!existingPriceRange) {
                    this.filterValues.push({ PriceRange: tag.value });
                }
            }
        });

        console.log("Updated filter values:", this.filterValues);
    }

    toggleSubcategories(index: number) {
        console.log("this category index to open sub category: ", index);
        this.showSubcategories[index] = !this.showSubcategories[index];
    }

    getFilteredValues() {
        //console.log(this.filterValues.filter(item => !item.SubCategoryName));
        return this.filterValues;
    }

    getBrandSettings() {
        console.log('getBrands: Fetching brands data');
        this.brandService.getBrandSettings()
            .subscribe((res) => {
                console.log('getBrands: Brands Settings data received in Theme Category:', res);
                this.IsSubCategoryShowing = res.isSubCategoryShowing;
                console.log("IS Sub Category Showing in Theme Category: ", this.IsSubCategoryShowing);
            },
                (error: HttpManagerErrorResponse) => { },
                () => {
                    console.log('getBrands: Completed fetching brands data');
                });
    }

}
