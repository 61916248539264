import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/base.component';
import { StyleMinQuantityRequest } from 'src/app/shared/classes/product/style-min-quantity-request.model';
import { ProductService } from 'src/app/shared/services/product.service';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from '../../../shared/components/modal/modal/modal.component';

@Component({
    selector: 'app-set-min-quantity-modal',
    templateUrl: './set-min-quantity-modal.component.html',
    styleUrls: ['./set-min-quantity-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, FormsModule]
})
export class SetMinQuantityModalComponent extends BaseComponent {
  minQuantity: number
  styleIDs: number[]

  constructor(private modalService: NgbModal,
    private productService: ProductService) {
    super();
  }

  onModalCanceled() {
    this.modalService.dismissAll(false);
  }

  onModalConfirmed() {
    const request: StyleMinQuantityRequest = { styleIDs: this.styleIDs, minQuantity: this.minQuantity }
    this.productService.setMinQuantity(request)
      .subscribe(() => {
        this.modalService.dismissAll(true);
      }, () => {
        this.modalService.dismissAll(false);
      })
  }

}
