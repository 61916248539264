import { Component, OnInit, Output, Input, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, NgIf, TitleCasePipe } from '@angular/common';
import { Options, Ng5SliderModule } from 'ng5-slider';
import { SliderFilterModel } from '../../classes/dynamic-fIlters';

@Component({
    selector: 'app-slide-filters',
    templateUrl: './slide-filters.component.html',
    styleUrls: ['./slide-filters.component.scss'],
    standalone: true,
    imports: [NgIf, Ng5SliderModule, TitleCasePipe]
})
export class SlideFiltersComponent implements OnInit {

  // define min, max and range
  @Input() min: number;
  @Input() max: number;
  @Input() title: string;
  @Input() options: Options = {
    floor: 0,
    ceil: 100
  };

  // Using Output EventEmitter
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();

  collapse: boolean = true;
  isBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true; // for ssr
    }
  }

  ngOnInit(): void { }

  // Range Changed
  filter(event: any) {
    this.filterChange.emit(new SliderFilterModel(event.value, event.highValue));
  }

}

