import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserNewRegister } from 'src/app/shared/classes/user';
import { ModalComponent } from '../../shared/components/modal/modal/modal.component';
import { NgIf, NgClass } from '@angular/common';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { Router, RouterModule } from '@angular/router';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    imports: [NgIf, ModalComponent, FormsModule, ReactiveFormsModule, NgClass, TranslationModule, BreadcrumbComponent, RouterModule]
})
export class RegisterComponent implements OnInit {
    @Input() users: UserNewRegister;
    form: FormGroup;
    title: string = "Create Account";
    confirmText: string = "Sign Up";
    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private modalService: NgbModal, private translate: TranslateService, private il8nService: il8nService
    ) {
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }

    ngOnInit(): void {
        this.createForm();
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    }

    createForm() {
        this.form = this.formBuilder.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            Email: ['', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
            PhoneNumber: ['', Validators.required],
            Password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator()]],
            ConfirmPassword: ['', [Validators.required]],
        }, { validators: this.passwordMatchValidator });
    }

    passwordMatchValidator(g: FormGroup) {
        return g.get('Password').value === g.get('ConfirmPassword').value
            ? null : { 'mismatch': true };
    }

    onModalCanceled() {
        this.modalService.dismissAll();
    }

    onModalConfirmed() {
        if (this.form.invalid) return
        this.submitForm();
        this.onModalCanceled();
    }

    submitForm() {
        const model = new UserNewRegister({
            Email: this.form.value.Email,
            PhoneNumber: this.form.value.PhoneNumber,
            FirstName: this.form.value.FirstName,
            LastName: this.form.value.LastName,
            Password: this.form.value.Password,
            ConfirmPassword: this.form.value.ConfirmPassword
        });

        this.authService.registernewCustomer(model).subscribe(
            () => {
                this.modalService.dismissAll();
                this.router.navigateByUrl('/account/login');
                this.toastrService.success('Customer Registered Successfully');
            },
            (error: HttpManagerErrorResponse) => this.showAllErrorMessages(error)
        );

    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void {
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }


    passwordValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            const password = control.value;
            const hasLetter = /[a-zA-Z]/.test(password);
            const hasUppercase = /[A-Z]/.test(password);
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
            const isValid = hasLetter && hasUppercase && hasSpecialChar && password.length >= 6;
            return !isValid ? { passwordStrength: true } : null;
        };
    }

}
