import { Component, Input, HostListener, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { Option } from 'src/app/Store-Section/shared/interface/theme-option.interface';
import { TopbarComponent } from 'src/app/shared/widgets/NewTheme/topbar/topbar.component';
import { NavigationEnd, RouterModule } from '@angular/router';
import { SearchComponent } from 'src/app/shared/widgets/NewTheme/search/search.component';
import { ThemeCategoriesComponent } from 'src/app/shared/NewTheme/category/theme-categories/theme-categories.component';
import { RoleEnum } from '../../classes/role/role.enum';
import { AuthService } from '../../../core/auth/auth.service';
import { NavService } from '../../services/nav.service';
import { PageService } from '../../services/page.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { BaseComponent } from '../../../core/base.component';
import { LoginComponent } from 'src/app/account/login/login.component';
import { RegisterComponent } from 'src/app/account/register/register.component';
import { GetPageVM } from '../../classes/pages';
import { SettingsComponent } from 'src/app/shared/components/settings/settings.component';
import { CategoryDTO } from '../../classes/category-filter/category-dto.model';
import { FilterDynamicService } from '../../services/filter-dynamic.service';
import { ButtonComponent } from 'src/app/shared/widgets/NewTheme/button/button.component';
import { WishlistService } from '../../services/wishlist.service';
import { wishlistSearchParamsModel } from '../../classes/wishlist/wishlist-search-param.model';
import { HttpManagerErrorResponse } from '../../../core/helper/http-client-manager';
import { GetAllWishlistItemDto } from '../../classes/wishlist/get-wishlist-items.model';
import { UserService } from '../../services/user.service';
import { GetUserProfileModel } from '../../classes/user';
import { WishlistCartComponent } from 'src/app/shop/wishlist/wishlist-cart/wishlist-cart.component';
import { BrandsService } from '../../services/brands.services';
import { Brands, BrandsSearchParamsModel } from '../../classes/brands.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/language.service';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { MobileMenu } from '../../../Store-Section/shared/interface/menu.interface';
import { NavbarMenuButtonComponent } from '../../components/navbar-menu-button/navbar-menu-button.component';
import { MenuComponent } from '../../components/menu/menu.component';
@Component({
  selector: 'app-header-five',
    standalone: true,
    imports: [TopbarComponent, RouterModule, SearchComponent, CommonModule, SettingsComponent, ThemeCategoriesComponent, ButtonComponent, WishlistCartComponent, TranslateModule, NavbarMenuButtonComponent, ButtonComponent, MenuComponent],
  templateUrl: './header-five.component.html',
  styleUrl: './header-five.component.scss'
})
export class HeaderFiveComponent extends BaseComponent implements OnInit {
    @Input() data: Option | null;
/*    @Input() logo: string | null | undefined;*/
    //@Input() logo: string = 'assets/images/icon/Group 5.png'; // Default Logo
    @Input() sticky: boolean | number | undefined; // Default false
    categoryFilterlist: CategoryDTO;

    wishlistItems: GetAllWishlistItemDto[] = [];
    userProfile: any;
    profileimageUrl: string | ArrayBuffer | null = null;
    fallbackImageUrl: string = 'https://laravel.pixelstrap.net/fastkart/storage/1466/Group-427320142.png';
    public stick: boolean = false;
    public active: boolean = false;
    userRole: RoleEnum;
    pages: GetPageVM[];
    isLoggedIn: boolean;
    logo: string;
    selectedCategory: string | null = null;
    isMobile: boolean;
    SelectedTheme: string = "paris";
    constructor(@Inject(PLATFORM_ID) private platformId: Object, public navServices: NavService,
        private router: Router, private pageService: PageService,
        private modalService: NgbModal, private authServices: AuthService, public dynamicFilterService: FilterDynamicService, private wishlistService: WishlistService, private userService: UserService, private brandService: BrandsService, private languageService: LanguageService, private translate: TranslateService, private il8nService: il8nService) {
        super();
        this.getFilterByCategory();
        //GetTheme
        const Themename = localStorage.getItem('Theme');
        this.SelectedTheme = Themename;
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.menuItem?.forEach((menu: MobileMenu) => {
                    menu.active = false;
                    if ((event.url.split("?")[0].toString()) === menu.path) {
                        menu.active = true;
                    }
                })
            }
        })
    }

    onCategorySelected(category: string) {
        this.selectedCategory = category;
        console.log('ParentComponent: Selected category:', category);
    }

    public menuItem: MobileMenu[] = [
        {
            id: 1,
            active: true,
            title: 'Home',
            icon: 'ri-home-2',
            path: '/'
        },
        {
            id: 2,
            active: false,
            title: 'Category',
            icon: 'ri-apps-line js',
            path: '/products'
        },
        {
            id: 3,
            active: false,
            title: 'Search',
            icon: 'ri-search-2',
            path: '/products'
        },
        {
            id: 4,
            active: false,
            title: 'My Wish',
            icon: 'ri-heart-3',
            path: '/shop/wishlist'
        },
        {
            id: 5,
            active: false,
            title: 'Cart',
            icon: 'fly-cate ri-shopping-bag',
            path: '/shop/cart'
        }
    ]

    activeMenu(menu: MobileMenu) {
        this.menuItem.forEach(item => {
            this.menuItem.includes(menu)
            item.active = false;
        })
        menu.active = !menu.active
    }

    ngOnInit(): void {
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
        this.getLogo();

        if (this.logo == null) {
            this.logo = "src/app/image/assests/images/logos/Ec_logo.png";
        }
        document.body.style.overflowX = 'unset';
        this.closeMainMenu();

        this.pageService.onUpdateItem
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                this.getPages();
            })

        this.userService.onUpdateItem
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                this.getUserProfile();
            })

        this.wishlistService.onUpdateItem
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                this.getAllWishlisttItems();
            })

        this.getPages();
        this.getLoginState();
        this.authServices.onLoginStateChanged
            .pipe(this.takeUntilDestroy())
            .subscribe((state: boolean) => {
                if (state) {
                    const roles = this.authServices.getRole();
                    if (roles && roles.length > 0) {
                        this.userRole = this.getUserRole(roles);
                    }
                    this.getUserProfile();
                }
            });

        if (this.isLoggedIn) {
            this.getUserProfile();
        }

        this.getAllWishlisttItems();
    }

    private getPages() {
        this.pageService.getAllMenu()
            .pipe(this.takeUntilDestroy())
            .subscribe(pages => {

                this.pages = pages.menu;
                console.log("Getting Pages all", this.pages);
            })
    }
    private getUserRole(roles: string[]): RoleEnum {
        if (roles.includes(RoleEnum.Admin)) {
            return RoleEnum.Admin;
        } else {
            return null; // Or any other appropriate value for non-admin users
        }
    }

    navigateToPage(item: any) {
        console.log('Navigating to:', ['/pages', item.url]); // Debugging output
        this.router.navigate(['/pages', item.url], { skipLocationChange: false, replaceUrl: true }).then(success => {
            if (success) {
                console.log('Navigation successful');
            } else {
                console.log('Navigation failed');
            }
        }).catch(error => {
            console.log('Navigation error:', error);
        });
    }

    getAllWishlisttItems(searchRequest?: wishlistSearchParamsModel): void {

        if (this.isLoggedIn) {
            this.wishlistService.getAllWishlistItems(searchRequest)
                .pipe(this.takeUntilDestroy())
                .subscribe(
                    (res) => {
                        if (res) {
                            this.wishlistItems = res.items.xData;
                            console.log("wishlist items in header: ", this.wishlistItems);
                        }
                    },
                    (error: HttpManagerErrorResponse) => {
                        this.wishlistService.showAllErrorMessages(error)
                    })
        }
        else {
            this.wishlistService.getAllGuestWishlistItems()
                .pipe(this.takeUntilDestroy())
                .subscribe(
                    (res) => {
                        if (res) {
                            this.wishlistItems = res.items.xData;
                            console.log("Guest wishlist items in header: ", this.wishlistItems);
                        }
                    },
                    (error: HttpManagerErrorResponse) => {
                        this.wishlistService.showAllErrorMessages(error)
                    })
        }
        
    }

    getUserProfile(): void {
        this.userService.getUserProfile().pipe(this.takeUntilDestroy()).subscribe(
            (res) => {
                if (res) {
                    this.userProfile = res.xResult;
                    this.profileimageUrl = this.userProfile.profileImage;
                    console.log("user prodile in header: ", this.userProfile)
                }
            }
        )
    }

    openLoginModal(): void {
        this.modalService.open(LoginComponent, {
            size: 'md',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart loginModalPopUp'
        })
    }

    openRegisterModal(): void {
        this.modalService.open(RegisterComponent, {
            size: 'md',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart loginModalPopUp'
        })
    }

    logout() {
        this.authServices.signOut()
    }

    private getLoginState() {
        this.authServices.onLoginStateChanged
            .pipe(this.takeUntilDestroy())
            .subscribe(
                (state: boolean) => this.isLoggedIn = state)
    }

    mainMenuToggle(): void {
        this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    }

    private closeMainMenu() {
        this.router.events
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                this.navServices.mainMenuToggle = false;
            });
    }

    ngOnDestroy(): void {
        document.body.style.overflowX = 'hidden';
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (isPlatformBrowser(this.platformId)) { // For SSR 
            let number = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
            //console.log('Scroll Position:', number);  // Debugging line
            this.stick = number >= 150 && window.innerWidth > 400;
            //console.log('Stick Value:', this.stick);  // Debugging line
        }
    }

    toggle(val: boolean) {
        this.active = val;
    }

    private getFilterByCategory() {
        this.dynamicFilterService.getCategoryFilter().subscribe(
            (categories: CategoryDTO) => {
                this.categoryFilterlist = categories;
            },
            (error) => {
            }
        );
    }
    getLogo() {
        console.log('getBrands: Fetching brands data');
        const getAllRequest = Object.assign(new BrandsSearchParamsModel(), { page: 0, pageSize: 0 });
        this.brandService.getAllBrands(getAllRequest)
            .pipe(this.takeUntilDestroy())
            .subscribe((res) => {
                console.log('getBrands: Brands data received:', res[0].brandLogo);
                this.logo = 'data:image/png;base64,' + res[0].brandLogo;
            },
                (error: HttpManagerErrorResponse) => { },
                () => {
                    console.log('getBrands: Completed fetching brands data');
                });
        }
}
