import { Component, OnInit } from '@angular/core';
import { NgbNavModule, NgbModal, ModalDismissReasons, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/core/base.component';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { AddAccountVM } from 'src/app/shared/classes/Payment-Accounts/add-account.model';
import { PaymentAccountService } from 'src/app/shared/services/payment-accounts.service';
import { PaymentsAccountSearch } from 'src/app/shared/classes/Payment-Accounts/PaymentAccounts.Search';
import { IntegrationAccountDetails } from 'src/app/shared/classes/Payment-Accounts/IntegrationAccountDetails.model';
import { NoItemFoundComponent } from '../../../shared/components/no-item-found/no-item-found.component';
import { GridSkeletonLoaderComponent } from '../../shared/grid-skeleton-loader/grid-skeleton-loader.component';
import { DataTablesModule } from 'angular-datatables';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ShippingInfo, ShippingInfoSearchParamsModel } from '../../../shared/classes/shipping-info.model';
import { ShippingInfoService } from '../../../shared/services/shipping-info.service';
import { AddFedexModalComponent } from './add-fedex-modal/add-fedex-modal.component';
import { TranslationModule } from 'src/app/translation.module';
import { TranslateService } from '@ngx-translate/core';
import { RouterModule, Router } from '@angular/router';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from '../../../shared/components/NewTheme/pagination/pagination.component';
import { TableColumn } from '../../../shared/NewTheme/interface/table.interface';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-fedex',
    templateUrl: './fedex.component.html',
    styleUrls: ['./fedex.component.scss'],
    standalone: true,
    imports: [NgIf, DataTablesModule, NgFor, NgbTooltipModule, TranslationModule, RouterModule, FormsModule, NgClass,
        GridSkeletonLoaderComponent, NoItemFoundComponent, PaginationComponent, ReactiveFormsModule]
})
export class FedexComponent extends BaseComponent implements OnInit {
    isLoading: boolean = true;
    public closeResult: string;
    shippings: ShippingInfo[] = []
    paginatedData = [];

    public rows = [10, 20, 30, 50, 100];
    term = new FormControl('');
    currentSortField: string | null = null;
    sortDirection: 'asc' | 'desc' = 'asc';

    public tableData: ShippingInfoSearchParamsModel = {
        'search': '',
        'page': 1,
        'pageSize': 10,
        sortBy: '',
        sortType: '',
        sortModel: undefined
    };
    totalPage: number;
    totalRecords: number;

    dtOptions: DataTables.Settings = {
        columnDefs: [
            { orderable: false, targets: [0, 3] }
        ],
        scrollX: true,
    };
    constructor(
        private shippingInfoService: ShippingInfoService,
        private modalService: NgbModal,
        private translate: TranslateService,
        private router: Router
    ) { super(); }

    ngOnInit(): void {
        this.getShippingInfo();

        this.shippingInfoService.onUpdateItem.pipe(this.takeUntilDestroy()).subscribe(res => {
            if (res)
                this.getShippingInfo();
        })

        this.term.valueChanges
            .pipe(debounceTime(300))
            .subscribe(value => {
                this.onChangeTable({ target: { value } }, 'search');
            });
    }

    editFedex(fedex: any): void {
        this.router.navigate(['/adminpanel/shipping-info/fedex/edit', fedex.id], { state: { fedex } });
    }

    getShippingInfo() {
        console.log('getShippingInfo: Fetching brands data');
        const getAllRequest = Object.assign(new ShippingInfoSearchParamsModel(), { page: 0, pageSize: 0 });
        this.shippingInfoService.getFedexShippingInfo()
            .pipe(this.takeUntilDestroy())
            .subscribe((res) => {
                console.log('getShippingInfo: Shipping data received:', res);
                this.resetDataTable(res);
            },
                (error: HttpManagerErrorResponse) => { },
                () => {
                    console.log('getShippings: Completed fetching shipping data');
                    this.isLoading = false;
                });
    }


    resetDataTable(res) {
        this.shippings = [];
        this.shippings = res;
        //setTimeout(() => this.shippings = res, 0.0001);
        this.totalRecords = this.shippings.length;
        this.totalPage = Math.ceil(this.totalRecords / this.tableData.pageSize);
        this.applyPagination();
    }

    openFedexModal(shippingInfo) {
        const modalRef = this.modalService.open(AddFedexModalComponent, {
            size: 'lg',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart'
        })
        modalRef.componentInstance.shippingInfo = shippingInfo;
        modalRef.result.then((result) => {
            `Result ${result}`
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        })
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    applyPagination() {
        let filteredData = this.applyFiltering();
        filteredData = this.applySorting(filteredData);
        const start = (this.tableData.page - 1) * this.tableData.pageSize;
        const end = start + this.tableData.pageSize;
        this.paginatedData = filteredData.slice(start, end);
    }

    applySorting(data: any[]) {
        if (this.tableData.sortBy) {
            return data.sort((a, b) => {
                const fieldA = a[this.tableData.sortBy];
                const fieldB = b[this.tableData.sortBy];

                if (fieldA == null || fieldB == null) {
                    return 0; 
                }

                if (this.tableData.sortType === 'asc') {
                    return fieldA > fieldB ? 1 : -1;
                } else {
                    return fieldA < fieldB ? 1 : -1;
                }
            });
        }
        return data;
    }


    applyFiltering() {
        if (this.tableData.search) {
            return this.shippings.filter(item =>
                Object.values(item).some(value =>
                    value != null && value.toString().toLowerCase().includes(this.tableData.search.toLowerCase())
                )
            );
        }
        return this.shippings;
    }


    onChangeTable(event: any, action: string) {
        if (action === 'sort') {
            this.tableData.sortBy = event.dataField;
            this.tableData.sortType = this.tableData.sortType === 'asc' ? 'desc' : 'asc';
        }

        if (action === 'page') {
            this.tableData.page = event;
        }

        if (action === 'paginate') {
            this.tableData.pageSize = +event.target.value;
            this.tableData.page = 1;
            this.totalPage = Math.ceil(this.totalRecords / this.tableData.pageSize);
        }

        if (action === 'search') {
            this.tableData.search = event.target.value;
            this.tableData.page = 1;
        }
        this.applyPagination();
    }

    getSortClass(field: string): string {
        if (this.currentSortField === field) {
            return this.sortDirection === 'asc' ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill';
        }
        return 'ri-arrow-up-s-fill';
    }

    updateStatus(model: ShippingInfo) {
        model.isActive = !model.isActive;
        this.shippingInfoService.UpdateAccountDetails(model).subscribe()
    }
}
