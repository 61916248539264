import { Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-side-filter-skeleton',
    templateUrl: './side-filter-skeleton.component.html',
    styleUrls: ['./side-filter-skeleton.component.scss'],
    standalone: true,
    imports: [NgFor, NgxSkeletonLoaderModule],
})
export class SideFilterSkeletonComponent {
  skeletonThemeOption = {
    height: '23px',
    background:
      'linear-gradient(270deg, rgba(240, 240, 244, .6),rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };

  skeletonThemeRange = {
    height: '30px',
    background:
      'linear-gradient(270deg, rgba(240, 240, 244, .6), rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };

  skeletonThemeTitle = {
    height: '33px',
    background:
      'linear-gradient(270deg, rgba(240, 240, 244, .6), rgba(240, 240, 244, .4))',
    marginBottom: '0px',
  };
}
