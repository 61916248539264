import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { ModalDismissReasons, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CouponService } from '../../shared/services/coupon.service';
import { HttpManagerErrorResponse } from '../../core/helper/http-client-manager';
import { AddEditCoupon, Coupon, CouponsSearchParamsModel } from '../../shared/classes/promotion/coupon.model';
import { CouponModalComponent } from './coupon-modal/coupon-modal.component';
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { GridSkeletonLoaderComponent } from '../shared/grid-skeleton-loader/grid-skeleton-loader.component';
import { NoItemFoundComponent } from '../../shared/components/no-item-found/no-item-found.component';
import { LoaderComponent } from '../../Store-Section/shared/components/widgets/loader/loader.component';
import { PaginationComponent } from '../../shared/components/NewTheme/pagination/pagination.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from 'src/app/translation.module';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '../../shared/NewTheme/interface/table.interface';
import { Router, RouterModule } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { CouponChangeStatusVM } from '../../shared/classes/promotion/coupon-change-status-vm';


@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
    styleUrl: './coupon.component.scss',
    standalone: true,
    imports: [NgIf, DataTablesModule, NgFor, NgbTooltipModule, PaginationComponent,NgClass,ReactiveFormsModule,CommonModule,
        GridSkeletonLoaderComponent, NoItemFoundComponent, LoaderComponent, TranslationModule, RouterModule]
})

export class CouponComponent extends BaseComponent implements OnInit {
    public rows = [10,20, 30, 50, 100];
    term = new FormControl('');
    currentSortField: string | null = null;
    sortDirection: 'asc' | 'desc' = 'asc';
    form: FormGroup;

    isLoading: boolean = true;
    public closeResult: string;
    coupons: Coupon[] = []

    dtOptions: DataTables.Settings = {
        columnDefs: [
            { orderable: false, targets: [0, 1] }
        ],
        scrollX: true,
    };

    paginatedData = [];
    public tableData: CouponsSearchParamsModel = {
        'search': '',
        'page': 1,
        'pageSize': 10,
        sortBy: '',
        sortType: '',
        sortModel: undefined
    };
    totalPage: number;
    totalRecords: number;

    constructor(
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private translate: TranslateService,
        private couponService: CouponService,
        private router: Router
    ) { super(); }

    ngOnInit(): void {
        this.getCouponslist();

        this.couponService.onUpdateItem
            .pipe(this.takeUntilDestroy())
            .subscribe(res => {
                this.getCouponslist();
            })

        this.term.valueChanges
            .pipe(debounceTime(100))
            .subscribe(value => {
                this.onChangeTable({ target: { value } }, 'search');
            });

    }

    getCouponslist() {
        this.couponService.getCoupons()
            .pipe(this.takeUntilDestroy())
            .subscribe((res) => {
                
                this.resetDataTable(res.xData)
            },
                (error: HttpManagerErrorResponse) => { },
                () => this.isLoading = false);
    }


    editCoupon(coupon: any): void {
        this.router.navigate(['/adminpanel/coupons/edit', coupon.id], { state: { coupon } });
    }

    //openCouponModal(coupon) {
    //    const modalRef = this.modalService.open(CouponModalComponent, {
    //        size: 'lg',
    //        ariaLabelledBy: 'size-modal',
    //        centered: true,
    //        windowClass: 'SizeChart'
    //    });
    //    console.log("Coupon before modal open :", coupon)
    //    if (coupon) {

    //        let productIds = null;
    //        let customerIds = null;

    //        if (coupon.couponsVariations && coupon.couponsVariations.length > 0) {
    //            productIds = coupon.couponsVariations[0].productID;
    //            customerIds = coupon.couponsVariations[0].customerID;
    //        }
    //        modalRef.componentInstance.addeditcoupon = {
    //            id: coupon.id,
    //            title: coupon.title,
    //            couponCode: coupon.couponCode,
    //            couponType: coupon.couponType,
    //            discountedPrice: coupon.discountedPrice,
    //            discountPercentage: coupon.discountPercentage,
    //            startDate: coupon.startDate,
    //            endDate: coupon.endDate,
    //            remainingUsage: coupon.remainingUsage,
    //            isActive: coupon.isActive,
    //            productIds: productIds,
    //            customerIds: customerIds
    //        };

    //        console.log("Coupon after modal open :", modalRef.componentInstance.addeditcoupon)
    //    }
    //    modalRef.result.then((result) => {
    //        `Result ${result}`
    //    }, (reason) => {
    //        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //    });
    //}

    //private getDismissReason(reason: any): string {
    //    if (reason === ModalDismissReasons.ESC) {
    //        return 'by pressing ESC';
    //    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    //        return 'by clicking on a backdrop';
    //    } else {
    //        return `with: ${reason}`;
    //    }
    //}

    resetDataTable(res) {
        this.coupons = res;
        this.totalRecords = res.length;
        this.totalPage = Math.ceil(this.totalRecords / this.tableData.pageSize);
        console.log("total page ::" + this.totalPage);
        this.applyPagination();
        //setTimeout(() => this.coupons = res, 0.0001);

    }

    removeCoupon(id) {
        this.couponService.removeCoupons(id);
    }

    applyPagination() {
        let filteredData = this.applyFiltering();
        filteredData = this.applySorting(filteredData);
        const start = (this.tableData.page - 1) * this.tableData.pageSize;
        const end = start + this.tableData.pageSize;
        this.paginatedData = filteredData.slice(start, end);
    }

    applySorting(data: any[]) {
        if (this.tableData.sortBy) {
            return data.sort((a, b) => {
                const fieldA = a[this.tableData.sortBy];
                const fieldB = b[this.tableData.sortBy];

                if (fieldA == null || fieldB == null) {
                    return 0;
                }

                if (this.tableData.sortType === 'asc') {
                    return fieldA > fieldB ? 1 : -1;
                } else {
                    return fieldA < fieldB ? 1 : -1;
                }
            });
        }
        return data;
    }


    applyFiltering() {
        if (this.tableData.search) {
            return this.coupons.filter(item =>
                Object.values(item).some(value =>
                    value != null && value.toString().toLowerCase().includes(this.tableData.search.toLowerCase())
                )
            );
        }
        return this.coupons;
    }


    onChangeTable(event: any, action: string) {
        if (action === 'sort') {
            this.tableData.sortBy = event.dataField;
            this.tableData.sortType = this.tableData.sortType === 'asc' ? 'desc' : 'asc';
        }

        if (action === 'page') {
            this.tableData.page = event;
        }

        if (action === 'paginate') {
            this.tableData.pageSize = +event.target.value;
            this.tableData.page = 1;
            this.totalPage = Math.ceil(this.totalRecords / this.tableData.pageSize);
        }

        if (action === 'search') {
            this.tableData.search = event.target.value;
            this.tableData.page = 1;
        }
        this.applyPagination();
    }

    getSortClass(field: string): string {
        if (this.currentSortField === field) {
            return this.sortDirection === 'asc' ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill';
        }
        return 'ri-arrow-up-s-fill';
    }

    updateStatus(coupon: Coupon) {
        const request: CouponChangeStatusVM = {
            id: +coupon.id,
            isActive: !coupon.isActive
        }

        this.couponService.updateCouponsStatus(request).subscribe()

        this.getCouponslist();
    }
}
