export class AuthError {

  constructor(public xCode: number) { }

}


export enum LoginResultEnum {

  isSuccessful = 1,
  isFailed = 2,
  initialMultilingualNotFilled = 3
  
}