import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private currentTheme: string;

    constructor() { }

    setTheme(theme: 'user' | 'admin') {
        this.currentTheme = theme;
        const themeLink = document.getElementById('app-theme') as HTMLLinkElement;

        if (themeLink) {
            /*themeLink.href = `assets/styles/${theme}-theme.css`;*/
            themeLink.href = `assets/scss/app.scss`;
        } else {
            const link = document.createElement('link');
            link.id = 'app-theme';
            link.rel = 'stylesheet';
            /*link.href = `assets/styles/${theme}-theme.css`;*/
            link.href = `src/assets/scss/app.scss`;
            document.head.appendChild(link);
        }
    }

    getTheme(): string {
        return this.currentTheme;
    }
}
