export class UserRegister {

    username: string;

    password: string;

    firstName: string;

    lastName: string;

    isActive: boolean;

    isNegotiator: boolean;

    roleId?: number;

    profileImage: any;

}