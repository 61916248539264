<!--<h3 class="shippinginfo-heading">
    Shipping Methods
</h3>
<div class="shippinginfo-tabs-main d-flex">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
        <li ngbNavItem="Fedex">
            <a ngbNavLink>Fedex</a>
            <ng-template ngbNavContent>
                <app-fedex></app-fedex>
            </ng-template>
        </li>
        <li ngbNavItem="UPS">
            <a ngbNavLink>UPS</a>
            <ng-template ngbNavContent>
                <app-ups></app-ups>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="ml-4 shippinginfo-tabs-content-main"></div>
</div>-->

<div class="vertical-tabs">
    <div class="row">
        <div class="col-xl-3 col-lg-4">
            <div class="card">
                <div class="card-body">
                    <div class="title-header">
                        <div class="d-flex align-items-center">
                            <h5>{{ 'Shipping Methods' | translate}}</h5>
                        </div>
                    </div>

                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                        <li ngbNavItem="Fedex">
                            <a ngbNavLink>Fedex</a>
                            <ng-template ngbNavContent>
                                <div class="tab">
                                    <app-fedex></app-fedex>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem="UPS">
                            <a ngbNavLink>UPS</a>
                            <ng-template ngbNavContent>
                                <div class="tab">
                                    <app-ups></app-ups>
                                </div>
                            </ng-template>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    
        <div class="col-xl-9 col-lg-8">
            <div class="card">
                <div class="card-body">
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>

    </div>
</div>

