import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private triggerAppSettings = new Subject<void>();

  // Observable for triggering the app-settings button
  triggerAppSettings$ = this.triggerAppSettings.asObservable();

  // Method to emit event for app-settings button
  triggerSettingsAction() {
    this.triggerAppSettings.next();
  }
}