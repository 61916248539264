import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { environment } from 'src/environments/environment';
import { BrandSettings, Brands, BrandsSearchParamsModel } from '../classes/brands.model';
import { AddSubCategory } from '../classes/category/add-sub-category.model';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class BrandsService extends HttpClientManager {

    controllerName: string = "BrandInfo";
    brandApiUrl: string = `${environment.api_base_url}${this.controllerName}`
    brandsettingcontrollerName: string = "BrandSettings";
    brandSettingApiUrl: string = `${environment.api_base_url}${this.brandsettingcontrollerName}`
    onUpdateItem: Subject<any>;
    searchRequest: BrandsSearchParamsModel;
    constructor(
        httpHandler: HttpHandler,
        protected router: Router,
        private toastrService: ToastrService) {
        super(httpHandler, router);
        this.onUpdateItem = new Subject();
        this.searchRequest = new BrandsSearchParamsModel();
    }
    getAllBrands(searchRequest: BrandsSearchParamsModel): Observable<PagingResult<Brands>> {
        return this.getAll<PagingResult<Brands>>(this.brandApiUrl)
            .pipe(map((promotionList: PagingResult<Brands>) => {
                return promotionList;
            }));
    }

    addBrand(brand: FormData): any {
        return this.uploadFile<boolean>(this.brandApiUrl, brand)
            .subscribe(() => {
                this.onUpdateItem.next();
                this.toastrService.success('add successful.');
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            );
    }

    updateBrand(brand: FormData) {

        return this.updateFile<boolean>(this.brandApiUrl, brand).pipe(tap(res => {
            this.onUpdateItem.next();
            this.toastrService.success('edited successful.');
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }

    removeBrand(id: number) {
        this.deleteIDs<boolean>(this.brandApiUrl + `/Delete/${id}`, null).subscribe(res => {
            this.onUpdateItem.next();
            this.toastrService.success('delete successful.');
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            });
    }


    getBrandSettings(searchRequest: BrandsSearchParamsModel): Observable<BrandSettings> {
        return this.getAll<BrandSettings>(this.brandSettingApiUrl)
            .pipe(map((brandSettingsList: BrandSettings) => {
                return brandSettingsList;
            }));
    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void { // TODO: make it a shared function in utility
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
}
