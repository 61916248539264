
<div class="title-header">
    <div class="d-flex align-items-center">
        <h5>{{ 'UPS Setting' | translate}}</h5>
    </div>
    <!--(click)="openUPSModal(null)"-->
    <div button>
        <a class="align-items-center btn btn-theme d-flex" [routerLink]="['./ups/add']">
            <i class="ri-add-line"></i>
            Add New
        </a>
    </div>
</div>

<div class="show-box">
    <div class="selection-box">
        <label>{{ 'Show' | translate }} :</label>
        <select class="form-control" (change)="onChangeTable($event, 'paginate')">
            <option *ngFor="let item of rows" [value]="item">{{item}}</option>
        </select>
        <label>{{ 'Items per page' | translate }}</label>
    </div>

    <div class="table-search">
        <label for="role-search" class="form-label">{{ 'Search' | translate }} :</label>
        <input type="search" class="form-control" id="role-search" [formControl]="term">
    </div>

</div>

<div>
    <div class="table-responsive datatable-wrapper border-table">
        <table *ngIf="shippings?.length else noResult" 
               class="table all-package theme-table no-footer">
            <thead>
                <tr>
                    <!--<th scope="col" class="sm-width">#</th>-->
                    <th scope="col" class="sm-width">Service Type</th>
                    <th scope="col" class="sm-width">Account Number</th>
                    <th scope="col" class="sm-width">Is Active</th>
                    <th scope="col" class="sm-width">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let shipping of paginatedData ; index as i">
                    <!--<td class="pl-4">{{ (i+1 )}}</td>-->
                    <td>{{shipping.serviceType}}</td>
                    <td>{{shipping.accountNumber}}</td>
                    <td>
                        <div class="form-check form-switch ps-0">
                            <label class="switch switch-sm">
                                <input type="checkbox" name="isActive"
                                       class="ng-untouched ng-pristine"
                                       (click)="updateStatus(shipping)"
                                       [checked]="shipping.isActive ? true : false" />
                                <span class="switch-state"></span>

                            </label>
                        </div>

                    </td>
                    <td (click)="$event.stopPropagation()">
                        <ul>
                            <li>
                                <!--(click)="openUPSModal(shipping)"-->
                                <a class="cursor-pointer" ngbTooltip="Edit" (click)="editUps(shipping)" container="body">
                                    <i class="ri-pencil-line"></i>
                                </a>
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <ng-template #noResult>
            <app-grid-skeleton-loader *ngIf="isLoading"></app-grid-skeleton-loader>
            <app-no-item-found *ngIf="!isLoading"></app-no-item-found>
        </ng-template>

    </div>
</div>

<nav class="custom-pagination">
    <app-pagination [total]="totalPage" [currentPage]="tableData['page']" [pageSize]="tableData['pageSize']"
                    (setPage)="onChangeTable($event, 'page')">
    </app-pagination>
</nav>

<!--<div class="theme-card card-border admin-card">
    <div class="">
        <h5 class="m-0 font-size-16 font-weight-bold d-flex justify-content-between align-items-center">
            UPS Setting
            <a title="New Shipping" class="btn btn-bold" (click)="openUPSModal(null)">
                Add New
            </a>
        </h5>

    </div>
    <table *ngIf="shippings?.length else noResult" datatable [dtOptions]="dtOptions"
           class="card-body border-top table-striped p-0 w-100 table-item-nowrap">
        <thead class="bg-light">
            <tr>
                <th scope="col" class="pl-4 sort-none">#</th>
                <th scope="col">Service Type</th>
                <th scope="col">Account Number</th>
                <th scope="col">Is Active</th>
                <th scope="col" class="sort-none">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let shipping of shippings ; index as i">
                <td class="pl-4">{{ (i+1 )}}</td>
                <td>{{shipping.serviceType}}</td>
                <td>{{shipping.accountNumber}}</td>
                <td>{{shipping.isActive}}</td>
                <td (click)="$event.stopPropagation()" class="cursor-default ">
                    <div class="d-flex flex-nowrap justify-content-center">
                        <a class="cursor-pointer pr-2" ngbTooltip="Edit" (click)="openUPSModal(shipping)" container="body">
                            <i class="fa fa-pencil panel-action-btn btn-sm"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <ng-template #noResult>
        <app-grid-skeleton-loader *ngIf="isLoading"></app-grid-skeleton-loader>
        <app-no-item-found *ngIf="!isLoading"></app-no-item-found>
    </ng-template>
</div>-->
