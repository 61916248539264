import { Component, Input, OnInit ,} from '@angular/core';
import { Router, RouterLink } from '@angular/router'; 
import { ProductService } from "src/app/shared/services/product.service";
import { Product, Variants } from "src/app/shared/classes/product";
import { DiscountPipe } from 'src/app/shared/pipes/discount.pipe';
import { NgIf, NgFor, TitleCasePipe, CurrencyPipe, CommonModule } from '@angular/common';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { BaseComponent } from 'src/app/core/base.component';
import { GetAllWishlistItemDto } from 'src/app/shared/classes/wishlist/get-wishlist-items.model';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { getPager } from 'src/app/core/helper/utility';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { wishlistSearchParamsModel } from 'src/app/shared/classes/wishlist/wishlist-search-param.model';
import { AddToBasketRequest } from 'src/app/shared/classes/basket';
import { BasketService } from 'src/app/shared/services/basket.service';
import { ProductBoxWishlistComponent } from 'src/app/fastkart/product-box/product-box-wishlist/product-box-wishlist.component';
import { LoaderComponent } from 'src/app/Store-Section/shared/components/widgets/loader/loader.component';
import { ButtonComponent } from 'src/app/shared/widgets/NewTheme/button/button.component';
import { ToggleSidebarCart } from '../../../Store-Section/shared/action/cart.action';
import { Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';
import { TranslationModule } from 'src/app/translation.module';
import { AuthService } from '../../../core/auth/auth.service';
@Component({
  selector: 'app-wishlist-cart',
  standalone: true,
    imports: [BreadcrumbComponent, NgIf, NgFor, RouterLink, TitleCasePipe, CurrencyPipe, DiscountPipe, ProductBoxWishlistComponent, LoaderComponent, ButtonComponent, CommonModule, TranslationModule],
  templateUrl: './wishlist-cart.component.html',
  styleUrl: './wishlist-cart.component.scss'
})  
export class WishlistCartComponent extends BaseComponent implements OnInit {
    showPanelClass: string = 'fixed-cart';
    public cartStyle: string = 'cart_sidebar';
    public products: Product[] = [];
   @Input() wishlistItems: GetAllWishlistItemDto[] = [];
    paginate: any = {};
    selectedVariant: Variants;
    hasMinQuantityError: boolean = false;
    page: number = 1;
    isLoggedIn: boolean;
    isLoading: boolean = true;
    imageBaseUrl: string = environment.style_image_base_url;
    constructor(
        private router: Router,
        private authServices: AuthService,
        public productService: ProductService,
        private basketService: BasketService,
        private wishlistService: WishlistService,
        private store: Store) {
        super();
    }

    ngOnInit(): void {
        // this.getAllWishlisttItems();
        this.getLoginState();
        console.log("wishlist items: ", this.wishlistItems);
    }

    loadWishlist() {
        this.getAllWishlisttItems();
        this.imageBaseUrl
    }
    getAllWishlisttItems(searchRequest?: wishlistSearchParamsModel): void {
        this.isLoading = true;
        if (this.isLoggedIn) {
            this.wishlistService.getAllWishlistItems(searchRequest)
                .pipe(this.takeUntilDestroy())
                .subscribe(
                    (res) => {
                        if (res) {
                            this.wishlistItems = res.items.xData;
                            console.log("wishlist items: ", this.wishlistItems);
                            this.hasMinQuantityError = res.hasMinQuantityError;
                            this.paginate = getPager(res.items.xCount, +this.page);
                            this.isLoading = false;
                        }
                    },
                    (error: HttpManagerErrorResponse) => {
                        this.wishlistService.showAllErrorMessages(error)
                    })
        }
        else {
            this.wishlistService.getAllGuestWishlistItems()
                .pipe(this.takeUntilDestroy())
                .subscribe(
                    (res) => {
                        if (res) {
                            this.wishlistItems = res.items.xData;
                            console.log("Guest wishlist items in header: ", this.wishlistItems);
                            this.isLoading = false;
                        }
                    },
                    (error: HttpManagerErrorResponse) => {
                        this.wishlistService.showAllErrorMessages(error)
                    })
        }
    }


    async addToCart(selectedproduct: Variants) {
        console.log("Product Id that send to cart from ishlist: ", selectedproduct.variant_id);
        const selectedVariants: AddToBasketRequest[] = []
        selectedVariants.push({ styleItemId: selectedproduct.variant_id, count: 30, Images: null });
        console.log("select variant from wishlist for send to cart: ", selectedVariants);
        const status = await this.basketService.addToCart(selectedVariants);
        await this.wishlistService.removeWishlist(selectedproduct.variant_id, false);
        if (status) {
            this.router.navigate(['/shop/cart']);
        }
    }

    async addToCartAsGuest(styleItemId:number, count: number, image: any) {
        const selectedVariants: AddToBasketRequest[] = []
        selectedVariants.push({ styleItemId: styleItemId, count: count, Images: image });
        console.log("select variant from wishlist for send to cart as guest: ", selectedVariants);
        const status = await this.basketService.addToCartAsGuest(selectedVariants);
        await this.wishlistService.removeGuestWishlist(styleItemId);
    }

    /*async addToCart(selectedproduct: Variants, imageurl: any) {
        console.log("Product Id that send to cart from Wishlist: ", selectedproduct.variant_id);

        const selectedVariants: AddToBasketRequest[] = []
        if (this.isLoggedIn) {
            selectedVariants.push({ styleItemId: selectedproduct.variant_id, count: selectedproduct.minQuantity, Images: null });
            console.log("select variant from wishlist for send to cart: ", selectedVariants);
            await this.basketService.addToCart(selectedVariants);
            await this.wishlistService.removeWishlist(selectedproduct.variant_id, false);
        } else {
            selectedVariants.push({ styleItemId: selectedproduct.variant_id, count: selectedproduct.minQuantity, Images: imageurl });
            console.log("select variant from wishlist for send to cart: ", selectedVariants);
            await this.basketService.addToCartAsGuest(selectedVariants);
            await this.wishlistService.removeGuestWishlist(selectedproduct.variant_id);
        }
    }*/

    async removefromWishlist(selectedproduct: Variants) {
            console.log("remove product from wishlist: ", selectedproduct.variant_id);
            await this.wishlistService.removeWishlist(selectedproduct.variant_id);
            this.getAllWishlisttItems();        
    }

    async removefromGuestWishlist(id: number) {
        console.log("remove product from wishlist: ", id);
        await this.wishlistService.removeGuestWishlist(id);
        this.getAllWishlisttItems();
    }

    cartToggle(value: boolean) {

        if (value) {
            this.showPanelClass = 'fixed-cart show';
        }
        else {
            this.showPanelClass = 'fixed-cart';
        }
        this.store.dispatch(new ToggleSidebarCart(value));
    }

    private getLoginState() {
        this.authServices.onLoginStateChanged
            .pipe(this.takeUntilDestroy())
            .subscribe((state: boolean) => {
                this.isLoggedIn = state;
            })
    }
     
}