<!-- Menu Button -->
<app-button
  [class]="'navbar-toggler d-xl-none d-flex navbar-menu-button'"
  [type]="'button'"
  [id]="'toggle_menu'"
  (click)="toggleMenu()"
  >
  <span class="navbar-toggler-icon">
    <i class="ri-align-left f-w-600 me-3"></i>
  </span>
</app-button>
