import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        return this.checkUserLoginAndRouteRoleIsMatched(route);
    }

    checkUserLoginAndRouteRoleIsMatched(route: ActivatedRouteSnapshot): boolean {

        if (this.authService.isLoggedIn()) {
            if (route.data.roles && !this.userHasAccess(route)) {
                this.router.navigate(['']);
                return false;
            }
            return true;
        }

        this.router.navigate(['']);
        return false;
    }


    private userHasAccess(route: ActivatedRouteSnapshot) {
        const userRoles: string[] = this.authService.getRole();
        return userRoles.some((role) => route.data.roles.indexOf(role) !== -1);


    }

}
