<!--<ngx-loading-bar></ngx-loading-bar>-->
<!--<app-top-header></app-top-header>-->
<app-header-five></app-header-five>
<!--<app-header></app-header>-->
<router-outlet></router-outlet>

<app-tap-to-top></app-tap-to-top>
<!--<app-layout-box></app-layout-box>-->
<!--<app-footer class="app-footer w-100"></app-footer>-->

<app-app-footer></app-app-footer>
<app-sticky-compare></app-sticky-compare>