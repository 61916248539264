import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GridSizeEnum } from '../../classes/grid-size-enum';
import { Product } from '../../classes/product';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class GridComponent implements OnInit {
    @Input() isAvailable: boolean = true;
    @Input() products: Product[] = [];
    @Input() paginate: any = {};
    @Input() searchProductName: string;
    @Input() layoutView: string = 'grid-view';

    _sortBy: string;
    @Input()
    set sortBy(value: string) {
        this._sortBy = value;
    };
    get sortBy(): string {
        return this._sortBy;
    }

    _sortType: string;
    @Input()
    set sortType(value: string) {
        this._sortType = value;
    };
    get sortType(): string {
        return this._sortType;
    }

    @Output() setGrid: EventEmitter<any> = new EventEmitter<any>();
    @Output() setLayout: EventEmitter<any> = new EventEmitter<any>();
    @Output() sortedBy: EventEmitter<any> = new EventEmitter<any>();
    @Output() searchProductNameChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() isAvailableChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    gridSizeEnum = GridSizeEnum;

    constructor(private router: Router) { }

    ngOnInit(): void { }

    setGridLayout(value: string) {
        this.setGrid.emit(value);  // Set Grid Size
    }

    setLayoutView(value: string) {
        this.layoutView = value
        this.setLayout.emit(value); // Set layout view
    }

    sorting(event) {
        const select = event.target;
        const sortType = select.options[select.selectedIndex].getAttribute('sortType');
        const sortBy = select.options[select.selectedIndex].getAttribute('sortBy');
        this.sortedBy.emit({ sortBy: sortBy, sortType: sortType });
    }
    searchName(searchProductName: string) {
        this.searchProductNameChange.emit(searchProductName);
    }

    onAvailabilityChange(availability: boolean) {
        this.isAvailable = availability
        this.isAvailableChange.emit(availability)
    }

}
