<ng-container *ngIf="promotionList?.length">

    <div class="text-center pt-3">
        <h3 class="mb-0 mx-auto mb-0 text-dark">
            {{'Promotion' | titlecase}}
        </h3>
        <hr class="w-5 mt-0 hr-primary">
    </div>

    <div class="px-3">
        <owl-carousel-o class="offer-slider slide-1 px-4" [options]="ProductSliderOneConfig">
            <ng-container *ngFor="let promotion of promotionList">
                <ng-template carouselSlide>
                    <div class="product-box product-wrap" style="width:307px">
                        <a class="promotion-cart mt-2 mx-2" (click)="onPromotionClick(promotion)">
                            <img *ngIf="promotion.promotionImage" class="img"
                                [src]="'data:image/png;base64,'+promotion.promotionImage" />
                            <span *ngIf="!promotion.promotionImage"> {{promotion.title}} </span>
                        </a>
                        <div class="p-2 mx-2 title">
                            <span class="d-flex justify-content-center font-weight-bold"> {{promotion.title}}
                            </span>
                        </div>
                    </div>
                    <div class="box freeShipping" *ngIf="promotion.hasFreeShipping">
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</ng-container>