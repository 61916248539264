import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { il8nService } from 'src/app/il8n-service/il8n-service';
@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    constructor(private translate: TranslateService, private il8nService: il8nService) {
        this.translate.setDefaultLang('en');
        this.translate.use('en'); // Set default language 
    }

    setLanguage(lang: string) {
        console.log('Setting language to:', lang); // Confirm language change
      /* //this.translate.use(lang);*/
        this.il8nService.ChangeLanguage(lang);
    }

    getCurrentLanguage() {
        return this.translate.currentLang;
    }
}
