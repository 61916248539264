<!-- Search Basic Style -->
<div class="input-group" *ngIf="style == 'basic'">
    <input type="search" class="form-control" placeholder="{{'search'|translate}}" #inputRef>
    <app-button [type]="'button'" [class]="'btn'" [spinner]="false" [id]="'basic_search_btn'" (click)="redirectToSearch(inputRef.value)">
        <i class="ri-search-line"></i>
    </app-button>
</div>

<!-- Search Standard Style -->
<div *ngIf="style == 'standard'">
    <input type="search" class="form-control" placeholder="Search for product, delivered to your door..." #inputRef>
    <app-button [type]="'button'" [class]="'btn search-button'" [spinner]="false" [id]="'standard_search_btn'" (click)="redirectToSearch(inputRef.value)">
        <i class="ri-search-line"></i>
    </app-button>
</div>

<!-- Search Classic Style -->
<div class="searchbar-box-2 input-group d-xl-flex d-none" *ngIf="style == 'classic'">
    <app-button [type]="'button'" [class]="'btn search-icon'" [spinner]="false" [id]="'standard_search_btn'" (click)="redirectToSearch(inputRef.value)">
        <i class="ri-search-line"></i>
    </app-button>
    <input type="search" class="form-control" placeholder="Search for product, delivered to your door..." #inputRef>
    <app-button [type]="'button'" [class]="'btn search-button'" [spinner]="false" [id]="'standard_search_btn'" (click)="redirectToSearch(inputRef.value)">
        {{ 'search' | translate }}
    </app-button>
</div>