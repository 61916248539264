import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'toLocalDate',
    standalone: true
})
export class ToLocalDatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value)
      return Date.now().toString()
    return moment.utc(value).local().format()
  }

}
