import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ColorEnum } from '../classes/color-enum';
import { ColorPicker } from '../data/slider';
import { ViewModel } from './model/viewModel.model';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgIf, NgFor, NgStyle, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    standalone: true,
    imports: [NgIf, CarouselModule, NgFor, NgStyle, TitleCasePipe]
})
export class ColorVariantComponent {
  colorEnum = ColorEnum;
  viewModel: ViewModel[];
  ColorPickerConfig: any = ColorPicker;

  @Input() activeColorIndex: number = 0;
  @Input() set colorList(colorList: string[]) {
    if (colorList.length > 0) this.initializeViewModel(colorList)
  }

  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>(); // TODO: remove

  onChangeColor(color: string) { // TODO: remove
    this.activeColorIndex = this.colorList.indexOf(color);
    this.colorChange.emit(color);
  }
  
  private initializeViewModel(colorList: string[]) {
    let list = []
    colorList.forEach((color) => {
      list.push(new ViewModel(color));
    })

    this.viewModel = list
  }
}
