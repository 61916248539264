import { Component, OnInit, Input, Output, EventEmitter, input } from '@angular/core';
import { CategoryDTO } from 'src/app/shared/classes/category-filter/category-dto.model'
import { TagData } from 'src/app/shared/classes/dynamic-fIlters';
import { CheckBoxComponent } from 'src/app/shared/components/check-box/check-box.component';
import { ButtonComponent } from 'src/app/shared/widgets/NewTheme/button/button.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import { TranslationModule } from 'src/app/translation.module';
import { BrandsSearchParamsModel } from '../../../classes/brands.model';
import { BrandsService } from '../../../services/brands.services';
import { HttpManagerErrorResponse } from '../../../../core/helper/http-client-manager';
@Component({
  selector: 'app-theme-categories',
    standalone: true,
    imports: [NgbAccordionModule, NgFor, NgIf, CheckBoxComponent, RouterModule, ButtonComponent, CarouselModule, TranslationModule, NgClass],
  templateUrl: './theme-categories.component.html',
  styleUrl: './theme-categories.component.scss'
})
export class ThemeCategoriesComponent implements OnInit{
    @Input() sliderOption: OwlOptions = {
        margin: 20,
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 3
            },
            400: {
                items: 3
            },
            500: {
                items: 4
            },
            740: {
                items: 5
            },
            940: {
                items: 7
            }
        },
        nav: false
    }
    @Input() filterModel: CategoryDTO[] = [];
    /*@Input() tagsData: TagData[];*/
    @Output() filterChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() categorySelected: EventEmitter<string> = new EventEmitter<string>();
    @Input() IsCategorySection: number = 0;
    IsSubCategoryShowing: boolean = true; 
    filterValues: any[] = [];
    standardFilterName: string;
    activeIds: any[] = []; 
    subCategoryList: { subCategoryID: number, subCategoryName: string, subCategoryImage: string }[] = [];
    showSubcategories: boolean[] = []; // Array to manage visibility of subcategorie
    isDropdownOpen = false;
    selectedCategory: string | null = null;

    
    //public get _tagsData(): TagData[] {
    //    return this.tagsData;
    //}

    constructor(private router: Router, private route: ActivatedRoute, private brandService: BrandsService) { }

    ngOnInit(): void {
        this.getBrandSettings();
        console.log("isSubcategoryShowing:", this.IsSubCategoryShowing);
       
        this.filterModel.forEach(() => {
            this.showSubcategories.push(false);
        });
        if (this.IsCategorySection < 6) {
            if (this.filterModel) {
                for (let [key, value] of Object.entries(this.filterModel)) {
                    this.activeIds.push(value.categoryName);

                }
            }
            if (this.filterModel && Array.isArray(this.filterModel)) {
                this.filterModel.forEach(category => {
                    if (Array.isArray(category.subCategories)) {
                        this.subCategoryList.push(...category.subCategories);
                    }
                });
            } else {
                console.error('filterModel is not properly defined or not an array');
            }
        }
        
        console.log("this.subCategoryList");
        console.log(this.filterModel)
    }

    onCategorySelected(event: string) {
        //console.log('ThemeCategoriesComponent: Selected value:', event);
        this.categorySelected.emit(event);
    }

    onCategoryClick(category: any): void {
        const queryParams: any = { category: category.categoryName };
        if (this.IsSubCategoryShowing && category.subCategories.length > 0) {
            queryParams.subCategory = category.subCategories.map(sub => sub.subCategoryName).join(',');
        }

        this.router.navigate(['/products'], { queryParams });
    }

    onCategorySelectedinDropDown(event: Event) {
        const selectedValue = (event.target as HTMLSelectElement).value;
        this.router.navigate(['/products'], {
            queryParams: { category: selectedValue },
            skipLocationChange: false,
            replaceUrl: true   
        });
    }

    toggleSubcategories(index: number) {
        console.log("this category index to open sub category: ", index);
        this.showSubcategories[index] = !this.showSubcategories[index];
    }

    toggleDropdown(): void {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    onSubcategorySelected(event: string) {
        //const selectedValue = (event.target as HTMLSelectElement).value;
        this.selectedCategory = event;
        this.router.navigate(['/products'], {
            queryParams: { subCategory: event },
            skipLocationChange: false,
            replaceUrl: true
        });
    }


    selectCategory(categoryName: string) {
        console.log(categoryName);
        this.isDropdownOpen = false;
    }

    selectSubcategory(subcategoryName: string) {
        console.log(subcategoryName);
        this.isDropdownOpen = false;
    }

    getBrandSettings() {
        console.log('getBrands: Fetching brands data');
        this.brandService.getBrandSettings()
            .subscribe((res) => {
                console.log('getBrands: Brands Settings data received in Theme Category:', res);
                this.IsSubCategoryShowing = res.isSubCategoryShowing;
                console.log("IS Sub Category Showing in Theme Category: ", this.IsSubCategoryShowing);
            },
                (error: HttpManagerErrorResponse) => { },
                () => {
                    console.log('getBrands: Completed fetching brands data');
                });
    }
}
