<ul class="color-variant" *ngIf="viewModel">
  <owl-carousel-o class="offer-slider slide-1 w-100" [options]="ColorPickerConfig">
    <ng-container *ngFor="let color of viewModel; let i=index">
      <ng-template carouselSlide [width]="color.width">
        <li *ngIf="color" class="item">
          <div class="d-flex flex-row align-items-center">
            <div class="border variant-color-indicator"
              [ngStyle]="{'background-color': colorEnum[color.text]}"> </div>
            <label for="color" class="caption my-1">{{color.text|titlecase}}</label>
          </div>
        </li>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</ul>