import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from 'src/app/shared/widgets/NewTheme/button/button.component';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-search',
  standalone: true,
    imports: [CommonModule, ButtonComponent, TranslationModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
    @Input() style: string = 'basic';
    @Input() selectedCategory: string | null = null;
    public term = new FormControl();
    public show: boolean = false;

    constructor(private route: ActivatedRoute, private translate: TranslateService, private il8nService: il8nService,
        private router: Router) {
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        if (!selectedLanguage || !selectedLanguage.code) {
            selectedLanguage = { code: 'en' }; // Assuming 'en' is the code for English
        }
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }
    redirectToSearch(value: string) { 
        console.log(this.selectedCategory)
        this.router.navigate(['/products'], {
            relativeTo: this.route,
            queryParams: {
                productTitle: this.term.value ? this.term.value : value
            },
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        });
    }
    ngOnInit() {
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    }
    /*redirectToSearch(value: string) {
        // Get current query params and remove 'productTitle' if exists
        const currentQueryParams = { ...this.route.snapshot.queryParams };
        if ('productTitle' in currentQueryParams) {
            delete currentQueryParams['productTitle'];
        }

        // Add or update 'productTitle' with new value
        const newQueryParams = {
            ...currentQueryParams,
            productTitle: this.term.value ? this.term.value : value
        };

        // Navigate to the '/products' route with updated query params
        this.router.navigate(['/products'], {
            queryParams: newQueryParams,
            queryParamsHandling: 'merge', // Merge with other existing query params
            skipLocationChange: false,  // Trigger navigation and update the URL
            replaceUrl: true  // Replace the current URL in history with the new one
        }).then(() => {
            // If you want to force reload, you can add a temporary parameter
            this.router.navigate([], {
                queryParams: { _refresh: new Date().getTime() }, // Use current timestamp
                queryParamsHandling: 'merge',
                replaceUrl: true
            });
        });
    }*/

    toggleSearchBox() {
        this.show = !this.show
    }
}
