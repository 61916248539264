import { Component } from '@angular/core';
import { NoticeComponent } from 'src/app/shared/widgets/NewTheme/notice/notice.component';
import { LanguageComponent } from 'src/app/shared/NewTheme/language/language.component';
@Component({
  selector: 'app-topbar',
  standalone: true,
    imports: [NoticeComponent, LanguageComponent],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent { 
}
