<div class="theme-card card-border admin-card">
    <div class="">
        <h5 class="m-0 font-size-16 font-weight-bold d-flex justify-content-between align-items-center">
            Fedex Setting
            <a title="New Shipping" class="btn btn-bold" (click)="openFedexModal(null)">
                Add New
            </a>
        </h5>

    </div>
    <table *ngIf="shippings?.length else noResult" datatable [dtOptions]="dtOptions"
           class="card-body border-top table-striped p-0 w-100 table-item-nowrap">
        <thead class="bg-light">
            <tr>
                <th scope="col" class="pl-4 sort-none">#</th>
                <th scope="col">Service Type</th>
                <th scope="col">Account Number</th>
                <th scope="col">Is Active</th>
                <th scope="col" class="sort-none">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let shipping of shippings ; index as i">
                <td class="pl-4">{{ (i+1 )}}</td>
                <td>{{shipping.serviceType}}</td>
                <td>{{shipping.accountNumber}}</td>
                <td>{{shipping.isActive}}</td>
                <td (click)="$event.stopPropagation()" class="cursor-default ">
                    <div class="d-flex flex-nowrap justify-content-center">
                        <a class="cursor-pointer pr-2" ngbTooltip="Edit" (click)="openFedexModal(shipping)" container="body">
                            <i class="fa fa-pencil panel-action-btn btn-sm"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <ng-template #noResult>
        <app-grid-skeleton-loader *ngIf="isLoading"></app-grid-skeleton-loader>
        <app-no-item-found *ngIf="!isLoading"></app-no-item-found>
    </ng-template>
</div>