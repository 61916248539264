import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SettingState } from '../../NewTheme/state/setting.state';
import { Values } from '../../NewTheme/interface/setting.interface';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true
})
export class FooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/lucent-logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  original_lucent_url: string = environment.original_lucent_url;
  public today: number = Date.now();


  //@Select(SettingState.setting) setting$: Observable<Values>;
    currentYear: number;

    constructor(
    ) {
        this.currentYear = new Date().getFullYear();
    }

  ngOnInit(): void {
  }
}
