import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/core/base.component';
import { GetPromotion, PromotionSearchParamsModel } from 'src/app/shared/classes/promotion';
import { PromotionList } from 'src/app/shared/data/slider';
import { PromotionService } from 'src/app/shared/services/promotion.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgIf, NgFor, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-promotion-list',
    templateUrl: './promotion-list.component.html',
    styleUrls: ['./promotion-list.component.scss'],
    standalone: true,
    imports: [NgIf, CarouselModule, NgFor, TitleCasePipe]
})
export class PromotionListComponent extends BaseComponent implements OnInit {

  promotionList: GetPromotion[] = [];
  ProductSliderOneConfig: any = PromotionList;

  @Output() change = new EventEmitter<GetPromotion>();
  @Output() selectedPromotionInit = new EventEmitter<GetPromotion>();

  constructor(private promotionService: PromotionService,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.getPromotionList()
  }

  getPromotionList() {
    const getAllRequest = Object.assign(new PromotionSearchParamsModel(), { page: 0, pageSize: 0, isActive: true, getToday: true });
    this.promotionService.getAllPromotion(getAllRequest)
      .pipe(this.takeUntilDestroy())
      .subscribe((res) => {
        this.promotionList = res.xData;
        this.emitSelectedPromotion()
      });
  }

  onPromotionClick(promotion: GetPromotion) {
    this.change.emit(promotion)
  }

  private emitSelectedPromotion() {
    this.activatedRoute.queryParams
      .subscribe(params => {
        const id: string = params['promotionId']
        if (id) {
          const selectedPromotion: GetPromotion = this.promotionList.find((p) => p.id == id)
          this.selectedPromotionInit.emit(selectedPromotion)
        }
      });
  }

}
