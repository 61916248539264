import { Images, Product, ProductReviews, ProductVariants, VariantAttributes } from "src/app/shared/classes/product";
import { VariantViewModel } from "./variant-view-model";

export class ProductViewModel extends Product {

  constructor(values: Product) {
    super()
    Object.assign(this, values);
    this.setVariantsSelection();
    if (values.leadTime) this.calcLeadDate();
    this.selected = false
  }

  minQuantity?: number;
  subCategory?: string;
  department?: string;
  gender?: string;
  material?: string;
  leadTime?: number;
  imageIds?: number[];
  id?: number;
  title?: string;
  description?: string;
  type?: string;
  brand?: string;
  collection?: any[];
  category?: string;
  price?: number;
  sale?: boolean;
  discount?: number;
  stock?: number;
  new?: boolean;
  quantity?: number;
    tags?: any[];
    noOfOrders?: number;
  variants?: VariantViewModel[] = new Array<VariantViewModel>();
    productReviews?: ProductReviews[] = new Array<ProductReviews>();
    productVariants?: ProductVariants[] = new Array<ProductVariants>();
    variantAttributes?: VariantAttributes[] = new Array<VariantAttributes>();
  images?: Images[];
  details?: any[];
  comments?: any[];

  selected: boolean = false;
  leadDate: Date = new Date();

  private calcLeadDate() {
    var today = new Date();
    this.leadDate.setDate(today.getDate() + this.leadTime);
  }

  private setVariantsSelection() {
    this.variants.forEach((v, i) => this.variants[i] = new VariantViewModel(v));
    if (this.variants.length) this.variants[0].selected = true;
  }
  
}