import { Component, Input, OnInit } from '@angular/core';
import { AddEditCoupon, Coupon } from '../../../shared/classes/promotion/coupon.model';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CouponService } from '../../../shared/services/coupon.service';
import { ProductService } from '../../../shared/services/product.service';
import { UserService } from '../../../shared/services/user.service';
import { HttpManagerErrorResponse } from '../../../core/helper/http-client-manager';
import { Product, ProductSearchParamsModel } from '../../../shared/classes/product';
import { UserInfo } from 'os';
import { UserListModel } from '../../../shared/classes/user';
import { forkJoin } from 'rxjs';
import {NgFor, NgIf } from '@angular/common';
import { ModalComponent } from '../../../shared/components/modal/modal/modal.component';

@Component({
  selector: 'app-coupon-modal',
  templateUrl: './coupon-modal.component.html',
    styleUrl: './coupon-modal.component.scss',
    standalone: true,
    imports: [NgIf, ModalComponent, FormsModule, ReactiveFormsModule, NgFor]
})
export class CouponModalComponent implements OnInit {

    @Input() addeditcoupon: AddEditCoupon;
    products: Product[] = [];
    customers: UserListModel[] = [];
    title: string = "Create Coupon";
    confirmText: string = "Create";
    form: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private couponService: CouponService,
        private productService: ProductService,
        private customerService: UserService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        forkJoin({

            products: this.getProductList(),
            customers: this.getCustomerList()
        }).subscribe(({ customers, products }) => {
            this.products = products;
            this.customers = customers;
            console.log("products: ", products);
            console.log("customers: ", customers);
            this.createForm();

            if (this.addeditcoupon) {
                this.title = "Edit Coupon";
                this.confirmText = "Edit";
            }
        });

        console.log("Products in Coupon Popup:", this.products);
        console.log("Customers in Coupon Popup:", this.customers);
    }

    getCustomerList() {
        const searchParams = {
            PageSize: 100,
            SortType: 'asc'
        };
        return this.customerService.getAllUsers(searchParams);
    }

    getProductList() {
        const searchParams = {
            PageSize: 100,
            SortType: 'asc'
        };
        return this.productService.getAllProductsList(searchParams);
    }

    createForm() {
        this.form = this.formBuilder.group({
            id: [this.addeditcoupon ? this.addeditcoupon.id : 0],
            title: [this.addeditcoupon ? this.addeditcoupon.title : '', Validators.required],
            couponCode: [this.addeditcoupon ? this.addeditcoupon.couponCode : '', Validators.required],
            couponType: [this.addeditcoupon ? this.addeditcoupon.couponType : '', Validators.required],
            discountedPrice: [this.addeditcoupon ? this.addeditcoupon.discountedPrice : null],
            discountPercentage: [this.addeditcoupon ? this.addeditcoupon.discountPercentage : null],
            discountType: [this.calculateDiscountType()],
            /*startDate: [this.addeditcoupon ? this.datePipe.transform(this.addeditcoupon.startDate, 'yyyy-MM-dd HH:mm:ss') : '', Validators.required],
            endDate: [this.addeditcoupon ? this.datePipe.transform(this.addeditcoupon.endDate, 'yyyy-MM-dd HH:mm:ss') : null],*/
            startDate: [this.addeditcoupon ? this.addeditcoupon.startDate : '', Validators.required],
            endDate: [this.addeditcoupon ? this.addeditcoupon.endDate : null],
            remainingUsage: [this.addeditcoupon ? this.addeditcoupon.remainingUsage : null, Validators.required],
            isActive: [this.addeditcoupon ? this.addeditcoupon.isActive : true, Validators.required],
            productIds: [this.addeditcoupon ? this.addeditcoupon.productIds : null],

            customerIds: [this.addeditcoupon ? this.addeditcoupon.customerIds : null],

        });
    }
    calculateDiscountType(): string {
        if (this.addeditcoupon && this.addeditcoupon.discountedPrice !== null) {
            return 'byPrice';
        } else if (this.addeditcoupon && this.addeditcoupon.discountPercentage !== null) {
            return 'byPercentage';
        } else {
            return 'byPercentage';
        }
    }
   
    onModalCanceled() {
        this.modalService.dismissAll();
    }

    onModalConfirmed() {
        if (this.form.invalid) return
        this.submitForm();
        this.onModalCanceled();
    }

    submitForm() {
        const formValue = this.form.value;
        this.addeditcoupon
            ? this.couponService.updateCoupons(formValue).subscribe()
            : this.couponService.addCoupons(formValue).subscribe()
    }
}
