import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sticky-compare',
  standalone: true,
    imports: [RouterModule, TranslationModule],
  templateUrl: './sticky-compare.component.html',
  styleUrl: './sticky-compare.component.scss'
})
export class StickyCompareComponent {
    constructor(private translate: TranslateService, private il8nService: il8nService) {
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        if (!selectedLanguage || !selectedLanguage.code) {
            selectedLanguage = { code: 'en' }; // Assuming 'en' is the code for English
        }
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }
    ngOnInit() {
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    }
}
