import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentAccountService } from 'src/app/shared/services/payment-accounts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgClass, NgIf } from '@angular/common';
import { ModalComponent } from '../../../../shared/components/modal/modal/modal.component';   
import { ShippingInfo } from '../../../../shared/classes/shipping-info.model';
import { ShippingInfoService } from '../../../../shared/services/shipping-info.service';


@Component({
    selector: 'app-add-fedex-modal',
    templateUrl: './add-fedex-modal.component.html',
    styleUrls: ['./add-fedex-modal.component.scss'],
    standalone: true,
    imports: [ModalComponent, FormsModule, ReactiveFormsModule, NgClass, NgIf]
})
export class AddFedexModalComponent implements OnInit {
    @Input() shippingInfo: ShippingInfo;
    accountname: string;
    isLoading: boolean;
    authorizationUrl: string;
    selectedOption: boolean;
    id: number;
    isDisabled: boolean = true;
    AccountStatus = [{ "id": "0", "name": "InActive" }, { "id": "1", "name": "Active" }];
    title: string = "Add Fedex Account";
    btnText: string = "Add";
    form: FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        private shippingInfoService: ShippingInfoService,
        private modalService: NgbModal,
        private router: Router,
        private ToastrService: ToastrService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit() {

        const id = this.activatedRoute.snapshot.params.id;
        if (id) {
            this.title = "Edit Fedex Setting";
            this.btnText = "Edit";
            this.shippingInfo = history.state.fedex;
        }
        this.createForm();

        this.form.get('isActive')?.valueChanges.subscribe(value => {
            const currentValue = this.form.controls['isActive'].value;
            if (currentValue !== value) {
                this.form.controls['isActive'].setValue(value, { emitEvent: false });
            }
        });

    }
    createForm() {
        this.form = this.formBuilder.group({
            id: [this.shippingInfo ? this.shippingInfo.id : ''],
            serviceType: [this.shippingInfo ? this.shippingInfo.serviceType : 'FEDEX_GROUND'],
            isActive: [this.shippingInfo ? this.shippingInfo.isActive : false, Validators.required],
            accountNumber: [this.shippingInfo ? this.shippingInfo.accountNumber : '', Validators.required],
            clientId: [this.shippingInfo ? this.shippingInfo.clientId : '', Validators.required],
            clientSecret: [this.shippingInfo ? this.shippingInfo.clientSecret : '', Validators.required],
            authorization: [this.shippingInfo ? this.shippingInfo.authorization : '', Validators.required],
            ship: [this.shippingInfo ? this.shippingInfo.ship : '', Validators.required],
            track: [this.shippingInfo ? this.shippingInfo.track : '', Validators.required],
            rateAndTransitTimes: [this.shippingInfo ? this.shippingInfo.rateAndTransitTimes : '', Validators.required],
            cancel: [this.shippingInfo ? this.shippingInfo.cancel : '', Validators.required],
            shipperName: [this.shippingInfo ? this.shippingInfo.shipperName : '', Validators.required],
            streetLines: [this.shippingInfo ? this.shippingInfo.streetLines : '', Validators.required],
            city: [this.shippingInfo ? this.shippingInfo.city : '', Validators.required],
            countryCode: [this.shippingInfo ? this.shippingInfo.countryCode : '', Validators.required],
            state: [this.shippingInfo ? this.shippingInfo.state : '', Validators.required],
            postalCode: [this.shippingInfo ? this.shippingInfo.postalCode : '', Validators.required],
            phoneNumber: [this.shippingInfo ? this.shippingInfo.phoneNumber : '', Validators.required],
            companyName: [this.shippingInfo ? this.shippingInfo.companyName : '', Validators.required],
            weightUnits: [this.shippingInfo ? this.shippingInfo.weightUnits : '', Validators.required],
            weightOfEachGlassInKg: [this.shippingInfo ? this.shippingInfo.weightOfEachGlassInKg : '', Validators.required],
            numberOfGlassInEachBox: [this.shippingInfo ? this.shippingInfo.numberOfGlassInEachBox : '', Validators.required]
        });

        console.log("form:", this.form);

        if (this.shippingInfo) {
            this.id = this.shippingInfo.id;
            this.selectedOption = this.shippingInfo.isActive;
        } else {
            this.selectedOption = true;
        }
    }

    //onModalCanceled() {
    //    this.modalService.dismissAll();
    //}
    
    
    //onModalConfirmed() {
    //    if (this.form.invalid) return
    //    this.submitForm();
    //}
    submitForm() {
        const formValue = this.form.value;
        this.shippingInfo
            ? this.shippingInfoService.UpdateAccountDetails(formValue).subscribe()
            : this.shippingInfoService.AddAccountDetails(formValue).subscribe()

        this.navigateToPageList()
    }

    onStateChange() { 
        this.form.controls['isActive'].setValue(this.selectedOption);
    }

    private navigateToPageList() {
        this.router.navigate(['.'], { relativeTo: this.activatedRoute.parent });
    }
}
