import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'texttransform',
    standalone: true
})

export class QuestionTextTransformPipe implements PipeTransform {
    transform(value: string): string {
        const splitBy = '<br/>'
        const splittedText = value.split(splitBy);
        return `${splittedText[0]} ${splitBy} <br> <b>${splittedText[1]}</b>`;
    }
}