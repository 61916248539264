export class UserVerification {
  public constructor(init?: Partial<UserVerification>) {
    Object.assign(this, init);
  }
  username: string;
  securityStamp: string;
    password: string;
    rememberMe: boolean;
    isUserExist: boolean;
    registerNewCustomer: boolean;
}

export class ForgotPassword {
    public constructor(init?: Partial<ForgotPassword>) {
        Object.assign(this, init);
    }
    Email: string;
}

export class ResetPassword {
    public constructor(init?: Partial<ResetPassword>) {
        Object.assign(this, init);
    }
    Email: string;
    Token: string;
    Password: string;
    ConfirmPassword: string;
}

export class TokenResult {
    access_token : string;
    expires_in : number;
    token_type: string;
    refresh_token : string;
}
