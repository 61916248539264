import { Component, OnInit, Input } from '@angular/core';
import { NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer-four',
    templateUrl: './footer-four.component.html',
    styleUrls: ['./footer-four.component.scss'],
    standalone: true,
    imports: [NgClass, DatePipe]
})
export class FooterFourComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo

  public today: number = Date.now();
  
  constructor() { }

  ngOnInit(): void {
  }

}
