import { HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HttpClientManager } from 'src/app/core/helper/http-client-manager';
import { environment } from 'src/environments/environment';
import { DynamicFilterListModel } from '../classes/dynamic-fIlters';
import { Product } from '../classes/product';
import { CategoryDTO } from '../classes/category-filter/category-dto.model';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class FilterDynamicService extends HttpClientManager {

  productItems: Product[] = [];
  base_url: string = environment.api_base_url;
  controllerName: string = "StyleFilter";

  constructor(httpHandler: HttpHandler,
    protected router: Router,
    private toastrService: ToastrService) {
    super(httpHandler, router);
  }


  getAllDynamicFilters(queryParams: Params): Observable<DynamicFilterListModel> {
      const url = `${this.base_url}${this.controllerName}`;
    return this.getAll<DynamicFilterListModel>(url,queryParams);
  }


    getCategoryFilter(): Observable<CategoryDTO> {
        const url = `${this.base_url}${this.controllerName}/NewStyleFilter`;
        return this.getAll<CategoryDTO>(url);
    }

}
