import { Component } from '@angular/core';

@Component({
  selector: 'app-horizontal-loader',
  standalone: true,
  imports: [],
  templateUrl: './horizontal-loader.component.html',
  styleUrl: './horizontal-loader.component.scss'
})
export class HorizontalLoaderComponent {

}
