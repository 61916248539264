import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoaderState } from 'src/app/shared/widgets/NewTheme/state/loader.state';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-button',
  standalone: true,
    imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
    @Input() class: string = 'btn btn-animation w-100 justify-content-center';
    @Input() iconClass: string | null;
    @Input() id: string;
    @Input() label: string;
    @Input() type: string = 'submit';
    @Input() spinner: boolean = true;
    @Input() disabled: boolean = false;

    public buttonId: string | null;

    @Select(LoaderState.buttonSpinner) public spinnerStatus$: Observable<boolean>;

    constructor() {
        this.spinnerStatus$.subscribe(res => {
            console.log(res)
            if (res == false) {
                this.buttonId = null;
            }
        });
    }

    public onClick(id: string) {
        console.log("its a button page");
        console.log(id);
        this.buttonId = id;
    }

}
