import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentAccountService } from '../../../../shared/services/payment-accounts.service';

@Component({
    selector: 'app-verifystripe',
    templateUrl: './verifystripe.component.html',
    styleUrls: ['./verifystripe.component.scss'],
    standalone: true
})
export class VerifystripeComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router, private _PaymentAccountService: PaymentAccountService) { }

    ngOnInit(): void {

        this.route.queryParams.subscribe(params => {
            const scope = params['scope'];
            const code = params['code'];
            const state = params['state'];
             
              
            this._PaymentAccountService.CallbackFromStripAccount(scope, code, state)
                .subscribe(
                    (response) => {
                        console.log('API Response:', response);
                        this.router.navigate(['/panel/PaymentsAccounts']); 
                    },
                    (error) => {
                        console.error('API Error:', error); 
                    }
                );
        });
    }
  

}
