import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  /** It is LAZY: it'll be created (and allocate memory) only if you use takeUntilDestroy */
  private _destroy?: Subject<void>;

  protected takeUntilDestroy = <T>() => {
    if (!this._destroy) {
      this._destroy = new Subject<void>();
    }
    return takeUntil<T>(this._destroy);
  }

  ngOnDestroy(): void {
    if (this._destroy) {
      this._destroy.next();
      this._destroy.complete();
    }
  }
}
