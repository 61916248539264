import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { ProductBoxOneComponent } from '../../components/product/product-box-one/product-box-one.component';
import { NgFor, SlicePipe } from '@angular/common';


@Component({
    selector: 'app-related-product',
    templateUrl: './related-product.component.html',
    styleUrls: ['./related-product.component.scss'],
    standalone: true,
    imports: [NgFor, ProductBoxOneComponent, SlicePipe]
})
export class RelatedProductComponent implements OnInit {
  
  @Input() type: string

  public products: Product[] = [];

  constructor(public productService: ProductService) { 
    this.productService.getProducts.subscribe(response => 
      this.products = response.filter(item => item.type == this.type)
    );
  }

  ngOnInit(): void {
  }

}
