import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss'],
    standalone: true
})
export class SocialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
