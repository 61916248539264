<app-breadcrumb [title]="'forgot_password'|translate" [breadcrumb]="'forgot_password'|translate"></app-breadcrumb>
<section class="log-in-section section-b-space forgot-section">
    <div class="container-fluid-lg w-100">
        <div class="row align-items-center">
            <div class="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div class="image-contain">
                    <img src="assets/User-section/images/inner-page/forgot.png" class="img-fluid" alt="image">
                </div>
            </div>
            <div class="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <!--<app-alert></app-alert>-->
                <div class="log-in-box">
                    <div class="log-in-title">
                        <h3>{{ 'welcome_to_ecom' | translate }}</h3>
                        <h4>{{ 'forgot_your_password' | translate }}</h4>
                    </div>
                    <div class="input-box">
                        <app-modal [title]="title" [confirmText]="'send'|translate" (cancel)="onModalCanceled()" [IshomeComponent]="true"
                                   (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">
                            <form class="row g-4" [formGroup]="form" (keydown.enter)="onModalConfirmed()">
                                <div class="col-12">
                                    <div class="form-floating theme-form-floating log-in-form">
                                        <input type="email" class="form-control" id="Email" name="Email" placeholder="Enter Email" [formControl]="form.controls['Email']"
                                               [ngClass]="{'is-invalid': form.get('Email').invalid && form.get('Email').touched }" />
                                        <span *ngIf="form.get('Email').hasError('required') && form.get('Email').touched ||
                                             form.get('Email').hasError('email') && form.get('Email').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <label for="email">{{ 'email_address' | translate }}</label>
                                        <div *ngIf="form.get('Email').touched && form.get('Email').errors?.['required']" class="invalid-feedback">
                                            {{ 'email_is_required' | translate }}
                                        </div>
                                        <div *ngIf="form.get('Email').touched && form.get('Email').errors?.['email']" class="invalid-feedback">
                                            {{ 'invalid_email' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="col-12">
                                    <app-button [id]="'forgot_btn'">{{ 'send' | translate }}</app-button>
                                </div>-->
                            </form>
                        </app-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>










<!--<ng-container *ngIf="form">
    <app-modal [title]="title" [cancelText]="'Cancel'" [confirmText]="confirmText" (cancel)="onModalCanceled()"
               (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">

        <form class="theme-form" [formGroup]="form" (keydown.enter)="onModalConfirmed()">
            <div class="form-row">
                <div class="col-md-12 mb-6">
                    <label for="LastName">Email *</label>
                    <input type="email" class="form-control" id="Email" name="Email" placeholder="Enter Email" [formControl]="form.controls['Email']"
                           [ngClass]="{'is-invalid': form.get('Email').invalid && form.get('Email').touched }" />
                    <span *ngIf="form.get('Email').hasError('required') && form.get('Email').touched ||
                          form.get('Email').hasError('email') && form.get('Email').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>
                </div>
            </div>
        </form>

    </app-modal>
</ng-container>-->