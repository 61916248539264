import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'check-box',
    templateUrl: './check-box.component.html',
    styleUrls: ['./check-box.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class CheckBoxComponent {
  @Output() checkedChange = new EventEmitter<boolean>();
  @Input() checked: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() disabled: boolean;
  @Input() name: string;


  onChange(v: boolean) {
    const checked = this.ifTheStateIsFalse(v) ? true : v
    this.checkedChange.emit(checked)
  }

  private ifTheStateIsFalse(v: any) {
    return !this.checked && !this.indeterminate && !v;
  }
}
