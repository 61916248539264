import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { ForgotPassword } from '../../shared/classes/user/user-verification.model';
import { ModalComponent } from '../../shared/components/modal/modal/modal.component';
import { NgIf, NgClass } from '@angular/common';
import { TranslationModule } from 'src/app/translation.module';
import { il8nService } from 'src/app/il8n-service/il8n-service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss'],
    standalone: true,
    imports: [NgIf, ModalComponent, FormsModule, ReactiveFormsModule, NgClass, TranslationModule, BreadcrumbComponent]
})
export class ForgetPasswordComponent implements OnInit {
    form: FormGroup;
    title: string = "Forgot Password";
    confirmText: string = "Send";

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private modalService: NgbModal, private translate: TranslateService, private il8nService: il8nService
    ) {
        var selectedLanguage = JSON.parse(localStorage.getItem("language"));
        this.translate.setDefaultLang(selectedLanguage.code);
        this.translate.use(selectedLanguage.code);
    }

    ngOnInit(): void {
        this.createForm();
        this.il8nService.localEvent.subscribe(lang => this.translate.use(lang));
    }

    createForm() {
        this.form = this.formBuilder.group({
            Email: ['', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
        });
    }

    onModalCanceled() {
        this.modalService.dismissAll();
    }

    onModalConfirmed() {
        if (!this.form.valid) return
        this.submitForm();

    }

    private submitForm(): void {
        const model = new ForgotPassword({
            Email: this.form.value.Email,
        });

        this.authService.ForgotPassword(model).subscribe(
            () => {
                this.router.navigateByUrl('/');
                this.modalService.dismissAll();
                this.toastrService.success('Check Your Email and Follow the Procedure to Reset the Password.');
                this.onModalCanceled();
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        );
    }

    showAllErrorMessages(error: HttpManagerErrorResponse): void {
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
}


