<app-modal [title]="id ? 'Edit' : 'Add'" [cancelText]="'Cancel'" [confirmText]="'Save'" (cancel)="onModalCanceled()"
    (confirm)="onModalConfirmed()" [disableConfirmBtn]="!title">
    <div class="d-flex flex-wrap">
        <div class="col-sm-5 col-12">
            <input type='file' class="w-100" [(ngModel)]="file" (change)="readURL($event);" />
            <img [src]="image" (error)="errorImage($event)" class="img-fluid mt-2" alt="">
        </div>
        <div class="col-sm-7 col-12">
            <label>Title *</label>
            <input #titleCtrl="ngModel" type="text" class="form-control" id="region-state"
                [class]="titleCtrl.invalid && (titleCtrl.dirty || titleCtrl.touched)? 'is-invalid': ''"
                placeholder="title" required [(ngModel)]="title">
            <span *ngIf="titleCtrl.invalid && (titleCtrl.dirty || titleCtrl.touched)" class="invalid-feedback">
                Required
            </span>
        </div>
    </div>
</app-modal>