import { HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClientManager, HttpManagerErrorResponse } from 'src/app/core/helper/http-client-manager';
import { BaseModel } from 'src/app/core/model/base-model.model';
import { PagingResult } from 'src/app/core/model/paging-result.model';
import { environment } from 'src/environments/environment';
import { ContactUsForm } from '../classes/pages/add-page.model';
import { EditNegotiatorUserVM, EditUser, PrepareData, GetUser, UserListModel, UserRegister } from '../classes/user';
import { EditUserProfileModel, GetUserProfileModel } from '../classes/user';
import { EditUserFreePaymentVM } from '../classes/user/edit-user-free-paument.model';
import { EditUserFreeShippingVM } from '../classes/user/edit-user-free-shipping.model';
import { GetUserAdditionalInfoVM } from '../classes/user/get-user-additional-info.model';
import { UserSearchParamsModel } from '../classes/user/user-search-param.model';
import { EditProfileImage } from '../classes/user/edit-user.model';
import { ChangePasswordUserVM } from '../classes/user/edit-negotiator-user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService extends HttpClientManager implements Resolve<any>{

    controllerName: string = "User";
    userApiUrl: string = `${environment.api_base_url}${this.controllerName}`;

    contactUsControllerName: string = "ContactUs";
    contactUsApiUrl: string = `${environment.api_base_url}${this.contactUsControllerName}`;

    onUpdateItem: Subject<any>;
    onGetProfileInfo: BehaviorSubject<GetUserProfileModel>;

    constructor(protected router: Router, httpHandler: HttpHandler, private toastrService: ToastrService) {
        super(httpHandler, router);
        this.onUpdateItem = new Subject();
        this.onGetProfileInfo = new BehaviorSubject(null);
    }

    resolve(): Observable<any> | any {
        return this.getUserProfile();
    }

    /*getAllUsers(): Observable<UserListModel[]> {
        return this.getAll<UserListModel[]>(this.userApiUrl)
            .pipe(
                map((userList: UserListModel[]) => {
                    return userList;
                })
            );
    }*/
    getAllUsers(searchParams: any): Observable<UserListModel[]> {
        return this.getAll<any>(this.userApiUrl, searchParams) // Assuming the response type is any
            .pipe(
                map((response: any) => {

                    console.log("get users list from api: ", response);
                    const products = response.xData as UserListModel[]; // Assuming xData contains the array of products
                    return products;
                })
            );
    }

    getUserList(searchRequest: UserSearchParamsModel): Observable<PagingResult<UserListModel>> {
        
        return this.getAll<PagingResult<UserListModel>>(this.userApiUrl, searchRequest).pipe(map(userList => {
            return userList;
        }));
    }

    getUserAdditionalInfo(): Observable<GetUserAdditionalInfoVM> {
        return this.get<BaseModel<GetUserAdditionalInfoVM>>(this.userApiUrl + '/AdditionalInfo')
            .pipe(map(userNegotiator => {
                return userNegotiator.xResult;
            }));
    }

    getUserProfile(): Observable<BaseModel<GetUserProfileModel>> {
        return this.get<BaseModel<GetUserProfileModel>>(this.userApiUrl + '/Profile').pipe(map(res => {
            console.log('result for getting user profile:', res.xResult.userAddresses)
            this.onGetProfileInfo.next(res.xResult);
            return res;
        }));
    }

    editUserProfile(editUser: EditUserProfileModel) {
        return this.update<boolean>(this.userApiUrl + '/EditProfile', editUser).pipe(tap(
            (res) => {
                if (res) {
                    this.getUserProfile().subscribe();
                    this.toastrService.success("Change successfully");
                }
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }

    changeNegotiatorState(request: EditNegotiatorUserVM) {
        return this.update<boolean>(this.userApiUrl + '/negotiator', request).pipe(tap(
            () => {
                this.onUpdateItem.next()
                this.toastrService.success("User has been Edited successfully");
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        ));
    }

    changePasswordbyAdmin(request: ChangePasswordUserVM) {
        return this.update<boolean>(this.userApiUrl + '/ChangePasswordByAdmin', request).pipe(tap(
            () => {
                this.onUpdateItem.next()
                this.toastrService.success("Password Changed successfully");
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        ));
    }

    changeUserFreePayment(request: EditUserFreePaymentVM) {
        return this.update<boolean>(this.userApiUrl + '/FreePayment', request).pipe(tap(
            () => {
                this.onUpdateItem.next()
                this.toastrService.success("User has been Edited successfully");
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        ));
    }

    changeUserFreeShipping(request: EditUserFreeShippingVM) {
        return this.update<boolean>(this.userApiUrl + '/FreeShipping', request).pipe(tap(
            () => {
                this.onUpdateItem.next()
                this.toastrService.success("User has been Edited successfully");
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        ));
    }

    getUserById(id: number): Observable<GetUser> {
        return this.getById(this.userApiUrl, +id)
            .pipe(map((response: GetUser) => {
                console.log("Get User by ID: ", response);
                return response;
            },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                })
            );
    }

    prepareData(): Observable<PrepareData> {
        return this.get<BaseModel<PrepareData>>(this.userApiUrl + '/PrepareData').pipe(map(res => {
            return res.xResult;
        }));
    }

    editUser(editUser: EditUser) {
        return this.update<boolean>(this.userApiUrl, editUser).pipe(tap(res => {
            if (res) {
                this.getUserProfile().subscribe();
                this.toastrService.success("User has been Edited successfully");
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        ));
    }

    registerUser(user: UserRegister) {
        return this.create<boolean>(this.userApiUrl, user)
            .pipe(tap(
                () => {
                    this.onUpdateItem.next()
                    this.toastrService.success('User has been added successful.');
                },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }
            ));
    }

    changeActivity(id: number) {
        return this.update<boolean>(this.userApiUrl + '/ChangeActivity/' + id)
            .pipe(tap(
                () => {
                    this.onUpdateItem.next();
                    this.toastrService.success('User has been Edited successfully.');
                },
                (error: HttpManagerErrorResponse) => {
                    this.showAllErrorMessages(error);
                }));
    }

    changeProfileImage(editUser: EditProfileImage) {
        return this.update<boolean>(this.userApiUrl + '/EditUserProfileImage', editUser).pipe(tap(res => {
            if (res) {
                this.onUpdateItem.next()
                this.toastrService.success("Image Updated successfully");
            }
        },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }
        ));
    }

    /*editUserProfile(editUser: EditUserProfileModel) {
        return this.update<boolean>(this.userApiUrl + '/EditProfile', editUser).pipe(tap(
            (res) => {
                if (res) {
                    this.getUserProfile().subscribe();
                    this.toastrService.success("Change successfully");
                }
            },
            (error: HttpManagerErrorResponse) => {
                this.showAllErrorMessages(error);
            }));
    }*/

    sendContactUsEmailPage(contactForm: ContactUsForm) {
        
        const url = this.contactUsApiUrl + '/postData';
       
        return this.create<boolean>(url, contactForm)
            .subscribe(
                response => {
                    this.toastrService.success('Inquiry submitted successfully.');
                },
                error => {
                    console.error('Error:', error);
                }
            );        
    }


    showAllErrorMessages(error: HttpManagerErrorResponse): void {
        error.msg.forEach((msg: string) => {
            this.toastrService.error(msg);
        });
    }
}