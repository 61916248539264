<!--footer section -->
<!--<footer [class]="class">
    <div class="footer-main">
        <div class="footer-top">
            <img src="assets/images/icon/Group 5.png" />
        </div>
        <div class="footer-middle">
            <div class="container-fluid">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3">
                            <div class="footer-content-main">
                                <h3>COMPANY</h3>
                                <ul>
                                    
                                    <li><a href="/pages/terms">Terms and Condition</a></li>
                                    <li><a href="/pages/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/pages/california-privacy-policy">California Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="footer-content-main">
                                <h3>CONTACT</h3>
                                <ul>
                                    
                                    <li class="mt-3">Business Center Dr., Irvine,CA 92612, USA</li>
                                    <li class="mt-4"><a href="mailto:cs@ecommerce.com">cs&#64;ecommerce.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="footer-end">
            <p style="font-size:16px">These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.</p>
            <p>© 2024 Ecommerce. All Rights Reserved.</p>

        </div>
    </div>-->
    <!--<section class="section-b-space light-layout">
      <div class="container-fluid">
        <div class="row footer-theme justify-content-center">
          <div class="col-xl-7">
            <div class="sub-title">
              <div class="footer-title">
                <h4 class="">contact us</h4>
              </div>
              <div class="footer-contant">
                <div class="mt-3 d-flex flex-column flex-lg-row justify-content-start">
                  <div class="align-items-center my-1 my-lg-0 mr-4"><i class="mr-1 fa fa-map-marker"></i>5515 Daniels St, Chino, California 91710</div>
                  <div class="align-items-center my-1 my-lg-0 mr-4"><i class="mr-1 fa fa-phone"></i>Call Us: (909) 591-7978</div>
                  <div class="align-items-center my-1 my-lg-0 mr-4"><i class="mr-1 fa fa-envelope"></i>Email Us: hello@lucentinc.com<a></a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5">
            <div class="sub-title mt-3 mt-xl-0">
              <div class="footer-title">
                <h4 class="">Social Media</h4>
              </div>
            <div class="footer-social mt-2">
              <ul class="d-flex">
                <li>
                  <a target="_blank" href="https://business.facebook.com/vbyvye/"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a target="_blank" href="mailto:media@vbyvye.com"><i class="fa fa-envelope" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/vbyvye"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/vbyvye/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="sub-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="footer-end d-flex justify-content-center">
              <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} © All material and moral rights
                of this website belong to PERSONAGE CARE.</p>
            </div>
          </div>
        </div>
      </div>
    </div>-->
<!--</footer>-->
<!--footer section end -->


<div class="container-fluid">
    <footer class="footer">
        <div class="row">
            <div class="col-md-12 footer-copyright text-center">
                <p class="mb-0">Copyright {{ currentYear }} &copy; E-Commerce. All rights reserved.</p>
                <!--<p class="mb-0">{{(setting$ )?.general?.copyright ?  (setting$)?.general?.copyright : '© Pixelstrap'}}</p>-->
            </div>
        </div>
    </footer>
</div>