<div class="ng-star-inserted" *ngIf="IsCategorySection===0">
    <div class="category-menu section-b-space">
        <h3>{{'categories'|translate}}</h3>
        <ul *ngFor="let category of subCategoryList">
            <li>
                <div class="category-list">
                    <img [src]="category.subCategoryImage? 'data:image/png;base64,' + category.subCategoryImage : 'assets/User-section/images/category.png'"
                         [alt]="category.subCategoryName">
                    <h5>
                        <a href="javascript:void(0)" [routerLink]="['/products']" [queryParams]="{ category: category.subCategoryName }" for="category.subCategoryID">{{ category.subCategoryName }}</a>
                    </h5>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="ng-star-inserted" *ngIf="IsCategorySection===1">
    <div class="category-menu" style="padding: unset !important">
        <ul *ngFor="let category of filterModel">
            <li *ngFor="let subCategory of category.subCategories">
                <div class="category-list">
                    <h5>
                        <a href="javascript:void(0)" [routerLink]="['/prod']" [queryParams]="{ category: subCategory.subCategoryName }" for="subCategory.subCategoryID">{{ subCategory.subCategoryName }}</a>
                    </h5>
                </div>
            </li>
        </ul>
    </div>
</div>

<!-- Input Category Dropdown -->
<app-button [class]="'btn location-button'"
            [id]="'category_btn'"
            [spinner]="false"
            [type]="'button'"
            *ngIf="IsCategorySection===2">
    <select class="form-select locat-name" (change)="onCategorySelected($event)">
        <option>{{ 'all_category'| translate}}</option>
        <option *ngFor="let category of filterModel" [value]="category.categoryName">
            {{ category.categoryName }}
        </option>
    </select>
</app-button>

<!--- Category Style Dropdown -->
<div *ngIf="IsCategorySection===3">
    <ul class="category-list">
        <li class="onhover-category-list" *ngFor="let category of subCategoryList">
            <a href="javascript:void(0)" class="category-name" [routerLink]="['/products']" [queryParams]="{ category: category.subCategoryName }">
                <img [src]="category.subCategoryImage? 'data:image/png;base64,' + category.subCategoryImage : 'assets/User-section/images/category.png'">
                <h6>{{ category.subCategoryName }}</h6>
            </a>
        </li>
    </ul>
</div>

<!-- Category Style Horizontal -->
<!--<div class="product-wrapper no-arrow" [class]="'category-slider'" *ngIf="IsCategorySection===4">
    <owl-carousel-o [options]="sliderOption">
        <ng-template carouselSlide *ngFor="let category of subCategoryList">
            <a href="javascript:void(0)" class="category-box category-dark" (click)="onCategorySelected(category.subCategoryName)">
                <img [src]="'assets/User-section/images/shape.png'" class="background-image" alt="shape">
                <img [src]="category.subCategoryImage? 'data:image/png;base64,' + category.subCategoryImage : 'assets/User-section/images/category.png'"
                     [alt]="category.subCategoryImage">
                <h5>{{ category.subCategoryName }}</h5>
            </a>
        </ng-template>
    </owl-carousel-o>
</div>-->

<div class="product-wrapper no-arrow" [class]="'category-slider'" *ngIf="IsCategorySection===4">
    <owl-carousel-o [options]="sliderOption">
        <ng-template carouselSlide *ngFor="let category of filterModel">
            <a href="javascript:void(0)" class="category-box category-dark" (click)="onCategorySelected(category.categoryName)">
                <img [src]="'assets/User-section/images/shape.png'" class="background-image" alt="shape">
                <img [src]="category.categoryImage ? 'data:image/png;base64,' + category.categoryImage : 'https://themes.pixelstrap.com/fastkart/assets/svg/1/vegetable.svg'" alt="">
                <h5>{{ category.categoryName }}</h5>
            </a>
        </ng-template>
    </owl-carousel-o>
</div>


<!-- Category Style Horizontal -->
<!--<div class="product-wrapper no-arrow" [class]="'category-slider'" *ngIf="IsCategorySection===5">
    <owl-carousel-o [options]="sliderOption">
        <ng-template carouselSlide *ngFor="let category of subCategoryList">
            <a [routerLink]="['/products']" [queryParams]="{ category: category.subCategoryName }" class="category-box category-dark">
                <img [src]="'assets/User-section/images/shape.png'" class="background-image" alt="shape">
                <img [src]="category.subCategoryImage? 'data:image/png;base64,' + category.subCategoryImage : 'assets/User-section/images/category.png'"
                     [alt]="category.subCategoryImage">
                <h5>{{ category.subCategoryName }}</h5>
            </a>
        </ng-template>
    </owl-carousel-o>
</div>-->
<!-- New Category Style Horizontal -->
<div class="product-wrapper no-arrow" [class]="'category-slider'" *ngIf="IsCategorySection===5">
    <owl-carousel-o [options]="sliderOption">
        <ng-template carouselSlide *ngFor="let category of filterModel">
            <a [routerLink]="['/products']" [queryParams]="{ category: category.categoryName }" class="category-box category-dark">
                <img [src]="'assets/User-section/images/shape.png'" class="background-image" alt="shape">
                <img [src]="category.categoryImage ? 'data:image/png;base64,' + category.categoryImage : 'https://themes.pixelstrap.com/fastkart/assets/svg/1/vegetable.svg'" alt="">
                <h5>{{ category.categoryName }}</h5>
            </a>
        </ng-template>
    </owl-carousel-o>
</div>





<!-- New Input Category Dropdown With Nested Dropdown -->
<div class="dropdown-container" *ngIf="IsCategorySection === 6">
    <!-- Categories with SubCategories -->
    <div *ngIf="IsSubCategoryShowing">
        <button class="dropdown-toggle" (click)="toggleDropdown()">
            {{ selectedCategory || 'All Category' }}
        </button>
        <div class="dropdown-menu" *ngIf="isDropdownOpen">
            <ul class="category-list">
                <ng-container *ngFor="let category of filterModel; let i = index">
                    <li class="category-item">
                        <div class="category-header">
                            <a (click)="onCategoryClick(category)" style=" width: 100%;">
                                <span>{{ category.categoryName }}</span>
                            </a>
                            <i *ngIf="category.subCategories.length > 0" [ngClass]="{'fa-chevron-down': !showSubcategories[i], 'fa-chevron-up': showSubcategories[i]}" class="fa" (click)="toggleSubcategories(i)"></i>
                        </div>
                        <ul class="subcategory-list" *ngIf="showSubcategories[i]">
                            <ng-container *ngFor="let subcategory of category.subCategories">
                                <li>
                                    <a href="javascript:void(0)" [routerLink]="['/products']" [queryParams]="{ category : category.categoryName ,subCategory: subcategory.subCategoryName }">
                                        {{ subcategory.subCategoryName }}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>


    <!-- Categories without SubCategories -->
    <div *ngIf="!IsSubCategoryShowing">
        <button class="dropdown-toggle" (click)="toggleDropdown()">
            {{ selectedCategory || 'All Category' }}
        </button>
        <div class="dropdown-menu" *ngIf="isDropdownOpen">
            <ul class="category-list">
                <ng-container *ngFor="let category of filterModel; let i = index">
                    <li class="category-item">
                        <div class="category-header">
                            <a [routerLink]="['/products']" [queryParams]="{ category: category.categoryName }" (click)="onCategorySelected(category.categoryName)">
                                <span>{{ category.categoryName }}</span>
                            </a>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

</div>


<!-- New Category Style Dropdown -->
<div *ngIf="IsCategorySection === 7">
    <!-- Categories with SubCategories -->
    <div *ngIf="IsSubCategoryShowing">

        <ul class="category-list">
            <ng-container *ngFor="let category of filterModel; let i = index">
                <li class="onhover-category-list">
                    <a href="javascript:void(0)" class="category-name"
                       (click)="onCategoryClick(category)">
                        <img [src]="category.categoryImage ? 'data:image/png;base64,' + category.categoryImage : 'https://themes.pixelstrap.com/fastkart/assets/svg/1/vegetable.svg'" alt="">
                        <h6>{{ category.categoryName }}</h6>
                        <i *ngIf="category.subCategories.length > 0" class="fa-chevron-right fa"></i>
                    </a>
                    <div *ngIf="category.subCategories.length > 0" class="onhover-category-box" style="width: 300px;">
                        <div class="list-1">
                                <ng-container *ngFor="let subcategory of category.subCategories">
                                
                                        <a style="margin-bottom:10px;" href="javascript:void(0)" [routerLink]="['/products']"
                                           [queryParams]="{ category : category.categoryName ,subCategory: subcategory.subCategoryName }">
                                            <img [src]="subcategory.subCategoryImage ? 'data:image/png;base64,' + subcategory.subCategoryImage : 'assets/User-section/images/category.png'" alt="">
                                            {{ subcategory.subCategoryName }}
                                        </a>
                                </ng-container>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>

    <!-- Categories without SubCategories -->
    <div *ngIf="!IsSubCategoryShowing">
        <ul class="category-list">
            <ng-container *ngFor="let category of filterModel; let i = index">
                <li class="onhover-category-list">
                    <a href="javascript:void(0)" class="category-name" [routerLink]="['/products']"
                       [queryParams]="{ category: category.categoryName }">
                        <img [src]="category.categoryImage ? 'data:image/png;base64,' + category.categoryImage : 'https://themes.pixelstrap.com/fastkart/assets/svg/1/vegetable.svg'" alt="">
                        <h6>{{ category.categoryName }}</h6>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>


    <!-- Old Vertical Category Style Dropdown With only categories-->
    <!--<div class="ng-star-inserted" *ngIf="IsCategorySection===8">
        <div class="category-menu section-b-space">
            <h3>{{ 'categories'| translate}}</h3>

            <ng-container *ngFor="let category of filterModel">

                <h4 style="margin: 10px 0; padding: 5px 0; font-weight: bold">
                    <a href="javascript:void(0)" [routerLink]="['/products']" [queryParams]="{ category: category.categoryName }">{{ category.categoryName }}</a>
                    <i>**</i>
                </h4>

                <ul style="margin-left: 25px;" *ngFor="let subcategory of category.subCategories">
                    <li>
                        <div class="category-list">
                            <img [src]="subcategory.subCategoryImage? 'data:image/png;base64,' + subcategory.subCategoryImage : 'assets/User-section/images/category.png'"
                                 [alt]="subcategory.subCategoryName">
                            <h5>
                            <a href="javascript:void(0)" [routerLink]="['/products']" [queryParams]="{ SubCategory: subcategory.subCategoryName }" for="subcategory.subCategoryID">{{ subcategory.subCategoryName }}</a>
                            </h5>
                        </div>
                    </li>
                </ul>
            </ng-container>
        </div>
    </div>-->
    <!-- New Vertical Category Style Dropdown With only categories-->
    <!--<div class="ng-star-inserted" *ngIf="IsCategorySection === 18">
        <div class="category-menu section-b-space">
            <h3>{{ 'Categories' | translate }}</h3>

            <ng-container *ngFor="let category of filterModel; let i = index">-->
                <!--<img src="https://themes.pixelstrap.com/fastkart/assets/svg/1/vegetable.svg" alt="">-->
                <!--<h4 style="margin: 10px 0; padding: 5px 0; font-weight: bold">
                    <a href="javascript:void(0)" [routerLink]="['/products']" [queryParams]="{ category: category.categoryName }">
                        {{ category.categoryName }}
                    </a>
                </h4>
            </ng-container>
        </div>
    </div>-->


<!-- New Vertical Category Style Dropdown With Nested Dropdown for Sub Categories-->
<div class="ng-star-inserted" *ngIf="IsCategorySection === 8">

    <!-- Categories with SubCategories -->
    <div *ngIf="IsSubCategoryShowing">

        <div class="category-menu section-b-space">
            <h3>{{ 'Categories' | translate }}</h3>

            <ng-container *ngFor="let category of filterModel; let i = index">
                <div style="width: 100%; display: flex; justify-content: space-between;">
                    <h5 style="margin: 10px 0; padding: 5px 0;">
                        <img [src]="category.categoryImage ? 'data:image/png;base64,' + category.categoryImage : 'https://themes.pixelstrap.com/fastkart/assets/svg/1/vegetable.svg'"alt="">
                        <a href="javascript:void(0)" (click)="onCategoryClick(category)">
                            {{ category.categoryName }}
                        </a>
                    </h5>
                    <i *ngIf="category.subCategories.length > 0" [ngClass]="{'fa-chevron-down': !showSubcategories[i], 'fa-chevron-up': showSubcategories[i]}" class="fa" style="align-content: center; " (click)="toggleSubcategories(i)"></i>
                </div>
                <ul *ngIf="showSubcategories[i]" style="margin-left: 25px;">
                    <li *ngFor="let subcategory of category.subCategories">
                        <div class="category-list">
                            <img [src]="subcategory.subCategoryImage ? 'data:image/png;base64,' + subcategory.subCategoryImage : 'assets/User-section/images/category.png'"
                                 [alt]="subcategory.subCategoryName">
                            <h5>
                                <a href="javascript:void(0)" [routerLink]="['/products']" [queryParams]="{ category : category.categoryName, subCategory: subcategory.subCategoryName }" for="subcategory.subCategoryID">
                                    {{ subcategory.subCategoryName }}
                                </a>
                            </h5>
                        </div>
                    </li>
                </ul>
            </ng-container>
        </div>
    </div>

    <!-- Categories without SubCategories -->
    <div *ngIf="!IsSubCategoryShowing">
        <div class="category-menu section-b-space">
            <h3>{{ 'categories' | translate }}</h3>

            <ng-container *ngFor="let category of filterModel; let i = index">
                <!---->
                <h5 style="margin: 10px 0; padding: 5px 0;">
                    <<img [src]="category.categoryImage ? 'data:image/png;base64,' + category.categoryImage : 'https://themes.pixelstrap.com/fastkart/assets/svg/1/vegetable.svg'" alt="">
                    <a href="javascript:void(0)" [routerLink]="['/products']" [queryParams]="{ category: category.categoryName }">
                        {{ category.categoryName }}
                    </a>
                </h5>
            </ng-container>
        </div>
    </div>
</div>

