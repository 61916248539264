<app-modal [title]="title" [cancelText]="'cancel'" [confirmText]="'Save setting'" (cancel)="onModalCanceled()"
           (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">
    <form class="theme-form" [formGroup]="form" (keydown.enter)="onModalConfirmed()">
        <div class="form-row">
            <div class="col-md-3 mb-3">
                <label for="Status">Status *</label>
                <select [(ngModel)]="selectedOption" class="form-control" [formControl]="form.controls['isActive']" (change)="onStateChange()">
                    <option [value]="true">Active</option>
                    <option [value]="false">InActive</option>
                </select>
            </div>
            <div class="col-md-12 mb-3" hidden>
                <label for="serviceType">serviceType *</label>
                <input type="text" class="form-control" id="serviceType" placeholder="Please serviceType" [formControl]="form.controls['serviceType']" [value]="form.get('serviceType').value"
                       [ngClass]="form.get('serviceType').hasError('required') && form.get('serviceType').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('serviceType').hasError('required') && form.get('serviceType').touched"
                      class="mx-0 invalid-feedback d-flex">
                    serviceType is Required.
                </span>
            </div>
            <div class="col-md-12">
                <h3 style="color:white">Fedex Configuration Setting</h3>
            </div>
            <div class="col-md-12 mb-3">
                <label for="accountNumber">Account Number *</label>
                <input type="text" class="form-control" id="accountNumber" placeholder="Please Account Number" [formControl]="form.controls['accountNumber']" [value]="form.get('accountNumber').value"
                       [ngClass]="form.get('accountNumber').hasError('required') && form.get('accountNumber').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('accountNumber').hasError('required') && form.get('accountNumber').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Account Number is Required.
                </span>
            </div>

            <div class="col-md-12 mb-3">
                <label for="clientId">Client ID *</label>
                <input type="text" class="form-control" id="clientId" placeholder="Please Enter Client ID" [formControl]="form.controls['clientId']" [value]="form.get('clientId').value"
                       [ngClass]="form.get('clientId').hasError('required') && form.get('clientId').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('clientId').hasError('required') && form.get('clientId').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Client ID is required.
                </span>
            </div>
            <div class="col-md-12 mb-3">
                <label for="clientSecret">Client Secret *</label>
                <input type="text" class="form-control" id="clientSecret" placeholder="Please Enter Client Secret" [formControl]="form.controls['clientSecret']" [value]="form.get('clientSecret').value"
                       [ngClass]="form.get('clientSecret').hasError('required') && form.get('clientSecret').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('clientSecret').hasError('required') && form.get('clientSecret').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Client Secret Key is required.
                </span>
            </div>

            <div class="col-md-12 mb-3">
                <label for="authorization">Authorization End Point *</label>
                <input type="text" class="form-control" id="authorization" placeholder="Please Enter Authorization End Point" [formControl]="form.controls['authorization']" [value]="form.get('authorization').value"
                       [ngClass]="form.get('authorization').hasError('required') && form.get('authorization').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('authorization').hasError('required') && form.get('authorization').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Authorization End Point is required.
                </span>
            </div>
            <div class="col-md-6 mb-3">
                <label for="ship">Ship End Point *</label>
                <input type="text" class="form-control" id="ship" placeholder="Please Enter Ship End Point" [formControl]="form.controls['ship']" [value]="form.get('ship').value"
                       [ngClass]="form.get('ship').hasError('required') && form.get('ship').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('ship').hasError('required') && form.get('ship').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Ship End Point is required.
                </span>
            </div>
            <div class="col-md-6 mb-3">
                <label for="track">Track End Point *</label>
                <input type="text" class="form-control" id="track" placeholder="Please Enter Track End Point" [formControl]="form.controls['track']" [value]="form.get('track').value"
                       [ngClass]="form.get('track').hasError('required') && form.get('track').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('track').hasError('required') && form.get('track').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Track End Point is required.
                </span>
            </div>
            <div class="col-md-6 mb-3">
                <label for="rateAndTransitTimes">Rate And Transit Times *</label>
                <input type="text" class="form-control" id="rateAndTransitTimes" placeholder="Please Enter Rate And Transit Times End Point" [formControl]="form.controls['rateAndTransitTimes']" [value]="form.get('rateAndTransitTimes').value"
                       [ngClass]="form.get('rateAndTransitTimes').hasError('required') && form.get('rateAndTransitTimes').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('rateAndTransitTimes').hasError('required') && form.get('rateAndTransitTimes').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Rate And Transit Times is required.
                </span>
            </div>
            <div class="col-md-6 mb-3">
                <label for="cancel">Cancel End Point *</label>
                <input type="text" class="form-control" id="cancel" placeholder="Please Enter Cancel End Point" [formControl]="form.controls['cancel']" [value]="form.get('cancel').value"
                       [ngClass]="form.get('cancel').hasError('required') && form.get('cancel').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('cancel').hasError('required') && form.get('cancel').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Cancel End Point is required.
                </span>
            </div>
            <div class="col-md-12">
                <h3 style="color:white">Shipper Address</h3>
            </div>
            <div class="col-md-6 mb-3">
                <label for="shipperName">Shipper Name *</label>
                <input type="text" class="form-control" id="shipperName" placeholder="Please Enter Shipper Name" [formControl]="form.controls['shipperName']" [value]="form.get('shipperName').value"
                       [ngClass]="form.get('shipperName').hasError('required') && form.get('shipperName').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('shipperName').hasError('required') && form.get('shipperName').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Shipper Name is required.
                </span>
            </div>
            <div class="col-md-6 mb-3">
                <label for="streetLines">Street Lines *</label>
                <input type="text" class="form-control" id="streetLines" placeholder="Please Enter Street Lines" [formControl]="form.controls['streetLines']" [value]="form.get('streetLines').value"
                       [ngClass]="form.get('streetLines').hasError('required') && form.get('streetLines').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('streetLines').hasError('required') && form.get('streetLines').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Street Lines are required.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="phoneNumber">Phone Number *</label>
                <input type="text" class="form-control" id="phoneNumber" placeholder="Please Enter Phone Number" [formControl]="form.controls['phoneNumber']" [value]="form.get('phoneNumber').value"
                       [ngClass]="form.get('phoneNumber').hasError('required') && form.get('phoneNumber').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('phoneNumber').hasError('required') && form.get('phoneNumber').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Phone Number is required.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="companyName">Company Name *</label>
                <input type="text" class="form-control" id="companyName" placeholder="Please Enter Company Name" [formControl]="form.controls['companyName']" [value]="form.get('companyName').value"
                       [ngClass]="form.get('companyName').hasError('required') && form.get('companyName').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('companyName').hasError('required') && form.get('companyName').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Company Name is required.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="city">City *</label>
                <input type="text" class="form-control" id="city" placeholder="Please Enter City" [formControl]="form.controls['city']" [value]="form.get('city').value"
                       [ngClass]="form.get('city').hasError('required') && form.get('city').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('city').hasError('required') && form.get('city').touched"
                      class="mx-0 invalid-feedback d-flex">
                    City.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="countryCode">Country Code *</label>
                <input type="text" class="form-control" id="countryCode" placeholder="Please Enter Country Code" [formControl]="form.controls['countryCode']" [value]="form.get('countryCode').value"
                       [ngClass]="form.get('countryCode').hasError('required') && form.get('countryCode').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('countryCode').hasError('required') && form.get('countryCode').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Country Code is required.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="state">State *</label>
                <input type="text" class="form-control" id="state" placeholder="Please Enter State" [formControl]="form.controls['state']" [value]="form.get('state').value"
                       [ngClass]="form.get('state').hasError('required') && form.get('state').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('state').hasError('required') && form.get('state').touched"
                      class="mx-0 invalid-feedback d-flex">
                    State is required.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="postalCode">Postal Code *</label>
                <input type="text" class="form-control" id="postalCode" placeholder="Please Enter Postal Code" [formControl]="form.controls['postalCode']" [value]="form.get('postalCode').value"
                       [ngClass]="form.get('postalCode').hasError('required') && form.get('postalCode').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('postalCode').hasError('required') && form.get('postalCode').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Postal Code is required.
                </span>
            </div>
            <div class="col-md-12">
                <h3 style="color:white">Shipping Box Configuration</h3>
            </div>
            <div class="col-md-4 mb-3">
                <label for="weightUnits">Weight Units *</label>
                <input type="text" class="form-control" id="weightUnits" placeholder="Please Enter Weight Units" [formControl]="form.controls['weightUnits']" [value]="form.get('weightUnits').value"
                       [ngClass]="form.get('weightUnits').hasError('required') && form.get('weightUnits').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('weightUnits').hasError('required') && form.get('weightUnits').touched"
                      class="mx-0 invalid-feedback d-flex">
                    Weight Units is required.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="weightOfEachGlassInKg">Weight Of Each Glass *</label>
                <input type="text" class="form-control" id="weightOfEachGlassInKg" placeholder="Please Enter Weight Of Each Glass" [formControl]="form.controls['weightOfEachGlassInKg']" [value]="form.get('weightOfEachGlassInKg').value"
                       [ngClass]="form.get('weightOfEachGlassInKg').hasError('required') && form.get('weightOfEachGlassInKg').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('weightOfEachGlassInKg').hasError('required') && form.get('weightOfEachGlassInKg').touched"
                      class="mx-0 invalid-feedback d-flex">
                    This is required.
                </span>
            </div>
            <div class="col-md-4 mb-3">
                <label for="numberOfGlassInEachBox">Number Of Glass In Each Box *</label>
                <input type="text" class="form-control" id="numberOfGlassInEachBox" placeholder="Please Enter Number Of Glass In Each Box" [formControl]="form.controls['numberOfGlassInEachBox']" [value]="form.get('numberOfGlassInEachBox').value"
                       [ngClass]="form.get('numberOfGlassInEachBox').hasError('required') && form.get('numberOfGlassInEachBox').touched ? 'is-invalid': ''" />
                <span *ngIf="form.get('numberOfGlassInEachBox').hasError('required') && form.get('numberOfGlassInEachBox').touched"
                      class="mx-0 invalid-feedback d-flex">
                    This is required.
                </span>
            </div>
        </div>
    </form>
</app-modal>