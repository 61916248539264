export class EditUserProfileModel {

    firstName: string;

    lastName: string;

    email: string;

    phoneNumber: string;
    
}