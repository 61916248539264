import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { ModalDismissReasons, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CouponService } from '../../shared/services/coupon.service';
import { HttpManagerErrorResponse } from '../../core/helper/http-client-manager';
import { Coupon, CouponsSearchParamsModel } from '../../shared/classes/promotion/coupon.model';
import { CouponModalComponent } from './coupon-modal/coupon-modal.component';
import { NgFor, NgIf } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';

import { GridSkeletonLoaderComponent } from '../shared/grid-skeleton-loader/grid-skeleton-loader.component';
import { NoItemFoundComponent } from '../../shared/components/no-item-found/no-item-found.component';
import { LoaderComponent } from '../../Store-Section/shared/components/widgets/loader/loader.component';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
    styleUrl: './coupon.component.scss',
    standalone: true,
    imports: [NgIf, DataTablesModule, NgFor, NgbTooltipModule, GridSkeletonLoaderComponent, NoItemFoundComponent, LoaderComponent]
})

export class CouponComponent extends BaseComponent implements OnInit {

    isLoading: boolean = true;
    public closeResult: string;
    coupons: Coupon[] = []

    dtOptions: DataTables.Settings = {
        columnDefs: [
            { orderable: false, targets: [0, 1] }
        ],
        scrollX: true,
    };

    constructor(
        private modalService: NgbModal,
        private toastrService: ToastrService,
        private couponService: CouponService
    ) { super(); }

    ngOnInit(): void {
        this.getCouponslist();

        this.couponService.onUpdateItem
            .pipe(this.takeUntilDestroy())
            .subscribe(res => {
                this.getCouponslist();
            })
    }

    getCouponslist() {
        const getAllRequest = Object.assign(new CouponsSearchParamsModel(), { page: 0, pageSize: 0 });
        this.couponService.getCoupons(getAllRequest)
            .pipe(this.takeUntilDestroy())
            .subscribe((res) => {
                console.log('Coupons list response:', res);
                this.resetDataTable(res.xData)
            },
                (error: HttpManagerErrorResponse) => { },
                () => this.isLoading = false);
    }
    openCouponModal(coupon) {
        const modalRef = this.modalService.open(CouponModalComponent, {
            size: 'lg',
            ariaLabelledBy: 'size-modal',
            centered: true,
            windowClass: 'SizeChart'
        });
        console.log("Coupon before modal open :", coupon)
        if (coupon) {

            let productIds = null;
            let customerIds = null;

            if (coupon.couponsVariations && coupon.couponsVariations.length > 0) {
                productIds = coupon.couponsVariations[0].productID;
                customerIds = coupon.couponsVariations[0].customerID;
            }
            modalRef.componentInstance.addeditcoupon = {
                id: coupon.id,
                title: coupon.title,
                couponCode: coupon.couponCode,
                couponType: coupon.couponType,
                discountedPrice: coupon.discountedPrice,
                discountPercentage: coupon.discountPercentage,
                startDate: coupon.startDate,
                endDate: coupon.endDate,
                remainingUsage: coupon.remainingUsage,
                isActive: coupon.isActive,
                productIds: productIds,
                customerIds: customerIds
            };

            console.log("Coupon after modal open :", modalRef.componentInstance.addeditcoupon)
        }
        modalRef.result.then((result) => {
            `Result ${result}`
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    resetDataTable(res) {
        setTimeout(() => this.coupons = res, 0.0001);
    }

    removeCoupon(id) {
        this.couponService.removeCoupons(id);
    }
}
