<div class="container-fluid">
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="title-header">
                            <div class="d-flex align-items-center">
                                <h5>{{ 'Coupons' | translate}}</h5>
                            </div>

                            <!--(click)="openCouponModal(null)"-->
                            <div button>
                                <a class="align-items-center btn btn-theme d-flex" [routerLink]="['./add']">
                                    <i class="ri-add-line"></i>
                                    {{'Add New Coupon' | translate }}
                                </a>
                            </div>
                        </div>


                        <div class="show-box">
                            <div class="selection-box">
                                <label>{{ 'Show' | translate }} :</label>
                                <select class="form-control" (change)="onChangeTable($event, 'paginate')">
                                    <option *ngFor="let item of rows" [value]="item">{{item}}</option>
                                </select>
                                <label>{{ 'Items per page' | translate }}</label>
                            </div>

                            <div class="table-search">
                                <label for="role-search" class="form-label">{{ 'Search' | translate }} :</label>
                                <input type="search" class="form-control" id="role-search" [formControl]="term">
                            </div>

                        </div>

                        <div>
                            <!--*ngIf="coupons?.length else noResult"-->
                            <div class="table-responsive datatable-wrapper border-table">
                                <table class="table all-package theme-table no-footer">
                                    <thead>
                                        <tr>
                                            <!--<th scope="col" class="sm-width">#</th>-->
                                            <th scope="col" class="sm-width" (click)="onChangeTable({ dataField: 'title' }, 'sort')">
                                                Title
                                                <div class="filter-arrow">
                                                    <div>
                                                        <i [ngClass]="getSortClass('title')"></i>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" class="sm-width" (click)="onChangeTable({ dataField: 'couponCode' }, 'sort')">
                                                Code
                                                <div class="filter-arrow">
                                                    <div>
                                                        <i [ngClass]="getSortClass('couponCode')"></i>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" class="sm-width" (click)="onChangeTable({ dataField: 'couponType' }, 'sort')">
                                                Coupon Type
                                                <div class="filter-arrow">
                                                    <div>
                                                        <i [ngClass]="getSortClass('couponType')"></i>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" class="sm-width">
                                                Discount
                                            </th>
                                            <th scope="col" class="sm-width" (click)="onChangeTable({ dataField: 'startDate' }, 'sort')">
                                                Start Date
                                                <div class="filter-arrow">
                                                    <div>
                                                        <i [ngClass]="getSortClass('startDate')"></i>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" class="sm-width" (click)="onChangeTable({ dataField: 'endDate' }, 'sort')">
                                                End Date
                                                <div class="filter-arrow">
                                                    <div>
                                                        <i [ngClass]="getSortClass('endDate')"></i>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" class="sm-width" (click)="onChangeTable({ dataField: 'remainingUsage' }, 'sort')">
                                                Remaining Usage
                                                <div class="filter-arrow">
                                                    <div>
                                                        <i [ngClass]="getSortClass('remainingUsage')"></i>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col" class="sm-width">
                                                IsActive
                                            </th>
                                            <th scope="col" class="sm-width">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let coupon of paginatedData ; index as i">
                                            <!--<td class="sm-width">{{ (i+1 )}}</td>-->
                                            <td class="sm-width">{{coupon.title}}</td>
                                            <td class="sm-width">{{coupon.couponCode}}</td>
                                            <td class="sm-width">{{coupon.couponType}}</td>
                                            <!--<td>
                        {{ coupon.discountPercentage ? "Percentage" : (coupon.discountedPrice !== null ? "Flat Discount": '') }}
                    </td>-->
                                            <td>
                                                {{ coupon.discountPercentage ? coupon.discountPercentage + '% OFF' : (coupon.discountedPrice !== null ? '$' + coupon.discountedPrice + ' Flat OFF' : '') }}
                                            </td>
                                            <!--<td>
                        {{ coupon.discountPercentage }}
                    </td>-->

                                            <td class="sm-width">{{ coupon.startDate | date: 'dd MMM yyyy hh:mm a' }}</td>
                                            <td class="sm-width">{{ coupon.endDate | date: 'dd MMM yyyy hh:mm a' }}</td>
                                            <td class="sm-width">{{coupon.remainingUsage}}</td>
                                            <td class="sm-width">
                                                <div class="form-check form-switch ps-0">
                                                    <label class="switch switch-sm">
                                                        <input type="checkbox" name="isActive"
                                                               class="ng-untouched ng-pristine"
                                                               (click)="updateStatus(coupon)"
                                                               [checked]="coupon.isActive ? true : false" />
                                                        <span class="switch-state"></span>

                                                    </label>
                                                </div>

                                                <!--{{coupon.isActive ? "Active" : "InActive"}}-->
                                            </td>
                                            <td class="sm-width">
                                                <ul>
                                                    <li>
                                                        <!--(click)="openCouponModal(coupon)"-->
                                                        <a class="cursor-pointer" ngbTooltip="Edit" (click)="editCoupon(coupon)"
                                                           container="body">
                                                            <i class="ri-pencil-line"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="cursor-pointer" (click)="removeCoupon(coupon.id)" ngbTooltip="Delete"
                                                           container="body">
                                                            <i class="ri-delete-bin-line"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <ng-template #noResult>
                                    <!--<app-grid-skeleton-loader *ngIf="isLoading"></app-grid-skeleton-loader>-->
                                    <!-- <app-no-item-found *ngIf="!isLoading"></app-no-item-found>-->
                                    <!--<app-loader [isLoading]="isLoading"></app-loader>-->
                                    <!--<app-loader [loaderClass]="'custom-loader-wrapper blur-bg'"></app-loader>-->

                                    <div class="no-data-added" *ngIf="!isLoading">
                                        <h4>{{ 'No record found' }}</h4>
                                    </div>

                                </ng-template>

                            </div>

                            <div class="loader-wrapper" *ngIf="isLoading">
                                <div>
                                    <div class="loader"></div>
                                    <h3>{{ 'loading' | translate }}</h3>
                                </div>
                            </div>

                        </div>

                        <nav class="custom-pagination">
                            <app-pagination [total]="totalPage" [currentPage]="tableData['page']" [pageSize]="tableData['pageSize']"
                                            (setPage)="onChangeTable($event, 'page')">
                            </app-pagination>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


