export class EditPageModel {
    id: number;

    title: string;

    keyWords: string;

    summary: string;
    
    description: string;

}