import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/core/base.component';
import { DynamicFilterEnum, DynamicFiltersModel, TagData } from '../../classes/dynamic-fIlters';
import { ProductService } from '../../services/product.service';
import { ColorEnum } from './../../classes/color-enum';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, NgStyle, NgClass, TitleCasePipe } from '@angular/common';
@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, NgStyle, NgClass, TitleCasePipe]
})
export class FiltersComponent extends BaseComponent implements OnInit {

  @Input() filterModel: DynamicFiltersModel;
  @Input() IsMultiSelect: boolean = true;
  @Input() IncludeColor: boolean;
  private _tagsData: TagData[];
  @Input()
  public get tagsData(): TagData[] {
    return this._tagsData;
  }
  public set tagsData(value: TagData[]) {
    this._tagsData = value;
    const keys = this._tagsData[0]?.key;
    if ((keys === DynamicFilterEnum.product ||
      keys === DynamicFilterEnum.promotionId ||
      keys === DynamicFilterEnum.linesheetId) &&
      this._tagsData.length === 1)
      this.filterValues = [];
  }
  @Input() filterType: string;

  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();

  dynamicFilter: DynamicFiltersModel[] = [];
  collapse: boolean = true;
  colorEnum = ColorEnum;
  standardFilterName: string;

  filterValues: string[] = [];

  constructor(
    public productService: ProductService) {
    super();
  }

  ngOnInit(): void {
      this.standardFilterName = this.filterModel?.filterName?.replace(' ', '');
      //console.log(this.filterModel);
    this.productService.clearTag.pipe(this.takeUntilDestroy()).subscribe(res => {
      if (res) {
        this.filterValues.splice(res, 1);
        this.tagsData.splice(res, 1);
      } else {
        this.filterValues = [];
      }
    })

    this.tagsData.forEach(x => {
      if (x.key === this.filterType)
        this.filterValues.push(x.value)
    });

    // if (this.IncludeColor && this.filterModel) {
    //   this.filterModel.filterListValue = this.filterModel.filterListValue.filter(x => ColorEnum[x.toLowerCase()] !== undefined)
    // }
  }

  // get filterbyCategory() {
  //   const category = [...new Set(this.dynamicFilter[0].FilterListValue)]
  //   return category;
  // }
  appliedFilter(event) {
    let index = this.filterValues.indexOf(event.target.value);  // checked and unchecked value
    if (event.target.checked)
      this.filterValues.push(event.target.value); // push in array cheked value
    else
      this.filterValues.splice(index, 1);  // removed in array unchecked value  

    const filterValues = this.filterValues.length ? this.filterValues.join(",") : null;
    this.filterChange.emit(filterValues);
  }

  checked(item) {
    if (this.tagsData) {
      if (this.tagsData.find(x => x.value == item && x.key === this.filterType))
        return true;
    }
  }
}
