<app-breadcrumb [title]="'sign_in'|translate" [breadcrumb]="'sign_in'|translate"></app-breadcrumb>
<section class="log-in-section section-b-space">
    <div class="container-fluid-lg w-100">
        <div class="row">
            <div class="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div class="image-contain">
                    <img src="assets/User-section/images/inner-page/sign-up.png" class="img-fluid" alt="sign-up">
                </div>
            </div>
            <div class="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <div class="log-in-box">
                    <div class="log-in-title">
                        <h3>{{ 'welcome_to_our_store' | translate }}</h3>
                        <h4>{{ 'create_new_account' | translate }}</h4>
                    </div>
                    <div class="input-box">
                        <app-modal [title]="title" [confirmText]="'sign_up'|translate" (cancel)="onModalCanceled()" [IshomeComponent]="true"
                                   (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">
                            <form class="row g-4" [formGroup]="form" (keydown.enter)="onModalConfirmed()">
                                <div class="col-12">
                                    <div class="form-floating log-in-form">
                                        <input type="text" class="form-control" id="FirstName" name="FirstName" placeholder="Enter First Name" [formControl]="form.controls['FirstName']"
                                               [ngClass]="form.get('FirstName').hasError('required') && form.get('FirstName').touched ? 'is-invalid': ''" />
                                        <span *ngIf="form.get('FirstName').hasError('required') && form.get('FirstName').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <label for="name">{{ 'first_name' | translate }}</label>
                                        <div *ngIf="form.get('FirstName').touched && form.get('FirstName').errors?.['required']" class="invalid-feedback">{{ 'first_name_is_required' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating log-in-form">
                                        <input type="text" class="form-control" id="LastName" name="LastName" placeholder="Enter Last Name" [formControl]="form.controls['LastName']"
                                               [ngClass]="form.get('LastName').hasError('required') && form.get('LastName').touched ? 'is-invalid': ''" />
                                        <span *ngIf="form.get('LastName').hasError('required') && form.get('LastName').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <label for="name">{{ 'last_name' | translate }}</label>
                                        <div *ngIf="form.get('LastName').touched && form.get('LastName').errors?.['required']" class="invalid-feedback">{{ 'last_name_is_required' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating log-in-form">
                                        <input type="email" class="form-control" id="Email" name="Email" placeholder="Enter Email" [formControl]="form.controls['Email']"
                                               [ngClass]="{'is-invalid': form.get('Email').invalid && form.get('Email').touched }" />
                                        <span *ngIf="form.get('Email').hasError('required') && form.get('Email').touched ||
                                         form.get('Email').hasError('email') && form.get('Email').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <label for="email">{{ 'email_address' | translate}}</label>
                                        <div *ngIf="form.get('Email').touched && form.get('Email').errors?.['required']" class="invalid-feedback">{{ 'email_is_required' | translate }}</div>
                                        <div *ngIf="form.get('Email').touched && form.get('Email').errors?.['email']" class="invalid-feedback">
                                            {{ 'invalid_email' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating log-in-form">
                                        <input type="number" class="form-control" id="PhoneNumber" name="PhoneNumber" placeholder="Enter Phone No" [formControl]="form.controls['PhoneNumber']"
                                               [ngClass]="form.get('PhoneNumber').hasError('required') && form.get('PhoneNumber').touched ? 'is-invalid': ''" />
                                        <span *ngIf="form.get('PhoneNumber').hasError('required') && form.get('PhoneNumber').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <label for="email">{{ 'phone_number' | translate}}</label>
                                        <div *ngIf="form.get('PhoneNumber').touched && form.get('PhoneNumber').errors?.['required']" class="invalid-feedback">{{ 'phone_number_is_required' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating log-in-form">
                                        <input type="password" class="form-control" id="Password" name="Password" placeholder="Enter Password" [formControl]="form.controls['Password']"
                                               [ngClass]="form.get('Password').hasError('required') && form.get('Password').touched ||
                                         form.get('Password').hasError('minlength') && form.get('Password').touched ? 'is-invalid': ''" />
                                        <span *ngIf="form.get('Password').hasError('required') && form.get('Password').touched ||
                                             form.get('Password').hasError('minlength') && form.get('Password').touched"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <label for="password">{{ 'password' | translate }}</label>
                                        <div *ngIf="form.get('Password').touched && form.get('Password').errors?.['required']" class="invalid-feedback">{{ 'password_is_required' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating log-in-form">
                                        <input type="password" class="form-control" id="ConfirmPassword" name="ConfirmPassword" placeholder="Enter Confirm Password" [formControl]="form.controls['ConfirmPassword']"
                                               [ngClass]="form.get('ConfirmPassword').hasError('required') && form.get('ConfirmPassword').touched ||
                                                 form.hasError('mismatch') ? 'is-invalid': ''" />
                                        <span *ngIf="form.get('ConfirmPassword').hasError('required') && form.get('ConfirmPassword').touched ||
                                                 form.hasError('mismatch')"
                                              class="mx-0 invalid-feedback d-flex">
                                        </span>
                                        <label for="password_confirmation">{{ 'password_confirmation' | translate }}</label>

                                        <div *ngIf="form.get('ConfirmPassword').touched && form.get('ConfirmPassword').errors?.['required']" autocomplete="false" class="invalid-feedback">{{ 'confirm_password_is_required' | translate }}</div>
                                        <div *ngIf="form.get('ConfirmPassword').touched && form.get('ConfirmPassword').errors?.['required'] || form.hasError('mismatch')" autocomplete="false" class="invalid-feedback">{{ 'confirm_password_does_not_matched' | translate }}</div>
                                         
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="forgot-box">
                                        <div class="form-check ps-0 m-0 remember-box">
                                            <input class="checkbox_animated check-box" type="checkbox"
                                                   id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault" >
                                                {{ 'i_agree_with' | translate }}
                                                <span>{{ 'terms' | translate }}</span> {{ 'and' | translate }} <span>{{ 'privacy' | translate }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                        </app-modal>
                    </div>

                    <div class="other-log-in">
                        <h6>{{ 'or' | translate }}</h6>
                    </div>

                    <div class="sign-up-box">
                        <h4>{{ 'already_have_an_account' | translate }}?</h4>
                        <a [routerLink]="['/account/login']">{{ 'log_in' | translate }}</a>
                    </div>
                </div>
            </div>
            <div class="col-xxl-7 col-xl-6 col-lg-6"></div>
        </div>
    </div>
</section>































<!--<ng-container *ngIf="form">
    <app-modal [title]="title" [cancelText]="'Cancel'" [confirmText]="confirmText" (cancel)="onModalCanceled()" [IshomeComponent]="true"
               (confirm)="onModalConfirmed()" [disableConfirmBtn]="form.invalid">

        <form class="theme-form" [formGroup]="form" (keydown.enter)="onModalConfirmed()">
            <div class="form-row">
                <div class="col-md-6 mb-3">
                    <label for="FirstName">First Name *</label>
                    <input type="text" class="form-control" id="FirstName" name="FirstName" placeholder="Enter First Name" [formControl]="form.controls['FirstName']"
                           [ngClass]="form.get('FirstName').hasError('required') && form.get('FirstName').touched ? 'is-invalid': ''" />
                    <span *ngIf="form.get('FirstName').hasError('required') && form.get('FirstName').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="LastName">First Name *</label>
                    <input type="text" class="form-control" id="LastName" name="LastName" placeholder="Enter Last Name" [formControl]="form.controls['LastName']"
                           [ngClass]="form.get('LastName').hasError('required') && form.get('LastName').touched ? 'is-invalid': ''" />
                    <span *ngIf="form.get('LastName').hasError('required') && form.get('LastName').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>
                </div>
                <div class="col-md-12 mb-6">
                    <label for="LastName">Email *</label>
                    <input type="email" class="form-control" id="Email" name="Email" placeholder="Enter Email" [formControl]="form.controls['Email']"
                           [ngClass]="{'is-invalid': form.get('Email').invalid && form.get('Email').touched }" />
                    <span *ngIf="form.get('Email').hasError('required') && form.get('Email').touched ||
                          form.get('Email').hasError('email') && form.get('Email').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>
                </div>
                <div class="col-md-12 mb-6">
                    <label for="PhoneNumber">Phone Number *</label>
                    <input type="number" class="form-control" id="PhoneNumber" name="PhoneNumber" placeholder="Enter Phone No" [formControl]="form.controls['PhoneNumber']"
                           [ngClass]="form.get('PhoneNumber').hasError('required') && form.get('PhoneNumber').touched ? 'is-invalid': ''" />
                    <span *ngIf="form.get('PhoneNumber').hasError('required') && form.get('PhoneNumber').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>
                </div>

                <div class="col-md-6 mb-3">
                    <label for="Password">Password *</label>
                    <input type="password" class="form-control" id="Password" name="Password" placeholder="Enter Password" [formControl]="form.controls['Password']"
                           [ngClass]="form.get('Password').hasError('required') && form.get('Password').touched ||
                           form.get('Password').hasError('minlength') && form.get('Password').touched ? 'is-invalid': ''" />
                    <span *ngIf="form.get('Password').hasError('required') && form.get('Password').touched ||
                          form.get('Password').hasError('minlength') && form.get('Password').touched"
                          class="mx-0 invalid-feedback d-flex">
                    </span>

                </div>
                <div class="col-md-6 mb-3">
                    <label for="ConfirmPassword">Confirm Password *</label>
                    <input type="password" class="form-control" id="ConfirmPassword" name="ConfirmPassword" placeholder="Enter Confirm Password" [formControl]="form.controls['ConfirmPassword']"
                           [ngClass]="form.get('ConfirmPassword').hasError('required') && form.get('ConfirmPassword').touched ||
                           form.hasError('mismatch') ? 'is-invalid': ''" />
                    <span *ngIf="form.get('ConfirmPassword').hasError('required') && form.get('ConfirmPassword').touched ||
                          form.hasError('mismatch')"
                          class="mx-0 invalid-feedback d-flex">
                    </span>
                </div>

            </div>
        </form>

    </app-modal>
</ng-container>-->
