<!-- Topbar -->
<div class="header-top" >
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-xxl-3 d-xxl-block d-none">
                <div class="top-left-header">
                    <a class="text-white" href="https://www.apple.com/in/store" target="_blank">
                        <i class="ri-apple-line"></i>
                        <span class="text-white">{{ 'Get the App' }}</span>
                    </a>
                </div>
            </div>
            <div class="col-xxl-6 col-lg-9 d-lg-block d-none pt-1">
                <div class="header-offer">
                    <!--<app-notice></app-notice>-->
                </div>
            </div>
            <div class="col-lg-3">
                <ul class="about-list right-nav-about">
                    <li class="right-nav-list">
                        <div class="dropdown theme-form-select">
                            <app-language></app-language>
                        </div>
                    </li>
                    <li class="right-nav-list">
                        <!--<div class="dropdown theme-form-select select-arrow">-->
                            <!--<app-currency></app-currency>-->
                        <!--</div>-->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


